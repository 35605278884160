import React, { useState, useRef, useEffect, useContext } from "react";
import './style.css'
import styled from 'styled-components';
import 'bootstrap/dist/css/bootstrap.min.css';
import CryptoJS from 'crypto-js';
import { SidebarContext } from "../../AppRoutes";
import { getCategorias, getItens, updateItensOrder, deleteCategoria, updateItemOut, updateItemPrice, deleteItem } from "../../services/api";

import PermissionGate from "../../services/PermissionGate";
import InputMoney from "./InputMoney";
import { useNavigate } from "react-router-dom";
import LeftMenu from "../../components/LeftMenu";
import ConfirmDialog from "../../components/ConfirmDialog";

import { toast } from "react-toastify";
import Loading from "react-loading";
import moment from 'moment'
import { FixedSizeList as List } from 'react-window';
import imageItens from '../../img/imgTeste.png'

import * as FiIcons from 'react-icons/fi'
import * as AiIcons from 'react-icons/ai'
import * as SlIcons from 'react-icons/sl'
import * as FaIcons from 'react-icons/fa'
import { FaFilter } from "react-icons/fa";
import { MdFastfood } from "react-icons/md";
import { MdKeyboardArrowDown } from "react-icons/md";
import { FaRegEdit } from "react-icons/fa";
import { FaRegTrashAlt } from "react-icons/fa";
import { IoLink } from "react-icons/io5";
import { MdDragIndicator } from "react-icons/md";

import ModalEditCategoria from "../../components/ModalEditCategoria";

import { Draggable, Droppable, DragDropContext } from '@hello-pangea/dnd';

const Teste = styled.div`
    display: flex;
    margin-left:  ${({ sidebar }) => (sidebar ? '250px' : '100px')}; 
    height:auto;
    width:auto;
    transition: 150ms;
    background-color:rgb(247,247,247)!important;
    overflow: initial;
    z-Index: 9;

    @media (max-width: 880px) {
        margin-left: 0;
    }
`;


const ListItem = ( {list = []}) =>{
    //const { user } = useContext(AuthContext);
    const ref = useRef();
    //TESTES
    const secretKey = 'my-custom-secret-key:1af0addcce4b56f753ed10c285667ad031bae57af19747f576b05312b8cf85be';
    const userEncrypted = localStorage.getItem('user')
    const user = CryptoJS.AES.decrypt(userEncrypted, secretKey).toString(CryptoJS.enc.Utf8);
    const userParse = JSON.parse(user)
    const userID = userParse._id;
    const empresa = localStorage.getItem('empresa')
    const empresaParse = JSON.parse(empresa)
    const empresaObjId = empresaParse._id;
    const idEmpresa = empresaParse.id_empresa
    const vinculo_empresa = empresaParse.cnpj;
    const nomeEmpresa = empresaParse.name;
    const nomeEmpresaForUrl = nomeEmpresa.replace(/\s+/g, '-').replace(/[A-Z]/g, c => c.toLowerCase());

    const navigate = useNavigate();
    const horizontalScrollRef = useRef(null);

    const [visibleDropdown, setVisibleDropdown] = useState(null);
    const [visibleInput, setVisibleInput] = useState(null);
    
    const [empresas, setEmpresas] = useState([]); 
    const [categorias, setCategorias] = useState([]);
    const [itens, setItens] = useState([]);
    const [selectedCategoria, setSelectedCategoria] = useState("");

    const [num, setNum] = useState(itens.map(item => 1));

    const [filter, setFilter] = useState('');
    const [result, setResult] = useState(false);

    const [order, setOrder] = useState(1)
    const [columnOrder, setColumnOrder] = useState("")
    const [currentPrice, setCurrentPrice] = useState(0);
    const dropdownRef = useRef(null);
    const inputRef = useRef(null);
    const { sidebar, setSidebar } = useContext(SidebarContext);;
    
    const [loading, setLoading] = useState(true);
    const [refresh, setRefresh] = useState(false);

    const [showEditCategoria, setEditCategoria] = useState(true);

    useEffect(() => {
        (async () => {
            const response = await getCategorias(userID, idEmpresa, vinculo_empresa, empresaObjId)//getEmpresas(user._id);
            const itemTemp = await getItens(userID, idEmpresa, vinculo_empresa, empresaObjId)

            ////console.log("itemTemp.data.itens>",itemTemp.data.itens);

            setCategorias(response.data.categorias);
            setItens(itemTemp.data.itens);
            setLoading(false);
            setRefresh(false);    

        })();
    },[refresh]);



    const [confirmOpen, setConfirmOpen] = useState(false);
    const [shouldDelete, setShouldDelete] = useState(false);
    const [idToDelete, setIdToDelete] = useState('');
    const handleDeleteItem = (idRecebido) => {
        // Abre Componente de Confirmação do delete
        setConfirmOpen(true)

        setIdToDelete(idRecebido)
        if(idRecebido){
            //console.log("ID RECEBIDO:",idRecebido)
            setShouldDelete(true);
        }
    }
    const deleteReg = async () => {
        //console.log("O ID CLICADO FOI :::",idToDelete);
        //console.log("ID do Usuário que irá efetuar a exclusão", user._id);

        if(shouldDelete){
            deleteItem(user._id, idToDelete).then( cast => {
                //console.log("RESULT DELETE:",cast);
                if(cast.status===201){
                    toast(cast.data.msg,{ autoClose: 5000 , type: "success"});
                    setRefresh(true);
                }else{
                    toast(cast.data.msg,{ autoClose: 5000 , type: "error"});
                }
            }).catch( err => {
                //console.log("ERROR:",err);
                toast(err.response.data.msg,{ autoClose: 5000 , type: "error"});
            });
        }

    }

    /*const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setVisibleDropdown(null);
        }
        if (inputRef.current && !inputRef.current.contains(event.target)) {
            setVisibleInput(null);
        }
    };*/
    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setVisibleDropdown(null);
        }
        if (inputRef.current && !inputRef.current.contains(event.target)) {
            console.log("visibleInput>>",visibleInput)
            if (visibleInput !== null) {
                const item = itens.find(it => it._id === visibleInput);
                handleBlurOrEnter(item._id, item.id_item, currentPrice);
                //setVisibleInput(null);
            }
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [visibleInput, currentPrice, itens]);

    const toggleOptions = (id) => {
        if (visibleDropdown === id) {
            setVisibleDropdown(null);
        } else {
            setVisibleDropdown(id);
        }
    };

    const toggleInput = (id, price) => {
        console.log("toggleInput de ID> ", id)
        setCurrentPrice(price);
        setVisibleInput(id);
    };

    /*const handleDeleteClick = async (id) => {
        // Implementar a lógica para excluir o item
        console.log('Excluir item com id:', id);
        const response = await deleteItem(userID, id);
        if (response.status === 201) {
            toast('Item excluído com sucesso', { autoClose: 2500, type: 'success' });
            setRefresh(true);
        } else {
            toast('Erro ao excluir o item', { autoClose: 2500, type: 'error' });
        }
    };*/

    const handleEditAdditionalClick = (id) => {
        // Implementar a lógica para editar adicionais do item
        console.log('Editar adicionais do item com id:', id);
    };

    const handleCheckboxChange = async (itemID, id_item, currentOut) => {
        const newOutValue = !currentOut;
        setItens(prevItens =>
            prevItens.map(item =>
                item._id === itemID ? { ...item, out: newOutValue } : item
            )
        ); // Atualiza o estado local imediatamente

        try {
            const response = await updateItemOut(userID, itemID, id_item, idEmpresa, newOutValue);
            if (response.status === 200) {
                toast('Item atualizado com sucesso', { autoClose: 2500, type: 'success' });
                setRefresh(true);
            }
        } catch (error) {
            console.error('Erro ao atualizar item:', error);
            toast('Erro ao atualizar o item', { autoClose: 2500, type: 'error' });
            // Reverte o estado local se a atualização falhar
            setItens(prevItens =>
                prevItens.map(item =>
                    item._id === itemID ? { ...item, out: currentOut } : item
                )
            );
        }
    };

    const handleBlurOrEnter = async (itemID, id_item, currentPrice) => {
        setVisibleInput(null); // Esconde o input
        try {
            const response = await updateItemPrice(userID, itemID, id_item, idEmpresa, parseFloat(currentPrice));
            if (response.status === 200) {
                toast('Preço atualizado com sucesso', { autoClose: 2500, type: 'success' });
                setRefresh(true);
            }
        } catch (error) {
            console.error('Erro ao atualizar preço do item:', error);
            toast('Erro ao atualizar o preço do item', { autoClose: 2500, type: 'error' });
            // Reverte o estado local se a atualização falhar
            setItens(prevItens =>
                prevItens.map(item =>
                    item._id === itemID ? { ...item, price: parseFloat(currentPrice) } : item
                )
            );
        } finally {
            setVisibleInput(null); // Esconde o input
        }
    };
    /*const handleBlurOrEnter = async (itemID, id_item, price) => {
        try {
            const priceAsNumber = price;
            if (isNaN(priceAsNumber)) {
                throw new Error('Invalid price');
            }
            const response = await updateItemPrice(userID, itemID, id_item, idEmpresa, priceAsNumber);
            if (response.status === 200) {
                toast('Preço atualizado com sucesso', { autoClose: 2500, type: 'success' });
                setRefresh(true);
            }
        } catch (error) {
            console.error('Erro ao atualizar preço do item:', error);
            toast('Erro ao atualizar o preço do item', { autoClose: 2500, type: 'error' });
            // Reverte o estado local se a atualização falhar
            setItens(prevItens =>
                prevItens.map(item =>
                    item._id === itemID ? { ...item, price: parseFloat(price) } : item
                )
            );
        } finally {
            setVisibleInput(null); // Esconde o input
        }
    };*/

    /*const onChange = (event, nameObject, isNumber) => {
        setValuePreco(isNumber ? Number(event.target.value) : event.target.value);
        formik.setFieldValue("preco", isNumber ? Number(event.target.value) : event.target.value );
    };*/

    const handlePriceChange = (e, itemID, isNumber) => {
        const newPrice = isNumber ? Number(e.target.value) : e.target.value;
        console.log("newPrice>>",newPrice)
        setCurrentPrice(newPrice);
        setItens(prevItens =>
            prevItens.map(item =>
                item._id === itemID ? { ...item, price: newPrice } : item
            )
        );
    };

    const [_idCategoriaToEdit, setIdCategoriaToEdit] = useState("")
    const [id_categoria, setIdCategoria] = useState("")
    const [_titleToEdit, setTitleToEdit] = useState("")
    const [_disponibilidadeToEdit, setDisponibilidadeToEdit] = useState("")
    const [_diaHorarioDispToEdit, setDiaHorarioDispToEdit] = useState([])
    const handleEdit = async (idToEdit, id_categoria, titleToEdit, disponibilidade, dia_horario_disponibilidade) => {
        setIdCategoriaToEdit(idToEdit);
        setIdCategoria(id_categoria);
        setTitleToEdit(titleToEdit);
        setDisponibilidadeToEdit(disponibilidade);
        //setDiaHorarioDispToEdit(dia_horario_disponibilidade);

        const novoArray = dia_horario_disponibilidade.map(obj => {
            const {dia, startTime, endTime} = obj;
            const novoObj = {
                dias: new Set([dia]),
                startTime,
                endTime
            };
            return novoObj;
        });

        const teste = novoArray.reduce((acc, curr) => {
            const prevItem = acc[acc.length - 1];
            if (
            prevItem &&
            prevItem.startTime === curr.startTime &&
            prevItem.endTime === curr.endTime
            ) {
            prevItem.dias = new Set([...prevItem.dias, ...curr.dias]);
            } else {
            acc.push(curr);
            }
            return acc;
        }, []);

        ////console.log("teste",teste)
        //setDiaHorarioDispToEdit(teste);
        if (teste.length === 0) {
            setDiaHorarioDispToEdit([
              {
                dias: new Set([""]),
                startTime: "00:00",
                endTime: "23:59",
              }
            ]);
        } else {
            setDiaHorarioDispToEdit(teste);
        }

        setEditCategoria(!showEditCategoria)
    }

    const handleRefresh = () => {
        setLoading(true);
        setRefresh(true);
    }

    const [isFocused, setIsFocused] = useState(false);
    const handleFocus = () => {
        setIsFocused(true);
    };
    
    const handleBlur = () => {
        setIsFocused(false);
    };

    const onChange = (e) => {
        setItens( prevItens => prevItens.map( item => item._id === e.target.id ? {...item, price: e.target.value} : item ) )
    };

    const handleEditClick = (itemObjId) => {
        navigate(`/edit-item`, { state: { itemObjId } });
    };

    const handleOrder = fieldName => {
        setOrder(-order)
        setColumnOrder(fieldName)
        //console.log("order:",order);
        //console.log("fieldName",fieldName)
    }

    const [copied, setCopied] = useState(false);
    const handleCopyClick = (productID) => {
        const hostname = window.location.hostname === "localhost" ? "http://localhost:3000" : `https://${window.location.hostname}`;
        const link = `${hostname}/${nomeEmpresaForUrl}/${idEmpresa}/detalhes/${productID}`
        navigator.clipboard.writeText(link).then(() => {
            setCopied(true);
            toast("Link copiado com sucesso!", { autoClose: 2500, type: "success" });
            setTimeout(() => setCopied(false), 2000); // Reset the copied state after 2 seconds
        }).catch((err) => {
            console.error('Failed to copy: ', err);
        });
    };

    
    var arrayCategorias = categorias.sort( (a, b) => {
        return a.order - b.order;
    });

    var arrayItens = itens.sort((a, b) => {
        return a.order - b.order;
    });

    const handleScrollOnClick = (mongodbId) => {
        const element = document.getElementById(mongodbId);
        if (element) {
          setSelectedCategoria(mongodbId)
          element.scrollIntoView({ behavior: "smooth" });
        } else {
            toast("Essa categoria não possui item vinculado!", {
                autoClose: 2500,
                type: "warning",
            });
            //console.error(`Element with id ${mongodbId} not found`);
        }
    };
    
    if (filter){
        const exp = eval(`/${filter.replace(/[^\d\w]+/,'.*')}/i`)
        list = categorias.filter( item => exp.test( item.title ) )
    }

    const handleFilter = e => {
        setFilter(e.target.value);
        setResult(!result);
    }

    const handleCadastro = () => {
        navigate("/cadastro-item");
    }


    const [draggedItem, setDraggedItem] = useState(null);
    const handleDragStart = (draggableId) => {
        //console.log("draggedItem>",draggableId)
        setDraggedItem(draggableId);
    };
    const handleDragEnd = () => {
        setDraggedItem(null);
        /*//console.log("destination",destination)
        if (!destination) { // || destination.droppableId === source.droppableId){
          //console.log("caiu aqui.")
          return;
        }
        const draggedItem = itens.filter((item) => item._id === source.draggableId)[0];
      
        const newItens = [...itens]
        //console.log("newItens>",newItens)
        newItens.splice(source.index, 1);
        newItens.splice(destination.index, 0, draggedItem)
        //console.log("newItens2>",newItens)
        //setItens(newItens);*/
    };

    const moveRow = async (source, destination) => {
        
        const filteredItens = itens.filter(item => item.category_item_id === source.droppableId);
        ////console.log("source>",source)
        ////console.log("destination>",destination)
        ////console.log("filteredItens>",filteredItens)

        const dragItem = filteredItens[source.index];
        const hoverItem = filteredItens[destination.index];

        ////console.log("dragItem>",dragItem)
        ////console.log("hoverItem>",hoverItem)
      
        // Mover o item do dragIndex para o hoverIndex
        const newItens = [...filteredItens];        
        newItens.splice(source.index, 1);
        newItens.splice(destination.index, 0, dragItem);
        // Atualizar o estado com o novo array de itens
        const itensAtualizados = newItens.map((item, index) => {
            return { ...item, order: index+1 };
        });
        


        const itensAtualizadosMap = itensAtualizados.reduce((acc, item) => {
            acc[item._id] = item;
            return acc;
        }, {});

        const newItensArray = itens.map(item => {
            const itemAtualizado = itensAtualizadosMap[item._id];
            return itemAtualizado || item;
        });

        //console.log(newItensArray);

        const promises = newItensArray.map( (itensToApi) => {
            return updateItensOrder(userID, itensToApi._id, itensToApi.id_item, itensToApi.id_empresa, itensToApi.order).then(response => {
                return response;
            }).catch(err => {
                return err;
            })
        });
        Promise.all(promises).then(results => {
            toast("Ordem atualizada com sucesso!",{ autoClose: 2500 , type: "success"});
        }).catch(err => {
            toast("Ocorreu um erro ao tentar atualizar a ordem!",{ autoClose: 2500 , type: "error"});
        });        

        setItens(newItensArray);
    };

    return(

        <>
        <PermissionGate permissions={['default']}>

            {/*<LeftMenu setSidebar={setSidebar} sidebar={sidebar} />*/}

            <ConfirmDialog
                title="Deletar Item?"
                open={confirmOpen}
                setOpen={setConfirmOpen}
                onConfirm={deleteReg}
            >
                <div>
                    Tem certeza que deseja deletar o item?                                         
                </div>
            </ConfirmDialog>

            <ModalEditCategoria showEditCategoria={showEditCategoria}
            setEditCategoria={setEditCategoria}
            setRefresh={setRefresh}
            _idCategoriaToEdit={_idCategoriaToEdit}
            id_categoria={id_categoria}
            _titleToEdit={_titleToEdit}
            _disponibilidadeToEdit={_disponibilidadeToEdit}
            setDisponibilidadeToEdit={setDisponibilidadeToEdit}
            _diaHorarioDispToEdit={_diaHorarioDispToEdit}
            setDiaHorarioDispToEdit={setDiaHorarioDispToEdit}
            />

            <Teste sidebar={sidebar}>
            
                {loading && <Loading type={"spinningBubbles"} style={{marginLeft:'14%'}} className="calculandoDistanciaModalEntrega"/>}
                <div className="w-100 p-4">
                    <div className="list-header-categories">
                        <div className="title">
                            <h1>Itens</h1>
                        </div>
                    </div>


                    <div className="flex-column flex-md-row" style={{display:"flex", justifyContent:"space-between"/*, height:"80px"*/}}>
                        <div className="input-box-list" style={{ maxWidth: "400px", width: "100%"}}>                
                            <input className="input-field" placeholder="Pesquisar" onChange={handleFilter}/>
                            <i className="icon"><FiIcons.FiSearch/></i>
                        </div>

                        <div className="div-buttons flex-column flex-md-row mb-2">
                            <div className="continue-button">                           
                                <button onClick={handleCadastro}>
                                    <AiIcons.AiOutlinePlusCircle style={{marginRight: "5px",fontSize: "22px",marginBottom: "2px"}}/><a>Adicionar</a> 
                                </button>                            
                            </div>

                            <div className="refresh-button" style={{ textAlign: "start" }}>                                                       
                                <button className="refresh-button" onClick={handleRefresh}>
                                    <SlIcons.SlRefresh style={{color:"#4281FF",marginRight: "5px",fontSize: "18px",marginBottom: "2px"}}/><a >Atualizar</a> 
                                </button>
                            </div>
                        </div>

                    </div>

                    
                    <div className="divPaiCategorias">

                        <div className="grid-cardapio" style={{width:'96%'}}>
                            
                            
                            <div className="center-cardapio horizontal">

                                
                                <div className="categoria-cardapio horizontal">
                                {arrayCategorias &&
                                    arrayCategorias
                                    /*.filter((cats) =>
                                        itens.some((item) => item.category_item_id === cats._id)
                                    )*/
                                    .map((c, i) => (
                                        <div
                                        key={Math.random()}
                                        className="categoria-item"
                                        onClick={() => handleScrollOnClick(c._id)}
                                        style={{
                                            background:
                                            c._id === selectedCategoria ? "rgb(49, 140, 213)" : "",
                                            color: c._id === selectedCategoria ? "white" : "",
                                        }}
                                        >
                                        <span>{c.title}</span>
                                        </div>
                                    ))}
                                </div>
                                
                                <div className="overflow-itensHorizontal">
                                    {arrayCategorias &&
                                        arrayCategorias.map((category, i) => (
                                            <div key={i} className="same-category">
                                                <div key={category._id} id={category._id} className="categoria-item-title">
                                                <span key={Math.random()} className="titulo-item">
                                                    {category.title}
                                                </span>
                                                </div>
                                                <DragDropContext onDragStart={(event) => handleDragStart(event.draggableId)} onDragEnd={({ source, destination }) => {
                                                handleDragEnd();
                                                if (destination) {
                                                    moveRow(source, destination);
                                                }
                                                }}>
                                                <Droppable droppableId={category._id} direction="vertical">
                                                    {(provided) => (
                                                    <div ref={provided.innerRef} {...provided.droppableProps}>
                                                        <div className="itens-categoriaHorizontal">
                                                            {arrayItens && arrayItens
                                                                .filter((item) => item.category_item_id === category._id)
                                                                .map((item, index) => (
                                                                <Draggable key={item._id} draggableId={item._id} index={index}>
                                                                    {(provided) => (
                                                                    <div
                                                                    {...provided.draggableProps}                                                                    
                                                                    ref={provided.innerRef}
                                                                    className={`${"resultItem"} ${
                                                                    index % 2 === 0 ? "even" : "odd"
                                                                    }`}
                                                                    style={{
                                                                        ...provided.draggableProps.style,
                                                                        backgroundColor: draggedItem == item._id ? 'white' : 'white',
                                                                        boxShadow: draggedItem == item._id ? '1px 1px 6px #a4a4a4' : 'none',
                                                                    }} // Adicione essa linha
                                                                    >
                                                                        <div className="itensList">
                                                                            <div className="divItemImgList">
                                                                                {item.image!=="" ? (
                                                                                <img
                                                                                    width={100}
                                                                                    height={100}
                                                                                    src={
                                                                                    item.image
                                                                                    }
                                                                                ></img>
                                                                                ) : (
                                                                                <div className="iconWhenImgNull">
                                                                                    <MdFastfood style={{ fontSize: 75 }} />
                                                                                </div>
                                                                                )}
                                                                            </div>
                                                                            <div className="divItemNameList flex-column flex-lg-row">
                                                                                <div className="titlePlusDragIcon" {...provided.dragHandleProps}>
                                                                                    <MdDragIndicator style={{fontSize:30, color:"darkgray"}}/>
                                                                                    <span>{item.title}</span>
                                                                                </div>
                                                                                <div className="itemsOptJustifyEnd">
                                                                                    <div className="divItemPrice">
                                                                                        {visibleInput === item._id ?
                                                                                            <div ref={inputRef}>                                                                                                                                                                                   
                                                                                                <InputMoney
                                                                                                    onChange={(event) => handlePriceChange(event, item._id, true)}
                                                                                                    value={item.price}
                                                                                                    onBlur={() => handleBlurOrEnter(item._id, item.id_item, item.price)}
                                                                                                    onKeyDown={(e) => {
                                                                                                        if (e.key === 'Enter') {
                                                                                                            handleBlurOrEnter(item._id, item.id_item, item.price);
                                                                                                        }
                                                                                                    }}
                                                                                                    autoFocus                                                                                                    
                                                                                                    margin="0px 0px 16px 0px"
                                                                                                    style={{
                                                                                                    outline: isFocused ? '2px solid #4070f4' : 'none',
                                                                                                    borderRadius:6,
                                                                                                    margin: '0.6rem 0 0.2rem 0',
                                                                                                    }}
                                                                                                    className="inputPersonalizedItem"
                                                                                                    placeholder="Preço"
                                                                                                /> 
                                                                                            </div>
                                                                                        :                                                                                        
                                                                                        <>
                                                                                            <span>R$ {item.price?.toFixed(2).toString().replace('.',',')}</span>
                                                                                            <div className={"icon-btn edit-print"} onClick={()=>toggleInput(item._id, item.price)}>
                                                                                                <div>
                                                                                                    <svg
                                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                                    width={16}
                                                                                                    height={16}
                                                                                                    viewBox="0 0 24 24"
                                                                                                    fill="none"
                                                                                                    stroke="currentColor"
                                                                                                    strokeWidth={2}
                                                                                                    strokeLinecap="round"
                                                                                                    strokeLinejoin="round"
                                                                                                    className="feather feather-edit-2"
                                                                                                    >
                                                                                                    <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z" />
                                                                                                    </svg>
                                                                                                </div>                                                                                                
                                                                                            </div>
                                                                                        </>
                                                                                        }
                                                                                    </div>
                                                                                    <div className="divEsgotarItem">
                                                                                        <span>Esgotar</span>
                                                                                        <div className="wrapper">
                                                                                            <div className="switch_box box_1">                                                                                                
                                                                                                <input type="checkbox" className="switch_1" 
                                                                                                checked={item.out} onChange={()=> handleCheckboxChange(item._id, item.id_item, item.out)}/>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="divLinkAcoes">
                                                                                        <div>
                                                                                            <span className="actionsItem" onClick={() => handleCopyClick(item._id)}><IoLink fontSize={15}/> Link</span>
                                                                                        </div>
                                                                                        <div>
                                                                                            <span onClick={() => toggleOptions(item._id)}
                                                                                            style={{
                                                                                            }}                                                                                            
                                                                                            className="actionsItem"
                                                                                            >
                                                                                                Ações do Item <MdKeyboardArrowDown />
                                                                                            </span>
                                                                                            {visibleDropdown === item._id && (
                                                                                                <div className="optionsDropdown" ref={dropdownRef}>
                                                                                                    <div 
                                                                                                        className="optionItem" 
                                                                                                        onClick={() => handleEditClick(item._id)}
                                                                                                    >
                                                                                                        <FaRegEdit /> Editar
                                                                                                    </div>
                                                                                                    <div 
                                                                                                        className="optionItem" 
                                                                                                        onClick={() => handleDeleteItem(item._id)}
                                                                                                    >
                                                                                                        <FaRegTrashAlt /> Excluir
                                                                                                    </div>
                                                                                                    <div 
                                                                                                        className="optionItem" 
                                                                                                        onClick={() => handleEditAdditionalClick(item._id)}
                                                                                                    >
                                                                                                        <FaRegEdit /> Editar Adicionais
                                                                                                    </div>
                                                                                                </div>
                                                                                            )}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <input
                                                                                //onChange={console.log()}
                                                                                value={num[index]}
                                                                                type="number"
                                                                                style={{
                                                                                display: isNaN(num[index]) ? "none" : "",
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        {provided.placeholder}
                                                                    </div>
                                                                    )}
                                                                </Draggable>
                                                            ))}
                                                            {/* Esta parte é para verificar se há itens na categoria */}
                                                            {arrayItens.filter((item) => item.category_item_id === category._id).length === 0 && (
                                                                <div className="nenhumItem">Nenhum item cadastrado</div>
                                                            )}
                                                        </div>
                                                    {provided.placeholder}
                                                    </div>
                                                    )}
                                                </Droppable>
                                                </DragDropContext>
                                            </div>
                                        ))}
                                    
                                </div>



                            </div>
                            
                            
                        </div>

                    </div>
                            
                </div>            
            </Teste>

        </PermissionGate>       
        </>
        

    );
};


export default ListItem;