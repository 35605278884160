import React, { useState, useRef, useEffect, useContext } from "react";
import './style.css'
import styled from 'styled-components';
//import InputMask from 'react-input-mask'
//import CurrencyInput from 'react-currency-input-field';
import ImageUploading from 'react-images-uploading';
import { CImage } from '@coreui/react'
//import Image from 'react-image'
//import Gallery from 'react-image-gallery'
import CryptoJS from 'crypto-js';
import AsyncSelect from 'react-select/async';
import { AuthContext } from "../../contexts/auth";
import { getCategorias, registerItem } from "../../services/api";
import PermissionGate from "../../services/PermissionGate";
import { useNavigate, useLocation } from "react-router-dom";
import LeftMenu from "../../components/LeftMenu";
import InputMoney from "./InputMoney";
import { useFormik } from "formik";
import * as Yup from "yup";
import * as AiIcons from 'react-icons/ai'
import * as SlIcons from 'react-icons/sl'
import { MdFastfood } from "react-icons/md";
import { BsPlusCircle } from "react-icons/bs";
import { FaTrash } from 'react-icons/fa'
import Loading from "react-loading";
import { toast } from "react-toastify";
import Input from "./Input";
import { SidebarContext } from "../../AppRoutes";

const Teste = styled.div`

    display: flex;
    margin-left:  ${({ sidebar }) => (sidebar ? '250px' : '100px')}; 
    height:auto;
    width:auto;
    transition: 150ms;
    background-color:rgb(247,247,247)!important;
    overflow: initial;
    z-Index: 9;

    @media (max-width: 880px) {
        margin-left: 0;
    }
`;


const Item = () => {

    const location = useLocation();
    const { infoCategoria, modelo } = location.state || {};

    const secretKey = 'my-custom-secret-key:1af0addcce4b56f753ed10c285667ad031bae57af19747f576b05312b8cf85be';
    const userEncrypted = localStorage.getItem('user')
    const user = CryptoJS.AES.decrypt(userEncrypted, secretKey).toString(CryptoJS.enc.Utf8);
    const userParse = JSON.parse(user)
    const userID = userParse._id;
    const userName = userParse.name;
    const userEmail = userParse.email;
    var userImg = null;
    //console.log(empresa)
    const empresa = localStorage.getItem('empresa')
    const empresaParse = JSON.parse(empresa)
    const idEmpresa = empresaParse.id_empresa;
    const objIdEmpresa = empresaParse._id;
    const vinculo_empresa = empresaParse.cnpj;

    const descriptionRef = useRef();
    const { sidebar, setSidebar } = useContext(SidebarContext);; //TESTANDO CONST NO AppROUTES

    const [valuePreco, setValuePreco] = useState(0);

    const [selectedOption, setSelectedOption] = useState("");
    const [selectedDays, setSelectedDays] = useState([]);
    const [startTime, setStartTime] = useState("");
    const [endTime, setEndTime] = useState("");
    const [diasHorarios, setDiasHorarios] = useState([
        {
            dias: new Set(),
            startTime: "00:00",
            endTime: "23:59",
        }
    ]);
    const [isSubmitting, setIsSubmitting] = useState(false);



    const INITIAL_DATA = {
        value: "",
        label: 'Selecione a categoria',
    };
    const [selectData, setselectData] = useState(infoCategoria ?? INITIAL_DATA);

    const mapResponseToValuesAndLabels = (data) => ({
        value: data._id,
        label: data.title,
    });

    /*async function callApi(value) {

        const data = await getCategorias(userID, idEmpresa, vinculo_empresa, objIdEmpresa)
            .then((response) => response.data.categorias)//response.json())
            .then((response) => response.sort((a, b) => a.order - b.order)) // Adicione esta linha para ordenar os dados
            .then((response) => response.map(mapResponseToValuesAndLabels))
            .then((response) => response.filter((i) => i.label.toLowerCase().includes(value.toLowerCase())));

        return data;
    }*/

    async function callApi(value) {
        const data = await getCategorias(userID, idEmpresa, vinculo_empresa, objIdEmpresa)
            .then((response) => response.data.categorias) // Pega as categorias
            .then((response) => {
                // Aplica o filtro de acordo com o valor de modeloFiltro
                if (modelo?.value === 2) {
                    //console.log("Pizza:", JSON.stringify(response));
                    return response.filter((categoria) => categoria.modelo?.value === 2); // Filtro para 'modelo === 2'
                } else if (modelo?.value === 1 || !modelo) {
                    //console.log("Simples:", response);
                    return response.filter((categoria) => categoria.modelo?.value === 1 || !categoria.modelo); // Filtro para 'modelo === 1' ou vazio
                }
                return response; // Se nenhum filtro for aplicado, retorna todas as categorias
            })
            .then((response) => response.sort((a, b) => a.order - b.order)) // Ordena as categorias
            .then((response) => response.map(mapResponseToValuesAndLabels)) // Mapeia para valores e labels
            .then((response) => response.filter((i) => i.label.toLowerCase().includes(value.toLowerCase()))); // Filtro pelo valor

        return data;
    }


    const [errImg, setErrImg] = React.useState(false);
    const [images, setImages] = React.useState([]);
    const maxNumber = 1;
    const maxMbFileSize = 2 * 1024 * 1024; // 2Mb

    const onChangeImg = (imageList, addUpdateIndex) => {
        // data for submit
        console.log(imageList, addUpdateIndex);
        setImages(imageList);
    };

    /*const handleChangeValuePreco = (event) => {
        const inputValue = event.target.value.replace(/,/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        setValuePreco(inputValue);
        if (event.onChange) {
            event.onChange(parseFloat(inputValue.replace(/,/g, '')));
        }
    };*/


    useEffect(() => {
        (async () => {

            //const teste = [response.data]
            //setLoading(false);
            //console.log("diasHorarios",diasHorarios)
            //console.log("horariosUnicos>",horariosUnicos)

        })();
    }, []);

    //Validações
    const SignUpSchema = Yup.object().shape({
        //email: Yup.string().email("Insira um e-mail válido").required("Obrigatório"),
        //cnpj: Yup.string().required("Obrigatório"),
        title: Yup.string().required("Obrigatório"),
        vinculo_categoria: !selectData.value && Yup.string().required("Obrigatório"),
        //disponibilidade: Yup.string().required("Obrigatório"),
    });

    const formik = useFormik({
        validationSchema: SignUpSchema,
        validateOnBlur: false,
        validateOnChange: false,
        initialValues: {
            vinculo_categoria: "",
            title: "",
            preco: 0,
        },
        onSubmit: (values) => {
            //alert(JSON.stringify(values, null, 2));
            //console.log(values);
            setIsSubmitting(true);
            const description = descriptionRef.current.value;
            const title = values.title;
            const price = values.preco;
            //handleSaveImg();

            handleSubmitAPI(title, description, price);

        },
    });

    const navigate = useNavigate();

    const handleBack = () => {
        //setSidebar(!sidebar)
        navigate("/list-categoria");
    }


    const handleSubmitAPI = (title, description, price) => {
        //e.preventDefault();

        const createdBy = userID;
        const out = false;
        const category_item_id = selectData.value;
        const category_item_title = selectData.label;
        const disponibilidade = 'sempre';
        const type = modelo?.value === 2 ? "pizza" : "simples";
        console.log("DEU SUBMIT")

        //Registrando o item
        console.log("submit item!", { createdBy, idEmpresa, category_item_id, category_item_title, title, description, out, images, price, disponibilidade, type });
        registerItem(createdBy, idEmpresa, category_item_id, category_item_title, title, description, out, images, price, disponibilidade, type).then(cast => {
            if (cast.status === 201) {
                toast(cast.data.msg, { autoClose: 5000, type: "success" });
                navigate("/list-categoria");
            }
            else {
                toast(cast.data.msg, { autoClose: 5000, type: "error" });
            }

        }).catch(err => {
            console.log('Oh noooo!!');
            const msg = err.response.data.msg;
            //console.log("MENSAGEM", msg)
            toast(msg, { autoClose: 5000, type: "error" });
        }).finally(() => {
            setIsSubmitting(false);
        });

    }


    const [valueDescricao, setValueDescricao] = useState('');

    const handleMaxCharDescricao = (e) => {
        const newValue = e.target.value;
        if (newValue.length <= 1000) {
            setValueDescricao(newValue);
        }
    };

    //const [horariosToDelete, setHorariosToDelete] = useState("");
    const handleDeleteHorario = (horarioIndex) => {
        setDiasHorarios((prevDiasHorarios) => {
            const newDiasHorarios = [...prevDiasHorarios];
            newDiasHorarios.splice(horarioIndex, 1);
            return newDiasHorarios;
        });
    };

    const handleOptionChange = (event) => {
        if (event.target.value === "sempre" || event.target.value === "pausado") {
            setDiasHorarios([
                {
                    dias: new Set([""]),
                    startTime: "00:00",
                    endTime: "23:59",
                }
            ]);
        }
        setSelectedOption(event.target.value);
    };

    const horariosPorDia = diasHorarios.reduce((acc, { dias, startTime, endTime }) => {
        dias.forEach((dia) => {
            if (!acc[dia]) {
                acc[dia] = {
                    dia,
                    startTime,
                    endTime,
                };
            } else {
                const { startTime: startTimeAcc, endTime: endTimeAcc } = acc[dia];
                const [startTimeHour, startTimeMin] = startTime.split(':').map(Number);
                const [startTimeAccHour, startTimeAccMin] = startTimeAcc.split(':').map(Number);
                const [endTimeHour, endTimeMin] = endTime.split(':').map(Number);
                const [endTimeAccHour, endTimeAccMin] = endTimeAcc.split(':').map(Number);

                if (startTimeHour < startTimeAccHour || (startTimeHour === startTimeAccHour && startTimeMin < startTimeAccMin)) {
                    acc[dia].startTime = startTime;
                }

                if (endTimeAccHour < endTimeHour || (endTimeAccHour === endTimeHour && endTimeAccMin < endTimeMin)) {
                    acc[dia].endTime = endTime;
                }
            }
        });

        return acc;
    }, {});

    const onChange = (event, nameObject, isNumber) => {
        setValuePreco(isNumber ? Number(event.target.value) : event.target.value);
        formik.setFieldValue("preco", isNumber ? Number(event.target.value) : event.target.value);
    };

    const horariosUnicos = Object.values(horariosPorDia);

    const handleDayChange = (horarioIndex, key) => {
        const newDiasHorarios = [...diasHorarios];
        const horario = newDiasHorarios[horarioIndex];

        if (horario.dias.has(key)) {
            horario.dias.delete(key);
        } else {
            horario.dias.add(key);
        }

        setDiasHorarios(newDiasHorarios);
    };

    const validateTime = (value) => {
        const regex = /^([0-1][0-9]|2[0-3]):[0-5][0-9]$/;
        return regex.test(value);
    };
    const handleTimeChange = (horarioIndex, setTime, event) => {
        const value = event.target.value;
        if (validateTime(value)) {
            const newHorarios = [...diasHorarios];
            newHorarios[horarioIndex][setTime] = value;
            setDiasHorarios(newHorarios);
        }
    };

    const handleAddHorario = () => {
        setDiasHorarios(diasHorarios.concat({
            dias: new Set(),
            startTime: "00:00",
            endTime: "23:59",
        }));
    };

    const isDayAlreadySelectedInHorario = (day, currentHorarioIndex) => {
        return diasHorarios
            .filter((horario, index) => index !== currentHorarioIndex)
            .some((horario) => horario.dias.has(day));
    };

    const [isFocused, setIsFocused] = useState(false);
    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        setIsFocused(false);
    };


    return (

        <>
            <PermissionGate permissions={['default']}>


                {/*<LeftMenu setSidebar={setSidebar} sidebar={sidebar} />*/}

                <Teste sidebar={sidebar}>
                    <div className="w-100 p-4">
                        {/*<form onSubmit={handleSubmitButton}>*/}
                        <form onSubmit={formik.handleSubmit}>

                            <div className="form-header" style={{ marginBottom: "0px" }}>
                                <div className="title">
                                    <h1>{modelo?.value === 2 ? "Adicionar Tamanho da Pizza" : "Adicionar Item"}</h1>
                                </div>
                            </div>

                            <div style={{ display: "flex", justifyContent: "right"/*, height:"80px"*/ }}>

                                <div className="div-buttons">
                                    <div className="continue-button">
                                        <button type="submit" disabled={isSubmitting} style={{ cursor: isSubmitting ? 'not-allowed' : '' }}>
                                            {isSubmitting ? <Loading type="spin" color="white" height={30} width={30}
                                                className="zIndexForLoadingSaveButton" /> :
                                                <><AiIcons.AiOutlineSave style={{ marginRight: "5px", fontSize: "22px", marginBottom: "2px" }} /><a>Salvar</a></>
                                            }
                                        </button>
                                    </div>

                                    <div className="back-button">
                                        <button onClick={handleBack}>
                                            <SlIcons.SlActionUndo style={{ color: "#ff4c4c", marginRight: "5px", fontSize: "18px", marginBottom: "2px" }} /><a >Voltar</a>
                                        </button>
                                    </div>
                                </div>

                            </div>

                            <div className="contentItemComplete flex-column flex-md-row">
                                <div className="input-group inputGroup-etapasItem w-100 mh-100" style={{ maxWidth: 200 }}>
                                    <div className="etapasAddItem etapaAtiva" style={{ borderBottom: '1px solid lightgray' }}>
                                        <label>1. {modelo?.value === 2 ? "Tamanho" : "Item"}</label>
                                    </div>
                                    <div className="etapasAddItem" style={{ borderBottom: '1px solid lightgray' }}>
                                        <label>2. Adicionais</label>
                                    </div>
                                    {/*<div className="etapasAddItem" style={{ borderBottom: '1px solid lightgray' }}>
                                        <label>3. Classificações</label>
                                    </div>
                                    <div className="etapasAddItem">
                                        <label>4. Disponibilidade</label>
                                    </div>*/}
                                </div>

                                <div className="input-group inputGroup-adicinaItem mw-100 mh-100 flex-column flex-md-row">
                                    <div className="formGroupRow w-100 flex-column flex-lg-row align-items-center">
                                        <div className="divContainerAddItem" style={{ width: "100%" }}>
                                            <div className="input-box" style={{ width: "100%", transition: "200ms" }}>
                                                <label>Categoria<span style={{ color: "red" }}>*</span></label>
                                                {/*<!-- Custom select structure --> */}
                                                <div className="selectdiv-teste" style={{ width: "100%", maxWidth: "100%" }}>
                                                    <label style={{ width: "100%" }}>
                                                        <AsyncSelect
                                                            cacheOptions
                                                            loadOptions={callApi}
                                                            onChange={(data) => {
                                                                setselectData(data);
                                                            }}
                                                            value={selectData}
                                                            //isDisabled={!!modelo}
                                                            defaultOptions
                                                            id="vinculo_categoria"
                                                            type="vinculo_categoria"
                                                            name="vinculo_categoria"
                                                            className="asyncSelectInput"
                                                            styles={{
                                                                control: (baseStyles, state) => ({
                                                                    ...baseStyles,
                                                                    margin: "0.6rem 0",
                                                                    paddingLeft: "2.5rem",
                                                                    height: "45px",
                                                                    border: "none",
                                                                    outline: state.isFocused ? '1px solid #4070f4;' : '0',
                                                                    borderRadius: "10px",
                                                                    boxShadow: "1px 1px 6px lightgray",
                                                                    fontSize: "0.8rem",
                                                                }),
                                                                input: (style) => ({
                                                                    ...style,
                                                                    color: "black",
                                                                }),
                                                                singleValue: (baseStyles) => ({
                                                                    ...baseStyles,
                                                                    color: selectData.value ? "black" : "gray",
                                                                }),
                                                                valueContainer: (baseStyles) => ({
                                                                    ...baseStyles,
                                                                    fontWeight: "lighter",
                                                                }),
                                                                option: (styles) => {
                                                                    return {
                                                                        ...styles,
                                                                        fontWeight: "lighter",
                                                                        color: "black",
                                                                    };
                                                                },
                                                            }}
                                                        />
                                                        {formik.errors.vinculo_categoria && (
                                                            <div className="error" style={{ fontWeight: "lighter" }}>{formik.errors.vinculo_categoria}</div>
                                                        )}
                                                    </label>
                                                </div>
                                                {/*<!-- Custom select structure --> */}
                                            </div>


                                            <div className="input-box" style={{ width: "100%", transition: sidebar ? "200ms" : "100ms" }}>
                                                <label>{modelo?.value === 2 ? "Nome do tamanho" : "Nome do item"}<span style={{ color: "red" }}>*</span></label>
                                                <input
                                                    placeholder={modelo?.value === 2 ? "Ex.: Grande" : "Digite o nome do item"}
                                                    id="title"
                                                    type="title"
                                                    name="title"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.title}
                                                />
                                                {formik.errors.title && (
                                                    <div className="error">{formik.errors.title}</div>
                                                )}
                                            </div>

                                            <div className="input-box descricaoItem" style={{ width: "100%", transition: sidebar ? "200ms" : "100ms" }}>
                                                <label>Descrição</label>
                                                {/*<input 
                                            placeholder="Digite a descrição" 
                                            id="description"
                                            type="description"
                                            name="description"
                                            onChange={formik.handleChange}
                                            value={formik.values.description}
                                            />
                                            */}
                                                <textarea placeholder="Digite aqui a descrição desejada"
                                                    value={valueDescricao} onChange={handleMaxCharDescricao} maxLength="1000" className="text-wrap-description" ref={descriptionRef} />
                                                <div className="divCounterChar">{valueDescricao.length}/1000</div>
                                                {formik.errors.description && (
                                                    <div className="error">{formik.errors.description}</div>
                                                )}
                                            </div>
                                        </div>

                                        <div className="divImgAddItem">
                                            <ImageUploading
                                                multiple
                                                value={images}
                                                onChange={onChangeImg}
                                                maxNumber={maxNumber}
                                                maxFileSize={maxMbFileSize}
                                                acceptType={["jpg", "png"]}
                                                dataURLKey="data_url"
                                            >

                                                {({
                                                    imageList,
                                                    onImageUpload,
                                                    onImageRemoveAll,
                                                    onImageUpdate,
                                                    onImageRemove,
                                                    isDragging,
                                                    dragProps,
                                                    errors
                                                }) => (
                                                    // write your building UI

                                                    <div className="upload__image-wrapper imgItem"
                                                        style={{
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                            width: "100%",
                                                            //marginLeft:"5%",
                                                            height: "100%",
                                                            padding: "10px"
                                                        }}
                                                    >

                                                        <div onClick={images.length === 0 ? onImageUpload : null} {...dragProps}
                                                            style={{
                                                                border: imageList.length > 0 ? "solid 1px lightgray" : "dashed 1px #4281FF",
                                                                borderRadius: "5px", minWidth: "198px", minHeight: "198px",
                                                                background: "rgb(247,247,247)",
                                                                cursor: images.length === 0 ? 'pointer' : '',
                                                                display: "flex",
                                                                alignItems: "center",
                                                                flexDirection: "column"
                                                            }}
                                                            className="divUploadImgItem"
                                                        >
                                                            {imageList.length > 0 ? "" :
                                                                <>
                                                                    <div className="iconLanche">
                                                                        <MdFastfood style={{ fontSize: 44 }} />
                                                                    </div>
                                                                    <div style={{ fontSize: 11, fontWeight: '700' }}>Escolha a foto</div>
                                                                </>
                                                            }
                                                            {isDragging ? "Solte aqui..." : imageList.length > 0 ? "" : <a style={{ padding: '0 33px', fontSize: 11 }}>Clique aqui, ou arraste a foto para cá.</a>}
                                                            {imageList.map((image, index) => (
                                                                <div key={index} className="image-item" style={{ justifyContent: "center", display: "grid", gap: 3 }}>
                                                                    {/*<div className="boxImgToFit">    
                                                                    <img src={image['data_url']} alt="" width="138"/>
                                                                </div> */}
                                                                    <CImage rounded thumbnail fluid src={image['data_url']} /*width={150} height={150}*/ />
                                                                    <div className="image-item__btn-wrapper addItemPart" style={{ marginLeft: "0px" }}>
                                                                        <button className="btnAttImgItem" type="button" style={{ fontSize: "14px" }}
                                                                            onClick={(event) => {
                                                                                event.stopPropagation();
                                                                                onImageUpdate(index)
                                                                            }
                                                                            }>
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width="18px"
                                                                                height="18px"
                                                                                viewBox="0 0 24 24"
                                                                                fill="none"
                                                                                stroke="#5A5A5A"
                                                                                strokeWidth={2}
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                                className="feather feather-refresh-ccw"
                                                                            >
                                                                                <polyline points="1 4 1 10 7 10" />
                                                                                <polyline points="23 20 23 14 17 14" />
                                                                                <path d="M20.49 9A9 9 0 0 0 5.64 5.64L1 10m22 4l-4.64 4.36A9 9 0 0 1 3.51 15" />
                                                                            </svg>
                                                                        </button>
                                                                        <button className="btnDelImgItem" type="button" style={{ fontSize: "14px" }} onClick={(event) => {
                                                                            event.stopPropagation();
                                                                            onImageRemove(index)
                                                                        }
                                                                        }>
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width="18px"
                                                                                height="18px"
                                                                                viewBox="0 0 24 24"
                                                                                fill="none"
                                                                                stroke="#5A5A5A"
                                                                                strokeWidth={2}
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                                className="feather feather-trash-2"
                                                                            >
                                                                                <polyline points="3 6 5 6 21 6" />
                                                                                <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />
                                                                                <line x1={10} y1={11} x2={10} y2={17} />
                                                                                <line x1={14} y1={11} x2={14} y2={17} />
                                                                            </svg>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>


                                                        {errors && (
                                                            <div>
                                                                {errors.maxNumber && (
                                                                    <span style={{ color: "red" }}>Limite de {maxNumber} imagem!</span>
                                                                )}
                                                                {errors.acceptType && (
                                                                    <span style={{ color: "red" }}>Formato de arquivo não suportado!</span>
                                                                )}
                                                                {errors.maxFileSize && (
                                                                    <span style={{ color: "red", fontSize: 12 }}>Arquivo Excede o Tamanho Máximo Permitido!</span>
                                                                )}
                                                                {/* {errors.resolution && (
                                                            <span>Resolução muito baixa.</span>
                                                        )} */}
                                                            </div>
                                                        )}

                                                        {errImg && (
                                                            <div>
                                                                <span style={{ color: "red" }}>Imagem Obrigatória!</span>
                                                            </div>
                                                        )
                                                        }
                                                        <div style={{ display: "flex", justifyContent: "center", width: "100%", marginTop: "0px" }}>
                                                            <div><a style={{ fontSize: "11px", color: 'gray' }}>(Serão aceitos somente arquivos JPG e PNG de no Máximo 2mb)</a></div>
                                                        </div>

                                                    </div>

                                                )}
                                            </ImageUploading>
                                        </div>
                                    </div>

                                    {
                                        modelo && modelo.value === 2 ?
                                            null
                                            :
                                            <div>
                                                <div className="input-box precoItemCustom" style={{ width: "100%", transition: sidebar ? "200ms" : "100ms" }}>
                                                    <label>Preço</label>
                                                    {console.log("MODELOO:::", modelo)}
                                                    <InputMoney
                                                        onChange={(event) => onChange(event, 'price', true)}
                                                        value={valuePreco}
                                                        onFocus={handleFocus}
                                                        onBlur={handleBlur}
                                                        margin="0px 0px 16px 0px"
                                                        style={{
                                                            outline: isFocused ? '2px solid #4070f4' : 'none',
                                                            borderRadius: 10,
                                                            margin: '0.6rem 0 0.2rem 0',
                                                        }}
                                                        className=""
                                                        placeholder="Preço"
                                                    />
                                                    {formik.errors.preco && (
                                                        <div className="error">{formik.errors.preco}</div>
                                                    )}
                                                </div>
                                            </div>
                                    }


                                </div>



                            </div>

                        </form>
                    </div>
                </Teste>

            </PermissionGate>
        </>


    );
};

export default Item;