import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { api } from '../../services/api'; // Importa a API para chamadas HTTP
import imgLogoCliente from '../../img/logoBlue.png';
import imageUrl from '../../img/backgroundLogin.png';
import './styles.css';
import { toast } from "react-toastify";
import { FaEye, FaEyeSlash } from 'react-icons/fa'; // Ícones para mostrar/ocultar senha

const RedefineSenhaPage = () => {
  const { token } = useParams(); // Captura o token da URL
  const navigate = useNavigate();

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false); // Estado para alternar visibilidade da senha
  const [showConfirmPassword, setShowConfirmPassword] = useState(false); // Estado para alternar visibilidade da confirmação

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setError("As senhas não coincidem!");
      toast("As senhas não coincidem!", { autoClose: 2000, type: "error" });
      return;
    }

    if (password.length < 6) {
      setError("A senha deve conter no mínimo 6 caracteres!")
      toast("A senha deve conter no mínimo 6 caracteres!", { autoClose: 2000, type: "error" });
      return;
    }

    try {
      const response = await api.post(`/reset-password/${token}`, { newPassword: password, confirmPassword: confirmPassword });

      if (response.status === 200) {
        setMessage(response.data.msg);
        toast(response.data.msg, { autoClose: 3000, type: "success" });
        setError("");
        setTimeout(() => navigate('/login'), 2000); // Redireciona para login após 2s
      } else {
        // Trata outros status (se aplicável)
        setMessage(response.data.msg || "Erro ao redefinir a senha.");
        toast(response.data.msg || "Erro ao redefinir a senha.", { autoClose: 2000, type: "error" });
      }
    } catch (error) {
      // Tratando erros retornados pelo backend
      if (error.response && error.response.data) {
        setError(error.response.data.msg || "Erro ao redefinir a senha.");
        toast(error.response.data.msg || "Erro ao redefinir a senha.", { autoClose: 2000, type: "error" });
      } else {
        // Tratando erros de conexão ou outros problemas
        setError("Erro ao conectar ao servidor.");
        toast("Erro ao conectar ao servidor.", { autoClose: 2000, type: "error" });
      }
      console.error(error);
    }
  };

  return (
    <div className="container">
      <div className="container-login"
        style={{
          background: `url(${imageUrl}) no-repeat left`,
          backgroundSize: 'cover',
          backgroundHeight: 'auto',
          backgroundSize: '100% 100%',
        }}
      >
        <div className="wrapContent" style={{
          width: '90%',
          height: '90%',
          background: 'white',
          boxShadow: '0px 0px 24px rgba(0, 0, 0, 0.40)',
          borderRadius: 8,
          overflow: 'hidden',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          display: 'inline-flex'
        }}>
          <div className="hide-on-mobile" style={{
            width: '50%', background: '#F5F5F5', display: 'inline-flex',
            flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 8, height:'100%'
          }}>
            <div style={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 24, display: 'flex' }}>
              <img style={{ width: 321.70, height: 99.47 }} src={imgLogoCliente} alt="Logo" />
              <div style={{ textAlign: 'center', color: '#074D82', fontSize: 32, fontFamily: 'Inter', fontWeight: '600', wordWrap: 'break-word' }}>Gerencie seus pedidos</div>
              <div style={{ width: 347, textAlign: 'center', color: '#074D82', fontSize: 24, fontFamily: 'Inter', fontWeight: '600', wordWrap: 'break-word' }}>E automatize o seu atendimento de verdade</div>
            </div>
          </div>

          <div className="onMobile" style={{ flex: '1 1 0', justifyContent: 'center', alignItems: 'center', display: 'flex', height:'100%' }}>
            <div style={{ width: 300, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 32, display: 'inline-flex' }}>
              <div className="text-center">
                <span style={{ color: 'gray', textDecoration: 'solid', fontSize: 24 }}>Nova Senha</span>
              </div>

              <div className="text-center">
                Quase pronto, basta digitar sua nova senha abaixo e confirmar.
              </div>

              <form className="login-form" onSubmit={handleSubmit}>
                <div className="wrap-input">
                  <input
                    className={password !== "" ? 'has-val input' : 'input'}
                    type={showPassword ? "text" : "password"} // Alterna entre texto e senha
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    placeholder="Digite a nova senha"
                    required
                  />
                  <button
                    type="button"
                    onClick={() => setShowPassword(!showPassword)} // Alterna o estado
                    style={{ position: 'absolute', top: '10px', right: '10px', background: 'none', border: 'none', cursor: 'pointer', color: 'dimgray' }}
                  >
                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                  </button>
                </div>

                <div className="wrap-input">
                  <input
                    className={confirmPassword !== "" ? 'has-val input' : 'input'}
                    type={showConfirmPassword ? "text" : "password"} // Alterna entre texto e senha
                    value={confirmPassword}
                    onChange={e => setConfirmPassword(e.target.value)}
                    placeholder="Confirme a nova senha"
                    required
                  />
                  <button
                    type="button"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)} // Alterna o estado
                    style={{ position: 'absolute', top: '10px', right: '10px', background: 'none', border: 'none', cursor: 'pointer', color: 'dimgray' }}
                  >
                    {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                  </button>
                </div>

                {error && <p style={{ color: 'red' }}>{error}</p>}
                {message && <p style={{ color: 'green' }}>{message}</p>}

                <div className="container-login-form-btn">
                  <button className="login-form-btn" type="submit">Cadastrar nova senha</button>
                </div>
              </form>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RedefineSenhaPage;
