import React, { useState, useRef, useEffect, useContext } from "react";
//import './style.css'
import styled from 'styled-components';
//import InputMask from 'react-input-mask'
//import CurrencyInput from 'react-currency-input-field';
import ImageUploading from 'react-images-uploading';
import { CImage } from '@coreui/react'
//import Image from 'react-image'
//import Gallery from 'react-image-gallery'
import Map from './index'
import CryptoJS from 'crypto-js';
import AsyncSelect from 'react-select/async';
import { AuthContext } from "../../contexts/auth";
import { SidebarContext } from "../../AppRoutes";
import ConfirmDialog from "../../components/ConfirmDialog";
import { getCategorias, registerItem, updateRaioEntregaEmpresa, getEmpresa, deleteRaioEntrega, deleteBairroEntrega, getBairros, updateTypeRegion, updateBairroEntregaEmpresa } from "../../services/api";

import PermissionGate from "../../services/PermissionGate";

import { useNavigate } from "react-router-dom";
import LeftMenu from "../../components/LeftMenu";


import InputMoney from "./InputMoney";

import { useFormik } from "formik";
import * as Yup from "yup";

import * as AiIcons from 'react-icons/ai'
import * as SlIcons from 'react-icons/sl'
import { MdFastfood } from "react-icons/md";
import { BsPlusCircle } from "react-icons/bs";
import { FaEdit, FaTrash } from 'react-icons/fa'

import { toast } from "react-toastify";

const Teste = styled.div`

    display: flex;
    margin-left:  ${({ sidebar }) => (sidebar ? '250px' : '100px')}; 
    height:auto;
    width:auto;
    transition: 150ms;
    background-color:rgb(247,247,247)!important;
    overflow: initial;
    z-Index: 9;

    @media (max-width: 880px) {
        margin-left: 0;
    }
`;


const AddRaioEntrega = () => {
    const secretKey = 'my-custom-secret-key:1af0addcce4b56f753ed10c285667ad031bae57af19747f576b05312b8cf85be';
    const userEncrypted = localStorage.getItem('user')
    const user = CryptoJS.AES.decrypt(userEncrypted, secretKey).toString(CryptoJS.enc.Utf8);
    const userParse = JSON.parse(user)
    const userID = userParse._id;
    const userName = userParse.name;
    const userEmail = userParse.email;
    var userImg = null;
    //console.log(empresa)
    const empresa = localStorage.getItem('empresa')
    const empresaParse = JSON.parse(empresa)
    const idEmpresa = empresaParse.id_empresa;
    const objIdEmpresa = empresaParse._id;

    const descriptionRef = useRef();
    const { sidebar, setSidebar } = useContext(SidebarContext);; //TESTANDO CONST NO AppROUTES

    const [selectedOption, setSelectedOption] = useState("");
    const [selectedDays, setSelectedDays] = useState([]);
    const [startTime, setStartTime] = useState("");
    const [endTime, setEndTime] = useState("");
    const [diasHorarios, setDiasHorarios] = useState([
        {
            dias: new Set(),
            startTime: "00:00",
            endTime: "23:59",
        }
    ]);
    const [isSubmitting, setIsSubmitting] = useState(false);



    const INITIAL_DATA = {
        value: "",
        label: 'Selecione o bairro',
    };
    const [selectData, setselectData] = useState(INITIAL_DATA);

    const mapResponseToValuesAndLabels = (data) => ({
        value: data.id,
        label: data.name,
    });

    const [latitudeEmpresa, setLatitudeEmpresa] = useState(null);
    const [longitudeEmpresa, setLongitudeEmpresa] = useState(null);
    const [refresh, setRefresh] = useState(false);
    const [raio, setRaio] = useState(1);
    const [valorEntrega, setValorEntrega] = useState(0);
    const [arrayRaioEntrega, setArrayRaioEntrega] = useState([]);
    const [arrayBairroEntrega, setArrayBairroEntrega] = useState([]);
    const [typeOfRegion, setTypeOfRegion] = useState("");

    const adicionaRaioNoMapa = async () => {

        if (raio === 0 || raio === '') {
            return toast("É obrigatório informar o raio!", { autoClose: 3000, type: "error" });
        }

        const raioToApi = raio * 1000;

        try {
            const response = await updateRaioEntregaEmpresa(objIdEmpresa, idEmpresa, userID, raioToApi, valorEntrega);
            if (response.status === 200) {
                //console.log(response.data)
                toast(response.data.msg, { autoClose: 3000, type: "success" });
                setRefresh(!refresh);
                setRaio(1)
                setValorEntrega(0);
            }
            else if (response.status === 201) {
                //console.log("Erro>",response)
                toast(response.data.msg, { autoClose: 3000, type: "error" });
                setRefresh(!refresh);
            }
        } catch (error) {
            //console.log("Erro>",error)
            toast("Erro ao adicionar raio de entrega!", { autoClose: 3000, type: "error" });
            setRefresh(!refresh);
        }

    }

    const adicionaBairro = async (bairro) => {

        //if(raio === 0 || raio === '' || valorEntrega === 0){
        //    return toast("Campos obrigatórios não preenchidos!",{ autoClose: 3000 , type: "error"});
        //}
        const bairroToApi = bairro;
        console.log("bairroToApi>", bairroToApi);

        try {
            const response = await updateBairroEntregaEmpresa(objIdEmpresa, idEmpresa, userID, bairroToApi, valorEntrega);
            if (response.status === 200) {
                //console.log(response.data)
                toast(response.data.msg, { autoClose: 3000, type: "success" });
                setRefresh(!refresh);
                setRaio(1)
                setValorEntrega(0);
            }
            else if (response.status === 201) {
                //console.log("Erro>",response)
                toast(response.data.msg, { autoClose: 3000, type: "error" });
                setRefresh(!refresh);
            }
        } catch (error) {
            //console.log("Erro>",error)
            toast("Erro ao adicionar bairro!", { autoClose: 3000, type: "error" });
            setRefresh(!refresh);
        } finally {
            setIsSubmitting(false);
            formik.setFieldValue("vinculo_bairro", "");
        }


    }

    const deletaRaioEntrega = async (raio_entrega_id) => {

        try {
            const response = await deleteRaioEntrega(userID, idEmpresa, raio_entrega_id);
            console.log("response>", response)
            if (response.status === 200) {
                toast(response.data.msg, { autoClose: 3000, type: "success" });
                setRefresh(!refresh);
            }
            else {
                //console.log("Erro>",response)
                toast(response.data.msg, { autoClose: 3000, type: "error" });
            }
        } catch (error) {
            toast("Erro na exclusão do raio de entrega!", { autoClose: 3000, type: "error" });
        }

    }

    const deletaBairroEntrega = async (bairro_entrega_id) => {

        try {
            const response = await deleteBairroEntrega(userID, idEmpresa, bairro_entrega_id);
            console.log("response>", response)
            if (response.status === 200) {
                toast(response.data.msg, { autoClose: 3000, type: "success" });
                setRefresh(!refresh);
            }
            else {
                //console.log("Erro>",response)
                toast(response.data.msg, { autoClose: 3000, type: "error" });
            }
        } catch (error) {
            toast("Erro na exclusão do bairro de entrega!", { autoClose: 3000, type: "error" });
        }

    }

    const [showMap, setShowMap] = useState(true);
    const [showBairro, setShowBairro] = useState(false);
    const [bairros, setBairros] = useState([]);

    const [confirmOpen, setConfirmOpen] = useState(false);
    const [regionTypeChange, setRegionTypeChange] = useState(null);
    const handleEditRegionType = (tempRegion) => {
        setConfirmOpen(true)
        setRegionTypeChange(tempRegion)
    }

    const changeRegionType = async () => {
        //console.log("regionTypeChange:", regionTypeChange)
        try {
            const response = await updateTypeRegion(objIdEmpresa, idEmpresa, userID, regionTypeChange);
            if (response.status === 200) {
                //carregaBairros();
                setShowMap(false);
                setShowBairro(true);
                setTypeOfRegion(regionTypeChange);
                setRefresh(!refresh);
                //console.log("response.data>", response.data)
            } else {
                alert('Ocorreu um erro ao atualizar o tipo de região de entrega');
            }

        } catch (err) {
            //alert('Ocorreu um erro ao atualizar o tipo de região de entrega');
            console.log(err)

        }
    }


    /*const carregaBairros = async () => {
        try {
            const response = await getBairros(); 
            console.log(response.data);
            setBairros(response.data);
          } catch (error) {
            console.error('Error fetching data:', error);
        }

    }*/
    const [city, setCity] = useState(null);
    const [uf, setUf] = useState(null);

    useEffect(() => {
        (async () => {
            const response = await getEmpresa(userID, idEmpresa);
            console.log(response.data.empresa.region_type_delivery)
            let latitude = parseFloat(response.data.empresa.latitude);
            let longitude = parseFloat(response.data.empresa.longitude);
            setLatitudeEmpresa(latitude);
            setLongitudeEmpresa(longitude);
            setCity(response.data.empresa.municipio)
            setUf(response.data.empresa.estado)
            if (response.data.empresa.region_type_delivery === "raio") {
                setTypeOfRegion(response.data.empresa.region_type_delivery)
                setArrayRaioEntrega(response.data.empresa.raios_entrega)
                console.log("Passou no raio")
            }
            if (response.data.empresa.region_type_delivery === "bairro") {
                setTypeOfRegion(response.data.empresa.region_type_delivery)
                setArrayBairroEntrega(response.data.empresa.bairros_entrega)
                console.log("Passou no bairro")
            }
        })();
        //eslint-disable-next-line
    }, [refresh]);


    async function callApi(value) {
        console.log("funcao chamada!")
        // Verifica se 'city' e 'uf' estão definidos antes de chamar a API
        if (!city || !uf) {
            console.log("Cidade ou UF ainda não definidos.");
            return []; // Retorna array vazio se 'city' ou 'uf' ainda não estão disponíveis
        }

        console.log({city, uf});

        const data = await getBairros(city, uf)
            .then((response) => response.data)
            .then((response) => response.map(mapResponseToValuesAndLabels))
            .then((response) => response.filter((i) => i.label.toLowerCase().includes(value.toLowerCase())));

        return data;
    }


    const [errImg, setErrImg] = React.useState(false);
    const [images, setImages] = React.useState([]);
    const maxNumber = 1;
    const maxMbFileSize = 2 * 1024 * 1024; // 2Mb

    const onChangeImg = (imageList, addUpdateIndex) => {
        // data for submit
        console.log(imageList, addUpdateIndex);
        setImages(imageList);
    };


    /*useEffect(() => {
        (async () => {
            
            // Get address from latitude & longitude.
            geocode(RequestType.LATLNG, "-16.722070,-49.278263")
            .then(({ results }) => {
            const address = results[0].formatted_address;
            console.log(address);
            })
            .catch(console.error);
            //const teste = [response.data]
            //setLoading(false);
            //console.log("diasHorarios",diasHorarios)
            //console.log("horariosUnicos>",horariosUnicos)
            
        })();
    },[]);*/

    //Validações
    const SignUpSchema = Yup.object().shape({
        //email: Yup.string().email("Insira um e-mail válido").required("Obrigatório"),
        //cnpj: Yup.string().required("Obrigatório"),
        //title: Yup.string().required("Obrigatório"),
        vinculo_bairro: Yup.string().required("Obrigatório"),
        //disponibilidade: Yup.string().required("Obrigatório"),
    });

    const formik = useFormik({
        validationSchema: SignUpSchema,
        validateOnBlur: false,
        validateOnChange: false,
        initialValues: {
            vinculo_bairro: "",
            //title: "",
            //preco: 0,
        },
        onSubmit: (values) => {
            //alert(JSON.stringify(values, null, 2));
            //console.log(values);
            setIsSubmitting(true);
            //const description = descriptionRef.current.value;
            //const title = values.title;
            //const price = values.preco;
            //handleSaveImg();

            const bairro = values.vinculo_bairro;

            adicionaBairro(bairro);
            //handleSubmitAPI(title, description, price);

        },
    });

    const navigate = useNavigate();

    const handleBack = () => {
        //setSidebar(!sidebar)
        navigate("/list-item");
    }


    /*const handleSubmitAPI = (title, description, price) => {
        //e.preventDefault();

        const createdBy = userID;
        const out = false;
        const category_item_id = selectData.value;
        const category_item_title = selectData.label;
        const disponibilidade = 'sempre';
        console.log("DEU SUBMIT")

        //Registrando o item
        console.log("submit categoria!", { createdBy, idEmpresa, category_item_id, category_item_title, title, description, out, images, price, disponibilidade });
        registerItem(createdBy, idEmpresa, category_item_id, category_item_title, title, description, out, images, price, disponibilidade).then(cast => {
            if (cast.status === 201) {
                toast(cast.data.msg, { autoClose: 5000, type: "success" });
                setIsSubmitting(false);
                navigate("/list-item");
            }
            else {
                toast(cast.data.msg, { autoClose: 5000, type: "error" });
                setIsSubmitting(false);
            }

        }).catch(err => {
            console.log('Oh noooo!!');
            const msg = err.response.data.msg;
            //console.log("MENSAGEM", msg)
            toast(msg, { autoClose: 5000, type: "error" });
            setIsSubmitting(false);

        });

    }*/


    const [valueDescricao, setValueDescricao] = useState('');

    const handleMaxCharDescricao = (e) => {
        const newValue = e.target.value;
        if (newValue.length <= 1000) {
            setValueDescricao(newValue);
        }
    };

    //const [horariosToDelete, setHorariosToDelete] = useState("");
    const handleDeleteHorario = (horarioIndex) => {
        setDiasHorarios((prevDiasHorarios) => {
            const newDiasHorarios = [...prevDiasHorarios];
            newDiasHorarios.splice(horarioIndex, 1);
            return newDiasHorarios;
        });
    };

    const handleOptionChange = (event) => {
        if (event.target.value === "sempre" || event.target.value === "pausado") {
            setDiasHorarios([
                {
                    dias: new Set([""]),
                    startTime: "00:00",
                    endTime: "23:59",
                }
            ]);
        }
        setSelectedOption(event.target.value);
    };

    const horariosPorDia = diasHorarios.reduce((acc, { dias, startTime, endTime }) => {
        dias.forEach((dia) => {
            if (!acc[dia]) {
                acc[dia] = {
                    dia,
                    startTime,
                    endTime,
                };
            } else {
                const { startTime: startTimeAcc, endTime: endTimeAcc } = acc[dia];
                const [startTimeHour, startTimeMin] = startTime.split(':').map(Number);
                const [startTimeAccHour, startTimeAccMin] = startTimeAcc.split(':').map(Number);
                const [endTimeHour, endTimeMin] = endTime.split(':').map(Number);
                const [endTimeAccHour, endTimeAccMin] = endTimeAcc.split(':').map(Number);

                if (startTimeHour < startTimeAccHour || (startTimeHour === startTimeAccHour && startTimeMin < startTimeAccMin)) {
                    acc[dia].startTime = startTime;
                }

                if (endTimeAccHour < endTimeHour || (endTimeAccHour === endTimeHour && endTimeAccMin < endTimeMin)) {
                    acc[dia].endTime = endTime;
                }
            }
        });

        return acc;
    }, {});

    const onChange = (event, nameObject, isNumber) => {
        setValorEntrega(isNumber ? Number(event.target.value) : event.target.value);
        formik.setFieldValue("preco", isNumber ? Number(event.target.value) : event.target.value);
    };

    const horariosUnicos = Object.values(horariosPorDia);

    const handleDayChange = (horarioIndex, key) => {
        const newDiasHorarios = [...diasHorarios];
        const horario = newDiasHorarios[horarioIndex];

        if (horario.dias.has(key)) {
            horario.dias.delete(key);
        } else {
            horario.dias.add(key);
        }

        setDiasHorarios(newDiasHorarios);
    };

    const validateTime = (value) => {
        const regex = /^([0-1][0-9]|2[0-3]):[0-5][0-9]$/;
        return regex.test(value);
    };
    const handleTimeChange = (horarioIndex, setTime, event) => {
        const value = event.target.value;
        if (validateTime(value)) {
            const newHorarios = [...diasHorarios];
            newHorarios[horarioIndex][setTime] = value;
            setDiasHorarios(newHorarios);
        }
    };

    const handleAddHorario = () => {
        setDiasHorarios(diasHorarios.concat({
            dias: new Set(),
            startTime: "00:00",
            endTime: "23:59",
        }));
    };

    const isDayAlreadySelectedInHorario = (day, currentHorarioIndex) => {
        return diasHorarios
            .filter((horario, index) => index !== currentHorarioIndex)
            .some((horario) => horario.dias.has(day));
    };

    const [isFocused, setIsFocused] = useState(false);
    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleChange = (type, value, delta) => {
        if (value + delta >= 0) {
            if (type === 'raio') {
                setRaio(Number(value) + delta);
            }
        }
    };

    const handleBlur = (type, value) => {
        setIsFocused(false);
    };

    const handleDecrement = (type) => {
        handleChange(type, type === 'raio' && raio, -1);
    };

    const handleIncrement = (type) => {
        handleChange(type, type === 'raio' && raio, 1);
    };

    const orderedArrayRaioEntrega = arrayRaioEntrega.sort((a, b) => a.raio_entrega - b.raio_entrega);

    const orderedArrayBairroEntrega = arrayBairroEntrega.sort((a, b) => a.bairro_entrega - b.bairro_entrega);

    return (

        <>
            <PermissionGate permissions={['default']}>


                {/*<LeftMenu setSidebar={setSidebar} sidebar={sidebar} />*/}

                <ConfirmDialog
                    title="Alterar o modo de cadastro de taxas de entrega?"
                    open={confirmOpen}
                    setOpen={setConfirmOpen}
                    onConfirm={changeRegionType}
                >
                    <div>
                        Tem certeza que deseja alterar o modo de cadastro de taxas de entrega?
                        <br />
                        <span style={{ fontSize: 12, display:'flex', lineHeight:1, marginTop:5, fontWeight:'500' }}>
                            Ao alterar o modo de cadastro de taxas de entrega, os cadastros já existentes serão excluídos.
                        </span>
                    </div>
                </ConfirmDialog>

                <Teste sidebar={sidebar}>
                    <div className="w-100 p-4">
                        {/*<form onSubmit={handleSubmitButton}>*/}
                        <form onSubmit={formik.handleSubmit}>

                            <div className="form-header" style={{ marginBottom: "0px" }}>
                                <div className="title">
                                    <h1>Região de Entrega</h1>
                                </div>
                            </div>

                            {/* 
                            <div style={{ display: "flex", justifyContent: "right" }}>

                                <div className="div-buttons">
                                    <div className="continue-button">
                                        <button type="submit" disabled={isSubmitting}>
                                            <AiIcons.AiOutlineSave style={{ marginRight: "5px", fontSize: "22px", marginBottom: "2px" }} /><a>Salvar</a>
                                        </button>
                                    </div>

                                    <div className="back-button">
                                        <button onClick={handleBack}>
                                            <SlIcons.SlActionUndo style={{ color: "#ff4c4c", marginRight: "5px", fontSize: "18px", marginBottom: "2px" }} /><a >Voltar</a>
                                        </button>
                                    </div>
                                </div>

                            </div>
                            */}

                            <div className="contentItemComplete flex-column flex-md-row">
                                {/*<div className="input-group w-100" style={{maxHeight:50, maxWidth: "450px"}}>
                                    <div className="etapasAddItem etapaAtiva">
                                        <label>Região de Entrega</label>
                                    </div>                                    
                                </div>*/}

                                <div className="input-group mh-100">
                                    <div className="toogleRaioContainer">
                                        <div className="divToogleRaio">
                                            <div className="btnToogleBairro">
                                                <button onClick={() => handleEditRegionType("bairro")} type="button"
                                                    style={{
                                                        background: typeOfRegion === "bairro" ? "rgb(49, 140, 213)" : "#FFF",
                                                        color: typeOfRegion === "bairro" ? "#FFF" : ""
                                                    }}>Bairro</button>
                                            </div>

                                            <div className="btnToogleRaio">
                                                <button onClick={() => handleEditRegionType("raio")} type="button"
                                                    style={{
                                                        background: typeOfRegion === "raio" ? "rgb(49, 140, 213)" : "#FFF",
                                                        color: typeOfRegion === "raio" ? "#FFF" : ""
                                                    }}>Raio</button>
                                            </div>

                                        </div>
                                    </div>

                                    {
                                        typeOfRegion === "bairro" ? (
                                            <>
                                                <div className="formGroupRow">
                                                    {/*<div className="divContainerAddItem Bairro">
                                                        <label>Bairro<span style={{ color: "red" }}>*</span></label>                                                        
                                                        <div className="selectdiv-teste" style={{ width: "100%", maxWidth: "100%" }}>
                                                            <label style={{ width: "100%" }}>
                                                                {
                                                                    city && uf ? (
                                                                        <AsyncSelect
                                                                            cacheOptions
                                                                            loadOptions={callApi}
                                                                            onChange={(data) => setselectData(data)}
                                                                            value={selectData}
                                                                            defaultOptions
                                                                            id="vinculo_bairro"
                                                                            type="vinculo_bairro"
                                                                            name="vinculo_bairro"
                                                                            className="asyncSelectInput"
                                                                            styles={{
                                                                                control: (baseStyles, state) => ({
                                                                                    ...baseStyles,
                                                                                    margin: "0.6rem 0",
                                                                                    paddingLeft: "2.5rem",
                                                                                    height: "45px",
                                                                                    border: "none",
                                                                                    outline: state.isFocused ? '1px solid #4070f4' : '0',
                                                                                    borderRadius: "10px",
                                                                                    boxShadow: "1px 1px 6px lightgray",
                                                                                    fontSize: "0.8rem",
                                                                                }),
                                                                                input: (style) => ({
                                                                                    ...style,
                                                                                    color: "black",
                                                                                }),
                                                                                singleValue: (baseStyles) => ({
                                                                                    ...baseStyles,
                                                                                    color: selectData.value ? "black" : "gray",
                                                                                }),
                                                                                valueContainer: (baseStyles) => ({
                                                                                    ...baseStyles,
                                                                                    fontWeight: "500",
                                                                                }),
                                                                                option: (styles) => ({
                                                                                    ...styles,
                                                                                    fontWeight: "500",
                                                                                    color: "black",
                                                                                }),
                                                                            }}
                                                                        />
                                                                    ) : (
                                                                        <p>Carregando dados da empresa...</p>
                                                                    )
                                                                }
                                                                {formik.errors.vinculo_bairro && (
                                                                    <div className="error" style={{ fontWeight: "lighter" }}>{formik.errors.vinculo_bairro}</div>
                                                                )}
                                                            </label>
                                                        </div>
                                                        
                                                    </div>
                                                    */}
                                                    <div className="input-box" style={{ width: "45%" }}>
                                                        <label>Bairro<span style={{color:"red"}}>*</span></label>
                                                        <input
                                                            placeholder="Digite o bairro"
                                                            id="vinculo_bairro"
                                                            type="vinculo_bairro"
                                                            name="vinculo_bairro"
                                                            onChange={formik.handleChange}
                                                            value={formik.values.vinculo_bairro}
                                                            style={{height:50, marginRight:10}}
                                                        />
                                                        {formik.errors.vinculo_bairro && (
                                                            <div className="error">{formik.errors.vinculo_bairro}</div>
                                                        )}
                                                    </div>

                                                    <div className="input-box precoItemCustom paraEntrega" style={{ width: "100%", transition: sidebar ? "200ms" : "100ms" }}>
                                                        <label>Preço<span style={{ color: 'red' }}>*</span></label>
                                                        <InputMoney
                                                            onChange={(event) => onChange(event, 'price', true)}
                                                            value={valorEntrega}
                                                            onFocus={handleFocus}
                                                            onBlur={handleBlur}
                                                            margin="0px 0px 16px 0px"
                                                            style={{
                                                                outline: isFocused ? '2px solid #4070f4' : 'none',
                                                                borderRadius: 10,
                                                                margin: '0.6rem 0 0.2rem 0',
                                                            }}
                                                            className=""
                                                            placeholder="Preço"
                                                        />
                                                        {/*valorEntrega===0 && (
                                                <div className="error">Obrigatório!</div>
                                            )*/}
                                                    </div>

                                                    <div className="divAddRaioEntrega">
                                                        <button className="buttonAddRaioEntrega" type="submit" style={{border:"none"}}>
                                                            <span>Adicionar</span>
                                                        </button>
                                                    </div>


                                                </div>

                                                <div>
                                                    <p>Total de {arrayBairroEntrega.length} registros</p>
                                                </div>

                                                <div className="scrollRaiosContainer">
                                                    <div className="tableQtdReg">
                                                        <div className="tableQtdRegHeader">
                                                            <div className="tableQtdRegHeaderRaio">Bairro</div>
                                                            <div className="tableQtdRegHeaderValor">Valor</div>
                                                        </div>
                                                        {orderedArrayBairroEntrega && orderedArrayBairroEntrega.length > 0 ? orderedArrayBairroEntrega.map((item, index) => (
                                                            <div className={`tableQtdRegBody ${index % 2 === 0 ? 'even' : 'odd'}`} key={index}>
                                                                <div className="tableQtdRegRow">
                                                                    <div className="qtdRegTableRowRaioAndValor">
                                                                        <div className="qtdRegTableRowRaio">{item.bairro_entrega}</div>
                                                                        <div className="qtdRegTableRowValor"><span>R$ {item.valor_entrega.toFixed(2).toString().replace('.', ',')}</span></div>
                                                                    </div>
                                                                    <div className="btnsQtdRegTable">
                                                                        {/*<FaEdit style={{color:"royalblue", cursor:"pointer"}}/>*/}
                                                                        <FaTrash style={{ color: "#ff4c4c", cursor: "pointer" }} type="button" onClick={()=>deletaBairroEntrega(item._id)}/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )) : null
                                                        }

                                                    </div>
                                                </div>
                                            </>

                                        ) :

                                            <>
                                                <div className="formGroupRow">
                                                    <div className="divContainerAddItem Mapa">

                                                        {latitudeEmpresa && longitudeEmpresa ? (
                                                            <Map arrayRaioEntrega={arrayRaioEntrega}
                                                                latitudeEmpresa={latitudeEmpresa}
                                                                longitudeEmpresa={longitudeEmpresa}
                                                            //raio={raio}
                                                            //setRaio={setRaio}
                                                            //valorEntrega={valorEntrega}
                                                            //setValorEntrega={setValorEntrega}
                                                            />
                                                        ) : (
                                                            <p>Carregando o mapa...</p>
                                                        )}


                                                    </div>

                                                </div>

                                                <div className="grupoKmAndPrice">

                                                    <div className="input-box number precoRaioEntrega">
                                                        <div className="precoEntrega">
                                                            <label>Raio(KM)<span style={{ color: 'red' }}>*</span></label>
                                                        </div>
                                                        <div style={{ paddingTop: 5 }}>
                                                            <button type="button" className={raio === 1 ? "decrement disable" : "decrement"} onClick={() => handleDecrement("raio")}
                                                            >
                                                                <pedeja-icon
                                                                    iconname="minus"
                                                                    style={{ height: 14 }}
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={14}
                                                                        height={14}
                                                                        viewBox="0 0 24 24"
                                                                        fill="none"
                                                                        stroke="currentColor"
                                                                        strokeWidth={2}
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                        className="feather feather-minus"
                                                                    >
                                                                        <line x1={5} y1={12} x2={19} y2={12} />
                                                                    </svg>
                                                                </pedeja-icon>
                                                            </button>
                                                            <input
                                                                id='raio'
                                                                name="raio"
                                                                type="number"
                                                                className="inputRaioPersonalizado"
                                                                //placeholder={0}
                                                                onKeyDown={(evt) =>
                                                                    //evt.key === '0' && evt.preventDefault() ||
                                                                    evt.key === 'e' && evt.preventDefault() ||
                                                                    evt.key === 'E' && evt.preventDefault() ||
                                                                    evt.key === '-' && evt.preventDefault() ||
                                                                    evt.key === '.' && evt.preventDefault() ||
                                                                    evt.key === ',' && evt.preventDefault()}
                                                                value={raio}
                                                                onChange={(e) => {
                                                                    const value = parseInt(e.target.value);
                                                                    if (value > 0 && value <= 9999) {
                                                                        setRaio(value);
                                                                    } else if (e.target.value === '') {
                                                                        setRaio('');
                                                                    }
                                                                }}
                                                                onBlur={() => handleBlur('raio', raio)}
                                                                max={9999}
                                                                min={1}
                                                                maxLength=""
                                                            />
                                                            <button type="button" className="increment" onClick={() => handleIncrement("raio")}
                                                            >
                                                                <pedeja-icon style={{ height: 14 }}>
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={14}
                                                                        height={14}
                                                                        viewBox="0 0 24 24"
                                                                        fill="none"
                                                                        stroke="currentColor"
                                                                        strokeWidth={2}
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                        className="feather feather-plus"
                                                                    >
                                                                        <line x1={12} y1={5} x2={12} y2={19} />
                                                                        <line x1={5} y1={12} x2={19} y2={12} />
                                                                    </svg>
                                                                </pedeja-icon>
                                                            </button>
                                                            {
                                                                raio === 0 ? (
                                                                    <div className="error" style={{ position: 'absolute' }}>O raio não pode ser 0!</div>
                                                                ) :
                                                                    raio === '' ? (
                                                                        <div className="error" style={{ position: 'absolute' }}>Obrigatório!</div>
                                                                    ) : null}
                                                        </div>
                                                    </div>

                                                    <div className="input-box precoItemCustom paraEntrega" style={{ width: "100%", transition: sidebar ? "200ms" : "100ms" }}>
                                                        <label>Preço<span style={{ color: 'red' }}>*</span></label>
                                                        <InputMoney
                                                            onChange={(event) => onChange(event, 'price', true)}
                                                            value={valorEntrega}
                                                            onFocus={handleFocus}
                                                            onBlur={handleBlur}
                                                            margin="0px 0px 16px 0px"
                                                            style={{
                                                                outline: isFocused ? '2px solid #4070f4' : 'none',
                                                                borderRadius: 10,
                                                                margin: '0.6rem 0 0.2rem 0',
                                                            }}
                                                            className=""
                                                            placeholder="Preço"
                                                        />
                                                        {/*valorEntrega===0 && (
                                                <div className="error">Obrigatório!</div>
                                            )*/}
                                                    </div>

                                                    <div className="divAddRaioEntrega">
                                                        <div className="buttonAddRaioEntrega" onClick={() => adicionaRaioNoMapa()}>
                                                            <span>Adicionar</span>
                                                        </div>
                                                    </div>

                                                </div>

                                                <div>
                                                    <p>Total de {arrayRaioEntrega.length} registros</p>
                                                </div>

                                                <div className="scrollRaiosContainer">
                                                    <div className="tableQtdReg">
                                                        <div className="tableQtdRegHeader">
                                                            <div className="tableQtdRegHeaderRaio">Raio(KM)</div>
                                                            <div className="tableQtdRegHeaderValor">Valor</div>
                                                        </div>
                                                        {orderedArrayRaioEntrega && orderedArrayRaioEntrega.length > 0 ? orderedArrayRaioEntrega.map((item, index) => (
                                                            <div className={`tableQtdRegBody ${index % 2 === 0 ? 'even' : 'odd'}`} key={index}>
                                                                <div className="tableQtdRegRow">
                                                                    <div className="qtdRegTableRowRaioAndValor">
                                                                        <div className="qtdRegTableRowRaio">{(item.raio_entrega) / 1000}</div>
                                                                        <div className="qtdRegTableRowValor"><span>R$ {item.valor_entrega.toFixed(2).toString().replace('.', ',')}</span></div>
                                                                    </div>
                                                                    <div className="btnsQtdRegTable">
                                                                        {/*<FaEdit style={{color:"royalblue", cursor:"pointer"}}/>*/}
                                                                        <FaTrash style={{ color: "#ff4c4c", cursor: "pointer" }} type="button" onClick={() => deletaRaioEntrega(item._id)} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )) : null
                                                        }

                                                    </div>
                                                </div>
                                            </>
                                    }

                                </div>



                            </div>

                        </form>
                    </div>
                </Teste>

            </PermissionGate>
        </>


    );
};

export default AddRaioEntrega;