import styled from "styled-components";
import React from 'react'
import ImageUploading from 'react-images-uploading';

import { updateUserImg } from "../../services/api";

import * as AiIcons from 'react-icons/ai';
import { toast } from "react-toastify";
import CryptoJS from 'crypto-js';

const ModalAddUserImg = styled.div`
  z-index:15;
  position: fixed;
  top: 0;
  left: 0;
  display: ${({ showModalImg }) => (showModalImg ? 'none' : 'flex')};
  float: left;
  min-width: 100%;
  min-height: 100%;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  padding: 5px 10px;
  list-style: none;
  background-color: rgba(0,0,0,0.5);
  background-clip: padding-box;
  align-items:center;
  justify-content:center;
`;

const DivAddImg = styled.div`
    z-index:20;
    position: absolute;
    top: 20%;
    left: 33%;
    float: left;
    min-width: 32%;
    min-height: 45%;
    padding: 5px 0;
    list-style: none;
    background-color: rgb(247,247,247)!important;
    background-clip: padding-box;
    border-radius: 5px;
`;


const ModalUserImg = ({setModalImg, showModalImg, userID}) => {
    
    const closeModelImg = () => setModalImg(!showModalImg);

    const [errImg, setErrImg] = React.useState(false);
    const [images, setImages] = React.useState([]);
    const maxNumber = 1;
    const maxMbFileSize = 2 * 1024 * 1024; // 2Mb

    const onChange = (imageList, addUpdateIndex) => {
        // data for submit
        console.log(imageList, addUpdateIndex);
        setImages(imageList);
    };

    // Lógica para atualizar a imagem do usuário no localStorage
    // Get the existing data
    //var existing = localStorage.getItem('user');
    const secretKey = 'my-custom-secret-key:1af0addcce4b56f753ed10c285667ad031bae57af19747f576b05312b8cf85be';
    const userEncrypted = localStorage.getItem('user')
    var existing = CryptoJS.AES.decrypt(userEncrypted, secretKey).toString(CryptoJS.enc.Utf8);
    // Convert the localStorage string to an array
    existing = JSON.parse(existing);

    const handleSaveImg = () => {
        if(images.length === 0){
            setErrImg(true);
        }else{
            //console.log("Save Image: ", images);
            updateUserImg(userID, images).then((res) => {
                //console.log("Update User Image: ", res);
                if(res.status === 200){
                    // Add new data to localStorage Array
                    //console.log("res.data.image:",res.data.image);
                    existing['user_img'] = [res.data.image];
                    // Save back to localStorage
                    const loggedUserEncrypted = CryptoJS.AES.encrypt(JSON.stringify(existing), secretKey).toString();
                    localStorage.setItem('user', loggedUserEncrypted);

                    toast(res.data.msg, {autoClose: 5000, type: "success"});
                    setErrImg(false);
                    closeModelImg(); 
                }
                else {
                    toast(res.data.msg, {autoClose: 5000, type: "error"});
                        
                }
            });
        }
                       
    }


    return (
        <ModalAddUserImg showModalImg={showModalImg}>
            <DivAddImg>

                {/* Teste Upload Images */}
                {/* <AiIcons.AiOutlineCloseCircle  onClick={closeModelImg} style={{marginLeft:"465px", fontSize:"25px", cursor:"pointer"}}/> */}

                <div className="m-5" style={{width:"94%"}}>
                <div style={{marginBottom:"20px", fontSize:"25px"}}>
                    <a>Editar Imagem</a>
                </div>

                <div style={{display:"flex", justifyContent:"right"/*, height:"80px"*/}}>
                    <div className="continue-button" style={{width:"150px", display:"flex", justifyContent:"right"}}>
                        <button onClick={handleSaveImg} style={{marginTop:"12px", height:"49.6px", width:"150px"}}>
                        <a><AiIcons.AiOutlineSave style={{marginRight: "5px",fontSize: "18px",marginBottom: "2px"}}/>Salvar</a> 
                        </button>
                    </div>                                 
                    <div className="back-button">                                                       
                        <button onClick={closeModelImg} type="button">    
                            <AiIcons.AiOutlineCloseCircle style={{color:"#ff4c4c",marginRight: "5px",fontSize: "18px",marginBottom: "2px"}}/><a>Fechar</a> 
                        </button>
                    </div>                            
                </div>   

                <ImageUploading
                    multiple
                    value={images}
                    onChange={onChange}
                    maxNumber={maxNumber}
                    maxFileSize={maxMbFileSize}
                    acceptType={["jpg", "png"]}
                    dataURLKey="data_url"
                >
                
                    {({
                    imageList,
                    onImageUpload,
                    onImageRemoveAll,
                    onImageUpdate,
                    onImageRemove,
                    isDragging,
                    dragProps,
                    errors
                    }) => (
                    // write your building UI
                    
                    <div className="upload__image-wrapper"
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                        //marginLeft:"5%",
                        height: "100%",
                        padding: "10px"                        
                    }}
                    >
                        
                        {imageList.length>0 ? <div></div>
                        :
                            <div style={{width:"100%"}}>
                            <a className="btn btn-secondary"
                            style={isDragging ? { background: 'red', marginBottom:"5px" } :{marginBottom:"5px"}}
                            onClick={onImageUpload}
                            {...dragProps}
                            >
                            Selecionar Arquivo
                            </a>  
                            </div>
                        }                  

                       
                        <div {...dragProps} 
                        style={{border: "dotted 2px #4281FF", 
                        borderRadius:"5px", width:"100%", height:"150px",
                        background:"rgb(247,247,247)",
                        display:"flex",
                        justifyContent:"center",
                        alignItems:"center"
                        }}
                        >
                            {isDragging ? "Solte aqui..." : imageList.length>0 ? "" : <a>... ou solte o arquivo aqui</a>}
                            {imageList.map((image, index) => (                                
                                <div key={index} className="image-item" style={{justifyContent:"center", display: "grid", padding:"4px"}}>
                                    <img src={image['data_url']} alt="" width="100" style={{marginLeft:"32px"}}/>                               
                                    <div className="image-item__btn-wrapper" style={{marginLeft:"0px"}}>
                                        <a className="btn btn-primary" style={{fontSize:"14px"}} onClick={() => onImageUpdate(index)}>Atualizar</a>
                                        <a className="btn btn-danger" style={{fontSize:"14px"}} onClick={() => onImageRemove(index)}>Remover</a>
                                    </div>
                                </div>
                            ))}
                        </div>                        

                        {errors && (
                            <div>
                            {errors.maxNumber && (
                                <span style={{color:"red"}}>Limite de {maxNumber} imagem!</span>
                            )}
                            {errors.acceptType && (
                                <span style={{color:"red"}}>Formato de arquivo não suportado!</span>
                            )}
                            {errors.maxFileSize && (
                                <span style={{color:"red"}}>Arquivo Excede o Tamanho Máximo Permitido!</span>
                            )}
                            {/* {errors.resolution && (
                                <span>Resolução muito baixa.</span>
                            )} */}
                            </div>
                        )}      

                        {errImg && (
                            <div>
                                <span style={{color:"red"}}>Imagem Obrigatória!</span>
                            </div>
                                )
                        }                  

                    </div>

                   
                                
                    )}                
                   
        
                </ImageUploading>
                

                <div style={{display:"flex", justifyContent:"center", width:"100%",marginTop:"0px"}}> 
                    <div><a style={{fontSize:"12px"}}>(Serão aceitos somente arquivos JPG e PNG de no Máximo 2mb)</a></div>                   
                </div> 

                {/* <a className="btn btn-sm btn-danger" onClick={closeModelImg} style={{color:"rgb(120,120,120)", background:"white"}}>Fechar</a> */}

                </div>
                {/* Fim Upload Images */}
                

            </DivAddImg>
        </ModalAddUserImg>
    )
}

export default ModalUserImg;