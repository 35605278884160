import React, { useState, useContext, useEffect } from "react";
import './style.css'
import styled from 'styled-components';
import 'bootstrap/dist/css/bootstrap.min.css';
import { SidebarContext } from "../../AppRoutes";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

import moment from 'moment'

import { AuthContext } from "../../contexts/auth";

import { getUsers, getUser, getEmpresa, getVinculoEmpresa, deleteUser } from "../../services/api";

import PermissionGate from "../../services/PermissionGate";

import { useNavigate } from "react-router-dom";
import LeftMenu from "../../components/LeftMenu";
import ModalEditUser from "../../components/ModalEditUser";
import ConfirmDialog from "../../components/ConfirmDialog";

import { BarContext } from "../../components/LeftMenu";

import { useFormik } from "formik";
import * as Yup from "yup";

import * as FiIcons from 'react-icons/fi'
import * as AiIcons from 'react-icons/ai'
import * as SlIcons from 'react-icons/sl'
import * as FaIcons from 'react-icons/fa'
import { useMemo } from "react";
import { Table } from "antd";

const Teste = styled.div`
    display: flex;
    margin-left:  ${({ sidebar }) => (sidebar ? '250px' : '100px')}; 
    height:auto;
    width:auto;
    transition: 150ms;
    background-color:rgb(247,247,247)!important;
    overflow: initial;
    z-Index: 9;

    @media (max-width: 880px) {
        margin-left: 0;
    }
`;


const columns = [
    {
      title: 'Código',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'E-mail de Acesso',
      dataIndex: 'access_email',
      key: 'access_email',
    },
    {
      title: 'Papel do Usuário',
      dataIndex: 'user_function',
      key: 'user_function',
      responsive: ['lg'],
    },
    {
        title: 'Data Inclusão',
        dataIndex: 'date_inclusion',
        key: 'date_inclusion',
        responsive: ['lg'],
    },
    {
        title: 'Empresa Vinculada',
        dataIndex: 'company_vinc',
        key: 'company_vinc',
        responsive: ['lg'],
    },
    {
        title: 'Nome da Empresa',
        dataIndex: 'company_name',
        key: 'company_name',
        responsive: ['lg'],
    },
    {
        title: 'Ações',
        dataIndex: 'actions',
        key: 'actions',
    }
];


const ListUsers = ({list = []} ) =>{
    const { user } = useContext(AuthContext);
    const navigate = useNavigate();

    const { sidebar, setSidebar } = useContext(SidebarContext);;
    //console.log("LISTUSERS",sidebar);

    const [order, setOrder] = useState(1)
    const [columnOrder, setColumnOrder] = useState('title')

    const [users, setUsers] = useState([]);
    //const [usersNomeEmpresa, setUsersNomeEmpresa] = useState([]);
    const [filter, setFilter] = useState('');
    
    const [_idUserEdit, set_idUserEdit] = useState('');
    const [usernameEdit, setUsernameEdit] = useState('');
    const [emailEdit, setEmailEdit] = useState('');
    const [roleEdit, setRoleEdit] = useState('');

    const [result, setResult] = useState(false);

    const [showEditUser, setEditUser] = useState(true);
    
    const [loading, setLoading] = useState(true);

    const [refresh, setRefresh] = useState(false);

    const INITIAL_DATA = {
        value: "",
        label: 'Selecione uma empresa',
    };
            
    const [selectData, setselectData] = useState(INITIAL_DATA);

    useEffect(() => {
        (async () => {
       
            const response = await getUsers(user._id);
            //console.log("TESTEEE:::",user._id)
            //const teste = [response.data]
            setUsers(response.data.user);
            //setUsersNomeEmpresa(response.data.empresas_vinculo);
            console.log(response.data)
            setLoading(false);
            setRefresh(false);
            
        })();
    },[refresh]);
  
    const handleEdit = async idToEdit => {
        setEditUser(!showEditUser);
        const response = await getUser(idToEdit);
        //console.log("Infos do Edit:",response.data.user)
        //console.log(idToEdit,"----");
        if(showEditUser){
            set_idUserEdit(idToEdit)            
            setUsernameEdit(response.data.user.name)
            setEmailEdit(response.data.user.email)            
            setRoleEdit(response.data.user.role)

            if (response.data.user.vinculo_empresa){
                //console.log("TEM EMPRESA VINCULADA!")
                const responseVinculo = await getVinculoEmpresa(idToEdit);            
                if(responseVinculo.data.vinculo){ 
       
                    setselectData({
                        value:responseVinculo.data.vinculo.id_empresa,
                        label:responseVinculo.data.vinculo.id_empresa + " - " + responseVinculo.data.vinculo.cnpj + " - " + responseVinculo.data.vinculo.name
                    })
                }
                if(!responseVinculo.data.vinculo){
                    //console.log("MSG:", responseVinculo.data.msg)
                }
                
            }
            else {
                //console.log("NÃO TEM EMPRESA VINCULADA!")
                setselectData({
                    value:"",
                    label:"",
                })
            }

        }
    }

    const [confirmOpen, setConfirmOpen] = useState(false);
    const [shouldDelete, setShouldDelete] = useState(false);
    const [idToDelete, setIdToDelete] = useState('');
    const handleDelete = (idRecebido) => {
        // Abre Componente de Confirmação do delete
        setConfirmOpen(true)

        setIdToDelete(idRecebido)
        if(idRecebido){
            //console.log("ID RECEBIDO:",idRecebido)
            setShouldDelete(true);
        }
    }
  
    const deleteReg = async () => {
        //console.log("O ID CLICADO FOI :::",idToDelete);
        //console.log("ID do Usuário que irá efetuar a exclusão", user._id);
        
          
        //const response = await deleteUser(idToDelete, user._id);
        if(shouldDelete){
            deleteUser(idToDelete, user._id).then( cast => {
                //console.log("RESULT DELETE:",cast);
                if(cast.status===201){
                    toast(cast.data.msg,{ autoClose: 5000 , type: "success"});
                    setRefresh(true);
                }else{
                    toast(cast.data.msg,{ autoClose: 5000 , type: "error"});
                }
            }).catch( err => {
                //console.log("ERROR:",err);
                toast(err.response.data.msg,{ autoClose: 5000 , type: "error"});
            });
        }

    }

    const handleOrder = fieldName => {
        setOrder(-order)
        setColumnOrder(fieldName)
        //console.log("order:",order);
        //console.log("fieldName",fieldName)
    }

    var arrayUsers = [];
    arrayUsers = users.sort( (a, b) => {
        return a[columnOrder] < b[columnOrder] ? -order : order;
    })

    const handleRefresh = () => {
        //window.location.reload(false);
        setLoading(true);
        setRefresh(true);
    }

    const handleCadastro = () => {
        //setSidebar(!sidebarTeste);
        navigate("/cadastro-user");
        
    }

    
    if (filter){
        const exp = eval(`/${filter.replace(/[^\d\w]+/,'.*')}/i`)

        list = users.filter( item => exp.test( item.name ) )
        //console.log(list.length)
        if(list.length==0){
            list = users.filter( item => exp.test( item.email ) )
            ////console.log(list);
        }
        if(list.length==0){
            list = users.filter( item =>          
                    exp.test( item.userEmpresa[0].name )            
            )
            ////console.log(list);
        }
    }

    const handleFilter = e => {
        setFilter(e.target.value);
        setResult(!result);
    }

    const focusSearch = () => {
        document.getElementById("search").focus();
    }

    const tableData = useMemo(() => {
        if(filter) return list.map((user) => {
            return {
                key: user._id,
                code: user.id_user,
                name: user.name,
                access_email: user.email,
                user_function: user.role,
                date_inclusion: user.createdAt,
                company_vinc: user.vinculo_empresa,
                company_name: user.userEmpresa.length>0 ? user.userEmpresa[0].name : '',
                actions: (
                <>
                    <a className="btn btn-sm btn-primary" onClick={e => handleEdit(user._id)}><FiIcons.FiEdit2 style={{color:"white"}}/></a>
                    <a className="btn btn-sm btn-danger" style={{marginLeft:"5px"}} onClick={e => handleDelete(user._id)}><FiIcons.FiTrash2 style={{color:"white"}}/></a>
                </>
                )
            }
        });
    
        return arrayUsers.map(({_id, id_user, name, email, role, createdAt, vinculo_empresa, userEmpresa}) => {
            return {
                key: _id,
                code: id_user,
                name: name,
                access_email: email,
                user_function: role,
                date_inclusion: createdAt,
                company_vinc: vinculo_empresa,
                company_name: userEmpresa.length>0 ? userEmpresa[0].name : '',
                actions: (
                <>
                    <span className="btn btn-sm btn-primary" onClick={e => handleEdit(_id)}><FiIcons.FiEdit2 style={{color:"white"}}/></span>
                    <span className="btn btn-sm btn-danger" style={{marginLeft:"5px"}} onClick={e => handleDelete(_id)}><FiIcons.FiTrash2 style={{color:"white"}}/></span>
                </>
                )
            }
        })
      }, [filter, arrayUsers, list]);

    if(loading){
        return (
         
        <>
        <div className="loading"><AiIcons.AiOutlineLoading3Quarters style={{fontSize:"100px", color:"rgb(180,180,180)"}}/></div>
        <PermissionGate permissions={['viewUser']}>
      
            {/*<LeftMenu setSidebar={setSidebar} sidebar={sidebar} />*/}

            <Teste sidebar={sidebar}>

                <div className="w-100 p-4">
                    <div className="list-header-users">
                        <div className="title">
                            <h1>Usuários</h1>
                        </div>
                    </div>

                    <div className="flex-column flex-md-row" style={{display:"flex", justifyContent:"space-between"/*, height:"80px"*/}}>

                        <div className="input-box-list" style={{ width:"100%", maxWidth: "400px"}}>                
                            <input className="input-field" placeholder="Pesquisar" onChange={handleFilter}/>
                            <i className="icon"><FiIcons.FiSearch/></i>
                        </div>

                        <div className="div-buttons">
                            <div className="continue-button">                           
                                <button onClick={handleCadastro}>
                                    <AiIcons.AiOutlinePlusCircle style={{marginRight: "5px",fontSize: "22px",marginBottom: "2px"}}/><a>Adicionar</a> 
                                </button>                            
                            </div>

                            <div className="refresh-button">                                                       
                                <button className="refresh-button" onClick={handleRefresh}>
                                    <SlIcons.SlRefresh style={{color:"#4281FF",marginRight: "5px",fontSize: "18px",marginBottom: "2px"}}/><a >Atualizar</a> 
                                </button>
                            </div>
                        </div>

                    </div>

                    <Table 
                        columns={columns} 
                        dataSource={tableData} 
                        pagination={false}
                        rowClassName="row-table"
                        rowHoverable={true}
                    />   
                </div>
            </Teste>

        </PermissionGate>       
        </>


        );
    }

    return(

        <>
        <PermissionGate permissions={['viewUser']}>
  

            {/*<LeftMenu setSidebar={setSidebar} sidebar={sidebar} />*/}

            
            <ConfirmDialog
                title="Deletar Usuário?"
                open={confirmOpen}
                setOpen={setConfirmOpen}
                onConfirm={deleteReg}
            >
                Tem certeza que deseja deletar o usuário?
            </ConfirmDialog>

            <ModalEditUser setEditUser={setEditUser} showEditUser={showEditUser} 
            setRefresh={setRefresh}
            selectData={selectData}
            setselectData={setselectData}
            _idUserEdit={_idUserEdit}
            usernameEdit={usernameEdit} 
            emailEdit={emailEdit} 
            roleEdit={roleEdit}    
            editPerfil={false}
            />

            <Teste sidebar={sidebar}>

                <div className="w-100 p-4">
                    <div className="list-header-users">
                        <div className="title">
                            <h1>Usuários</h1>
                        </div>
                    </div>

                    <div className="flex-column flex-md-row" style={{display:"flex", justifyContent:"space-between"/*, height:"80px"*/}}>

                        <div className="input-box-list">                
                            <input className="input-field" id="search" placeholder="Pesquisar" onChange={handleFilter} style={{ maxWidth: "400px" }} />
                            <i className="icon"><FiIcons.FiSearch/></i>
                        </div>

                        <div className="div-buttons flex-column flex-md-row">
                            <div className="continue-button">                           
                                <button onClick={handleCadastro}>
                                    <AiIcons.AiOutlinePlusCircle style={{marginRight: "5px",fontSize: "22px",marginBottom: "2px"}}/><a>Adicionar</a> 
                                </button>                            
                            </div>

                            <div className="refresh-button" style={{ textAlign: "start" }}>                                                       
                                <button className="refresh-button" onClick={handleRefresh}>
                                    <SlIcons.SlRefresh style={{color:"#4281FF",marginRight: "5px",fontSize: "18px",marginBottom: "2px"}}/><a >Atualizar</a> 
                                </button>
                            </div>
                        </div>

    

                    </div>                    
                    <Table 
                        columns={columns} 
                        dataSource={tableData} 
                        pagination={false}
                        rowClassName="row-table"
                        rowHoverable={true}
                    />      
                </div>
            </Teste>

        </PermissionGate>       
        </>
        

        );
    };
    
    export default ListUsers;