import React, { useState, useRef, useEffect, useContext } from "react";
import './style.css'
import styled from 'styled-components';
import 'bootstrap/dist/css/bootstrap.min.css';
import CryptoJS from 'crypto-js';
import { AuthContext } from "../../contexts/auth";
import { SidebarContext } from "../../AppRoutes";
import { getAdicionais, deleteGrupoAdicional, deleteItemAdicional, updateItemAdicionalOut, updateItemAdicionalOutSalao } from "../../services/api";

import PermissionGate from "../../services/PermissionGate";

import { useNavigate } from "react-router-dom";
import LeftMenu from "../../components/LeftMenu";
import ConfirmDialog from "../../components/ConfirmDialog";

import { toast } from "react-toastify";
import Loading from "react-loading";
import { MdFastfood } from "react-icons/md";
import * as FiIcons from 'react-icons/fi'
import * as AiIcons from 'react-icons/ai'
import * as SlIcons from 'react-icons/sl'
import { TbDotsVertical } from "react-icons/tb";
import { FaRegEdit } from "react-icons/fa";
import { FaRegTrashAlt } from "react-icons/fa";
import ModalEditGrupoAdd from "./ModalEditGrupoAdd";

const Teste = styled.div`
    display: flex;
    margin-left:  ${({ sidebar }) => (sidebar ? '250px' : '100px')}; 
    height:auto;
    width:auto;
    transition: 150ms;
    background-color:rgb(247,247,247)!important;
    overflow: initial;
    z-Index: 9;

    @media (max-width: 880px) {
        margin-left: 0;
    }
`;


const ListAdicional = ({ list = [] }) => {
    //const { user } = useContext(AuthContext);
    const ref = useRef();
    //TESTES
    const secretKey = 'my-custom-secret-key:1af0addcce4b56f753ed10c285667ad031bae57af19747f576b05312b8cf85be';
    const userEncrypted = localStorage.getItem('user')
    const user = CryptoJS.AES.decrypt(userEncrypted, secretKey).toString(CryptoJS.enc.Utf8);
    const userParse = JSON.parse(user)
    const userID = userParse._id;
    const empresa = localStorage.getItem('empresa')
    const empresaParse = JSON.parse(empresa)
    const empresaObjId = empresaParse._id;
    const idEmpresa = empresaParse.id_empresa
    const vinculo_empresa = empresaParse.cnpj

    const navigate = useNavigate();

    const [empresas, setEmpresas] = useState([]);
    const [adicionais, setAdicionais] = useState([]);
    const [filter, setFilter] = useState('');
    const [result, setResult] = useState(false);

    const [order, setOrder] = useState(1)
    const [columnOrder, setColumnOrder] = useState("")

    const { sidebar, setSidebar } = useContext(SidebarContext);;

    const [loading, setLoading] = useState(true);
    const [refresh, setRefresh] = useState(false);

    const [showEditCategoria, setEditCategoria] = useState(true);
    const [visibleDropdown, setVisibleDropdown] = useState(null);
    const dropdownRef = useRef(null);

    useEffect(() => {
        (async () => {
            const response = await getAdicionais(userID, idEmpresa, vinculo_empresa, empresaObjId)//getEmpresas(user._id);
            //setEmpresas(response.data.empresa);
            //console.log("response.data.adicionais>",response.data.adicionais)
            setAdicionais(response.data.adicionais);
            setLoading(false);
            setRefresh(false);

        })();
    }, [refresh]);

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setVisibleDropdown(null);
        }
    };

    const handleCheckboxChangeAdicional = async (idGrupo, itemID, titulo, price, id_item, currentOut) => {
        const newOutValue = !currentOut;
        setAdicionais(prevAdicionais =>
            prevAdicionais.map(adicional =>
                adicional._id === itemID ? { ...adicional, out: newOutValue } : adicional
            )
        ); // Atualiza o estado local imediatamente

        try {
            console.log("O que esta indo no submit:", { userID, idGrupo, itemID, titulo, price, id_item, idEmpresa, newOutValue })
            const response = await updateItemAdicionalOut(userID, idGrupo, itemID, titulo, price, id_item, idEmpresa, newOutValue);
            if (response.status === 200) {
                toast('Item atualizado com sucesso', { autoClose: 2500, type: 'success' });
                setRefresh(true);
            }
        } catch (error) {
            console.error('Erro ao atualizar item:', error);
            toast('Erro ao atualizar o item', { autoClose: 2500, type: 'error' });
            // Reverte o estado local se a atualização falhar
            setAdicionais(prevAdicionais =>
                prevAdicionais.map(adicional =>
                    adicional._id === itemID ? { ...adicional, out: currentOut } : adicional
                )
            );
        }
    };

    const handleCheckboxChangeAdicionalSalao = async (idGrupo, itemID, titulo, price, id_item, currentOut) => {
        const newOutValue = !currentOut;
        setAdicionais(prevAdicionais =>
            prevAdicionais.map(adicional =>
                adicional._id === itemID ? { ...adicional, out_salao: newOutValue } : adicional
            )
        ); // Atualiza o estado local imediatamente

        try {
            console.log("O que esta indo no submit:", { userID, idGrupo, itemID, titulo, price, id_item, idEmpresa, newOutValue })
            const response = await updateItemAdicionalOutSalao(userID, idGrupo, itemID, titulo, price, id_item, idEmpresa, newOutValue);
            if (response.status === 200) {
                toast('Item atualizado com sucesso', { autoClose: 2500, type: 'success' });
                setRefresh(true);
            }
        } catch (error) {
            console.error('Erro ao atualizar item:', error);
            toast('Erro ao atualizar o item', { autoClose: 2500, type: 'error' });
            // Reverte o estado local se a atualização falhar
            setAdicionais(prevAdicionais =>
                prevAdicionais.map(adicional =>
                    adicional._id === itemID ? { ...adicional, out_salao: currentOut } : adicional
                )
            );
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [visibleDropdown]);

    const [confirmOpen, setConfirmOpen] = useState(false);
    const [shouldDelete, setShouldDelete] = useState(false);
    const [idToDelete, setIdToDelete] = useState('');
    const handleDelete = (categoriaToDelete) => {
        const idRecebido = categoriaToDelete._id;
        // Abre Componente de Confirmação do delete
        setConfirmOpen(true)

        setIdToDelete(idRecebido)
        if (idRecebido) {
            //console.log("ID RECEBIDO:",idRecebido)
            setShouldDelete(true);
        }
    }
    const deleteReg = async () => {
        console.log("O ID CLICADO FOI :::", idToDelete);
        console.log("ID do Usuário que irá efetuar a exclusão", userID);

        if (shouldDelete) {
            deleteGrupoAdicional(userID, idToDelete).then(cast => {
                console.log("RESULT DELETE:", cast);
                if (cast.status === 201) {
                    toast(cast.data.msg, { autoClose: 5000, type: "success" });
                    setRefresh(true);
                } else {
                    toast(cast.data.msg, { autoClose: 5000, type: "error" });
                }
            }).catch(err => {
                console.log("ERROR:", err);
                toast(err.response.data.msg, { autoClose: 5000, type: "error" });
            });
        }

    }

    const [categoriaToEdit, setCategoriaToEdit] = useState(null)
    const handleEdit = async (categoria) => {
        setCategoriaToEdit(categoria);
        setEditCategoria(!showEditCategoria)
    }

    const handleRefresh = () => {
        setLoading(true);
        setRefresh(true);
    }

    const handleOrder = fieldName => {
        setOrder(-order)
        setColumnOrder(fieldName)
        console.log("order:", order);
        console.log("fieldName", fieldName)
    }

    const toggleOptions = (id) => {
        if (visibleDropdown === id) {
            setVisibleDropdown(null);
        } else {
            setVisibleDropdown(id);
        }
    };

    const handleEditClick = (id_grupo, adicional) => {
        console.log("ADD DO ITEM A SER EDITADO:", adicional);
        navigate("/edit-item-adicional", { state: { id_grupo, adicional } });
    }

    const handleDeleteItem = (id_grupo, id) => {
        console.log("ID DO ITEM A SER DELETADO:", id);
        const id_subadicional = id;
        const data = {
            userID,
            id_subadicional,
            id_grupo,
        };

        deleteItemAdicional(data).then(response => {
            if (response.status === 200) {
                toast(response.data.msg, { autoClose: 5000, type: "success" });
                // Atualize a lista de adicionais ou faça outra ação necessária
                setRefresh(true);
            } else {
                toast(response.data.msg, { autoClose: 5000, type: "error" });
            }
        }).catch(err => {
            toast(err.response.data.msg, { autoClose: 5000, type: "error" });
        });
    }
    /*var arrayAdicionais = [];
    arrayAdicionais = categorias.sort( (a, b) => {
        return a[columnOrder] < b[columnOrder] ? -order : order;
    })*/

    var arrayAdicionais = adicionais.sort((a, b) => {
        return a.order - b.order;
    });
    //var arrayAdicionais = categorias;

    if (filter) {
        const exp = eval(`/${filter.replace(/[^\d\w]+/, '.*')}/i`)
        list = adicionais.filter(item => exp.test(item.title))
    }

    const handleFilter = e => {
        setFilter(e.target.value);
        setResult(!result);
    }

    const handleCadastro = () => {
        navigate("/cadastro-adicional");
    }

    const handleCadastroItemAdicional = (id_grupo, type) => {
        navigate("/cadastro-item-adicional", { state: { id_grupo, type } });
    }


    const [draggedItem, setDraggedItem] = useState(null);
    const handleDragStart = (draggableId) => {
        setDraggedItem(draggableId);
    };
    const handleDragEnd = () => {
        setDraggedItem(null);
    };

    /*const moveRow = async (dragIndex, hoverIndex) => {
        
        const dragItem = adicionais[dragIndex];
        const hoverItem = adicionais[hoverIndex];
      
        // Mover o item do dragIndex para o hoverIndex
        const newAdicionais = [...adicionais];        
        newAdicionais.splice(dragIndex, 1);
        newAdicionais.splice(hoverIndex, 0, dragItem);
        // Atualizar o estado com o novo array de adicionais
        
        const adicionaisAtualizados = newAdicionais.map((categoria, index) => {
            return { ...categoria, order: index+1 };
        });

        const promises = adicionaisAtualizados.map( (adicionaisToApi) => {
            return updateCategoriasOrder(userID, adicionaisToApi._id, adicionaisToApi.id_categoria, adicionaisToApi.id_empresa, adicionaisToApi.order).then(response => {
                return response;
            }).catch(err => {
                return err;
            })
        });
        Promise.all(promises).then(results => {
            toast("Ordem atualizada com sucesso!",{ autoClose: 3000 , type: "success"});
        }).catch(err => {
            toast("Ocorreu um erro ao tentar atualizar a ordem!",{ autoClose: 3000 , type: "error"});
        });
        setAdicionais(categoriasAtualizadas);
    };*/

    return (

        <>
            <PermissionGate permissions={['default']}>

                {/*<LeftMenu setSidebar={setSidebar} sidebar={sidebar} />*/}

                <ConfirmDialog
                    title="Deletar Grupo de Adicional?"
                    open={confirmOpen}
                    setOpen={setConfirmOpen}
                    onConfirm={deleteReg}
                >
                    <div>
                        Tem certeza que deseja deletar o grupo de adicional?
                        <br />
                        <span style={{ fontSize: 12 }}>(Os Itens cadastrados com esse grupo de adicional ficarão sem adicional vinculado)</span>
                    </div>
                </ConfirmDialog>

                <ModalEditGrupoAdd showEditCategoria={showEditCategoria}
                    setEditCategoria={setEditCategoria}
                    setRefresh={setRefresh}
                    categoriaToEdit={categoriaToEdit}
                />

                <Teste sidebar={sidebar}>

                    {loading && <Loading type={"spinningBubbles"} style={{ marginLeft: '14%' }} className="calculandoDistanciaModalEntrega" />}
                    <div className="w-100 p-3">
                        <div className="list-header-categories">
                            <div className="title">
                                <h1>Grupo de Complementos</h1>
                            </div>
                        </div>


                        <div className="flex-column flex-md-row" style={{ display: "flex", justifyContent: "space-between"/*, height:"80px"*/ }}>
                            <div className="input-box-list" style={{ maxWidth: "400px", width: "100%" }}>
                                <input className="input-field" placeholder="Pesquisar" onChange={handleFilter} />
                                <i className="icon"><FiIcons.FiSearch /></i>
                            </div>

                            <div className="div-buttons">
                                <div className="continue-button">
                                    <button onClick={handleCadastro}>
                                        <AiIcons.AiOutlinePlusCircle style={{ marginRight: "5px", fontSize: "22px", marginBottom: "2px" }} /><a>Novo Grupo</a>
                                    </button>
                                </div>

                                <div className="refresh-button">
                                    <button className="refresh-button" onClick={handleRefresh}>
                                        <SlIcons.SlRefresh style={{ color: "#4281FF", marginRight: "5px", fontSize: "18px", marginBottom: "2px" }} /><a >Atualizar</a>
                                    </button>
                                </div>
                            </div>

                        </div>


                        <div className="divPaiCategorias">
                            {filter ?
                                <>


                                    <div id="adicionais"
                                    >
                                        <div className="divCategorias">
                                            {list.map((categoria, i) => (
                                                <div key={categoria._id} index={i}>
                                                    <div style={{ padding: "5px 20px", fontSize: 15, fontWeight: '600' }}>
                                                        <label style={{ textDecoration: "underline" }}>Grupo de {categoria.type === "pizza" ? "Sabor" : "Adicional"}</label>
                                                    </div>
                                                    <div
                                                        //className="categoria-individual"
                                                        style={{
                                                            //...provided.draggableProps.style,
                                                            backgroundColor: i % 2 === 0 ? 'white' : 'white',
                                                            boxShadow: draggedItem === categoria._id ? '1px 1px 6px #a4a4a4' : 'none',
                                                        }}
                                                        className={`${'resultCategoriaAdicionais'} ${i % 2 === 0 ? 'even' : 'odd'}`}
                                                    >

                                                        <div style={{ display: 'flex', flexDirection: 'row', gap: 80, alignItems: 'center', width: '90%', justifyContent: 'space-between', paddingLeft: 10 }}>
                                                            <div style={{ maxWidth: '50vw' }}>
                                                                <div className="flex-column flex-md-row" style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
                                                                    <div className="nomeCategoria">{categoria.title}</div>
                                                                    <div className={categoria.mandatory ? "divMandatory" : "divOpcional"}>
                                                                        <span>{categoria.mandatory ? "Obrigatório" : "Opcional"}</span>
                                                                    </div>
                                                                    <div>
                                                                        <span>Adicionais selecionáveis de {categoria.min} até {categoria.max}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="divDescricaoAdicionais">{categoria.description}</div>
                                                            </div>
                                                            {/*<div className="valorDoAdicional">R$ {categoria.price.toFixed(2).toString().replace('.',',')}</div>*/}

                                                        </div>
                                                        <div>
                                                            <a
                                                                className="btn btn-sm btn-primary editaCategoria"
                                                                onClick={(e) => handleEdit(categoria)}
                                                            >
                                                                <FiIcons.FiEdit2 style={{ color: 'white' }} />
                                                            </a>
                                                            <a
                                                                className="btn btn-sm btn-danger deletaCategoria"
                                                                style={{ marginLeft: '5px' }}
                                                                onClick={(e) => handleDelete(categoria)}
                                                            >
                                                                <FiIcons.FiTrash2 style={{ color: 'white' }} />
                                                            </a>
                                                        </div>

                                                    </div>
                                                    <div className="resultAdicionaisChildren" style={{ backgroundColor: "white" }}>
                                                        {
                                                            categoria.adicionais && categoria.adicionais.length > 0 && categoria.adicionais.map((adicional, i) => (
                                                                <div key={adicional._id} index={i}>
                                                                    <div className="divItemAdicionalInfo">
                                                                        <div style={{ display: 'flex', gap: 20, alignItems: 'center' }}>
                                                                            {
                                                                                adicional.image === "" || !adicional.image ?
                                                                                    <div>
                                                                                        <MdFastfood style={{ fontSize: 50 }} />
                                                                                    </div>
                                                                                    :
                                                                                    <img width={50} height={50} src={adicional.image}></img>
                                                                            }
                                                                            {adicional.title}
                                                                        </div>
                                                                        <div style={{ display: 'flex', justifyContent: 'space-between', minWidth: '40%' }}>
                                                                            <div style={{ display: 'flex', gap: 20 }}>
                                                                                <div className="divEsgotarItem">
                                                                                    <span style={{ fontSize: 12 }}>Esgotar Delivery</span>
                                                                                    <div className="wrapper">
                                                                                        <div className="switch_box box_1">
                                                                                            <input type="checkbox" className="switch_1"
                                                                                                checked={adicional.out} onChange={() =>
                                                                                                    handleCheckboxChangeAdicional(categoria._id,
                                                                                                        adicional._id,
                                                                                                        adicional.title,
                                                                                                        adicional.price,
                                                                                                        adicional.id_adicional,
                                                                                                        adicional.out
                                                                                                    )
                                                                                                }
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="divEsgotarItem">
                                                                                    <span style={{ fontSize: 12 }}>Esgotar Salão</span>
                                                                                    <div className="wrapper">
                                                                                        <div className="switch_box box_1">
                                                                                            <input type="checkbox" className="switch_1"
                                                                                                checked={adicional.out_salao} onChange={() =>
                                                                                                    handleCheckboxChangeAdicionalSalao(categoria._id,
                                                                                                        adicional._id,
                                                                                                        adicional.title,
                                                                                                        adicional.price,
                                                                                                        adicional.id_adicional,
                                                                                                        adicional.out_salao
                                                                                                    )
                                                                                                }
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div className="divEditRmvSubAdicionais">
                                                                                {adicional.price > 0 ?
                                                                                    <div>A partir de R$ {adicional.price.toFixed(2).toString().replace('.', ',')}</div>
                                                                                    :
                                                                                    <div>Adicional Grátis</div>
                                                                                }
                                                                                <TbDotsVertical onClick={() => toggleOptions(adicional._id)} />
                                                                                {visibleDropdown === adicional._id && (
                                                                                    <div className="optionsDropdown" ref={dropdownRef} style={{ top: 22 }}>
                                                                                        <div
                                                                                            className="optionItem"
                                                                                            onClick={() => handleEditClick(categoria._id, adicional)}
                                                                                        >
                                                                                            <FaRegEdit /> Editar
                                                                                        </div>
                                                                                        <div
                                                                                            className="optionItem"
                                                                                            onClick={() => handleDeleteItem(categoria._id, adicional._id)}
                                                                                        >
                                                                                            <FaRegTrashAlt /> Excluir
                                                                                        </div>
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            ))

                                                        }

                                                        <div className="divCadastroAdicionalChildren" onClick={() => handleCadastroItemAdicional(categoria._id, categoria.type)}>
                                                            <span>Cadastrar {categoria.type === "pizza" ? "Sabor" : "Adicional"}</span>
                                                        </div>

                                                    </div>

                                                </div>
                                            ))}

                                        </div>

                                    </div>

                                </>
                                :
                                <>


                                    <div id="adicionais"
                                    >
                                        <div className="divCategorias">
                                            {arrayAdicionais.map((categoria, i) => (
                                                <div key={categoria._id} index={i}>
                                                    <div style={{ padding: "5px 20px", fontSize: 15, fontWeight: '600' }}>
                                                        <label style={{ textDecoration: "underline" }}>Grupo de {categoria.type === "pizza" ? "Sabor" : "Adicional"}</label>
                                                    </div>
                                                    <div
                                                        //className="categoria-individual"
                                                        style={{
                                                            //...provided.draggableProps.style,
                                                            backgroundColor: i % 2 === 0 ? 'white' : 'white',
                                                            boxShadow: draggedItem === categoria._id ? '1px 1px 6px #a4a4a4' : 'none',
                                                        }}
                                                        className={`${'resultCategoriaAdicionais'} ${i % 2 === 0 ? 'even' : 'odd'}`}
                                                    >

                                                        <div style={{ display: 'flex', flexDirection: 'row', gap: 80, alignItems: 'center', width: '90%', justifyContent: 'space-between', paddingLeft: 10 }}>
                                                            <div style={{ maxWidth: '50vw' }}>
                                                                <div className="flex-column flex-md-row" style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
                                                                    <div className="nomeCategoria">{categoria.title}</div>
                                                                    <div className={categoria.mandatory ? "divMandatory" : "divOpcional"}>
                                                                        <span>{categoria.mandatory ? "Obrigatório" : "Opcional"}</span>
                                                                    </div>
                                                                    <div>
                                                                        <span>Adicionais selecionáveis de {categoria.min} até {categoria.max}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="divDescricaoAdicionais">{categoria.description}</div>
                                                            </div>
                                                            {/*<div className="valorDoAdicional">R$ {categoria.price.toFixed(2).toString().replace('.',',')}</div>*/}

                                                        </div>
                                                        <div>
                                                            <a
                                                                className="btn btn-sm btn-primary editaCategoria"
                                                                onClick={(e) => handleEdit(categoria)}
                                                            >
                                                                <FiIcons.FiEdit2 style={{ color: 'white' }} />
                                                            </a>
                                                            <a
                                                                className="btn btn-sm btn-danger deletaCategoria"
                                                                style={{ marginLeft: '5px' }}
                                                                onClick={(e) => handleDelete(categoria)}
                                                            >
                                                                <FiIcons.FiTrash2 style={{ color: 'white' }} />
                                                            </a>
                                                        </div>

                                                    </div>
                                                    <div className="resultAdicionaisChildren" style={{ backgroundColor: "white" }}>
                                                        {
                                                            categoria.adicionais && categoria.adicionais.length > 0 && categoria.adicionais.map((adicional, i) => (
                                                                <div key={adicional._id} index={i}>
                                                                    <div className="divItemAdicionalInfo">
                                                                        <div style={{ display: 'flex', gap: 20, alignItems: 'center' }}>
                                                                            {
                                                                                adicional.image === "" || !adicional.image ?
                                                                                    <div>
                                                                                        <MdFastfood style={{ fontSize: 50 }} />
                                                                                    </div>
                                                                                    :
                                                                                    <img width={50} height={50} src={adicional.image}></img>
                                                                            }
                                                                            {adicional.title}
                                                                        </div>
                                                                        <div style={{ display: 'flex', justifyContent: 'space-between', minWidth: '40%' }}>
                                                                            <div style={{ display: 'flex', gap: 20 }}>
                                                                                <div className="divEsgotarItem">
                                                                                    <span style={{ fontSize: 12 }}>Esgotar Delivery</span>
                                                                                    <div className="wrapper">
                                                                                        <div className="switch_box box_1">
                                                                                            <input type="checkbox" className="switch_1"
                                                                                                checked={adicional.out} onChange={() =>
                                                                                                    handleCheckboxChangeAdicional(categoria._id,
                                                                                                        adicional._id,
                                                                                                        adicional.title,
                                                                                                        adicional.price,
                                                                                                        adicional.id_adicional,
                                                                                                        adicional.out
                                                                                                    )
                                                                                                }
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="divEsgotarItem">
                                                                                    <span style={{ fontSize: 12 }}>Esgotar Salão</span>
                                                                                    <div className="wrapper">
                                                                                        <div className="switch_box box_1">
                                                                                            <input type="checkbox" className="switch_1"
                                                                                                checked={adicional.out_salao} onChange={() =>
                                                                                                    handleCheckboxChangeAdicionalSalao(categoria._id,
                                                                                                        adicional._id,
                                                                                                        adicional.title,
                                                                                                        adicional.price,
                                                                                                        adicional.id_adicional,
                                                                                                        adicional.out_salao
                                                                                                    )
                                                                                                }
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="divEditRmvSubAdicionais">
                                                                                {adicional.price > 0 ?
                                                                                    <div>A partir de R$ {adicional.price.toFixed(2).toString().replace('.', ',')}</div>
                                                                                    :
                                                                                    <div>Adicional Grátis</div>
                                                                                }
                                                                                <TbDotsVertical onClick={() => toggleOptions(adicional._id)} />
                                                                                {visibleDropdown === adicional._id && (
                                                                                    <div className="optionsDropdown" ref={dropdownRef} style={{ top: 22 }}>
                                                                                        <div
                                                                                            className="optionItem"
                                                                                            onClick={() => handleEditClick(categoria._id, adicional)}
                                                                                        >
                                                                                            <FaRegEdit /> Editar
                                                                                        </div>
                                                                                        <div
                                                                                            className="optionItem"
                                                                                            onClick={() => handleDeleteItem(categoria._id, adicional._id)}
                                                                                        >
                                                                                            <FaRegTrashAlt /> Excluir
                                                                                        </div>
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            ))

                                                        }

                                                        <div className="divCadastroAdicionalChildren" onClick={() => handleCadastroItemAdicional(categoria._id, categoria.type)}>
                                                            <span>Cadastrar {categoria.type === "pizza" ? "Sabor" : "Adicional"}</span>
                                                        </div>

                                                    </div>

                                                </div>
                                            ))}

                                        </div>

                                    </div>

                                </>
                            }
                        </div>

                    </div>
                </Teste>

            </PermissionGate>
        </>


    );
};


export default ListAdicional;