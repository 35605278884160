import React, { useState, useContext, useEffect } from "react";
import './style.css'
import styled from 'styled-components';
import 'bootstrap/dist/css/bootstrap.min.css';
import { SidebarContext } from "../../AppRoutes";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Modal from "react-modal";
import { FaMoneyBillWave } from "react-icons/fa";

import moment from 'moment'

import { AuthContext } from "../../contexts/auth";

import { deleteInstanceAdmin, getEmpresasAdmin, createInstanceAdmin, getInstanceStatus } from "../../services/api";

import PermissionGate from "../../services/PermissionGate";

import { useNavigate } from "react-router-dom";
import ModalEditEntregador from "./modalEditEntregador";
import ConfirmDialog from "../../components/ConfirmDialog";

import { BarContext } from "../../components/LeftMenu";

import { useFormik } from "formik";
import * as Yup from "yup";

import * as FiIcons from 'react-icons/fi'
import * as AiIcons from 'react-icons/ai'
import * as SlIcons from 'react-icons/sl'
import * as FaIcons from 'react-icons/fa'
import { date } from "yup/lib/locale";
import { useMemo } from "react";
import { Table } from "antd";

const InvoicesModal = ({ invoices, isOpen, onClose }) => {
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            contentLabel="Faturas Disponíveis"
            ariaHideApp={false}
            className="custom-invoices-modal"
            overlayClassName="custom-invoices-overlay"
        >
            <h2>Faturas Disponíveis</h2>
            <table className="custom-invoices-table">
                <thead>
                    <tr>
                        <th>Data de Vencimento</th>
                        <th>Status</th>
                        <th>Total</th>
                        <th>Ação</th>
                    </tr>
                </thead>
                <tbody>
                    {invoices?.map((invoice, index) => (
                        <tr key={index}>
                            <td>{moment(invoice?.dueDate).format("DD/MM/YYYY")}</td>
                            <td>{statusMap[invoice?.status] || "Status desconhecido"}</td>
                            {(invoice?.status === "OVERDUE" || invoice?.status === "PENDING") ?
                                <td>
                                    {((invoice?.value || 0) - (invoice?.discount?.value || 0))
                                        .toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}
                                </td>
                                :
                                <td>
                                    {((invoice?.value || 0))
                                        .toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}
                                </td>
                            }
                            <td>
                                {(invoice?.status === "OVERDUE" || invoice?.status === "PENDING") ? (
                                    <a
                                        href={invoice?.invoiceUrl}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="custom-invoices-pay-button"
                                    >
                                        <FaMoneyBillWave /> Pagar
                                    </a>
                                ) : (
                                    <button className="custom-invoices-pay-button custom-invoices-disabled-button" disabled>
                                        <FaMoneyBillWave /> Indisponível
                                    </button>
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <button onClick={onClose} className="custom-invoices-close-button">Fechar</button>
        </Modal>
    );
};

const Teste = styled.div`
    display: flex;
    margin-left:  ${({ sidebar }) => (sidebar ? '250px' : '100px')}; 
    height:auto;
    width:auto;
    transition: 150ms;
    background-color:rgb(247,247,247)!important;
    overflow: initial;
    z-Index: 9;

    @media (max-width: 880px) {
        margin-left: 0;
    }
`;

// Tooltip (Mini Modal)
const Tooltip = styled.div`
    position: absolute;
    background-color: black;
    color: white;
    padding: 5px 8px;
    border-radius: 5px;
    font-size: 12px;
    white-space: nowrap;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    pointer-events: none;
    transform: translate(-50%, -5px);
    z-index:12;
`;

// Wrapper para os botões com tooltip
const ButtonWrapper = styled.div`
    position: relative;
    display: inline-block;
    
    &:hover ${Tooltip} {
        opacity: 1;
    }
`;


const columns = [
    {
        title: 'Nome',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: 'E-mail',
        dataIndex: 'email',
        key: 'email',
    },
    {
        title: 'CPF/CNPJ',
        dataIndex: 'cnpj',
        key: 'cnpj',
    },
    {
        title: 'ID da Instância',
        dataIndex: 'instanceId',
        key: 'instanceId',
    },
    {
        title: 'Status WhatsApp',
        dataIndex: 'instanceStatus',
        key: 'instanceStatus',
    },
    {
        title: 'Data Criação',
        dataIndex: 'createdAt',
        key: 'createdAt',
    },
    {
        title: 'Ver faturas',
        dataIndex: 'verFaturas',
        key: 'verFaturas',
    },
    {
        title: 'Ações',
        dataIndex: 'actions',
        key: 'actions',
    }
];

const statusMap = {
    PENDING: "Pendente",
    PAID: "Paga",
    CANCELED: "Cancelada",
    IN_ANALYSIS: "Em Análise",
    DRAFT: "Rascunho",
    PARTIALLY_PAID: "Parcialmente Paga",
    REFUNDED: "Reembolsada",
    EXPIRED: "Expirada",
    IN_PROTEST: "Em Protesto",
    CHARGEBACK: "Contestada",
    EXTERNALLY_PAID: "Paga Externamente",
    RECEIVED: "Paga",
    CONFIRMED: "Confirmada",
    OVERDUE: "Atrasada",
    RECEIVED_IN_CASH: "Recebida em Dinheiro",
    REFUND_REQUESTED: "Reembolso Solicitado",
    REFUND_IN_PROGRESS: "Reembolso em Andamento",
    CHARGEBACK_REQUESTED: "Contestação Solicitada",
    CHARGEBACK_DISPUTE: "Disputa de Contestação",
    AWAITING_CHARGEBACK_REVERSAL: "Aguardando Reversão de Contestação",
    DUNNING_REQUESTED: "Cobrança Judicial Solicitada",
    DUNNING_RECEIVED: "Cobrança Judicial Recebida",
    AWAITING_RISK_ANALYSIS: "Aguardando Análise de Risco",
};

const ListEmpresas = ({ list = [] }) => {
    const { user } = useContext(AuthContext);
    const empresa = localStorage.getItem('empresa')
    const empresaParse = JSON.parse(empresa)
    const objIdEmpresa = empresaParse._id;
    const vinculo_empresa = empresaParse.cnpj;
    const navigate = useNavigate();

    const { sidebar, setSidebar } = useContext(SidebarContext);;
    //console.log("LISTUSERS",sidebar);

    const [order, setOrder] = useState(1)
    const [columnOrder, setColumnOrder] = useState('title')
    const [allEmpresas, setAllEmpresas] = useState([]); // Armazena todas as empresas
    const [empresas, setEmpresas] = useState([]);
    const [filter, setFilter] = useState('');

    const [_idEntregadorEdit, set_idEntregadorEdit] = useState('');
    const [nomeEntregadorEdit, setNomeEntregadorEdit] = useState('');
    const [veiculoEdit, setVeiculoEdit] = useState('');
    const [telefoneEdit, setTelefoneEdit] = useState('');
    const [placaEdit, setPlacaEdit] = useState('');

    const [result, setResult] = useState(false);

    const [showEditEntregador, setEditEntregador] = useState(true);

    const [loading, setLoading] = useState(true);

    const [refresh, setRefresh] = useState(false);
    const [page, setPage] = useState(1);
    const [limit] = useState(10);
    const [total, setTotal] = useState(0); // Armazena o total de empresas disponíveis
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [invoices, setInvoices] = useState([]);

    const INITIAL_DATA = {
        value: "",
        label: 'Selecione uma empresa',
    };

    const [selectData, setselectData] = useState(INITIAL_DATA);

    /*useEffect(() => {
        (async () => {

            const response = await getEmpresasAdmin(user._id);
            //console.log("TESTEEE:::",user._id)
            //const teste = [response.data]
            setEmpresas(response.data.empresas);
            console.log(response.data)
            setLoading(false);
            setRefresh(false);

        })();
    }, [refresh]);*/
    /*useEffect(() => {
        (async () => {
            setLoading(true);
            const response = await getEmpresasAdmin(user._id, page, limit);
            console.log(response.data)
            setEmpresas(response.data.empresas);
            setTotal(response.data.total); // Atualiza o total com base no backend
            setLoading(false);
            setRefresh(false);
        })();
    }, [refresh, page]); // Dispara o efeito ao mudar de página*/
    useEffect(() => {
        (async () => {
            setLoading(true);

            // Buscar todas as empresas apenas uma vez (sem paginação)
            if (allEmpresas.length === 0 || refresh) {
                const response = await getEmpresasAdmin(user._id, 1, 1000000); // Pegamos todas as empresas
                setAllEmpresas(response.data.empresas);
            }

            // Agora buscamos apenas as empresas da página atual
            const response = await getEmpresasAdmin(user._id, page, limit);
            setEmpresas(response.data.empresas);
            setTotal(response.data.total);
            setLoading(false);
            setRefresh(false);
        })();
    }, [refresh, page]);

    const handleEdit = async idToEdit => {
        //setEditEntregador(!showEditEntregador);
        console.log(JSON.stringify(empresas))
        const entregadorToEdit = empresas.find(entregador => entregador._id === idToEdit);
        if (entregadorToEdit) {
            set_idEntregadorEdit(entregadorToEdit._id);
            setNomeEntregadorEdit(entregadorToEdit.name);
            setVeiculoEdit(entregadorToEdit.veiculo);
            console.log("entregadorToEdit.telefone>", entregadorToEdit.telefone)
            setTelefoneEdit(entregadorToEdit.telefone);
            setPlacaEdit(entregadorToEdit.placa);
            setEditEntregador(!showEditEntregador);  // Supondo que isso abra o modal
        }
        /*const response = await getEntregadores(idToEdit);
        //console.log("Infos do Edit:",response.data.user)
        //console.log(idToEdit,"----");
        if(showEditEntregador){
            set_idEntregadorEdit(idToEdit)            
            setNomeEntregadorEdit(response.data.user.name)
            setVeiculoEdit(response.data.user.email)            
            setRoleEdit(response.data.user.role)           

        }*/
    }

    const [confirmOpen, setConfirmOpen] = useState(false);
    const [shouldDelete, setShouldDelete] = useState(false);
    const [idToDelete, setIdToDelete] = useState('');
    const handleDelete = (idRecebido) => {
        // Abre Componente de Confirmação do delete
        setConfirmOpen(true)

        setIdToDelete(idRecebido)
        if (idRecebido) {
            //console.log("ID RECEBIDO:",idRecebido)
            setShouldDelete(true);
        }
    }

    const deleteReg = async () => {
        //console.log("O ID CLICADO FOI :::",idToDelete);
        //console.log("ID do Usuário que irá efetuar a exclusão", user._id);    
        if (shouldDelete) {
            setLoading(true);
            deleteInstanceAdmin(user._id, idToDelete).then(cast => {
                //console.log("RESULT DELETE:",cast);
                if (cast.status === 201) {
                    toast(cast.data.msg, { autoClose: 5000, type: "success" });
                    setRefresh(true);
                } else {
                    toast(cast.data.msg, { autoClose: 5000, type: "error" });
                }
            }).catch(err => {
                //console.log("ERROR:",err);
                toast(err.response.data.msg, { autoClose: 5000, type: "error" });
            });
        }

    }

    const handleCreateInstance = async (empresaId) => {
        try {
            setLoading(true);
            const response = await createInstanceAdmin(user._id, empresaId);

            if (response.status === 201) {
                toast.success(response.data.msg); // Exibe toast apenas se sucesso
                setRefresh(true); // Atualiza a lista
            }
        } catch (error) {
            console.error("Erro ao criar instância:", error);
            const msg = error.response?.data?.msg || "Erro desconhecido";
            toast.error(msg);
        } finally {
            setLoading(false); // Garante que o estado de carregamento seja atualizado no final
        }
    };

    const handleOrder = fieldName => {
        setOrder(-order)
        setColumnOrder(fieldName)
        //console.log("order:",order);
        //console.log("fieldName",fieldName)
    }

    const handleRefresh = () => {
        //window.location.reload(false);
        setLoading(true);
        setRefresh(true);
    }

    const handleCadastro = () => {
        //setSidebar(!sidebarTeste);
        navigate("/cadastro-entregador");

    }

    const handleFilter = e => {
        setFilter(e.target.value);
        setResult(!result);
    }

    const handleOpenFaturas = (faturas) => {
        setIsModalOpen(true)
        setInvoices(faturas)
    }

    /*
    var arrayEmpresas = [];
    arrayEmpresas = empresas.sort((a, b) => {
        return a[columnOrder] < b[columnOrder] ? -order : order;
    })

    if (filter) {
        const exp = eval(`/${filter.replace(/[^\d\w]+/, '.*')}/i`)

        list = empresas.filter(item => exp.test(item.name))
    }

    const tableData = useMemo(() => {
        if (filter) return list.map(({ _id, id_empresa, name, cnpj, whatsapp, instanceStatus, faturas }) => {
            return {
                key: _id,
                code: id_empresa,
                name: name,
                cnpj: cnpj,
                instanceId: whatsapp.id,
                instanceStatus: (
                    <span style={{
                        color: instanceStatus.code === 400 ? "red"
                            : instanceStatus.connected ? "green"
                                : "gray",
                        fontWeight: "bold"
                    }}>
                        {instanceStatus.code === 400 ? "Desconectado"
                            : instanceStatus.connected ? "Conectado"
                                : "Desconhecido"}
                    </span>
                ),
                verFaturas: (
                    <>
                    {faturas.length > 0 ?
                        <a className="openFaturasText" onClick={() => handleOpenFaturas(faturas)}>Ver faturas</a>
                        :
                        <span>Não gerada</span>
                    }
                    </>
                ),
                actions: (
                    <>
                        <span className="btn btn-sm btn-danger" style={{ marginLeft: "5px" }}><FiIcons.FiTrash2 style={{ color: "white" }} /></span>
                    </>
                )
            }
        });

        return arrayEmpresas.map(({ _id, id_empresa, name, cnpj, whatsapp, instanceStatus, faturas }) => {
            return {
                key: _id,
                code: id_empresa,
                name: name,
                cnpj: cnpj,
                instanceId: whatsapp.id,
                instanceStatus: (
                    <span style={{
                        color: instanceStatus.code === 400 ? "red"
                            : instanceStatus.connected ? "green"
                                : "gray",
                        fontWeight: "bold"
                    }}>
                        {instanceStatus.code === 400 ? "Desconectado"
                            : instanceStatus.connected ? "Conectado"
                                : "Desconhecido"}
                    </span>
                ),
                verFaturas: (
                    <>
                    {faturas.length > 0 ?
                        <a className="openFaturasText" onClick={() => handleOpenFaturas(faturas)}>Ver faturas</a>
                        :
                        <span>Não gerada</span>
                    }
                    </>
                ),
                actions: (
                    <>
                        <span className="btn btn-sm btn-danger" style={{ marginLeft: "5px" }}><FiIcons.FiTrash2 style={{ color: "white" }} /></span>
                    </>
                )
            }
        })
    }, [filter, arrayEmpresas, list]);
    */

    
    // Função para remover caracteres não numéricos
    const removeMask = (str) => str.replace(/\D/g, '');
    // Função para filtrar empresas
    const filteredEmpresas = useMemo(() => {
        if (!filter) return allEmpresas; // Se não houver filtro, retorna todas

        const cleanFilter = removeMask(filter); // Remove a máscara do filtro digitado
        const exp = new RegExp(filter.replace(/[^\d\w]+/, '.*'), 'i');

        return allEmpresas.filter(item =>
            exp.test(item.name) ||               // Filtra pelo nome
            exp.test(item.email) ||              // Filtra pelo email
            exp.test(item.cnpj) ||               // Filtra pelo CNPJ com máscara
            exp.test(removeMask(item.cnpj)) ||   // Filtra pelo CNPJ sem máscara
            (item.whatsapp?.id && exp.test(item.whatsapp?.id)) // Filtra pelo ID do WhatsApp, se existir
        );
    }, [filter, allEmpresas]);

    // Aplicar a paginação na lista filtrada
    const paginatedEmpresas = useMemo(() => {
        return filteredEmpresas.slice((page - 1) * limit, page * limit);
    }, [filteredEmpresas, page, limit]);

    const tableData = useMemo(() => {
        return paginatedEmpresas.map(({ _id, id_empresa, name, email, cnpj, whatsapp, instanceStatus, createdAt, faturas }) => ({
            key: _id,
            code: id_empresa,
            name: name,
            email: email,
            cnpj: cnpj,
            instanceId: whatsapp?.id || "N/A", // 🔹 Evita erro quando whatsapp é undefined
            instanceStatus: (
                <span style={{
                    color: instanceStatus?.code === 400 ? "red"
                        : instanceStatus?.connected ? "green"
                            : "gray",
                    fontWeight: "bold"
                }}>
                    {instanceStatus?.code === 400 ? "Desconectado"
                        : instanceStatus?.connected ? "Conectado"
                            : "Desconhecido"}
                </span>
            ),
            createdAt: moment(createdAt).format("DD/MM/YYYY"),
            verFaturas: (
                <>
                    {faturas.length > 0 ?
                        <a className="openFaturasText" onClick={() => handleOpenFaturas(faturas)}>Ver faturas</a>
                        :
                        <span>Não gerada</span>
                    }
                </>
            ),
            actions: (
                <>
                    {/* Botão de deletar instância */}
                    <ButtonWrapper>
                        <span
                            className="btn btn-sm"
                            onClick={whatsapp?.id ? () => handleDelete(whatsapp.id) : undefined}
                            style={{
                                marginLeft: "5px",
                                backgroundColor: whatsapp?.id ? "red" : "gray",
                                cursor: whatsapp?.id ? "pointer" : "not-allowed",
                                position: "relative"
                            }}
                        >
                            <FiIcons.FiTrash2 style={{ color: "white" }} />
                        </span>
                        <Tooltip>Deletar Instância</Tooltip>
                    </ButtonWrapper>

                    {/* Botão de criar instância */}
                    <ButtonWrapper>
                        <span
                            className="btn btn-sm"
                            onClick={!whatsapp?.id ? () => handleCreateInstance(_id) : undefined}
                            style={{
                                marginLeft: "5px",
                                backgroundColor: !whatsapp?.id ? "rgb(66, 129, 255)" : "gray",
                                cursor: !whatsapp?.id ? "pointer" : "not-allowed",
                                position: "relative"
                            }}
                        >
                            <FaIcons.FaPlus style={{ color: "white" }} />
                        </span>
                        <Tooltip>Criar Instância</Tooltip>
                    </ButtonWrapper>
                </>
            )
        }));
    }, [paginatedEmpresas]);

    return (

        <>
            <PermissionGate permissions={['default']}>

                <ConfirmDialog
                    title="Deletar Instância?"
                    open={confirmOpen}
                    setOpen={setConfirmOpen}
                    onConfirm={deleteReg}
                >
                    Tem certeza que deseja deletar a instância/whatsapp da empresa?
                </ConfirmDialog>

                <ModalEditEntregador setEditEntregador={setEditEntregador} showEditEntregador={showEditEntregador}
                    setRefresh={setRefresh}
                    selectData={selectData}
                    setselectData={setselectData}
                    _idEntregadorEdit={_idEntregadorEdit}
                    nomeEntregadorEdit={nomeEntregadorEdit}
                    veiculoEdit={veiculoEdit}
                    telefoneEdit={telefoneEdit}
                    placaEdit={placaEdit}
                    userID={user._id}
                    id_empresa={objIdEmpresa}
                />

                <InvoicesModal
                    invoices={invoices}
                    isOpen={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                />

                <Teste sidebar={sidebar}>
                    {loading ? <div className="loading"><AiIcons.AiOutlineLoading3Quarters style={{ fontSize: "100px", color: "rgb(180,180,180)" }} /></div> : null}
                    <div className="w-100 p-4">
                        <div className="list-header-empresas">
                            <div className="title">
                                <h1>Empresas</h1>
                            </div>
                        </div>

                        <div className="flex-column flex-md-row" style={{ display: "flex", justifyContent: "space-between"/*, height:"80px"*/ }}>

                            <div className="input-box-list" style={{ width: "100%", maxWidth: "400px" }}>
                                <input className="input-field" placeholder="Pesquisar" onChange={handleFilter} />
                                <i className="icon"><FiIcons.FiSearch /></i>
                            </div>

                            <div className="div-buttons flex-column flex-md-row">

                                <div className="refresh-button" style={{ textAlign: "start", marginBottom: "10px" }}>
                                    <button className="refresh-button" onClick={handleRefresh}>
                                        <SlIcons.SlRefresh style={{ color: "#4281FF", marginRight: "5px", fontSize: "18px", marginBottom: "2px" }} /><a >Atualizar</a>
                                    </button>
                                </div>
                            </div>



                        </div>

                        <Table
                            columns={columns}
                            dataSource={tableData}
                            pagination={{
                                current: page,
                                pageSize: limit,
                                total: filteredEmpresas.length, // Total agora é baseado na lista filtrada
                                onChange: (newPage) => setPage(newPage),
                                showSizeChanger: false,
                            }}
                            rowClassName="row-table"
                            rowHoverable={true}
                        />
                        <div>Total de empresas ativas: {total}</div>
                    </div>
                </Teste>

            </PermissionGate>
        </>


    );
};

export default ListEmpresas;