import React, { useState, useContext } from "react";
import AsyncSelect from 'react-select/async';
import './style.css'
import styled from 'styled-components';
import { SidebarContext } from "../../AppRoutes";
import { AuthContext } from "../../contexts/auth";

import { toast } from "react-toastify";

import { register, getEmpresas, registerGarcom } from "../../services/api";

import PermissionGate from "../../services/PermissionGate";

import { useNavigate } from "react-router-dom";
import LeftMenu from "../../components/LeftMenu";

import { useFormik } from "formik";
import * as Yup from "yup";

import * as AiIcons from 'react-icons/ai'
import * as SlIcons from 'react-icons/sl'
import ReactInputMask from "react-input-mask";

const Teste = styled.div`

    display: flex;
    margin-left:  ${({ sidebar }) => (sidebar ? '250px' : '100px')}; 
    height:auto;
    width:auto;
    transition: 150ms;
    background-color:rgb(247,247,247)!important;
    overflow: initial;
    z-Index: 9;

    @media (max-width: 880px) {
        margin-left: 0;
    }
`;



const AddGarcom = () =>{
    const { user } = useContext(AuthContext);

    const { sidebar, setSidebar } = useContext(SidebarContext);; //TESTANDO CONST NO AppROUTES


    const INITIAL_DATA = {
        value: "",
        label: 'Selecione a empresa',
    };
    
    const [selectData, setselectData] = useState(INITIAL_DATA);
    const mapResponseToValuesAndLabels = (data) => ({
        value: data.id_empresa,
        label: data.id_empresa+" - "+data.cnpj+" - "+data.name,
    });   

    //Validações
    const SignUpSchema = Yup.object().shape({
        email: Yup.string()
            .email("Insira um e-mail válido")
            .required("O e-mail é obrigatório"),
        password: Yup.string()
            .min(6, "A senha deve ter no mínimo 6 caracteres")
            .required("A senha é obrigatória"),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('password'), null], "As senhas devem coincidir")
            .required("A confirmação de senha é obrigatória"),
        nome: Yup.string()
            .min(2, "O nome deve ter pelo menos 2 caracteres")
            .required("O nome é obrigatório"),
        number: Yup.string()
            .min(15, "O número de telefone deve ter 11 dígitos")
            .required("O número de telefone é obrigatório"),
    });
    
    

    const formik = useFormik({
        validationSchema: SignUpSchema,
        validateOnBlur: false,
        validateOnChange: false,
        //enableReinitialize: true,
        initialValues: {
          email: "",
          password: "",
          confirmPassword: "",
          nome: "",
          number: "",
        },
        onSubmit: (values) => {
          //alert(JSON.stringify(values, null, 2));
          //console.log(values);
          //setEmail(values.email);
          const email=values.email;
          //setPassword(values.password);
          const password=values.password;
          //setNome(values.nome);
          const nome=values.nome;
          const number = values.number;
          //const vinculo=values.vinculo;
          setselectData(INITIAL_DATA);

          handleSubmitAPI(email, password, nome, number);
          
        },
    });

    const navigate = useNavigate();
    
    const handleSubmitAPI = (email, password, nome, number) => {

        registerGarcom(nome, email, password, number).then(cast => {
            //console.log("Usuário criado com Sucesso!:::",cast)
            if (cast.status === 201){   
                toast(cast.data.message, {autoClose: 5000, type: "success"})       
                navigate("/mesas-garcons");
            } else{
                toast(cast.data.message, {autoClose: 5000, type: "error"})
            }
        }).catch(err => {
            //console.log('Ocorreu algum erro no servidor!');
            //console.log(err.response.data.msg);
            toast(err.response.data.message, {autoClose: 5000, type: "error"})
        });
        
        
    }

    const handleBack = () => {
        //setSidebar(!sidebar)
        navigate("/mesas-garcons");
        
    }

    return (
        <>
            <PermissionGate permissions={['viewUser']}>
                {/*<LeftMenu setSidebar={setSidebar} sidebar={sidebar} />*/}
                <Teste sidebar={sidebar}>
                    <div className="w-100 p-4">
                        {/* <div className="form"> */}
                            {/*<form onSubmit={handleSubmitButton}>*/}
                            <form onSubmit={formik.handleSubmit} >
                            
                                <div className="form-header" style={{marginBottom:"0px"}}>
                                    <div className="title">
                                        <h1>Cadastro de Garçom</h1>
                                    </div>
                                </div>

                                <div style={{display:"flex", justifyContent:"right"/*, height:"80px"*/}}>


                                    <div className="div-buttons flex-column flex-md-row">
                                        <div className="continue-button">                           
                                            <button type="submit">
                                                <AiIcons.AiOutlineSave style={{marginRight: "5px",fontSize: "22px",marginBottom: "2px"}}/><a>Salvar</a> 
                                            </button>                            
                                        </div>

                                        <div className="back-button" style={{ textAlign: "start" }}>                                                       
                                            <button onClick={handleBack}>
                                                <SlIcons.SlActionUndo style={{color:"#ff4c4c",marginRight: "5px",fontSize: "18px",marginBottom: "2px"}}/><a >Voltar</a> 
                                            </button>
                                        </div>
                                    </div>

                                </div>
                            
                                <div className="input-group mh-100">
                                    <div className="input-box w-100" >
                                        <label>Nome<span style={{color:"red"}}>*</span></label>
                                        <input 
                                        placeholder="Digite o nome do Usuário"
                                        id="nome"
                                        type="nome"
                                        name="nome"
                                        onChange={formik.handleChange}
                                        value={formik.values.nome}
                                        />
                                        {formik.errors.nome && (
                                            <div className="error">{formik.errors.nome}</div>
                                        )}
                                    </div>


                                    <div className="input-box w-100" style={{ transition:sidebar?"200ms":"100ms"}}>
                                        <label htmlFor="email">E-mail de acesso<span style={{color:"red"}}>*</span></label>
                                        <input 
                                            placeholder="Digite o e-mail que será utilizado para acessar o sistema" 
                                            id="email"
                                            name="email"
                                            type="email"
                                            //value={email}
                                            //onChange={e => setEmail(e.target.value)}
                                            onChange={formik.handleChange}
                                            value={formik.values.email}
                                        />
                                        {formik.errors.email && (
                                            <div className="error">{formik.errors.email}</div>
                                        )}
                                    </div>
                                    <div className="input-box w-100" style={{ transition:sidebar?"200ms":"100ms"}}>
                                        <label htmlFor="number">Telefone<span style={{color:"red"}}>*</span></label>
                                        <ReactInputMask mask="(99) 99999-9999" id="number" type="tel" name="number" 
                                            onChange={formik.handleChange}
                                            value={formik.values.number}
                                        placeholder="(xx) xxxx-xxxx" />
                                        {formik.errors.number && (
                                            <div className="error">{formik.errors.number}</div>
                                        )}
                                    </div>

                                    

                                    <div className="input-box w-100" >
                                        <label htmlFor="password">Senha<span style={{color:"red"}}>*</span></label>                            
                                        <input 
                                        placeholder="Crie uma senha" 
                                        id="password"
                                        name="password"
                                        type="password"
                                        //value={password}
                                        //onChange={e => setPassword(e.target.value)}
                                        onChange={formik.handleChange}
                                        value={formik.values.password}
                                        />
                                        {formik.errors.password && (
                                            <div className="error">{formik.errors.password}</div>
                                        )}
                                    </div>


                                    <div className="input-box w-100">
                                        <label htmlFor="confirmPassword">Confirme a Senha<span style={{color:"red"}}>*</span></label>
                                        <input 
                                        placeholder="Digite a senha novamente" 
                                        id="confirmPassword"
                                        name="confirmPassword"
                                        type="password"
                                        //value={confirmpassword}
                                        //onChange={e => setConfirmPassword(e.target.value)}
                                        onChange={formik.handleChange}
                                        value={formik.values.confirmPassword}
                                        />
                                        {formik.errors.confirmPassword && (
                                            <div className="error">{formik.errors.confirmPassword}</div>
                                        )}
                                    </div>
                                </div>
                            </form>
                    </div>
                </Teste>                       
            </PermissionGate>       
        </>
        );
    };
    
export default AddGarcom;