import styled from "styled-components";
import React, { useRef, useContext } from 'react';
import QRCode from 'react-qr-code';
import * as AiIcons from 'react-icons/ai';
import { AuthContext } from "../../contexts/auth";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const DivContainer = styled.div`
  z-index: 15;
  position: fixed;
  top: 0;
  left: 0;
  display: ${({ showLinkCardapioSalao }) => (showLinkCardapioSalao ? 'flex' : 'none')};
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0,0.5);
  align-items: center;
  justify-content: center;
  opacity: ${({ showLinkCardapioSalao }) => (showLinkCardapioSalao ? '1' : '0')};
  transition: opacity 0.3s ease-in-out;
  padding: 20px;

  @media print {
    display: flex;
    background-color: white;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
`;

const DivWhiteBG = styled.div`
  z-index: 20;
  position: relative;
  background-color: rgb(247, 247, 247);
  border-radius: 15px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  max-width: 500px;
  max-height: 90vh;
  overflow-y: auto;

  @media (max-width: 768px) {
    width: 90%;
  }

  @media print {
    width: 100%;
    max-width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    page-break-inside: avoid;
  }
`;

const CloseButton = styled(AiIcons.AiOutlineCloseCircle)`
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;

  @media print {
    display: none;
  }
`;

const QRCodeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  padding: 20px;
  border: 2px dashed gray;
  border-radius: 10px;
  max-height:400px;
  @media print {
    border: none;
    page-break-inside: avoid;
  }
`;

const Button = styled.button`
  background-color: #007bff;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  margin: 10px 0;
  cursor: pointer;

  @media print {
    display: none;
  }
`;

const BtnDiv = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;

  @media print {
    display: none;
  }
`;

const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const DivLink = styled.div`
  background-color: lightblue;
  color: #000;
  padding: 20px;
  border-radius: 10px;

  @media print {
    font-size: 20px;
    font-weight: bold;
    background-color: transparent;
    page-break-inside: avoid;
  }
`;

const ModalLinkCardapioSalao = ({ setShowLinkCardapioSalao, showLinkCardapioSalao, nomeEmpresaForUrl }) => {
  const { user } = useContext(AuthContext);
  const empresa = JSON.parse(localStorage.getItem('empresa'));
  const idEmpresa = empresa?.id_empresa;

  const hostname = window.location.hostname === 'localhost' ? 'http://localhost:3000' : `https://${window.location.hostname}`;
  const yourLink = `${hostname}/cardapio-salao/${nomeEmpresaForUrl}/${idEmpresa}`;

  const modalRef = useRef(null);

  const closeShowLinkCardapioSalao = () => setShowLinkCardapioSalao(!showLinkCardapioSalao);

  const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      toast.success("Link copiado!");
    } catch (err) {
      toast.error("Falha ao copiar o link.");
    }
  };

  const printModal = () => {
    window.print();
  };

  return (
    <DivContainer showLinkCardapioSalao={showLinkCardapioSalao}>
      <DivWhiteBG ref={modalRef}>
        <CloseButton onClick={closeShowLinkCardapioSalao} />
        <h2>O link do seu Cardápio do Salão</h2>
        <p>Copie o link e cole onde quiser compartilhar!</p>
        <LinkContainer>
          <DivLink>
            <span>{yourLink}</span>
          </DivLink>
          <BtnDiv>
            <Button onClick={() => window.open(yourLink, '_blank')}>Abrir cardápio</Button>
            <Button onClick={() => copyToClipboard(yourLink)}>Copiar</Button>
          </BtnDiv>
        </LinkContainer>
        <QRCodeContainer>
          <QRCode value={yourLink} size={350} level="H" />
          <p>Escaneie o QR Code abaixo com o seu celular e faça seu pedido</p>
        </QRCodeContainer>
        <Button onClick={printModal}>Imprimir QR Code</Button>
      </DivWhiteBG>
    </DivContainer>
  );
};

export default ModalLinkCardapioSalao;
