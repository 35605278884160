import React, { useState, useEffect, useContext } from "react";
import { useRef } from "react";
import { useLocation } from "react-router-dom";
import LeftMenu from "../../components/LeftMenu";
//import { MenuProfile } from "../../contexts/MenuProfileContext";
import './style.css';
import styled from "styled-components";
import Profile from "../../components/Profile";
import Chat from "../../components/Chat";
import io from 'socket.io-client';
import mockMessages from "./mock.json";
import mockOptions from "./mockOptions.json";
import contactUserPhoto from "../../assets/img/contact-user-photo.png";
import backgroundChat from "../../assets/img/chat-background.png";
import SearchBar from "../../components/SearchBar";
import * as S from "./stylesProfile";
import UserProfile from "./UserProfile"; // Importa o modal
import { SidebarContext } from "../../AppRoutes";
import { MenuProfileContext } from "../../contexts/MenuProfileContext";
import { getQrCodeWhatsapp, getWhatsappChats, getWhatsappChatLead, sendMessage, sendTextMessageWhatsapp, removeWhatsappSession, toggleBotStatus, getBotStatusLead } from "../../services/api";
import Loading from "react-loading";
import CryptoJS from 'crypto-js';
import moment from 'moment-timezone';
import 'moment/locale/pt-br';  // Adiciona a localidade em português
//import ChatMenu from "../Menu";
import userIcon from "../../assets/img/user-icon.png";
import { ReactComponent as CheckIcon } from "../../assets/svg/check-icon.svg";
import { ReactComponent as StatusIcon } from "../../assets/svg/status-icon.svg";
import { ReactComponent as ChatIcon } from "../../assets/svg/chat-icon.svg";
import { ReactComponent as MenuIcon } from "../../assets/svg/menu-icon.svg";
import { ReactComponent as BackIcon } from "../../assets/svg/arrow-back-icon.svg";
import { FaWhatsapp } from "react-icons/fa";
import { FiSend, FiPauseCircle, FiPlayCircle } from "react-icons/fi"; // Importando o ícone de envio
import { toast } from 'react-toastify';

import Input from "../../components/Input/index";

import GlobalStyles, { Container } from "../../styles/global";

export const WrapperContainerAll = styled.div`
  display: flex;
  height: 100%;
`;

export const WrapperContainerConversa = styled.div`
  width: 100%;
  background-image: url(${backgroundChat});
  position: relative;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 120px);
`;

const WrapperContent = styled.div`
  padding: 1rem 4rem;
  height: 100%;
  overflow-y: scroll;
  margin-bottom: 60px;
  background: ${backgroundChat};
`;

const ChatDate = styled.time`
  display: block;
  background: #e1f3fb;
  padding: 10px;
  border-radius: 7.5px;
  box-shadow: 0 1px 0.5px rgba(0, 0, 0, 0.13);
  font-size: 1.42rem;
  margin: 10px auto;
  width: fit-content;
  text-transform: uppercase;
`;

const Message = styled.div`
  background: ${(props) => (props.fromMe ? '#dcf8c6' : '#ffffff')};
  padding: 10px;
  border-radius: 7.5px;
  box-shadow: 0 1px 0.5px rgba(0, 0, 0, 0.13);
  font-size: 1.42rem;
  margin: 1px 0px 1px ${(props) => (props.fromMe ? 'auto' : '0px')};
  width: fit-content;
  max-width: 60%;
  margin-bottom: ${(props) => (props.fromMe ? '3px' : '10px')};
  white-space: pre-wrap;
  word-break: break-word; /* Adicionado para quebra de palavras longas */
  span {
    font-size: 1.1rem;
    color: rgba(0, 0, 0, 0.45);
    display: block;
    text-align: right;
  }
`;

export const Warn = styled.div`
  background: #fdf4c4;
  width: fit-content;
  margin: 30px auto;
  padding: 5px 10px;
  font-size: 1.6rem;
  border-radius: 5px;
`;

export const WrapperHMC = styled.aside`
  position: relative;
  width: 55rem;
  height: 100%;
  background: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  @media (max-width: 1200px) {
    width: 45rem;
  }
`;


export const WrapperMenu = styled.div`
  position: absolute;
  z-index: 10;
  top: 60px;
  right: 20px;
  background: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: 2px;

  transition: transform 0.1s ease;
  transform-origin: right top;
  transform: scale(0);

  &.active {
    transform: scale(1);
  }
`;

export const ActionWrapperMenu = styled.ul`
  padding: 10px 0px;
`;

export const ActionMenu = styled.li`
  padding: 10px 20px;
  cursor: pointer;
  font-size: 1.4rem;
  color: #333;
  transition: background 0.2s ease-in-out;

  &:hover {
    background: #f5f5f5;
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.3;

    &:hover {
      background: none;
    }
  }
`;


export const Header = styled.header`
  display: flex;
  align-items: center;

  padding: 13px;
  background: #ededed;
`;

export const ContactPhoto = styled.div`
  margin-right: 15px;

  img {
    border-radius: 100%;
  }
`;

export const ContactName = styled.span`
  font-size: 1.7rem;
`;


export const WrapperChat = styled.div`
  padding: 10px;
  margin-top: 15px;
  background: #f0f0f0;
  position: absolute;
  bottom: 0;
  width: 100%;
`;

export const WrapperListContacts = styled.div`
  display: flex;
  align-items: center;
  background: ${(props) => (props.isSelected ? '#eaeaea' : 'white')};
  cursor: pointer;
  padding: 10px;  /* Adicionado padding para espaçamento */
  border-bottom: 1px solid #eee;
  &:hover {
    background: #f4f4f4;
  }
`;

export const ContactPhotoListContacts = styled.div`
  flex: 0 0 60px;  /* Define a largura fixa da área da foto */
  padding: 10px;

  img {
    border-radius: 100%;
    width: 100%;  /* Garante que a imagem ocupe a largura total do contêiner */
  }
`;

export const ContactNameAndTime = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  span {
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 70%;
  }

  p {
    font-size: 1.2rem;
    color: rgba(0, 0, 0, 0.45);
    margin-left: 10px;  /* Adiciona espaço entre o nome e a data */
    flex-shrink: 0;  /* Garante que a data não encolha */
  }
`;

export const ContactMessage = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  svg {
    margin-right: 5px;
  }

  P {
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;  /* Ajusta a largura máxima para evitar overflow */
  }
`;

export const MessageDataWrapper = styled.div`
  flex: 1;
  padding-right: 15px;
  overflow: hidden;  /* Garante que o conteúdo não extrapole os limites */
  display: flex;
  flex-direction: column;
`;


export const WrapperListHeader = styled.div`
  border-right: 1px solid rgba(0, 0, 0, 0.1);
`;

export const HeaderListHeader = styled.header`
  background-color: #ededed;
  width: 100%;
  padding: 15px;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const UserImage = styled.div`
  margin-right: auto;
  cursor: pointer;

  img {
    border-radius: 100%;
  }
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;

  svg {
    width: 50px;
    cursor: pointer;

    &:not(:last-child) {
      cursor: not-allowed;
      opacity: 0.3;
    }
  }
`;


const Teste = styled.div`
  display: flex;
  margin-left:  ${({ sidebar }) => (sidebar ? '250px' : '100px')}; 
  height:auto;
  width:auto;
  transition: 150ms;
  background-color:rgb(247,247,247)!important;
  --background-color:white!important;
  overflow: initial;
  z-Index: 9;
`;

// Helper Function to Group Messages by Date
const groupMessagesByDate = (messages) => {
  return messages.reduce((acc, message) => {
    const date = moment(message.createdAt).format('YYYY-MM-DD');
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(message);
    return acc;
  }, {});
};

// Função para gerar ObjectId aleatório
const generateObjectId = () => {
  return Math.floor(Date.now() / 1000).toString(16) + 'xxxxxxxxxxxxxxxx'.replace(/[x]/g, function () {
    return Math.floor(Math.random() * 16).toString(16);
  }).toLowerCase();
};

const WhatsAppWeb = () => {
  moment.locale('pt-br');  // Define o locale para português
  const secretKey = 'my-custom-secret-key:1af0addcce4b56f753ed10c285667ad031bae57af19747f576b05312b8cf85be';
  const userEncrypted = localStorage.getItem('user');
  const user = CryptoJS.AES.decrypt(userEncrypted, secretKey).toString(CryptoJS.enc.Utf8);
  const userParse = JSON.parse(user);
  const userID = userParse._id;
  const empresa = localStorage.getItem('empresa');
  const empresaParse = JSON.parse(empresa);
  const empresaID = empresaParse._id;
  const token = localStorage.getItem('token');

  const location = useLocation(); // Captura o state passado pelo navigate
  const leadIdParaAbrir = location.state?.lead_id || null; // Verifica se recebeu um lead_id

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  //const { openMenu } = useContext(MenuProfileContext);
  const { isOpen, openMenu, closeMenu } = useContext(MenuProfileContext);
  //const openMenu = () => setIsMenuOpen(true);
  const menuRef = useRef(null);
  const [message, setMessage] = useState("");
  const { sidebar, setSidebar } = useContext(SidebarContext);; //TESTANDO CONST NO AppROUTES
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [qrCodeImg, setQrCodeImg] = useState('');
  const [generatingQrCode, setGeneratingQrCode] = useState(false);
  const [isLoged, setIsLoged] = useState(true);
  //const [socket, setSocket] = useState(null);
  const [chats, setChats] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedChat, setSelectedChat] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [messagesLeadChannel, setMessagesLeadChannel] = useState([]);
  const timeoutRef = useRef(null); // UseRef to store the timeout ID
  const isMountedRef = useRef(true); // Para saber se o componente está montado
  const cancelQrCodeFetchRef = useRef(false); // Flag para cancelar a execução do fetch
  const [botPausado, setBotPausado] = useState(false);
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  // Sort messages by date
  const sortedMessages = [...messagesLeadChannel].sort((a, b) =>
    new Date(a.createdAt) - new Date(b.createdAt)
  );

  const groupedMessages = groupMessagesByDate(sortedMessages);
  const messageEndRef = useRef(null);

  useEffect(() => {
    if (messageEndRef.current) {
      messageEndRef.current.scrollIntoView({ behavior: "instant" });
    }
  }, [messagesLeadChannel]);

  const handleClickOutside = event => {
    if (menuRef.current && !menuRef.current.contains(event.target))
      setIsMenuOpen(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!message) return;
    //console.log(selectedChat.mobile_number);
    const messageData = {
      id: generateObjectId(),
      message,
      menssage: 'any',  // Substitua com os dados reais
      number: selectedChat.mobile_number,     // Substitua com os dados reais
      quoted_message_id: 'any'  // Substitua com os dados reais
    };

    console.log("messageData>>", messageData)

    try {
      const appName = empresaParse.whatsapp.id;
      const token = empresaParse.whatsapp.token;
      setMessage('');  // Limpa o campo de mensagem antes do envio
      //await sendMessage(appName, messageData, token);
      await sendTextMessageWhatsapp(empresaID, selectedChat.mobile_number, message).then(response => console.log(response));
      setMessage('');  // Limpa o campo de mensagem após o envio
    } catch (error) {
      console.error('Erro ao enviar a mensagem:', error);
    }
  };

  const isDevelopment = window.location.hostname === 'localhost';
  const apiUrl = isDevelopment
    ? process.env.REACT_APP_SERVER_URL_DEV
    : process.env.REACT_APP_SERVER_URL_PROD;

  useEffect(() => {
    const socketNamespace = "/whatsapp";
    const wsUrl = apiUrl + socketNamespace; // Alterar a URL se o servidor estiver em um endereço diferente
    const socket = io(wsUrl, { withCredentials: true, transports: ['websocket'], auth: { token: localStorage.getItem('token') } });

    socket.on('chats', (newChat) => {
      setChats(oldChats => [newChat, ...oldChats.filter(chat => chat._id !== newChat._id)]);
      console.log("PASSOU AQUI!!!")
    });

    return () => {
      socket.off('chats');
    };
  }, []);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  });

  useEffect(() => {
    // Defina isMounted como true ao montar o componente
    isMountedRef.current = true;
    cancelQrCodeFetchRef.current = false; // Redefine o cancelamento ao montar o componente
    fetchQrCode();

    // Função de limpeza ao desmontar o componente
    return () => {
      isMountedRef.current = false; // Define como false quando o componente desmonta
      cancelQrCodeFetchRef.current = true; // Cancela qualquer execução futura de fetchQrCode
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        console.log('Timeout cleared on unmount');
      }
    };
  }, []);

  const fetchQrCode = () => {
    if (!isMountedRef.current) return; // Impede a execução se o componente estiver desmontado

    console.log('Fetching QR Code...');
    setGeneratingQrCode(true);

    getQrCodeWhatsapp(empresaID).then((response) => {
      console.log('API Response:', response);

      if (!isMountedRef.current) return; // Verifica se o componente ainda está montado antes de continuar

      if (response.data.qrcode.error === 'is_loged') {
        console.log('User is logged in, stopping QR code regeneration.');
        setIsLoged(true);
        setGeneratingQrCode(false);
        return;
      }

      if (response.data.qrcode.qr !== '') {
        console.log('User is not logged in');
        setIsLoged(false);
      }

      setQrCodeImg(response.data.qrcode.qr);

      const ttl = response.data.qrcode.ttl;
      const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
      console.log('Current Time:', currentTime);
      console.log('TTL:', ttl);

      const timeoutDuration = 10 * 1000; // Time until 10 seconds after ttl in milliseconds
      console.log('Timeout Duration (ms):', timeoutDuration);

      if (timeoutDuration > 0) {
        timeoutRef.current = setTimeout(() => {
          console.log('Regenerating QR Code...');
          fetchQrCode();
        }, timeoutDuration);
      } else {
        console.log('TTL has already passed.');
      }
    }).catch((error) => {
      console.log('Error:', error);
      setGeneratingQrCode(false);
    }).finally(() => {
      if (isMountedRef.current) {
        setGeneratingQrCode(false);
      }
      console.log('Finally block executed.');
    });
  };

  useEffect(() => {
    if (selectedChat?._id) {
      getBotStatusLead(selectedChat._id)
        .then(response => {
          console.log("Status desse bot:", response.data.bot_pausado)
          setBotPausado(response.data.bot_pausado);
        })
        .catch(error => {
          console.error("Erro ao obter status do bot:", error);
        });
    }
  }, [selectedChat]);

  /*useEffect(() => {
    const fetchChats = async () => {
      try {
        const page = 0;
        const pageSize = 30;
        const query = searchQuery; // Adicione seu termo de busca aqui, se houver

        //console.log("O que está sendo enviado:",{empresaID, page, pageSize, query, token})
        const response = await getWhatsappChats(empresaID, page, pageSize, query, token);
        //console.log("O que está sendo Recebido!:",response)
        if (query === '' || query.length >= 3) {
          setChats(response.data.messages[0].data);
        }
        if (response.data.messages[0].data.length > 0 && query !== '') {
          const data = response.data.messages[0].data;
          if (data.length > 0 && query.length >= 3) {
            //console.log("Oq tem aqui?", data);
            setChats([data[0]]); // Armazena apenas o primeiro elemento como array
          }
        }
        //console.log(response.data.messages[0].data);
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };

    // Chame fetchChats inicialmente
    fetchChats();

  }, [searchQuery]);*/
  const [page, setPage] = useState(0); // Página inicial
  const [hasMoreChats, setHasMoreChats] = useState(true); // Se ainda há chats para carregar
  const chatsContainerRef = useRef(null); // Referência ao container de chats
  const [loadingMore, setLoadingMore] = useState(false); // Para evitar requisições duplicadas

  const fetchChats = async (newPage, append = false) => {
    try {
      const pageSize = 30;
      const query = searchQuery;

      console.log("🔄 Carregando página:", newPage);

      const response = await getWhatsappChats(empresaID, newPage, pageSize, query, token);
      const newChats = response.data.messages[0].data;

      if (newChats.length === 0) {
        setHasMoreChats(false); // Se não há mais chats, parar carregamento
      }

      setChats((prevChats) => append ? [...prevChats, ...newChats] : newChats);
      setLoading(false);
      setLoadingMore(false);
    } catch (err) {
      console.error("❌ Erro ao carregar chats:", err);
      setLoading(false);
      setLoadingMore(false);
    }
  };

  // **📌 Carregar os chats ao iniciar e ao buscar**
  useEffect(() => {
    setChats([]); // Resetar chats ao buscar
    setPage(0);
    setHasMoreChats(true);
    fetchChats(0, false);
  }, [searchQuery]);

  // **📌 Detectar scroll e carregar mais chats**
  const handleScroll = () => {
    if (!hasMoreChats || loadingMore) return;

    const container = chatsContainerRef.current;
    if (!container) return;

    const bottom = container.scrollHeight - container.scrollTop <= container.clientHeight + 10;
    if (bottom) {
      console.log("📢 Rolou até o fim, carregando mais chats...");
      setLoadingMore(true);
      setPage((prevPage) => {
        const nextPage = prevPage + 1;
        fetchChats(nextPage, true);
        return nextPage;
      });
    }
  };

  // **📌 Adicionar listener de scroll**
  useEffect(() => {
    const container = chatsContainerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, [hasMoreChats, loadingMore]);


  useEffect(() => {

    const socketNamespace = "/whatsapp";
    const wsUrl = apiUrl + socketNamespace; // Alterar a URL se o servidor estiver em um endereço diferente
    const socket = io(wsUrl, { withCredentials: true, transports: ['websocket'], auth: { token: localStorage.getItem('token') } });


    if (selectedChat) {

      socket.emit('join-lead-messages', { leadID: selectedChat._id });
      const fetchChatLead = async () => {
        try {
          const page = 0;
          const pageSize = 30;
          const response = await getWhatsappChatLead(empresaID, selectedChat._id, page, pageSize, token);
          //console.log(response.data);
          setMessagesLeadChannel(response.data.messages[0].data);
        } catch (err) {
          console.error(err);
        }
      };

      socket.on('messages', (newMessage) => {
        //messagesLeadChannel
        setMessagesLeadChannel(oldMessages => [newMessage, ...oldMessages.filter(message => message._id !== newMessage._id)]);
        console.log("newMessage", newMessage);
        console.log("messagesLeadChannel", messagesLeadChannel);
      });
      // Chame fetchChatLead inicialmente
      fetchChatLead();

      // Defina o intervalo para chamar fetchChatLead a cada 1 segundo
      // const interval = setInterval(fetchChatLead, 1000);

      // Limpe o intervalo quando o componente for desmontado ou quando selectedChat mudar
      // return () => clearInterval(interval);
      return () => {
        socket.emit('leave-lead-messages', { leadID: selectedChat._id });
        socket.off('messages');
      };
    }
  }, [selectedChat]);

  // **📌 Verificar se há um chat correspondente ao lead_id**
  useEffect(() => {
    if (leadIdParaAbrir) {
      const chatParaAbrir = chats.find(chat => chat._id === leadIdParaAbrir);

      if (chatParaAbrir) {
        setSelectedChat(chatParaAbrir); // Abre o chat automaticamente
      } else {
        console.warn("⚠️ Chat não encontrado para o lead_id:", leadIdParaAbrir);
      }
    }
  }, [leadIdParaAbrir, chats]);

  const formatDate = (date) => {
    const now = moment().tz("America/Sao_Paulo");
    const inputDate = moment(date).tz("America/Sao_Paulo");

    if (now.isSame(inputDate, 'day')) {
      return inputDate.format('HH:mm');
    } else if (now.subtract(1, 'days').isSame(inputDate, 'day')) {
      return 'Ontem';
    } else if (now.subtract(6, 'days').isBefore(inputDate)) {
      return inputDate.format('dddd');
    } else {
      return inputDate.format('DD/MM/YYYY');
    }
  };


  const handleDisconnect = async () => {
    try {
      const res = await removeWhatsappSession(empresaID); // Aguarda a resposta da API

      // Exibe um toast de sucesso com a mensagem da resposta da API
      toast(res.data.msg, {
        autoClose: 3000,
        type: "success"
      });

      // Após a desconexão, chama a função para gerar um novo QR Code
      fetchQrCode();
    } catch (error) {
      console.error("Erro ao encerrar sessão:", error.response?.data || error.message);

      // Exibe um toast de erro com a mensagem da API ou um fallback padrão
      toast(error.response?.data?.msg || "Erro ao encerrar sessão!", {
        autoClose: 3000,
        type: "error"
      });
    }
  };

  const handleToggleBot = async (lead_id) => {
    try {
      const response = await toggleBotStatus(lead_id);
      setBotPausado(response.data.bot_pausado);
      toast(response.data.msg, {
        autoClose: 3000,
        type: "success"
      });
    } catch (error) {
      console.error("Erro ao alternar o status do bot:", error);
    }
  };

  return (
    <>
      {/*<LeftMenu setSidebar={setSidebar} sidebar={sidebar} />*/}
      <Teste sidebar={sidebar}>
        {!isLoged ?
          <div className="contentItemComplete" style={{ width: '100%' }}>
            <div className="input-group inputGroup-adicinaItem">
              <div className="formGroupRow">
                <div style={{ backgroundColor: 'white', padding: '20px', display: 'flex', flexDirection: 'column', gap: '20px', margin: 'auto' }}>
                  <h2>Para sincronizar o WhatsApp faça os seguintes passos</h2>
                  <ol style={{ paddingLeft: '20px' }}>
                    <li>Abra o WhatsApp no seu celular.</li>
                    <li>Toque em Mais opções no Android ou em Configurações no iPhone</li>
                    <li>Toque em Dispositivos conectados e, em seguida, em Conectar dispositivo.</li>
                    <li>Aponte seu celular para esta tela para escanear o QR code.</li>
                  </ol>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <div style={{ padding: '10px', backgroundColor: 'white', border: '1px solid #E0E0E0', borderRadius: '8px' }}>
                      <div style={{ width: '340px', height: '340px', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '8px' }}>
                        {
                          generatingQrCode ? <Loading type={"spinningBubbles"} className="generatingQrCodeLoading" />
                            :
                            <img src={`${qrCodeImg}`} alt="QR Code" />
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          :
          <Container>
            <WrapperContainerAll>

              <WrapperHMC>

                <WrapperListHeader>
                  <HeaderListHeader>
                    <UserImage onClick={openMenu}>
                      <img src={userParse?.user_img[0] || userIcon} width="35" height="35" alt="Foto de perfil do usuário" />
                    </UserImage>
                    <Actions ref={menuRef}>
                      <StatusIcon role="img" aria-label="Ícone de abrir status" />
                      <ChatIcon role="img" aria-label="Ícone de iniciar nova conversa" />
                      <MenuIcon
                        role="img"
                        aria-label="Ícone de expandir menu"
                        onClick={() => setIsMenuOpen(true)}
                      />
                    </Actions>
                  </HeaderListHeader>
                </WrapperListHeader>

                <WrapperMenu className={isMenuOpen ? "active" : ""}>
                  <ActionWrapperMenu>
                    {mockOptions.map(({ id, action }) => (
                      <ActionMenu
                        key={id}
                        onClick={action === "Desconectar" ? handleDisconnect : openMenu}
                        className={action === "Desconectar" ? "" : ""} // Apenas desativa se necessário
                      >
                        {action}
                      </ActionMenu>
                    ))}
                  </ActionWrapperMenu>
                </WrapperMenu>



                <SearchBar searchQuery={searchQuery} setSearchQuery={setSearchQuery} />

                {/* 📌 Adicionamos `ref={chatsContainerRef}` para detectar o scroll */}
                <div className="containerChatsWhatsapp" ref={chatsContainerRef}>
                  {chats.map((chat) => (
                    <WrapperListContacts key={chat._id}
                      isSelected={chat._id === selectedChat?._id}
                      onClick={() => {
                        setSelectedChat(chat);
                      }}>
                      <ContactPhotoListContacts>
                        <img
                          src={chat.channel_data.whatsapp.profile_picture ? chat.channel_data.whatsapp.profile_picture : userIcon}
                          width="60"
                          alt="Foto de perfil do usuário"
                        />
                      </ContactPhotoListContacts>
                      <MessageDataWrapper>
                        <ContactNameAndTime>
                          <span>{chat.name}</span>
                          <p>{formatDate(chat.message.createdAt)}</p>
                        </ContactNameAndTime>
                        <ContactMessage>
                          <CheckIcon />
                          <p>{chat.message.text}</p>
                        </ContactMessage>
                      </MessageDataWrapper>
                    </WrapperListContacts>
                  ))}

                  {/* **📌 Indicador de carregamento enquanto busca mais chats** */}
                  {loadingMore && <p className="loadingChats-indicator">🔄 Carregando mais conversas...</p>}
                </div>

              </WrapperHMC>

              {selectedChat ?
                <WrapperContainerConversa>

                  <Header onClick={() => setIsProfileOpen(true)} style={{ cursor: "pointer" }}>
                    <ContactPhoto>
                      {console.log("selectedChat:", JSON.stringify(selectedChat))}
                      <img
                        src={selectedChat.channel_data.whatsapp.profile_picture || userIcon}
                        width="40"
                        alt="Foto de perfil do usuário"
                      />
                    </ContactPhoto>
                    <ContactName>{selectedChat.name}</ContactName>
                  </Header>

                  <WrapperContent>
                    {Object.keys(groupedMessages).map((date) => (
                      <React.Fragment key={date}>
                        <ChatDate>{moment(date).calendar(null, {
                          sameDay: '[Hoje]',
                          lastDay: '[Ontem]',
                          lastWeek: 'dddd',
                          sameElse: 'DD/MM/YYYY'
                        })}</ChatDate>
                        {groupedMessages[date].map(({ _id, text, createdAt, fromMe }) => (
                          <Message key={_id} fromMe={fromMe}>
                            <p>{text}</p>
                            <span>{moment(createdAt).format('HH:mm')}</span>
                          </Message>
                        ))}
                      </React.Fragment>
                    ))}
                    <div ref={messageEndRef} />
                  </WrapperContent>

                  <WrapperChat>
                    <form onSubmit={handleSubmit} style={{ display: "flex", alignItems: "center", gap: 10 }}>
                      <Input
                        type="text"
                        placeholder="Digite uma mensagem"
                        onChange={({ target }) => setMessage(target.value)}
                        value={message}
                      />

                      <button
                        type="submit"
                        style={{
                          background: "transparent",
                          border: "none",
                          cursor: "pointer",
                          color: "#25D366", // Cor do ícone (verde do WhatsApp)
                          fontSize: "2rem",
                        }}
                      >
                        <FiSend />
                      </button>

                      {/* Botão de Pausar/Reativar o Bot */}
                      <button
                        type="button"
                        onClick={() => handleToggleBot(selectedChat._id)}
                        style={{
                          background: "transparent",
                          border: botPausado ? "1px solid #FF4D4D" : "1px solid #25D366",
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          gap: 5,
                          color: botPausado ? "#FF4D4D" : "#25D366", // Vermelho se pausado, branco se ativo
                          fontSize: "14px",
                          fontWeight: '600',
                          minWidth: 140,
                          justifyContent: 'center',
                          borderRadius: 8
                        }}
                      >
                        {botPausado ? <FiPauseCircle style={{ fontSize: 17 }} /> : <FiPlayCircle style={{ fontSize: 17 }} />}
                        <span>{botPausado ? "Robô Pausado" : "Robô Ativado"}</span>
                      </button>
                    </form>
                  </WrapperChat>
                  {/* Modal do Perfil */}
                  <UserProfile isOpen={isProfileOpen} onClose={() => setIsProfileOpen(false)} user={selectedChat} />
                </WrapperContainerConversa>
                :
                <WrapperContainerConversa style={{
                  background: 'white', justifyContent: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center'
                }}>
                  <FaWhatsapp style={{ fontSize: '100px', color: 'rgba(0, 0, 0, 0.45)' }} />
                  <Warn style={{ background: 'white' }}>Selecione uma conversa para começar a conversar</Warn>
                </WrapperContainerConversa>
              }

            </WrapperContainerAll>


            {isOpen && (
              <S.Wrapper>
                <S.Header>
                  <S.Back>
                    <BackIcon onClick={closeMenu} />
                    <h3>Perfil</h3>
                  </S.Back>
                </S.Header>

                <S.UserImage>
                  <img src={userParse?.user_img[0] || userIcon} width="200" height="200" alt="Foto de perfil do usuário" />
                </S.UserImage>

                <S.UserData>
                  <span>Nome</span>
                  <p>{empresaParse.name}</p>
                </S.UserData>

                <S.UserData>
                  <span>Recado</span>
                  <p>Olá! Eu estou usando o WhatsApp.</p>
                </S.UserData>
              </S.Wrapper>
            )}
          </Container>
        }

        <GlobalStyles />

      </Teste>
    </>
  );
}

export default WhatsAppWeb;