import React, { useContext, useRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import * as HiIcons from "react-icons/hi";
import { SidebarData } from "./SidebarData";
import SubMenu from "./SubMenu";
import { IconContext } from "react-icons/lib";
import PermissionGate from "../../services/PermissionGate";
import "./style.css";
import userDiv from "../../img/userDiv2.png";
import defaultUserImg from "../../img/defaultUserImg.png";
import LogoP from "../../img/logoP.png";
import logoImg from "../../img/logoBlue.png";
import audioNotify from "../../assets/audio/soundNotify.mp3";
import { MdStorefront } from "react-icons/md";
import { RiArrowDropDownLine } from "react-icons/ri";
import { FaRobot } from "react-icons/fa";
import CryptoJS from "crypto-js";
import io from "socket.io-client";
import { ImPrinter } from "react-icons/im";
import ModalUserImg from "../ModalAddUserImg";
import ModalEditUser from "../../components/ModalEditUser";
import ModalLinkCardapio from "../ModalLinkCardapio";
import ModalLinkCardapioSalao from "../ModalLinkCardapioSalao";
import { RiMenuFoldFill } from "react-icons/ri";
import { RiMenuUnfoldFill } from "react-icons/ri";
import { GiHamburgerMenu } from "react-icons/gi";
import { MdExitToApp } from "react-icons/md";
import roundTable from "../../img/round-table.png"
import entregadorIco from "../../img/entregador.png"
import { v4 as uuidv4 } from "uuid"; // Biblioteca para gerar IDs únicos
import { SidebarContext } from "../../AppRoutes";
import { AuthContext } from "../../contexts/auth";
import {
  getUser,
  getVinculoEmpresa,
  updateStatusBotEmpresa,
  changeStatusLoja,
  getDaysToExpireLicense,
} from "../../services/api";
import { Tooltip, Drawer } from "antd";
import AtendimentoModal from "./AtendimentoModal";

const Nav = styled.div`
  background: white;
  --background: linear-gradient(to left, #4281ff, #51d2ff);
  left: ${({ sidebar }) => (sidebar ? "250px" : "100px")};
  transition: 150ms;
  height: 80px;
  width: ${({ sidebar }) =>
    sidebar ? "calc(100% - 250px)" : "calc(100% - 100px)"};
  display: flex;
  --justify-content: flex-start;
  align-items: center;
  --border-bottom: 2px solid #0000001c;
  position: relative;
  z-index: 10;
  box-shadow: 1px 1px 6px rgb(180, 180, 180);

  @media (max-width: 880px){
    left: 0;
    width: 100%;
  }
`;

//const NavIcon = styled(Link)`
const NavIcon = styled.div`
  justify-content: center;
  font-size: 13px;
  height: 20px;
  display: flex;
  align-items: center;
  color: white;
  text-decoration: unset;
  transition: font-size 150ms;

`;

const SidebarNav = styled.nav`
  background: white;
  width: ${({ sidebar }) => (sidebar ? "250px" : "100px")};
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  transition: width 150ms cubic-bezier(0.4, 0, 0.2, 1); // Ajustado para uma curva bezier comum para movimento
  z-index: 10;
  border-right: solid 1px rgb(240, 240, 240);

  span {
    transition-delay: 200ms;
    transition-property: font-size, visibility, opacity; // Adicionando propriedades específicas
    visibility: ${({ sidebar }) => (sidebar ? "visible" : "hidden")};
    opacity: ${({ sidebar }) => (sidebar ? "1" : "0")};
    font-size: ${({ sidebar }) => (sidebar ? "14px" : "0px")};
    transition: visibility 0s, opacity 0.5s,
      ${({ sidebar }) =>
    sidebar ? "font-size 250ms linear" : "font-size 100ms linear"};
  }

  @media (max-width: 880px) {
    display: none;
  }
`;

const ModalUserOptions = styled.div`
  font-size: 14px;
  position: absolute;
  top: 82px;
  left: 150px;
  display: ${({ showOptions }) => (showOptions ? "none" : "")};
  float: left;
  min-width: 160px;
  margin: 2px 0 0;
  padding: 5px 0;
  list-style: none;
  text-align: left;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  background-color: #fff;
  --background-color: rgb(247, 247, 247) !important;
  background-clip: padding-box;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  z-index: 25;

  li {
    font-weight: 400;
    line-height: 1.42857143;
    display: block;
    clear: both;
    padding: 3px 20px;
    white-space: nowrap;
    color: #58595b;
  }
  li:hover {
    background: #f5f5f5;
  }
`;

/*
const OrdercolumnPrint = styled.div`
    min-width: 600px;
    max-width: 600px; 
    min-height: 1300px;
    max-height: 1300px;       
    position: absolute;
    top: 10%;
    left: 50%;
    font-size:26px;
    z-index:500
`;*/

const SidebarWrap = styled.div`
  width: 100%;
`;

const MenuAjuda = styled.div`
    position: absolute;
    top: 90px;
    right: 0px;
    background: white;
    height: 60px;
    width: max-content;
    border-radius: 10px;
    box-shadow: 1px 1px 5px 2px lightgray;
    display: inline-grid;
    justify-content: center;
    align-items: center;
    justify-items: center;
    z-index:2;
`;

const HelpButtonContainer = styled.div`
  position: fixed;
  bottom: 80px;
  right: 10px;
  z-index: 14;
`;

const HelpButton = styled.button`
  background: #007bff;
  color: white;
  font-size: 24px;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  cursor: pointer;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  transition: transform 0.2s;
  
  &:hover {
    transform: scale(1.1);
  }
`;

const HelpModal = styled.div`
  position: absolute;
  bottom: 60px;
  right: 0;
  background: white;
  width: 220px;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
  text-align: center;
  display: ${({ show }) => (show ? "block" : "none")};
`;

const SupportButton = styled.button`
  background: #28a745;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 5px;
  margin-top: 10px;
  cursor: pointer;
  width: 100%;
  font-size: 14px;

  &:hover {
    background: #218838;
  }
`;

const HelpWidget = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleSupportClick = () => {
    window.open("https://api.whatsapp.com/send?phone=+5562999677687&text=Olá, preciso de suporte!", "_blank");
  };

  return (
    <HelpButtonContainer>
      <HelpButton onClick={() => setIsModalOpen(!isModalOpen)}>?</HelpButton>
      <HelpModal show={isModalOpen}>
        <div className="closeModalPedido" onClick={() => setIsModalOpen(!isModalOpen)}>
          <div
            iconname="x"
            iconcolor="#2B2B2B"
            iconsize={18}
            className="iconCancel"
            style={{ height: 18, display: "flex" }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={18}
              height={18}
              viewBox="0 0 24 24"
              fill="none"
              stroke="#2B2B2B"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-x"
            >
              <line x1={18} y1={6} x2={6} y2={18} />
              <line x1={6} y1={6} x2={18} y2={18} />
            </svg>
          </div>
        </div>
        <p>Necessita de Ajuda?</p>
        <SupportButton onClick={handleSupportClick}>Falar com o suporte</SupportButton>
      </HelpModal>
    </HelpButtonContainer>
  );
};

const CrispChat = () => {
  const { user } = useContext(AuthContext);
  useEffect(() => {
    if (!window.$crisp) {
      window.$crisp = [];
      window.CRISP_WEBSITE_ID = "b90fc97a-9b0e-45d4-bf0b-94597c4c9f1e";

      const script = document.createElement("script");
      script.src = "https://client.crisp.chat/l.js";
      script.async = true;
      document.head.appendChild(script);

      script.onload = () => {
        console.log("✅ Crisp Chat carregado");

        // 🔹 Define a posição do botão (bottom-right)
        window.$crisp.push(["config", "position", ["bottom", "right"]]);

        // 🔹 Ajusta o z-index do widget Crisp
        const crispButton = document.querySelector(".crisp-client");
        if (crispButton) {
          crispButton.style.zIndex = "14";
        }

        // 🔹 Identifica o usuário no Crisp
        if (user) {
          console.log("📌 Definindo usuário no Crisp:", user.name, user.email);
          window.$crisp.push(["set", "user:nickname", [user.name]]);
          window.$crisp.push(["set", "user:email", [user.email]]);
        }
      };
    }
    
  }, []);

  return null; // 🔹 Não renderiza NENHUM botão extra, apenas embute o Crisp Chat
};

const LeftMenu = (/*{setSidebar , sidebar}*/) => {
  const { sidebar, setSidebar } = useContext(SidebarContext);
  const socketRef = useRef(null);
  const [showEditUser, setEditUser] = useState(true);
  const [_idUserEdit, set_idUserEdit] = useState("");
  const [usernameEdit, setUsernameEdit] = useState("");
  const [emailEdit, setEmailEdit] = useState("");
  const [roleEdit, setRoleEdit] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [open, setOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const INITIAL_DATA = {
    value: "",
    label: "Selecione uma empresa",
  };
  const [selectData, setselectData] = useState(INITIAL_DATA);
  const navigate = useNavigate();

  const [dropstatusrobo, setDropStatusRobo] = useState(false);
  const [statusLojaTemporario, setStatusLojaTemporario] = useState(false);

  const { logout } = useContext(AuthContext);
  const secretKey =
    "my-custom-secret-key:1af0addcce4b56f753ed10c285667ad031bae57af19747f576b05312b8cf85be";

  const userEncrypted = localStorage.getItem("user");
  const user = CryptoJS.AES.decrypt(userEncrypted, secretKey).toString(
    CryptoJS.enc.Utf8
  );
  const userParse = JSON.parse(user);
  //console.log(userParse)
  //const userParse = user;
  const userID = userParse._id;
  const userName = userParse.name;
  const userEmail = userParse.email;
  var userImg = null;
  if (userParse.user_img?.length > 0) {
    userImg = userParse.user_img[0];
  }

  const empresa = localStorage.getItem("empresa");
  const empresaParse = JSON.parse(empresa);
  const idEmpresa = empresaParse.id_empresa;
  const objIdEmpresa = empresaParse._id;
  const cnpj = empresaParse.cnpj;
  const razao = empresaParse.razao;
  const nomeEmpresa = empresaParse.name;
  const nomeEmpresaForUrl = nomeEmpresa
    .replace(/\s+/g, "-")
    .replace(/[A-Z]/g, (c) => c.toLowerCase());

  const [showLinkCardapio, setShowLinkCardapio] = useState(false);
  const [showLinkCardapioSalao, setShowLinkCardapioSalao] = useState(false);
  const [showOptions, setUserOptions] = useState(true);
  const [showModalImg, setModalImg] = useState(true);
  const userOptionsRef = useRef();
  const leftMenuRef = useRef();
  const menuStatusRoboRef = useRef();
  const menuStatusRoboRef_ = useRef();
  const [statusBot, setStatusBot] = useState(false);
  var imageDataURL = null;
  const [atendimentosPendentes, setAtendimentosPendentes] = useState([]);

  const userCreatedAt = new Date(userParse.createdAt);
  const agora = new Date();
  const diferencaEmDias = (agora - userCreatedAt) / (1000 * 60 * 60 * 24);
  // Calcular dias de teste restantes (7 dias - diferença desde a criação)
  const diasTesteRestantes = Math.max(Math.ceil(7 - diferencaEmDias), 0);
  const [carregandoCheckLicense, setCarregandoCheckLicense] = useState(true);

  const toggleSidebar = () => {
    setSidebar(!sidebar);
  };

  const handleLogout = () => {
    logout();
  };

  const handleSwitchChange = async () => {
    const newStatus = !statusBot;
    setStatusBot(newStatus);
    console.log("Status atualizado:", newStatus);
    try {
      const response = await updateStatusBotEmpresa(objIdEmpresa, newStatus);
      console.log("Resposta do servidor:", response);
    } catch (error) {
      console.error("Erro ao atualizar status_bot:", error);
    }
  };

  // Verifique se o navegador suporta notificações
  if (!("Notification" in window)) {
    alert("Este navegador não suporta notificações de sistema.");
  } else if (Notification.permission !== "denied") {
    // Pede permissão ao usuário
    Notification.requestPermission();
  }

  const handleEdit = async (idToEdit) => {
    setEditUser(!showEditUser);
    setUserOptions(!showOptions);
    const response = await getUser(idToEdit);
    //console.log("Infos do Edit:",response.data.user)
    //console.log(idToEdit,"----");
    if (showEditUser) {
      set_idUserEdit(idToEdit);
      setUsernameEdit(response.data.user.name);
      setEmailEdit(response.data.user.email);
      setRoleEdit(response.data.user.role);

      if (response.data.user.vinculo_empresa) {
        //console.log("TEM EMPRESA VINCULADA!")
        const responseVinculo = await getVinculoEmpresa(idToEdit);
        if (responseVinculo.data.vinculo) {
          setselectData({
            value: responseVinculo.data.vinculo.id_empresa,
            label:
              responseVinculo.data.vinculo.id_empresa +
              " - " +
              responseVinculo.data.vinculo.cnpj +
              " - " +
              responseVinculo.data.vinculo.name,
          });
        }
        if (!responseVinculo.data.vinculo) {
          //console.log("MSG:", responseVinculo.data.msg)
        }
      } else {
        //console.log("NÃO TEM EMPRESA VINCULADA!")
        setselectData({
          value: "",
          label: "",
        });
      }
    }
  };

  const showUserOptions = () => {
    setUserOptions(!showOptions);
  };

  const showModalAddUserImg = () => {
    setUserOptions(!showOptions);
    setModalImg(!showModalImg);
  };

  //const [pedidos, setPedidos] = useState([])
  const [statusPrinter, setStatusPrinter] = useState("");
  const [daysToExpire, setDaysToExpire] = useState("");
  const [planType, setPlanType] = useState("");
  const [semCobrancaOuInvoice, setSemCobrancaOuInvoice] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log("🔄 Atualizando informações do vínculo da empresa...");

        // Obtendo o vínculo da empresa
        const response = await getVinculoEmpresa(userID);
        setStatusLojaTemporario(response.data.vinculo.fechamento_temporario);
        setStatusBot(response.data.vinculo.status_bot);
        setStatusPrinter(response.data.vinculo.status_printer);

        // Verificar último check armazenado no localStorage
        const lastCheckEncrypted = localStorage.getItem("ldc");
        const lastCheck = lastCheckEncrypted
          ? CryptoJS.AES.decrypt(lastCheckEncrypted, secretKey).toString(
            CryptoJS.enc.Utf8
          )
          : null;
        const now = Date.now();

        // Obtendo os dias restantes para expiração da licença
        const responseCheckLicense = await getDaysToExpireLicense(
          response.data.vinculo._id
        );

        console.log("responseCheckLicense:", responseCheckLicense);
        if (responseCheckLicense.status === 204) {
          setSemCobrancaOuInvoice(true);
        }

        setDaysToExpire(responseCheckLicense.data.daysRemaining || ""); // Definir valor padrão vazio
        setPlanType(responseCheckLicense.data.plan_type || ""); // Definir valor padrão vazio

        // Atualiza o timestamp da última verificação no localStorage
        const nowEncrypted = CryptoJS.AES.encrypt(
          JSON.stringify(now),
          secretKey
        ).toString();
        localStorage.setItem("ldc", nowEncrypted);

        setCarregandoCheckLicense(false);
      } catch (error) {
        console.error("❌ Erro ao obter dados:", error);
        setCarregandoCheckLicense(false);
      }
    };

    // Chama a função imediatamente ao montar o componente
    fetchData();

    // Configura o intervalo para executar a cada 1 hora (3600000ms)
    const intervalId = setInterval(fetchData, 3600000); // 1 hora

    // Cleanup para limpar o intervalo ao desmontar o componente
    return () => clearInterval(intervalId);

    // eslint-disable-next-line
  }, []);

  /*useEffect(() => {
    ////const intervalId = setInterval(() => {
    ////  fetchData().then(newPedidos => setPedidos(newPedidos));
    ////}, 5 * 1000); // Atualizar a cada 5 segundo

    var i;
    if(pedidos){
      for(i=0; i<pedidos.length; i++){
          //console.log("tipoImpressao>",tipoImpressao);
          if(pedidos[i].status_pedido=='2' && tipoImpressao == 'automatico'){
              //console.log(pedidos[i])
              //console.log("CHGEOU AUQI?");
              const orderElement = document.getElementById(`${pedidos[i].id_pedido}`);

              //console.log(orderElement);
              if (orderElement && orderElement.getAttribute('data-status') == "true") {
                updateStatusPrint(userID, pedidos[i]._id, pedidos[i].id_pedido).then(printPdf(pedidos[i].id_pedido));
              }
          }
      }
    }
    ////return () => clearInterval(intervalId);
  }, [pedidos]); // Sem dependências, então o efeito será executado apenas uma vez*/

  //const [statusImpressora, setStatusImpressora] = useState('');
  const isDevelopment = window.location.hostname === "localhost";
  const apiUrl = isDevelopment
    ? process.env.REACT_APP_SERVER_URL_DEV
    : process.env.REACT_APP_SERVER_URL_PROD;

  /*useEffect(() => {
    const wsUrl = apiUrl;
    const socket = io(wsUrl, {
      withCredentials: true,
      transports: ["websocket"],
      auth: { token: localStorage.getItem("token") },
    });

    // **Entrar na sala da empresa correta**
    socket.emit("joinCompanyRoom", {
      companyId: idEmpresa.toString(),
      clientId: "reactClient",
    });

    // **Verificar conexão**
    socket.on("connect", () => {
      console.log(`✅ Conectado ao WebSocket! Socket ID: ${socket.id}`);
    });

    console.log(`📢 Entrando na sala da empresa: ${idEmpresa.toString()}`);

    // **Monitorar todos os eventos recebidos no socket**
    socket.onAny((event, data) => {
      console.log(`📥 Evento recebido no frontend: ${event}`, data);
    });

    // **Escutando novos pedidos**
    socket.on("novoPedido", (data) => {
      console.log("📦 NOVO PEDIDO RECEBIDO!");
      handleNotify();
    });

    // **Escutando status da impressora**
    socket.on("statusUpdate", ({ companyId: updatedCompanyId, status }) => {
      if (idEmpresa.toString() === updatedCompanyId) {
        console.log(`🖨️ Status atualizado da impressora: ${status}`);
        setStatusPrinter(status === "Online" ? "Online" : "Offline");
      }
    });

    // **Escutando solicitações de atendimento humano**
    socket.on("atendimento_pendente", (data) => {
      console.log("🚨 Novo atendimento solicitado! Dados recebidos:", data);

      // **Verificar se a empresa corresponde**
      console.log(`Comparando company_id recebido (${data.company_id}) com idEmpresa (${idEmpresa.toString()})`);

      // **📌 Gerar um ID único para cada solicitação**
      const atendimentoComID = {
        ...data,
        atendimento_id: uuidv4(), // Gerando um ID único para cada solicitação
      };

      setAtendimentosPendentes((prev) => [...prev, atendimentoComID]);
    });

    return () => {
      socket.off("novoPedido");
      socket.off("statusUpdate");
      socket.off("atendimento_pendente");
      socket.disconnect();
    };
  }, [idEmpresa]);*/
  useEffect(() => {
    if (!idEmpresa) return;

    // Se já existir uma conexão WebSocket, desconecta antes de criar outra
    if (socketRef.current) {
      console.log("🔄 Desconectando socket anterior...");
      socketRef.current.disconnect();
    }

    console.log(`🔌 Conectando ao WebSocket para a empresa ${idEmpresa.toString()}...`);

    // Criar a conexão WebSocket com lógica de reconexão
    const socket = io(apiUrl, {
      withCredentials: true,
      transports: ["websocket"],
      auth: { token: localStorage.getItem("token") },
      reconnection: true, // Ativa a reconexão automática
      reconnectionAttempts: 10, // Máximo de 10 tentativas
      reconnectionDelay: 5000, // Intervalo de 5 segundos entre tentativas
    });

    // Armazena a referência globalmente
    socketRef.current = socket;

    // **Entrar na sala da empresa**
    socket.emit("joinCompanyRoom", {
      companyId: idEmpresa.toString(),
      clientId: "reactClient",
    });

    // **Solicitar status da impressora ao conectar**
    socket.on("connect", () => {
      console.log(`✅ Conectado ao WebSocket! Socket ID: ${socket.id}`);
      socket.emit("statusRequest", { companyId: idEmpresa.toString() }); // 🔹 Garante que o status seja atualizado
    });

    // **Monitorar tentativas de reconexão**
    socket.on("reconnect_attempt", (attempt) => {
      console.log(`🔄 Tentativa de reconexão (${attempt}/10)...`);
    });

    socket.on("reconnect", (attempt) => {
      console.log(`✅ Reconectado ao WebSocket após ${attempt} tentativas!`);
      socket.emit("statusRequest", { companyId: idEmpresa.toString() }); // 🔹 Atualiza status após reconectar
    });

    // **Monitorar erro de conexão**
    socket.on("connect_error", (error) => {
      console.error("❌ Erro na conexão WebSocket:", error);
    });

    // **Monitorar eventos recebidos**
    socket.onAny((event, data) => {
      //console.log(`📥 Evento recebido: ${event}`, data);
    });

    // **Escutando novos pedidos**
    socket.on("novoPedido", () => {
      console.log("📦 NOVO PEDIDO RECEBIDO!");
      handleNotify();
    });

    // **Escutando status da impressora**
    socket.on("statusUpdate", ({ companyId: updatedCompanyId, status }) => {
      if (idEmpresa.toString() === updatedCompanyId) {
        console.log(`🖨️ Status atualizado da impressora: ${status}`);
        setStatusPrinter(status === "Online" ? "Online" : "Offline");
      }
    });

    // **Escutando solicitações de atendimento humano**
    socket.on("atendimento_pendente", (data) => {
      console.log("🚨 Novo atendimento solicitado! Dados recebidos:", data);

      // **Verificar se a empresa corresponde**
      if (data.company_id === idEmpresa.toString()) {
        console.log(`✔️ Atendimento pertence à empresa ${idEmpresa}`);

        // **📌 Gerar um ID único para cada solicitação**
        const atendimentoComID = {
          ...data,
          atendimento_id: uuidv4(),
        };

        setAtendimentosPendentes((prev) => [...prev, atendimentoComID]);
      }
    });

    // **Lógica de cleanup ao desmontar ou atualizar empresa**
    return () => {
      console.log("🛑 Desconectando WebSocket ao desmontar ou mudar empresa...");
      socket.off("novoPedido");
      socket.off("statusUpdate");
      socket.off("atendimento_pendente");
      socket.off("reconnect_attempt");
      socket.off("reconnect");
      socket.off("connect_error");
      socket.disconnect();
      socketRef.current = null;
    };
  }, [idEmpresa]);


  // **📌 Função para remover uma solicitação específica**
  const removerAtendimento = (atendimento_id) => {
    setAtendimentosPendentes((prev) => prev.filter((item) => item.atendimento_id !== atendimento_id));
  };

  // **📌 Simular chegada de um atendimento manualmente (apenas para teste local)**
  const simularAtendimento = () => {
    const atendimentoFake = {
      lead_id: "6636337cc37488bfcfdcfa82",
      company_id: idEmpresa.toString(),
      nome: "Matheus",
      celular: "556283320252@s.whatsapp.net",
      mensagem: "Quero falar com atendente",
      timestamp: new Date().toISOString(),
    };
    const atendimentoComID = {
      ...atendimentoFake,
      atendimento_id: uuidv4(), // Gerando um ID único para cada solicitação
    };
    setAtendimentosPendentes((prev) => [...prev, atendimentoComID]);
  };

  const sendNotification = (title, options) => {
    // Verifica se o usuário aceitou receber notificações
    if (Notification.permission === "granted") {
      const notification = new Notification(title, {
        ...options,
        icon: LogoP, // Certifique-se de que o caminho para o ícone está correto
      });

      // Toca um som quando a notificação é exibida
      notification.onshow = () => {
        const audio = new Audio(audioNotify);
        audio
          .play()
          .catch((error) =>
            console.log("Erro ao reproduzir o som da notificação:", error)
          );
      };
    }
  };

  const handleNotify = () => {
    const title = "Pede Já - Novo Pedido";
    const options = {
      body: "Você recebeu um novo pedido.",
      // O campo 'sound' ainda não é amplamente suportado
      sound: audioNotify, // caminho para o arquivo de áudio
    };
    sendNotification(title, options);
  };

  const handleOpenCloseLoja = async () => {
    console.log("Fechar Loja");
    const newStatus = !statusLojaTemporario;
    try {
      const response = await changeStatusLoja(objIdEmpresa, newStatus);
      if (response.status === 200) {
        setStatusLojaTemporario(newStatus);
      } else {
        console.error("Falha ao atualizar o status da loja:", response);
      }
    } catch (error) {
      console.error("Erro ao chamar a API:", error);
    }
  };

  useEffect(() => {
    // add when mounted
    document.addEventListener("mousedown", handleClickOutsideOptions);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideOptions);
    };
  }, []);

  const handleClickOutsideOptions = (y) => {
    if (userOptionsRef.current.contains(y.target)) {
      return;
    } else {
      setUserOptions(true);
    }
  };

  useEffect(() => {
    // add when mounted
    //document.addEventListener("mouseover", handleMouseOverLeftMenu);
    //document.addEventListener("mouseout", handleMouseOutLeftMenu);

    window.addEventListener("resize", handleResize);
    // return function to be called when unmounted
    return () => {
      //document.removeEventListener("mouseover", handleMouseOverLeftMenu);
      //document.removeEventListener("mouseout", handleMouseOutLeftMenu);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleResize = () => {
    const currentWidth = window.innerWidth;
    if (currentWidth < 1300) {
      setSidebar(false);
    }
  }

  const handleMouseOverStatusRobo = (y) => {
    if (
      (menuStatusRoboRef.current &&
        menuStatusRoboRef.current.contains(y.target)) ||
      (menuStatusRoboRef_.current &&
        menuStatusRoboRef_.current.contains(y.target))
    ) {
      setDropStatusRobo(true);
      return;
    }
  };

  const handleMouseOutStatusRobo = (y) => {
    if (
      (menuStatusRoboRef.current &&
        !menuStatusRoboRef.current.contains(y.target)) ||
      (menuStatusRoboRef_.current &&
        !menuStatusRoboRef_.current.contains(y.target))
    ) {
      setDropStatusRobo(false);
      return;
    }
  };

  const handleToggleStatusRobo = () => {
    setDropStatusRobo((previous) => !previous);
  }

  return (
    <>
      <ModalUserImg
        setModalImg={setModalImg}
        showModalImg={showModalImg}
        userID={userID}
      />

      <ModalEditUser
        setEditUser={setEditUser}
        showEditUser={showEditUser}
        setRefresh={setRefresh}
        selectData={selectData}
        setselectData={setselectData}
        _idUserEdit={_idUserEdit}
        usernameEdit={usernameEdit}
        emailEdit={emailEdit}
        roleEdit={roleEdit}
        editPerfil={true}
      />

      <ModalLinkCardapio
        showLinkCardapio={showLinkCardapio}
        setShowLinkCardapio={setShowLinkCardapio}
        nomeEmpresaForUrl={nomeEmpresaForUrl}
      />

      <ModalLinkCardapioSalao
        showLinkCardapioSalao={showLinkCardapioSalao}
        setShowLinkCardapioSalao={setShowLinkCardapioSalao}
        nomeEmpresaForUrl={nomeEmpresaForUrl}
      />

      <IconContext.Provider value={{ color: "#fff" }}>
        <Nav sidebar={sidebar} style={{ justifyContent: "space-between" }}>
          <div className="d-flex ms-3 align-items-center">
            <div onClick={showDrawer} className="desktop-hidden me-4" style={{ cursor: "pointer" }}>
              <GiHamburgerMenu color="black" />
            </div>
            <div
              className="userCircleImg"
              style={{
                paddingTop: "4px",
                paddingBottom: "2px",
              }}
            >
              <img src={logoImg} alt="pede-ja logo" width={120} height={40} className="logoImg" />
            </div>
          </div>
          <div className="d-flex">
            {/* Dropdown Menu Status Robo */}
            <div
              ref={menuStatusRoboRef_}
              className={
                dropstatusrobo ? "menu-dropdownShow" : "menu-dropdownClosed"
              }
            >
              <div className="menu-options">
                <div className="option bottom">
                  <div className="info-option">
                    <div className="status-option online" />
                    <div className="label-option">
                      <pedeja-icon
                        iconname="whatsapp"
                        iconstroke={2}
                        iconcolor="#797878"
                        iconsize={18}
                        _nghost-ng-c3181319476=""
                        style={{ height: 18 }}
                      >
                        <svg
                          width={18}
                          height={18}
                          fill="#797878"
                          viewBox="0 0 13 13"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9.477 7.79066C9.31409 7.70819 8.52069 7.32103 8.37241 7.26578C8.22412 7.21297 8.11647 7.18534 8.00841 7.34825C7.90278 7.50709 7.59159 7.87069 7.49653 7.97672C7.40147 8.08275 7.30803 8.09088 7.14756 8.01897C6.98466 7.9365 6.46425 7.76709 5.84634 7.21297C5.36372 6.78356 5.04237 6.25462 4.94691 6.09172C4.85184 5.93084 4.93634 5.83984 5.01678 5.75941C5.09072 5.68547 5.17969 5.57334 5.26216 5.47584C5.34056 5.37834 5.36575 5.31294 5.42303 5.20731C5.47584 5.09316 5.44862 5.00419 5.40841 4.92375C5.36819 4.84331 5.04441 4.04584 4.90912 3.72816C4.77994 3.41291 4.64466 3.45312 4.54512 3.45312C4.45209 3.44459 4.34403 3.44459 4.23638 3.44459C4.12872 3.44459 3.95281 3.48481 3.80453 3.63919C3.65625 3.80209 3.23741 4.19128 3.23741 4.97859C3.23741 5.76794 3.81712 6.53169 3.89756 6.64584C3.98003 6.75147 5.03791 8.37647 6.66087 9.07481C7.04803 9.23772 7.34866 9.33522 7.58347 9.41566C7.97062 9.53834 8.32406 9.52128 8.60316 9.48106C8.91191 9.43028 9.55947 9.08944 9.69516 8.70878C9.83288 8.32569 9.83288 8.00841 9.79266 7.9365C9.75244 7.86256 9.64681 7.82234 9.48391 7.75044L9.477 7.79066ZM6.53372 11.7812H6.52519C5.56441 11.7812 4.61459 11.5208 3.78503 11.0342L3.59044 10.918L1.55919 11.4469L2.10519 9.4705L1.97397 9.26738C1.4375 8.41439 1.1529 7.42722 1.15294 6.41956C1.15294 3.47019 3.56728 1.06437 6.53778 1.06437C7.97672 1.06437 9.32669 1.625 10.3423 2.64062C10.8433 3.13568 11.2407 3.72555 11.5114 4.37582C11.782 5.0261 11.9204 5.72376 11.9186 6.42809C11.9145 9.37544 9.50219 11.7812 6.53575 11.7812H6.53372ZM11.1146 1.86834C9.87878 0.674781 8.25378 0 6.52519 0C2.95994 0 0.056875 2.89047 0.0548438 6.44272C0.0548438 7.57697 0.351 8.68359 0.918125 9.66306L0 13L3.432 12.105C4.38275 12.6176 5.44547 12.8872 6.52559 12.8899H6.52763C10.0949 12.8899 12.998 9.99944 13 6.44475C13 4.72469 12.3293 3.10578 11.1065 1.88906L11.1146 1.86834Z"
                            fill="#797878"
                          />
                        </svg>
                      </pedeja-icon>
                      <span className="description">Whatsapp</span>
                    </div>
                  </div>

                  {/* Switch Personalized */}
                  {/*
                    <div className="wrapper">
                        <div className="switch_box box_1">
                          <input type="checkbox" className="switch_1"/>
                        </div>
                    </div>
                    */}
                  <div className="wrapper">
                    <div className="switch_box box_1">
                      <input
                        type="checkbox"
                        className="switch_1"
                        checked={statusBot}
                        onChange={handleSwitchChange}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="option bottom-last"
                  style={{ marginBottom: "5px" }}
                >
                  <div className="info-option">
                    <div className="status-option online" />
                    <div
                      className="label-option"
                      style={{ display: "inline-flex", alignItems: "center" }}
                    >
                      <pedeja-icon
                        iconname="users"
                        iconstroke={2}
                        iconcolor="#797878"
                        iconsize={18}
                        _nghost-ng-c3181319476=""
                        style={{ height: 18 }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={18}
                          height={18}
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="#797878"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-users"
                        >
                          <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2" />
                          <circle cx={9} cy={7} r={4} />
                          <path d="M23 21v-2a4 4 0 0 0-3-3.87" />
                          <path d="M16 3.13a4 4 0 0 1 0 7.75" />
                        </svg>
                      </pedeja-icon>
                      <div style={{ display: "inline-grid", lineHeight: "17px" }}>
                        <span className="description">Chamar</span>
                        <span className="description">Atendente</span>
                      </div>
                    </div>
                  </div>
                  {/* Switch Personalized */}
                  <div className="wrapper">
                    <div className="switch_box box_1">
                      <input type="checkbox" className="switch_1" />
                    </div>
                  </div>
                </div>
              </div>
              {/**/}
            </div>

            {isDevelopment ?
              <button className="atendimento-teste-btn" onClick={simularAtendimento}>
                🚀 Simular Atendimento
              </button>
              :
              undefined
            }

            {/* Fim Dropdown Menu Status Robo */}
            <div
              style={{
                padding: "5px 24px 5px 24px",
                borderLeft: "1px solid rgb(208, 209, 209)",
                height: 80,
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: "2px",
                display: "inline-flex",
              }}
              ref={menuStatusRoboRef}
              onClick={handleToggleStatusRobo}
            //dropstatusrobo={dropstatusrobo}
            //className={dropstatusrobo ? 'open' : 'closed'}
            >
              <div className="hidden-mobile">
                <span style={{ fontSize: 15, fontWeight: 500, marginLeft: 5 }}>
                  Status
                </span>
              </div>
              <FaRobot style={{ color: "black", fontSize: 24 }} />
              <div
                className="hidden-mobile"
                style={{
                  fontWeight: "bold",
                  display: "flex",
                  justifyContent: "center",
                  fontSize: 15,
                  position: "relative"
                }}
              >
                <span>Robo Pede Já</span>
                <RiArrowDropDownLine style={{ color: "black", fontSize: 24 }} />
              </div>
            </div>


            <Tooltip
              placement="bottom"
              title={`Impressora: ${statusPrinter === "Offline" ? 'Offline' : 'Conectada'}`}
            >
              <div style={{
                padding: "5px 24px 5px 24px",
                borderLeft: "1px solid rgb(208, 209, 209)",
                height: 80,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                gap: "5px",
                display: "inline-flex",
              }}>
                <ImPrinter color={statusPrinter === "Offline" ? 'red' : '#07c670'} fontSize={30} />
                <div className="hidden-mobile">
                  <div>
                    <span style={{ fontSize: 15, fontWeight: 500, marginLeft: 5 }}>
                      Impressora
                    </span>
                  </div>
                  {statusPrinter && statusPrinter === "Offline" ? (
                    <div className="divStatusPrintNavBarOffline">
                      <span>Offline</span>
                    </div>
                  ) : (
                    <div className="divStatusPrintNavBar">
                      <span>Conectada</span>
                    </div>
                  )}
                </div>
              </div>
            </Tooltip>
            <Tooltip
              placement="bottom"
              title={`Cardápio Delivery`}
            >
              <div
                style={{
                  padding: "5px 24px",
                  borderLeft: "1px solid rgb(208, 209, 209)",
                  height: 80,
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "2px",
                  display: "inline-flex",
                }}
              >
                <div
                  onClick={() => setShowLinkCardapio(true)}
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <img src={entregadorIco} height={30} width={30} />
                  <div className="d-flex flex-column hidden-mobile">
                    <span style={{ fontSize: 14, fontWeight: 400, marginLeft: 5 }}>
                      Link
                    </span>
                    <span style={{ fontSize: 14, fontWeight: 700, marginLeft: 5 }}>
                      Cardápio Delivery
                    </span>
                  </div>
                </div>
              </div>
            </Tooltip>

            <Tooltip
              placement="bottom"
              title={`Cardápio Salão`}
            >
              <div
                style={{
                  padding: "5px 24px",
                  borderLeft: "1px solid rgb(208, 209, 209)",
                  height: 80,
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "2px",
                  display: "inline-flex",
                }}
              >
                <div
                  onClick={() => setShowLinkCardapioSalao(true)}
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <img src={roundTable} height={30} width={30} />
                  <div className="d-flex flex-column hidden-mobile">
                    <span style={{ fontSize: 14, fontWeight: 400, marginLeft: 5 }}>
                      Link
                    </span>
                    <span style={{ fontSize: 14, fontWeight: 700, marginLeft: 5 }}>
                      Cardápio Salão
                    </span>
                  </div>
                </div>
              </div>
            </Tooltip>

            <div
              style={{
                padding: "5px 24px 5px 24px",
                height: 80,
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: "2px",
                display: "inline-flex",
                borderLeft: "1px solid rgb(208, 209, 209)"
              }}
              className="hidden-sm-mobile"
            >
              <div>
                <MdStorefront style={{ color: "black", fontSize: 20 }} />
                <span style={{ fontSize: 15, fontWeight: 500, marginLeft: 5 }}>
                  Loja
                  {!statusLojaTemporario ? (
                    <div
                      style={{
                        width: 70,
                        height: 18,
                        borderRadius: 10,
                        marginLeft: 10,
                        background: "#9CE8C6",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: 8,
                        display: "inline-flex",
                      }}
                    >
                      <span>Aberta</span>
                    </div>
                  ) : (
                    <div
                      style={{
                        width: 80,
                        height: 18,
                        borderRadius: 10,
                        marginLeft: 10,
                        background: "#ff0000b5",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: 8,
                        color: "white",
                        fontSize: 13,
                        display: "inline-flex",
                      }}
                    >
                      <span>Fechada</span>
                    </div>
                  )}
                </span>
              </div>
              <div
                style={{
                  fontWeight: "bold",
                  display: "flex",
                  justifyContent: "center",
                  fontSize: 15,
                }}
              >
                <span>{nomeEmpresa}</span>
              </div>
              {statusLojaTemporario ? (
                <div
                  type="button"
                  onClick={() => handleOpenCloseLoja()}
                  style={{
                    width: 150,
                    height: 20,
                    borderRadius: 5,
                    background: "#318CD5",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 8,
                    display: "inline-flex",
                  }}
                >
                  <a style={{ color: "white", fontSize: 10 }}>Abrir Loja</a>
                </div>
              ) : (
                <div
                  type="button"
                  onClick={() => handleOpenCloseLoja()}
                  style={{
                    width: 150,
                    height: 20,
                    borderRadius: 5,
                    background: "#318CD5",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 8,
                    display: "inline-flex",
                  }}
                >
                  <a style={{ color: "white", fontSize: 10 }}>Fechar Loja</a>
                </div>
              )}
            </div>
            <div className="hidden-mobile" style={{ width: 128, height: 80, paddingLeft: 24, paddingRight: 24, borderLeft: '1px #D0D1D1 solid', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 8, display: 'inline-flex' }}>
              <div onClick={handleLogout} style={{ textAlign: 'center', cursor: "pointer", color: '#001B30', fontSize: 18, fontWeight: '700', letterSpacing: 0.48, wordWrap: 'break-word' }}>
                <MdExitToApp style={{ color: 'black', fontSize: 25 }} />Sair
              </div>
            </div>
          </div>
        </Nav>
        <Drawer title="Navegação" onClose={onClose} open={open} key="left" placement="left" bodyStyle={{ padding: 0 }}>
          <div className="d-flex flex-column justify-content-between h-100">
            <div
              className="boxLeftMenuScroll"
              style={{ overflowY: "scroll", height: "calc(100vh - 120px)" }}
            >
              {SidebarData.map((item, index) => {
                return (
                  <PermissionGate
                    key={index}
                    permissions={[SidebarData[index].permission]}
                  >
                    <SubMenu
                      item={item}
                      key={index}
                      style={{ background: "black" }}
                    />
                  </PermissionGate>
                );
              })}
            </div>
            <div className="d-flex flex-column">
              <div
                style={{
                  padding: "5px 24px 5px 24px",
                  borderLeft: "1px solid rgb(208, 209, 209)",
                  height: 80,
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "2px",
                  display: "inline-flex",
                }}
              >
                <div>
                  <MdStorefront style={{ color: "black", fontSize: 20 }} />
                  <span style={{ fontSize: 15, fontWeight: 500, marginLeft: 5 }}>
                    Loja
                    {!statusLojaTemporario ? (
                      <div
                        style={{
                          width: 70,
                          height: 18,
                          borderRadius: 10,
                          marginLeft: 10,
                          background: "#9CE8C6",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: 8,
                          display: "inline-flex",
                        }}
                      >
                        <span>Aberta</span>
                      </div>
                    ) : (
                      <div
                        style={{
                          width: 80,
                          height: 18,
                          borderRadius: 10,
                          marginLeft: 10,
                          background: "#ff0000b5",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: 8,
                          color: "white",
                          fontSize: 13,
                          display: "inline-flex",
                        }}
                      >
                        <span>Fechada</span>
                      </div>
                    )}
                  </span>
                </div>
                <div
                  style={{
                    fontWeight: "bold",
                    display: "flex",
                    justifyContent: "center",
                    fontSize: 15,
                  }}
                >
                  <span>{nomeEmpresa}</span>
                </div>
                {statusLojaTemporario ? (
                  <div
                    type="button"
                    onClick={() => handleOpenCloseLoja()}
                    style={{
                      width: 150,
                      height: 20,
                      borderRadius: 5,
                      background: "#318CD5",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: 8,
                      display: "inline-flex",
                    }}
                  >
                    <a style={{ color: "white", fontSize: 10 }}>Abrir Loja</a>
                  </div>
                ) : (
                  <div
                    type="button"
                    onClick={() => handleOpenCloseLoja()}
                    style={{
                      width: 150,
                      height: 20,
                      borderRadius: 5,
                      background: "#318CD5",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: 8,
                      display: "inline-flex",
                    }}
                  >
                    <a style={{ color: "white", fontSize: 10 }}>Fechar Loja</a>
                  </div>
                )}
              </div>
              <div className="hidden-mobile" style={{ width: 128, height: 80, paddingLeft: 24, paddingRight: 24, borderLeft: '1px #D0D1D1 solid', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 8, display: 'inline-flex' }}>
                <div onClick={handleLogout} style={{ textAlign: 'center', cursor: "pointer", color: '#001B30', fontSize: 18, fontWeight: '700', letterSpacing: 0.48, wordWrap: 'break-word' }}>
                  <MdExitToApp style={{ color: 'black', fontSize: 25 }} />Sair
                </div>
              </div>
            </div>
          </div>
        </Drawer>
        {(!planType || planType === "" || daysToExpire == 0) && diasTesteRestantes > 0 ?
          !semCobrancaOuInvoice && !carregandoCheckLicense ?
            <div className="info-licenca">
              <span>Dias de teste restantes: {diasTesteRestantes} dias</span>
            </div>
            :
            null
          : null}
        {planType && planType !== "free_trial" && daysToExpire <= 3 && diferencaEmDias > 7 ? (
          <div className="info-licenca">
            {daysToExpire <= 0 ? (
              <span>
                Hoje seu plano irá expirar, para realizar o pagamento da fatura{" "}
                <span
                  className="clickNavigateToPlanos"
                  onClick={() => navigate("/planos")}
                >
                  CLIQUE AQUI
                </span>
              </span>
            ) : (
              <span>
                Faltam apenas {daysToExpire} dias para sua assinatura expirar
              </span>
            )}
          </div>
        ) : null}

        <MenuAjuda>
          <div className={atendimentosPendentes.length > 0 ? "item-menu-help-info-active" : "item-menu-help-info"}
            onClick={() => setModalOpen(true)}
          >
            <svg
              width={30}
              height={30}
              viewBox="0 0 30 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              id={atendimentosPendentes.length > 0 ? "shaking" : undefined}
            >
              <path
                d="M28.9167 24.4441V22.2775C28.916 21.3173 28.5965 20.3846 28.0082 19.6258C27.42 18.867 26.5964 18.325 25.6667 18.085"
                stroke="currentColor"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M22.4166 24.4443V22.2777C22.4166 21.1284 21.96 20.0262 21.1474 19.2135C20.3347 18.4009 19.2325 17.9443 18.0833 17.9443H9.41658C8.26731 17.9443 7.16511 18.4009 6.35246 19.2135C5.5398 20.0262 5.08325 21.1284 5.08325 22.2777V24.4443"
                stroke="currentColor"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M21.3333 5.08496C22.2654 5.32362 23.0915 5.86572 23.6815 6.6258C24.2715 7.38587 24.5917 8.32069 24.5917 9.28288C24.5917 10.2451 24.2715 11.1799 23.6815 11.94C23.0915 12.7 22.2654 13.2421 21.3333 13.4808"
                stroke="currentColor"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M13.7501 13.611C16.1433 13.611 18.0834 11.6709 18.0834 9.27767C18.0834 6.88444 16.1433 4.94434 13.7501 4.94434C11.3568 4.94434 9.41675 6.88444 9.41675 9.27767C9.41675 11.6709 11.3568 13.611 13.7501 13.611Z"
                stroke="currentColor"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8.02771 1.81393C6.65952 2.40269 4.00254 4.64734 4.32012 8.9159M3.20673 1.3753C2.33013 2.1057 1.18146 3.37006 1.36262 5.9846"
                stroke="currentColor"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            {atendimentosPendentes.length > 0 ? (
              <div className="number-box number-box--active">
                {atendimentosPendentes.length}
              </div>
            ) : undefined}
          </div>
        </MenuAjuda>
        {/* 📌 Modal de Atendimentos Pendentes */}
        <AtendimentoModal
          atendimentosPendentes={atendimentosPendentes}
          removerAtendimento={removerAtendimento}
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
        />

        <CrispChat />

        {/*<HelpWidget />*/}
        

        <SidebarNav
          ref={leftMenuRef}
          sidebar={sidebar}
          className={sidebar ? "open" : "closed"}
          style={{ boxShadow: "1px 1px 6px lightgray" }}
        >
          <SidebarWrap>
            <div className="collapseDiv">
              {sidebar ? (
                <RiMenuFoldFill
                  onClick={toggleSidebar}
                  fill="gray"
                  className="collapseInLeftMenuBtn"
                />
              ) : (
                <RiMenuUnfoldFill
                  onClick={toggleSidebar}
                  fill="gray"
                  className="collapseOutLeftMenuBtn"
                />
              )}
            </div>

            {sidebar ? (
              <div
                style={{
                  borderBottom: "3px solid #4281FF",
                  backgroundImage: `url(${userDiv})` /*background:"rgba(0,0,0,0.8)"*/,
                }}
              >
                <div
                  className="userCircleImg"
                  style={{ paddingTop: "4px", paddingBottom: "2px" }}
                >
                  {/*<HiIcons.HiUserCircle style={{color:"rgb(200,200,200)", fontSize:"60px", padding:"4px"}}/>
                <img style={{backgroundImage:`url(${userImg})`}}/>*/}
                  <img
                    src={
                      userImg !== null && userImg.length > 0
                        ? userImg
                        : defaultUserImg
                    }
                    width={60}
                    height={60}
                    className="userImg"
                  />
                </div>
                <NavIcon /*to='#'*/ style={{ marginLeft: "30px" }}>
                  <label
                    style={{
                      maxWidth: "80%",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {userName}
                  </label>
                  <div ref={userOptionsRef}>
                    <HiIcons.HiOutlineDotsVertical
                      onClick={showUserOptions}
                      style={{
                        color: "white",
                        cursor: "pointer",
                        marginLeft: "10px",
                        fontSize: "22px",
                      }}
                    />
                    <ModalUserOptions showOptions={showOptions}>
                      <li
                        onClick={(e) => handleEdit(userID)}
                        style={{ cursor: "pointer" }}
                      >
                        <a>Editar Perfil</a>
                      </li>
                      <li
                        onClick={showModalAddUserImg}
                        style={{ cursor: "pointer" }}
                      >
                        <a>Editar Imagem</a>
                      </li>
                      <li onClick={handleLogout} style={{ cursor: "pointer" }}>
                        <a>Sair</a>
                      </li>
                    </ModalUserOptions>
                  </div>
                </NavIcon>
                <div
                  style={{
                    paddingBottom: "2px",
                    justifyContent: "center",
                    display: "flex",
                    fontSize: "12px",
                    color: "white",
                  }}
                >
                  {userEmail}
                </div>
              </div>
            ) : (
              <div
                style={{
                  boxShadow: "-1px 1px 1px 0px #0000001c",
                  borderBottom: "3px solid transparent",
                }}
              >
                <div
                  className="userCircleImg"
                  style={{
                    paddingTop: 15,
                    paddingBottom: 5,
                    paddingRight: 3,
                    paddingBottom: 11,
                  }}
                >
                  {/*<HiIcons.HiUserCircle style={{color:"rgb(180,180,180)", fontSize:"108px", padding:"4px"}}/>*/}
                  <img
                    src={
                      userImg !== null && userImg.length > 0
                        ? userImg
                        : defaultUserImg
                    }
                    width={80}
                    height={80}
                    className="userImg"
                    style={{ padding: "0px" }}
                  />
                </div>
                <NavIcon
                  to="#"
                  style={{
                    /*display:"none",*/ fontSize: "0px",
                    height: "0px" /*visibility:"hidden"*/,
                  }}
                >
                  <label
                    style={{
                      maxWidth: "80%",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {userName}
                  </label>
                  <div ref={userOptionsRef} style={{ display: "none" }} />
                </NavIcon>
                <div
                  style={{
                    paddingBottom: "10px",
                    justifyContent: "center",
                    display: "flex",
                    fontSize: "12px",
                    display: "none",
                  }}
                >
                  {userEmail}
                </div>
              </div>
            )}

            <div
              className="boxLeftMenuScroll"
              style={{ overflowY: "scroll", height: "calc(100vh - 120px)" }}
            >
              {SidebarData.map((item, index) => {
                return (
                  <PermissionGate
                    key={index}
                    permissions={[SidebarData[index].permission]}
                  >
                    <SubMenu
                      item={item}
                      key={index}
                      style={{ background: "black" }}
                    />
                  </PermissionGate>
                );
              })}
            </div>
          </SidebarWrap>
        </SidebarNav>
      </IconContext.Provider>
    </>
  );
};

export default LeftMenu;
