import React, { useState } from 'react';
import { api } from '../../services/api'; // Importando a configuração de API
import imgLogoCliente from '../../img/logoBlue.png';
import imageUrl from '../../img/backgroundLogin.png';
import { SlArrowLeft } from "react-icons/sl";
import './styles.css';
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";

const RecuperaSenhaPage = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [responseStatus, setResponseStatus] = useState(null); // Novo estado para armazenar o status
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage("");

    if (!email) {
      setMessage("Por favor, insira um e-mail válido.");
      toast("Por favor, insira um e-mail válido.", { autoClose: 2000, type: "error" });
      setLoading(false);
      return;
    }

    try {
      const response = await api.post('/forgot-password', { email });

      if (response.status === 200) {
        setMessage(response.data.msg);
        setResponseStatus(response.status); // Armazena o status HTTP
        toast(response.data.msg, { autoClose: 2000, type: "success" });
      } else {
        // Trata outros status (se aplicável)
        setResponseStatus(response?.status || 500); // Armazena o status do erro ou 500 como padrão
        setMessage(response.data.msg || "Ocorreu um erro desconhecido.");
        toast(response.data.msg || "Ocorreu um erro desconhecido.", { autoClose: 2000, type: "error" });
      }
    } catch (error) {
      // Tratando erros retornados pelo backend
      if (error.response && error.response.data) {
        setMessage(error.response.data.msg || "Erro ao solicitar recuperação de senha.");
        setResponseStatus(error.response?.status || 500);
        toast(error.response.data.msg || "Erro ao solicitar recuperação de senha.", { autoClose: 2000, type: "error" });
      } else {
        // Tratando erros de conexão ou outros problemas
        setMessage("Erro ao conectar ao servidor.");
        setResponseStatus(error.response?.status || 500);
        toast("Erro ao conectar ao servidor.", { autoClose: 2000, type: "error" });
      }
      console.error(error);
    }

    setLoading(false);
  };

  return (
    <div className="container">
      <div className="container-login"
        style={{
          background: `url(${imageUrl}) no-repeat left`,
          backgroundSize: '100% 100%'
        }}>
        <div className="wrapContent"
          style={{
            width: '90%', height: '90%', background: 'white',
            boxShadow: '0px 0px 24px rgba(0, 0, 0, 0.40)',
            borderRadius: 8, overflow: 'hidden', display: 'inline-flex'
          }}>

          {/* Lado esquerdo - Informações */}
          <div className="hide-on-mobile" style={{
            width: '50%', background: '#F5F5F5', display: 'inline-flex',
            flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 8
          }}>
            <img style={{ width: 321.70, height: 99.47 }} src={imgLogoCliente} />
            <div style={{ textAlign: 'center', color: '#074D82', fontSize: 32, fontWeight: '600' }}>Gerencie seus pedidos</div>
            <div style={{ width: 347, textAlign: 'center', color: '#074D82', fontSize: 24, fontWeight: '600' }}>
              E automatize o seu atendimento de verdade
            </div>
          </div>

          {/* Lado direito - Formulário */}
          <div className="onMobile" style={{ flex: '1 1 0', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
            <div style={{ width: 300, display: 'inline-flex', flexDirection: 'column', gap: 32 }}>

              <div className="text-center" onClick={() => navigate('/login')} style={{ cursor: 'pointer' }}>
                <SlArrowLeft style={{ color: 'gray' }} />
                <span style={{ color: 'gray', textDecoration: 'underline', fontSize: 20 }}>Voltar</span>
              </div>

              <div className="text-center">Olá!</div>
              <div className="text-center">Poxa, uma pena que esqueceu sua senha, mas não se preocupe estamos aqui pra te ajudar. Vamos lá?</div>
              <div className="text-center">
                Digite o e-mail que você usou no seu cadastro no campo abaixo, depois clique no botão Recuperar e em alguns instantes você receberá um e-mail para redefinir sua senha.
              </div>

              <form className="login-form" onSubmit={handleSubmit}>
                <div className="wrap-input">
                  <input
                    className={email !== "" ? 'has-val input' : 'input'}
                    type="email"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    required
                  />
                  <span className="focus-input" data-placeholder="E-mail"></span>
                </div>

                <div className="container-login-form-btn">
                  <button className="login-form-btn" type="submit" disabled={loading}>
                    {loading ? "Enviando..." : "Recuperar"}
                  </button>
                </div>
              </form>

              {message && (
                <div className={`text-center ${responseStatus === 200 ? 'successRecuperarSenha' : 'errorRecuperarSenha'}`}>
                  {message}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecuperaSenhaPage;
