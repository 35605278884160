import React, { useState, useContext, useEffect, useRef } from "react";
import './style.css'
import styled from 'styled-components';
import 'bootstrap/dist/css/bootstrap.min.css';
import { SidebarContext } from "../../AppRoutes";
import { AuthContext } from "../../contexts/auth";

import { getOrcamentos, getEmpresa, deleteEmpresa, deleteOrcamento } from "../../services/api";

import PermissionGate from "../../services/PermissionGate";

import { useNavigate, Link } from "react-router-dom";
import LeftMenu from "../../components/LeftMenu";
import ConfirmDialog from "../../components/ConfirmDialog";

import { toast } from "react-toastify";

import moment from 'moment';
import * as XLSX from 'xlsx';

import ModalEditEmpresa from "../../components/ModalEditEmpresa";

import * as FiIcons from 'react-icons/fi'
import * as AiIcons from 'react-icons/ai'
import * as SlIcons from 'react-icons/sl'
import * as FaIcons from 'react-icons/fa'


const Teste = styled.div`
    display: flex;
    margin-left:  ${({ sidebar }) => (sidebar ? '250px' : '100px')}; 
    height:auto;
    width:auto;
    transition: 150ms;
    background-color:rgb(247,247,247)!important;
    overflow: initial;
    z-Index: 9;

    @media (max-width: 880px) {
        margin-left: 0;
    }
`;

const ModalOrcOptions = styled.div`
  font-size: 14px;
  position: absolute;
  top: 20px;
  left: -135px;
  --display: ${({ showOptions }) => (showOptions ? 'none' : '')};
  float: left;
  min-width: 120px;
  margin: 2px 0 0;
  padding: 5px 0;
  list-style: none;
  text-align: left;
  border: 1px solid #ccc;
  border: 1px solid rgba(0,0,0,.15);
  border-radius: 4px;
  --background-color: #fff;
  background-color:rgb(247,247,247)!important;
  background-clip: padding-box;
  -webkit-box-shadow: 0 6px 12px rgba(0,0,0,.175);
  box-shadow: 0 6px 12px rgba(0,0,0,.175);
  z-index: 25;

  li{
    font-weight: 400;
    line-height: 1.42857143;
    display: block;
    clear: both;
    padding: 3px 20px;
    white-space: nowrap;
    color: #58595b;
  }
  li:hover{
    background: #f5f5f5
  }

`;


const ListOrcamento = ( {list = []} ) =>{
    const { user } = useContext(AuthContext);

    const navigate = useNavigate();

    const [orcamentos, setOrcamentos] = useState([]);
    const [filter, setFilter] = useState('');
    const [result, setResult] = useState(false);

    const [order, setOrder] = useState(1)
    const [columnOrder, setColumnOrder] = useState('title')

    const { sidebar, setSidebar } = useContext(SidebarContext);;
    
    const [loading, setLoading] = useState(true);

    const [refresh, setRefresh] = useState(false);
    const [showOrcOpt, setShowOrcOpt] = useState('');

    const [showEditEmpresa, setEditEmpresa] = useState(true);
    const userOptionsRef = useRef();

    useEffect(() => {
        (async () => {
            const response = await getOrcamentos(user._id);
            console.log("TESTEEE:::",user._id)
            //const teste = [response.data]
            console.log(response.data)
            setOrcamentos(response.data.orcamento);
            setLoading(false);
            setRefresh(false);
        })();
    },[refresh]);

    // Click Outside Options
    useEffect(() => {    
        // add when mounted
        document.addEventListener("mousedown", handleClickOutsideOptions);
        // return function to be called when unmounted
        return () => {
            document.removeEventListener("mousedown", handleClickOutsideOptions);
        };
    }, []);

    const handleClickOutsideOptions = y => {
        try{
            if (userOptionsRef.current.contains(y.target)) {    
            return 
            }
            else{
            setShowOrcOpt('');
            }
        }catch(error) {
            return;
        }
    };    

    const [confirmOpen, setConfirmOpen] = useState(false);
    const [shouldDelete, setShouldDelete] = useState(false);
    const [idToDelete, setIdToDelete] = useState('');
    const handleDelete = (idRecebido) => {
        // Abre Componente de Confirmação do delete
        setConfirmOpen(true)
        setShowOrcOpt('');
        setIdToDelete(idRecebido)
        if(idRecebido){
            console.log("ID RECEBIDO:",idRecebido)
            setShouldDelete(true);
        }
    }
    const deleteReg = async () => {
        console.log("O ID CLICADO FOI :::",idToDelete);
        console.log("ID do Usuário que irá efetuar a exclusão", user._id);

        if(shouldDelete){
            deleteOrcamento(idToDelete, user._id).then( cast => {
                console.log("RESULT DELETE:",cast);
                if(cast.status===201){
                    toast(cast.data.msg,{ autoClose: 5000 , type: "success"});
                    setRefresh(true);
                }else{
                    toast(cast.data.msg,{ autoClose: 5000 , type: "error"});
                }
            }).catch( err => {
                console.log("ERROR:",err);
                toast(err.response.data.msg,{ autoClose: 5000 , type: "error"});
            });
        }

    }


    // const [_idEmpresaEdit, set_idEmpresaEdit] = useState('');
    // const [cnpj, setCnpj] = useState('');
    // const [nome, setNome] = useState('');
    // const [razao, setRazao] = useState('');
    // const [email, setEmail] = useState('');
    // const [cepNumber, setCep] = useState('');
    // const [estado, setEstado] = useState('');
    // const [municipio, setMunicipio] = useState('');
    // const [bairro, setBairro] = useState('');
    // const [complemento, setComplemento] = useState('');
    // const [telefone, setTelefone] = useState('');
    // const [celular, setCelular] = useState('');
    const handleEnviarPedido = async idToSend => {
        setShowOrcOpt('');
        console.log("ID TO SEND: ",idToSend);            
    }

    const handleIntegrarOrcamento = async idToIntegrate => {
        setShowOrcOpt('');
        console.log("ID TO INTEGRATE: ",idToIntegrate);
    }

    const handleImprimir = async idToPrint => {
        console.log("ID TO PRINT: ",idToPrint);
    }

    const handleRefresh = () => {
        //window.location.reload(false);
        setLoading(true);
        setRefresh(true);
    }

    function handleMenuOrcOpt(codigo){
        console.log(codigo);
        setShowOrcOpt(codigo);
        //setItems(items.filter(item => item.codigo !== codigo))
    }

    const handleOrder = fieldName => {
        setOrder(-order)
        setColumnOrder(fieldName)
        console.log("order:",order);
        console.log("fieldName",fieldName)
    }

    var arrayOrcamentos = [];
    arrayOrcamentos = orcamentos.sort( (a, b) => {
        return a[columnOrder] < b[columnOrder] ? -order : order;
    })

    
    if (filter){
        const exp = eval(`/${filter.replace(/[^\d\w]+/,'.*')}/i`)

        list = orcamentos.filter( item => exp.test( addZeroes(item.id_orcamento) ) )       

        console.log(list.length)
        if(list.length==0){
            list = orcamentos.filter( item => exp.test( item.nome_cliente ) )
            //setResult('teste');
            //console.log(list);
        }
        // if(list.length==0){
        //     list = orcamentos.filter( item => exp.test( item.orcamento_email ) )
        //     //setResult(true);
        // }
        /*if(list.length==0){
            list = empresas.filter( item => exp.test( item.celular ) )
        }*/
    }

    const handleFilter = e => {
        setFilter(e.target.value);
        setResult(!result);
    }

    const handleCadastro = () => {
        navigate("/cadastro-orcamento");
    }

    const handleExport = () => {
        console.log(orcamentos)
        var wb = XLSX.utils.book_new();
        var ws = XLSX.utils.json_to_sheet(orcamentos);

        XLSX.utils.book_append_sheet(wb, ws, "Orçamentos");
        XLSX.writeFile(wb, "Orçamentos.xlsx");
    }

    function addZeroes(num, len=6) {
        var numberWithZeroes = String(num);
        var counter = numberWithZeroes.length;
          
        while(counter < len) {        
            numberWithZeroes = "0" + numberWithZeroes;        
            counter++;    
        }
      
      return numberWithZeroes;
    }

    if(loading){
        return(

            <>
            <PermissionGate permissions={['viewCVO']}>
            <div className="loading"><AiIcons.AiOutlineLoading3Quarters style={{fontSize:"100px", color:"rgb(180,180,180)"}}/></div>
            

                {/*<LeftMenu setSidebar={setSidebar} sidebar={sidebar} />*/}

                <Teste sidebar={sidebar}>

                    <div className="m-5">
                        <div className="list-header">
                            <div className="title">
                                <h1>Orçamentos</h1>
                            </div>
                        </div>


                        <div style={{display:"flex", justifyContent:"space-between"/*, height:"80px"*/}}>
                            <div className="input-box-list" style={{width:"30%"}}>                
                                <input className="input-field" placeholder="Pesquisar" onChange={handleFilter}/>
                                <i className="icon"><FiIcons.FiSearch/></i>
                            </div>

                            <div className="div-buttons">
                                <div className="export-button">                           
                                    <button onClick={handleExport}>
                                        <AiIcons.AiOutlineFileExcel style={{marginRight: "5px",fontSize: "22px",marginBottom: "2px"}}/><a>Exportar</a> 
                                    </button>                            
                                </div>     

                                <div className="continue-button">                                                        
                                    <button /*onClick={handleCadastro}*/ >                                        
                                        <AiIcons.AiOutlinePlusCircle style={{marginRight: "5px",fontSize: "22px",marginBottom: "2px"}}/><a>Adicionar</a> 
                                    </button>                            
                                </div>

                                <div className="refresh-button">                                                       
                                    <button className="refresh-button" onClick={handleRefresh}>
                                        <SlIcons.SlRefresh style={{color:"#4281FF",marginRight: "5px",fontSize: "18px",marginBottom: "2px"}}/><a >Atualizar</a> 
                                    </button>
                                </div>
                            </div>

                        </div>

                        <div className={result?"table result":"table"} style={{background:"white"}}>
                            <table className={"table"}>
                                <thead>
                                    <tr>
                                        <th scope="col">Status</th>
                                        <th scope="col">Código</th> 
                                        <th scope="col">Emissão</th>                                       
                                        <th scope="col">Cliente</th>
                                        <th scope="col">Nome do Cliente</th>
                                        <th scope="col">Vendedor</th>
                                        <th scope="col">Nome do Vendedor</th>
                                        <th scope="col">Total</th>                                        
                                        <th scope="col" style={{paddingLeft:"35px"}}>Ações</th>
                                    </tr>
                                </thead>

                                <tbody>                                                                                                      
                                </tbody>

                            </table>

                        </div>
                                
                    </div>
                </Teste>
        
        
            {/*</div>*/}

            </PermissionGate>       
            </>

        );
    }

    return(

        <>
        <PermissionGate permissions={['viewCVO']}>
        {/*<div className="container">*/}

            {/*<LeftMenu setSidebar={setSidebar} sidebar={sidebar} />*/}

            <ConfirmDialog
                title="Deletar Orçamento?"
                open={confirmOpen}
                setOpen={setConfirmOpen}
                onConfirm={deleteReg}
            >
                Tem certeza que deseja deletar o Orçamento?
            </ConfirmDialog>

            {/* <ModalEditEmpresa setEditEmpresa={setEditEmpresa} showEditEmpresa={showEditEmpresa} 
            setRefresh={setRefresh}
            _idEmpresaEdit={_idEmpresaEdit}
            cnpj={cnpj}
            nome={nome}
            razao={razao}
            email={email}
            cepNumber={cepNumber}
            setCep={setCep}
            estado={estado}
            setEstado={setEstado}
            municipio={municipio}
            setMunicipio={setMunicipio}
            bairro={bairro}
            setBairro={setBairro}
            complemento={complemento}
            setComplemento={setComplemento}
            telefone={telefone}
            setTelefone={setTelefone}
            celular={celular}
            setCelular={setCelular}
            /> */}

            <Teste sidebar={sidebar}>

                <div className="m-5">
                    <div className="list-header">
                        <div className="title">
                            <h1>Orçamentos</h1>
                        </div>
                    </div>


                    <div style={{display:"flex", justifyContent:"space-between"/*, height:"80px"*/}}>
                        <div className="input-box-list" style={{width:"30%"}}>                
                            <input className="input-field" placeholder="Pesquisar" onChange={handleFilter}/>
                            <i className="icon"><FiIcons.FiSearch/></i>
                        </div>

                        <div className="div-buttons">
                            <div className="export-button">                           
                                <button onClick={handleExport}>
                                    <AiIcons.AiOutlineFileExcel style={{marginRight: "5px",fontSize: "22px",marginBottom: "2px"}}/><a>Exportar</a> 
                                </button>                            
                            </div>        

                            <div className="continue-button">  
                                <Link 
                                to={{
                                    pathname: "/orcamento/cadastro"
                                }}
                                state={{stateParam: null}}
                                >   
                                    <button>                                        
                                        <AiIcons.AiOutlinePlusCircle style={{marginRight: "5px",fontSize: "22px",marginBottom: "2px"}}/>Adicionar 
                                    </button>     
                                </Link>    
                            </div>

                            <div className="refresh-button">                                                       
                                <button className="refresh-button" onClick={handleRefresh}>
                                    <SlIcons.SlRefresh style={{color:"#4281FF",marginRight: "5px",fontSize: "18px",marginBottom: "2px"}}/><a >Atualizar</a> 
                                </button>
                            </div>
                        </div>

                    </div>
                  
                    <table className={"table"}>
                        <thead>
                            <tr>                                                      
                                <th scope="col" onClick={ e => handleOrder('status_orc')} style={{cursor:"pointer"}}>Status<FaIcons.FaSort/></th>    
                                <th scope="col" onClick={ e => handleOrder('id_orcamento')} style={{cursor:"pointer"}}>Código<FaIcons.FaSort/></th>                                
                                <th scope="col" onClick={ e => handleOrder('data_emmissao')} style={{cursor:"pointer"}}>Emissão<FaIcons.FaSort/></th>
                                <th scope="col" onClick={ e => handleOrder('codigo_cliente')} style={{cursor:"pointer"}}>Cliente<FaIcons.FaSort/></th>
                                <th scope="col" onClick={ e => handleOrder('nome_cliente')} style={{cursor:"pointer"}}>Nome do Cliente<FaIcons.FaSort/></th>
                                <th scope="col" onClick={ e => handleOrder('codigo_vendedor')} style={{cursor:"pointer"}}>Vendedor<FaIcons.FaSort/></th>                              
                                <th scope="col" onClick={ e => handleOrder('nome_vendedor')} style={{cursor:"pointer"}}>Nome do Vendedor<FaIcons.FaSort/></th>
                                <th scope="col" onClick={ e => handleOrder('total_orc')} style={{cursor:"pointer"}}>Total<FaIcons.FaSort/></th>
                                <th scope="col" style={{paddingLeft:"32px"}}>Ações</th>
                            </tr>
                        </thead>

                        <tbody className={result?"result":"result search"}>
                            
                        {
                        filter ? 
                            list.map((orcamento) => (
                                <tr key={orcamento._id}>
                                    <td>{
                                    orcamento.status_orc==="1" ? <div className="status-aberto"><label><span>EM ABERTO</span></label></div> 
                                    : orcamento.status_orc==="2" ? <div className="status-enviado"><label><span>ENVIADO</span></label></div>
                                    : orcamento.status_orc==="3" ? <div className="status-faturado"><label><span>FATURADO</span></label></div>
                                    : orcamento.status_orc==="4" && <div className="status-cancelado"><label><span>CANCELADO</span></label></div>                                                                 
                                    }</td>
                                    <td>{addZeroes(orcamento.id_orcamento)}</td>
                                    {orcamento.data_emissao ?
                                        <td>{moment(orcamento.data_emissao).format("DD/MM/YYYY")}</td>
                                        :
                                        <td></td>
                                    }
                                    <td>{addZeroes(orcamento.codigo_cliente)}</td>
                                    <td>{orcamento.nome_cliente}</td>
                                    <td>{addZeroes(orcamento.codigo_vendedor)}</td>
                                    <td>{orcamento.nome_vendedor}</td>
                                    <td>{
                                    orcamento.total_orc.toLocaleString('pt-BR', { 
                                        style: 'currency', 
                                        currency: 'BRL' 
                                    })
                                    }</td>
                                    <td className="menuItemOpt" style={{width:'100px', position:"relative"}}>                                                                           
                                        <a className="btn btn-sm btn-primary" style={{marginLeft:"15%", height:'28px'}} onClick={e => handleMenuOrcOpt(orcamento._id)}>
                                            <FiIcons.FiMenu style={{color:"white"}} strokeWidth='3'/>
                                        </a>
                                        <a className="btn btn-sm btn-secondary" style={{marginLeft:"5%", height:'28px'}} onClick={e => handleImprimir(orcamento.id_orcamento)}><FiIcons.FiPrinter style={{color:"white"}}/></a> 
                                        {
                                        showOrcOpt===orcamento._id ?
                                        <ModalOrcOptions ref={userOptionsRef} id={orcamento._id} showOrcOpt={showOrcOpt}>
                                            <Link style={{textDecoration:"none"}}                                        
                                            to={{
                                                pathname: "/orcamento/view"
                                            }}
                                            state={{stateParam: orcamento.id_orcamento}}
                                            >                                                                                   
                                                <li style={{cursor:"pointer"}}>Visualizar</li> 
                                            </Link>  
                                            <Link style={{textDecoration:"none"}}                                        
                                            to={{
                                                pathname: "/orcamento/edicao"
                                            }}
                                            state={{stateParam: orcamento.id_orcamento}}
                                            >                                                                                   
                                                <li style={{cursor:"pointer"}}>Editar</li> 
                                            </Link>                 
                                            <li onClick={e => handleDelete(orcamento._id)} style={{cursor:"pointer"}}><a>Excluir</a></li> 
                                            <li onClick={e => handleIntegrarOrcamento(orcamento.id_orcamento)} style={{cursor:"pointer"}}><a>Integrar Orçamento</a></li>     
                                            <li onClick={e => handleEnviarPedido(orcamento.id_orcamento)} style={{cursor:"pointer"}}><a>Enviar Pedido</a></li>                                       
                                        </ModalOrcOptions>
                                        : null
                                        }      
                                    </td>
                                </tr>
                            )) :
                            arrayOrcamentos.map(({_id, status_orc, id_orcamento, data_emissao, codigo_cliente, nome_cliente, codigo_vendedor, nome_vendedor, total_orc}) => (
                                <tr key={_id}>
                                    <td>{
                                    status_orc==="1" ? <div className="status-aberto"><label><span>EM ABERTO</span></label></div> 
                                    : status_orc==="2" ? <div className="status-enviado"><label><span>ENVIADO</span></label></div>
                                    : status_orc==="3" ? <div className="status-faturado"><label><span>FATURADO</span></label></div>
                                    : status_orc==="4" && <div className="status-cancelado"><label><span>CANCELADO</span></label></div>                                                                 
                                    }</td>
                                    <td>{addZeroes(id_orcamento)}</td>
                                    {data_emissao ?
                                        <td>{moment(data_emissao).format("DD/MM/YYYY")}</td>
                                        :
                                        <td></td>
                                    }
                                    <td>{addZeroes(codigo_cliente)}</td>
                                    <td>{nome_cliente}</td>
                                    <td>{addZeroes(codigo_vendedor)}</td>
                                    <td>{nome_vendedor}</td>
                                    <td>{
                                    total_orc.toLocaleString('pt-BR', { 
                                        style: 'currency', 
                                        currency: 'BRL' 
                                    })                                    
                                    }</td>
                                    <td className="menuItemOpt" style={{width:'100px', position:"relative"}}>                                                                           
                                        <a className="btn btn-sm btn-primary" style={{marginLeft:"15%", height:'28px'}} onClick={e => handleMenuOrcOpt(_id)}>
                                            <FiIcons.FiMenu style={{color:"white"}} strokeWidth='3'/>
                                        </a>
                                        <a className="btn btn-sm btn-secondary" style={{marginLeft:"5%", height:'28px'}} onClick={e => handleImprimir(id_orcamento)}><FiIcons.FiPrinter style={{color:"white"}}/></a> 
                                        {
                                        showOrcOpt===_id ?
                                        <ModalOrcOptions ref={userOptionsRef} id={_id} showOrcOpt={showOrcOpt}>
                                            <Link style={{textDecoration:"none"}}                                        
                                            to={{
                                                pathname: "/orcamento/view"
                                            }}
                                            state={{stateParam: id_orcamento}}
                                            >                                                                                   
                                                <li style={{cursor:"pointer"}}>Visualizar</li> 
                                            </Link>  
                                            <Link style={{textDecoration:"none"}}                                        
                                            to={{
                                                pathname: "/orcamento/edicao"
                                            }}
                                            state={{stateParam: id_orcamento}}
                                            >                                                                                   
                                                <li style={{cursor:"pointer"}}>Editar</li> 
                                            </Link>                 
                                            <li onClick={e => handleDelete(_id)} style={{cursor:"pointer"}}><a>Excluir</a></li>    
                                            <li onClick={e => handleIntegrarOrcamento(id_orcamento)} style={{cursor:"pointer"}}><a>Integrar Orçamento</a></li> 
                                            <li onClick={e => handleEnviarPedido(id_orcamento)} style={{cursor:"pointer"}}><a>Enviar Pedido</a></li>                                       
                                        </ModalOrcOptions>
                                        : null
                                        }      
                                    </td>
                                    {/* <td>
                                        <a className="btn btn-sm btn-secondary" onClick={e => handleEdit(id_orcamento)}><FiIcons.FiEye style={{color:"white"}}/></a>
                                        <a className="btn btn-sm btn-primary" style={{marginLeft:"2px"}} onClick={e => handleEdit(id_orcamento)}><FiIcons.FiEdit2 style={{color:"white"}}/></a>
                                        <a className="btn btn-sm btn-danger" style={{marginLeft:"2px"}} onClick={e => handleDelete(_id)}><FiIcons.FiTrash2 style={{color:"white"}}/></a>
                                    </td> */}
                                </tr>
                            )) 
                        }
                            
                        </tbody>


                    </table>

                            
                </div>
            </Teste>
    
      
        {/*</div>*/}

        </PermissionGate>       
        </>
        

        );
    };
    
    export default ListOrcamento;