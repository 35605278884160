import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import './style.css'
import PermissionGate from "../../services/PermissionGate";
import LeftMenu from "../../components/LeftMenu";
import styled from 'styled-components';
import QRCode from 'qrcode.react';
import { SidebarContext } from "../../AppRoutes";
import * as AiIcons from 'react-icons/ai'
import * as SlIcons from 'react-icons/sl'
import { testeImpressao } from "../../services/api";

const Teste = styled.div`

    display: flex;
    margin-left:  ${({ sidebar }) => (sidebar ? '250px' : '100px')}; 
    height:auto;
    width:auto;
    transition: 150ms;
    background-color:rgb(247,247,247)!important;
    overflow: initial;
    z-Index: 9;

    @media (max-width: 880px) {
        margin-left: 0;
    }
`;

const ConfigPrinter = () => {

    const { sidebar, setSidebar } = useContext(SidebarContext);; //TESTANDO CONST NO AppROUTES
    const [isSubmitting, setIsSubmitting] = useState(false);
    const navigate = useNavigate();

    const empresa = localStorage.getItem('empresa')
    const empresaParse = JSON.parse(empresa)
    const idEmpresa = empresaParse.id_empresa;

    const qrCodeData = 'https://www.example.com';

    const handleBack = () => {
        //setSidebar(!sidebar)
        navigate("/list-item");
    }

    const downloadInstallationPackage = async () => {
        try {
            // Endpoint da GitHub API para obter as releases
            const releasesUrl = 'https://api.github.com/repos/leadgarantido/PedeJaPrint-Release/releases/latest';
            
            // Obtém a última release
            const response = await fetch(releasesUrl);
            if (!response.ok) {
                throw new Error('Não foi possível obter as releases do GitHub');
            }
            const data = await response.json();
    
            // Encontra o link para o arquivo PedeJaPrintSetup.exe na última release
            const setupAsset = data.assets.find(asset => asset.name === 'PedeJaPrintSetup.exe');
            if (!setupAsset) {
                throw new Error('Arquivo PedeJaPrintSetup.exe não encontrado na última release');
            }
    
            // URL para download do arquivo
            const downloadUrl = setupAsset.browser_download_url;
    
            // Inicia o download
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.download = 'PedeJaPrintSetup.exe'; // Nome do arquivo ao salvar
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            
            console.log('Download iniciado para:', downloadUrl);
        } catch (error) {
            console.error('Erro ao baixar o pacote de instalação:', error);
        }
    };

    const handlePrinterTest = async () => {
        const response = await testeImpressao(idEmpresa)
        console.log("Resposta do print test:",response)
    }

    return (
        <>
        <PermissionGate permissions={['default']}>
    

            {/*<LeftMenu setSidebar={setSidebar} sidebar={sidebar} />*/}

            <Teste sidebar={sidebar}>
                <div className="w-100 p-4">
                    {/*<form onSubmit={handleSubmitButton}>*/}
                    <form /*onSubmit={formik.handleSubmit}*/>
                    
                        <div className="form-header" style={{marginBottom:"0px"}}>
                            <div className="title">
                                <h1>Configurar Impressora</h1>
                            </div>
                        </div>                   

                        <div className="contentItemComplete flex-column flex-md-row">
                            {/*<div className="input-group inputGroup-etapasItem w-100 justify-content-center" style={{maxHeight:100, maxWidth: "450px"}}> 
                                <div className="etapasAddItem etapaAtiva" style={{borderBottom:'1px solid lightgray'}}>
                                    <label>1. Configuração da Impressora</label>
                                </div>                        
                            </div>*/}
                        
                            <div className="input-group inputGroup-adicinaItem mw-100 mh-100">                               
                                
                                <div className="formGroupRow" style={{display:'flex', justifyContent:"center"}}>
                                                                    
                                <div className="app-container">
                                    <div className="configuration-section-printer">
                                        <h1 style={{textAlign:'center'}}>Instalar Impressora</h1>                                        
                                        <button onClick={downloadInstallationPackage} className="download-button" type="button">
                                            Baixar Instalador
                                        </button>
                                    </div>
                                    <div className="test-printer">
                                        <h2>Teste sua impressora</h2>
                                        <p>Teste a sua impressora para começar a receber pedidos e aumentar suas vendas.</p>
                                        <button type="button" onClick={handlePrinterTest}>Testar</button>
                                    </div>
                                </div>

                                </div>                           

                            </div>

                            

                        </div>

                    </form>
                </div>
            </Teste>
                
        </PermissionGate>       
        </>
    )
}

export default ConfigPrinter;