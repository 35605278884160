import React, { useState, useContext, useEffect, useRef} from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import './style.css'
import styled from 'styled-components';
//import Calendar from 'react-calendar'
//import 'react-calendar/dist/Calendar.css';
import moment from 'moment';
import CurrencyInput from 'react-currency-input-field';
import AsyncSelect from 'react-select/async';
import {components} from 'react-select';
import { SidebarContext } from "../../AppRoutes";
import { AuthContext } from "../../contexts/auth";

import { registerOrcamento, getClientes, getVendedores, getEmpresa, getOrcamento, updateOrcamento } from "../../services/api";

import PermissionGate from "../../services/PermissionGate";

import { useNavigate } from "react-router-dom";
import LeftMenu from "../../components/LeftMenu";

import { useFormik } from "formik";
import * as Yup from "yup";

import * as AiIcons from 'react-icons/ai'
import * as SlIcons from 'react-icons/sl'
import * as FiIcons from 'react-icons/fi'

import { toast } from 'react-toastify';
import ModalAddItem from "../../components/ModalAddItem";
import { isDisabled } from "@testing-library/user-event/dist/utils";

const Teste = styled.div`

    display: flex;
    margin-left:  ${({ sidebar }) => (sidebar ? '250px' : '100px')}; 
    height:auto;
    width:auto;
    transition: 150ms;
    background-color:rgb(247,247,247)!important;
    overflow: initial;
    z-Index: 9;

    @media (max-width: 880px) {
        margin-left: 0;
    }
`;

const DivEmptyItem = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    margin-top: 10px;
    height: 40px;
    border-radius: 15px !important;
    color: rgb(120,120,120);
`;

const ModalItemOptions = styled.div`
  font-size: 14px;
  position: absolute;
  top: 10px;
  left: -100px;
  --display: ${({ showOptions }) => (showOptions ? 'none' : '')};
  float: left;
  min-width: 120px;
  margin: 2px 0 0;
  padding: 5px 0;
  list-style: none;
  text-align: left;
  border: 1px solid #ccc;
  border: 1px solid rgba(0,0,0,.15);
  border-radius: 4px;
  background-color: #fff;
  --background-color:rgb(247,247,247)!important;
  background-clip: padding-box;
  -webkit-box-shadow: 0 6px 12px rgba(0,0,0,.175);
  box-shadow: 0 6px 12px rgba(0,0,0,.175);
  z-index: 25;

  li{
    font-weight: 400;
    line-height: 1.42857143;
    display: block;
    clear: both;
    padding: 3px 20px;
    white-space: nowrap;
    color: #58595b;
  }
  li:hover{
    background: #f5f5f5
  }

`;

const Orcamento = () =>{
    
    const { type } = useParams();
    const stateParam = useLocation().state.stateParam;
    const { user } = useContext(AuthContext);

    console.log("Props Param: ", type);
    console.log("State Param: ", stateParam);

    const { sidebar, setSidebar } = useContext(SidebarContext);; //TESTANDO CONST NO AppROUTES
    const [showModalAddItem, setShowModalAddItem] = useState(false);
    const [showItemOpt, setShowItemOpt] = useState('');
    
    const [data_emissao, setData_emissao] = useState(new Date());

    //const [orcamento, setOrcamento] = useState('');

    //const [clientes, setClientes] = useState([]);
    const [nome_cliente, setNomeCliente] = useState('');    
    const [nome_vendedor, setNomeVendedor] = useState('');
    //const [total_orc, setTotalOrcamento] = useState('');

    // Informações do Item
    const [items, setItems] = useState([]);
    const [itemToEdit, setItemToEdit] = useState('');
    //const [refreshTotalItem, setRefreshTotalItem] = useState(false);
    
    const userOptionsRef = useRef();    


    // Constantes do calendário
    // const [dataEmissaoCalendar, setDataEmissaoCalendar] = useState(null);
    // const [showDataEmissaoCalendar, setShowDataEmissaoCalendar] = useState(false);
    // const dataEmissaoRef = useRef();

    // useEffect(() => {
    //     (async () => {
    //         const response = await getClientes(user._id);
    //         //console.log("TESTEEE:::",user._id)
    //         //const teste = [response.data]
    //         //console.log(response.data)
    //         setClientes(response.data.cliente);
    //         //setLoading(false);
    //         //setRefresh(false);
    //     })();
    // },[]);

    
    // Scroll Horizontal Items
    /*
    useEffect(() => {    
        var item = document.getElementById("tabela");
        console.log("EXECUTOU USEEFFECT",item);
        
        // add when mounted    
        window.addEventListener("wheel", function(e){
            if(item!==null){
                console.log("PASSOU ADD")
                if (e.deltaY > 0) item.scrollLeft += 50;
                else item.scrollLeft -= 50;                
            }
        });
        // return function to be called when unmounted
        return () => {
            window.removeEventListener("wheel", function(e){
                if(item!==null){
                    console.log("PASSOU REMOVE")
                    if (e.deltaY > 0) item.scrollLeft += 50;
                    else item.scrollLeft -= 50;
                }
            });
        };
    }, []);
    */
    
    const [loading, setLoading] = useState(type==='cadastro' ? false : true);
    useEffect(() => {
        (async () => { 
            if(type === 'edicao' || type === 'view'){                        
                console.log("USEEFFECT CAPTURANDO ORCAMENTO");  
                const response = await getOrcamento(user._id, stateParam);
                
                setData_emissao(response.data.orcamento.data_emissao);

                let dadosCliente = {
                    value: response.data.orcamento.codigo_cliente,
                    label: addZeroes(response.data.orcamento.codigo_cliente),
                };
                setselectDataCliente(dadosCliente);
                setNomeCliente(response.data.orcamento.nome_cliente);

                let dadosVendedor = {
                    value: response.data.orcamento.codigo_vendedor,
                    label: addZeroes(response.data.orcamento.codigo_vendedor),
                };
                setselectDataVendedor(dadosVendedor);                                    
                setNomeVendedor(response.data.orcamento.nome_vendedor);  
                
                setItems(response.data.orcamento.items);

                console.log(response.data.orcamento)
                setLoading(false);
                //setRefresh(false);
            }
            
        })();
    },[]);    
    


    // Click Outside Options
    useEffect(() => {    
        // add when mounted
        document.addEventListener("mousedown", handleClickOutsideOptions);
        // return function to be called when unmounted
        return () => {
            document.removeEventListener("mousedown", handleClickOutsideOptions);
        };
    }, []);

    const handleClickOutsideOptions = y => {
        try{
            if (userOptionsRef.current.contains(y.target)) {    
            return 
            }
            else{
            setShowItemOpt('');
            }
        }catch(error) {
            return;
        }
    };

    const INITIAL_DATA = {
        value: "",
        label: 'Selecione o Cliente',
    };

    const INITIAL_DATA_VENDEDOR = {
        value: "",
        label: 'Selecione o Vendedor',
    };
    
    const [selectDataCliente, setselectDataCliente] = useState(INITIAL_DATA);
    const mapResponseToValuesAndLabels = (data) => ({
    value: data.id_cliente,
    label: addZeroes(data.id_cliente)+" - "+data.documento+" - "+data.name,
    });

    const [selectDataVendedor, setselectDataVendedor] = useState(INITIAL_DATA_VENDEDOR);
    const mapResponseToValuesAndLabelsVend = (data) => ({
    value: data.id_vendedor,//+" - "+data.name,
    label: addZeroes(data.id_vendedor)+" - "+data.documento+" - "+data.name,
    });
    
      
    async function callApi(value) {
    
        const data = await getClientes(user._id)
        .then((response) => response.data.cliente)//response.json())
        .then((response) => response.map(mapResponseToValuesAndLabels))
        .then((response) =>
            response.filter((i) => i.label.toLowerCase().includes(value.toLowerCase()))
        );

        return data;
    }


    async function callApiVendedor(value) {
    
        const data = await getVendedores(user._id)
        .then((response) => response.data.vendedor)//response.json())
        .then((response) => response.map(mapResponseToValuesAndLabelsVend))
        .then((response) =>
            response.filter((i) => i.label.toLowerCase().includes(value.toLowerCase()))
        );

        return data;
    }
    

    //Validações
    const SignUpSchema = Yup.object().shape({
        //data_emissao: dataEmissaoCalendar===null && Yup.string().required("Obrigatório"), // Usado no calendário
        data_emissao: data_emissao==='' && Yup.string().required("Obrigatório"),
        cliente: selectDataCliente.value==="" && Yup.string().required("Obrigatório"),
        vendedor: selectDataVendedor.value==="" && Yup.string().required("Obrigatório"),
        //total_orc: total_orc==='' && Yup.string().required("Obrigatório"),
    });

    const formik = useFormik({
        validationSchema: SignUpSchema,
        validateOnBlur: false,
        validateOnChange: false,
        initialValues: {
            nome_cliente: "",
            nome_vendedor: "",
            cliente: "",
            vendedor: "",
            total_orc: "",

        },
        onSubmit: (values) => {
          
          handleSubmitAPI();
          
        },
    });
    

    const navigate = useNavigate();

    const handleBack = () => {
        //setSidebar(!sidebar)
        navigate("/list-orcamento");
    }



    const handleSubmitAPI = async () =>{
        //e.preventDefault();
        const idOrcamentoEdit = stateParam;
        const createdBy = user._id;
        const vinculo_empresa = user.vinculo_empresa;
        //console.log("USERDATA:",user);
        const codigo_cliente = selectDataCliente.value;
        const codigo_vendedor = selectDataVendedor.value;
        const numberTotal_orc = parseFloat(obtemTotalOrcamento().replace(/,/g, '.'));
        //console.log(typeof(numberTotal_orc));
        const status_orc = "1";
        let id_grupo = "";
        const getIdGrupo = await getEmpresa(user._id, vinculo_empresa).then(
            (response) => {
                console.log(response)
                id_grupo = response.data.empresa.id_grupo;
                console.log(items);
                //Registrando o Orcamento
                if(type==='cadastro'){
                    console.log("submit!",{createdBy, data_emissao, codigo_cliente, nome_cliente, codigo_vendedor, nome_vendedor, numberTotal_orc, status_orc, id_grupo, vinculo_empresa, items});
                    registerOrcamento(createdBy, data_emissao, codigo_cliente, nome_cliente, codigo_vendedor, nome_vendedor, numberTotal_orc, status_orc, id_grupo, vinculo_empresa, items).then(cast => {
                        //console.log("AQUI DEU BOM, CRIOU REVENDA:::",cast)
                        if(cast.status===201){
                            toast(cast.data.msg, {type: "success"});
                            navigate("/list-orcamento");
                        }
                        else{
                            toast(cast.data.msg, {type: "error"});
                        }
                    }).catch(err => {
                        //console.log('Oh noooo!!');
                        const msg = err.response.data.msg;
                        ////console.log("MENSAGEM", msg)
                        toast(msg, {type: "error"});                                      
                    });
                }
                if(type==='edicao'){
                    console.log("Atualiza Orçamento: ", idOrcamentoEdit, createdBy, codigo_cliente, nome_cliente, codigo_vendedor, nome_vendedor, numberTotal_orc, status_orc, id_grupo, vinculo_empresa, items)                    
                    updateOrcamento(idOrcamentoEdit, createdBy, codigo_cliente, nome_cliente, codigo_vendedor, nome_vendedor, numberTotal_orc, status_orc, id_grupo, vinculo_empresa, items).then(cast => {
                        //console.log("AQUI DEU BOM, CRIOU REVENDA:::",cast)
                        if(cast.status===200){
                            toast(cast.data.msg, {type: "success"});
                            navigate("/list-orcamento");
                        }
                        else{
                            toast(cast.data.msg, {type: "error"});
                        }
                    }).catch(err => {
                        //console.log('Oh noooo!!');
                        const msg = err.response.data.msg;
                        ////console.log("MENSAGEM", msg)
                        toast(msg, {type: "error"});                                      
                    });
                }

            }
        )        
        .catch(err => {
            const msg = err.response.data.msg;
            console.log("Erro ao buscar o ID do Grupo",msg);
        });    
        
    }

    function handleDelete(codigo){
        console.log(codigo);
        console.log(items);
        setItems(items.filter(item => item.codigo !== codigo))
        console.log(items.filter(item => item.codigo !== codigo))
        setShowItemOpt("");
        setItemToEdit("");
    }

    function handleMenuItemOpt(codigo){
        console.log(codigo);
        setShowItemOpt(codigo);
        //setItems(items.filter(item => item.codigo !== codigo))
    }

    function handleEditItem(itemToEdit){
        console.log(itemToEdit);
        setItemToEdit(itemToEdit);
        setShowModalAddItem(true);
        setShowItemOpt("");
    }

    function handleCloseOpt(){
        setShowItemOpt("");
    }

    function addZeroes(num, len=6) {
        var numberWithZeroes = String(num);
        var counter = numberWithZeroes.length;
          
        while(counter < len) {        
            numberWithZeroes = "0" + numberWithZeroes;        
            counter++;    
        }
      
      return numberWithZeroes;
    }


    const DropdownIndicator = (
        props
      ) => {
        return (
          <components.DropdownIndicator {...props}>
            <AiIcons.AiOutlineSearch style={{fontSize:"20px"}}/>
          </components.DropdownIndicator>
        );
    };

    const handleAddItem = (e) => {
        e.preventDefault()
        setItemToEdit("");
        //executaUseEffect();
        console.log("ITEM TO EDIT:",itemToEdit )
        setShowModalAddItem(true);
    }

    function obtemTotalOrcamento() {
        let total = 0;
        items.map(item => {
            total += item.preco_total;
        })
        return total.toFixed(2);
    }


    /* // Funções para o calendário
    useEffect(() => {    
        // add when mounted
        document.addEventListener("mousedown", handleClickOutsideDataEmissaoCalendar);
        // return function to be called when unmounted
        return () => {
            document.removeEventListener("mousedown", handleClickOutsideDataEmissaoCalendar);
        };
    }, []);
    
    const handleDataEmissaoCalendar = () => {
        setShowDataEmissaoCalendar(true);        
    }

    const handleClickOutsideDataEmissaoCalendar = y => {
        var field = document.getElementById("data_emissao");
        if (dataEmissaoRef.current.contains(y.target)) {            
            setTimeout(function() { 
                field.focus();                
            }, 0);
            console.log("clicou dentro")        
        return 
        }
        else{
          console.log("clicou fora")
          setShowDataEmissaoCalendar(false);
        }
    };
   
    const handleSelectDataEmissao = (data) => {
        var field = document.getElementById("data_emissao");
        field.blur();
        setDataEmissaoCalendar(data);    
        setShowDataEmissaoCalendar(false);                
    }
    */


    if(loading && (type==='edicao' || type==='view')){
        return(
            <>
            <PermissionGate permissions={['viewCVO']}>
            <div className="loading"><AiIcons.AiOutlineLoading3Quarters style={{fontSize:"100px", color:"rgb(180,180,180)"}}/></div>
                {/*<LeftMenu setSidebar={setSidebar} sidebar={sidebar} />*/}
            </PermissionGate>
            </>
        )
    }

    return(

        <>
        <PermissionGate permissions={['viewCVO']}>
    

            {/*<LeftMenu setSidebar={setSidebar} sidebar={sidebar} />*/}

            <ModalAddItem
            showModalAddItem={showModalAddItem}
            setShowModalAddItem={setShowModalAddItem}
            items={items}
            setItems={setItems}
            itemToEdit={itemToEdit}
            setItemToEdit={setItemToEdit}
            type={type}            
            />

            <Teste sidebar={sidebar}>
                <div className="m-5" style={{width:'98%'}}>
                    {/*<form onSubmit={handleSubmitButton}>*/}
                    <form onSubmit={formik.handleSubmit} style={{marginTop:"-30px"}}>
                    
                        
                        {/* <div className="form-header" style={{marginBottom:"0px", width:"50%"}}>
                            <div className="title">
                                <h1>Cadastrar Orçamento</h1>
                            </div>
                        </div>  */}

                        <div style={{display:"flex", /*justifyContent:"right", height:"80px"*/}}>
                            <div className="form-header" style={{marginBottom:"0px", width:"50%"}}>
                                <div className="title">
                                    <h1 style={{display:type==='cadastro'?'':'none'}}>Cadastrar Orçamento</h1>
                                    <h1 style={{display:type==='edicao'?'':'none'}}>Editar Orçamento</h1>
                                    <h1 style={{display:type==='view'?'':'none'}}>Visualizar Orçamento</h1>
                                </div>
                            </div> 

                            <div className="div-buttons" style={{width:"50%", justifyContent:"right"}}>
                                {type==='view'? null :
                                <div className="continue-button">                           
                                    <button type="submit">
                                        <AiIcons.AiOutlineSave style={{marginRight: "5px",fontSize: "22px",marginBottom: "2px"}}/><a>Salvar</a> 
                                    </button>                            
                                </div>
                                }
                                <div className="back-button">                                                       
                                    <button onClick={handleBack}>
                                        <SlIcons.SlActionUndo style={{color:"#ff4c4c",marginRight: "5px",fontSize: "18px",marginBottom: "2px"}}/><a >Voltar</a> 
                                    </button>
                                </div>
                            </div>

                        </div>
                        

                      
                        <div className="input-group">                                                                                                                         
                                   
                            
                            {/* // Exemplo de como usar o componente de calendário
                            <div className="input-box" ref={dataEmissaoRef} style={{width: sidebar?"15rem":"20rem", transition:sidebar?"200ms":"100ms"}}>   
                                <label>Data Emissão<span style={{color:"red"}}>*</span></label>
                                <input
                                //mask="99/99/9999"
                                readOnly={true}
                                placeholder="Selecione a data de emissão"
                                id="data_emissao"
                                type="data_emissao"
                                name="data_emissao"
                                onFocus={handleDataEmissaoCalendar}
                                value={dataEmissaoCalendar ===null ? '' : moment(dataEmissaoCalendar).format('DD/MM/YYYY')}
                                />
                                {formik.errors.data_emissao && (
                                    <div className="error">{formik.errors.data_emissao}</div>
                                )}
                            <Calendar className={showDataEmissaoCalendar?'open':''} onChange={(value, event) => handleSelectDataEmissao(value)} value={dataEmissaoCalendar}/> 
                            </div>
                            */}

                            <div className="input-box" style={{width: "32%", transition:sidebar?"100ms":"150ms"}}>   
                                <label>Data de Emissão<span style={{color:"red"}}>*</span></label>
                                <input
                                //mask="99/99/9999"
                                readOnly={true}
                                disabled={true}
                                placeholder="Selecione a data de emissão"
                                id="data_emissao"
                                type="data_emissao"
                                name="data_emissao"
                                value={moment(data_emissao).format('DD/MM/YYYY')}
                                />
                                {formik.errors.data_emissao && (
                                    <div className="error">{formik.errors.data_emissao}</div>
                                )}                             
                            </div>                            

                            
                            <div className="input-box" style={{width:"32%", transition:sidebar?"100ms":"150ms"}}>
                                <label>Cliente<span style={{color:"red"}}>*</span></label>
                                {/*<!-- Custom select structure --> */}
                                <div className="selectdiv-teste" style={{width:"100%", maxWidth:"100%"}}>
                                <label style={{width:"100%"}}>
                                <AsyncSelect                                 
                                    isDisabled={type==='view'?true:false}
                                    cacheOptions
                                    components={{DropdownIndicator}}
                                    loadOptions={callApi}
                                    onChange={(data) => {
                                    const dadosCliente = {
                                        value: data.value,
                                        label: addZeroes(data.value),
                                    };
                                    console.log(dadosCliente);
                                    setselectDataCliente(dadosCliente);
                                    setNomeCliente(data.label.split(" - ")[2]);
                                    }}
                                    value={selectDataCliente}
                                    defaultOptions
                                    id="cliente"
                                    type="cliente"
                                    name="cliente" 
                                    className="asyncSelectInput"                                       
                                    styles={{
                                        control: (baseStyles, state) => ({
                                            ...baseStyles,                                                
                                            margin:"0.6rem 0",
                                            paddingLeft: "2.5rem",
                                            height: "45px",
                                            border:"none",
                                            outline: state.isFocused?'1px solid #4070f4;':'0',
                                            borderRadius: "10px",
                                            boxShadow: "1px 1px 6px lightgray",
                                            fontSize: "0.8rem",    
                                            backgroundColor: "white",                                      
                                        }),
                                        input:(style) => ({
                                            ...style,
                                            color:"black",
                                        }),
                                        singleValue: (baseStyles) => ({
                                            ...baseStyles,
                                            color:selectDataCliente.value && type!=='view'?"black":"gray",
                                        }),
                                        valueContainer: (baseStyles) => ({
                                            ...baseStyles,
                                            fontWeight: "lighter",
                                        }),
                                        option: (styles) => {
                                            return {
                                            ...styles,
                                            fontWeight: "lighter", 
                                            color: "black",                                               
                                            };
                                        },                                           
                                    }}
                                />                                        
                                    {formik.errors.cliente && (
                                    <div className="error" style={{fontWeight: "lighter"}}>{formik.errors.cliente}</div>
                                    )}
                                </label>
                                </div>
                                {/*<!-- Custom select structure --> */}
                            </div>  


                            <div className="input-box" style={{width: "32%", transition:sidebar?"100ms":"150ms"}}>   
                                <label>Nome do Cliente<span style={{color:"red"}}>*</span></label>
                                <input
                                //mask="99/99/9999"
                                readOnly={true}
                                disabled={true}
                                placeholder=""
                                id="nome_cliente"
                                type="nome_cliente"
                                name="nome_cliente"
                                value={nome_cliente}
                                />
                                {formik.errors.nome_cliente && (
                                    <div className="error">{formik.errors.nome_cliente}</div>
                                )}                             
                            </div>

                            <div className="input-box" style={{width:"32%", transition:sidebar?"100ms":"150ms"}}>
                                <label>Vendedor<span style={{color:"red"}}>*</span></label>
                                {/*<!-- Custom select structure --> */}
                                <div className="selectdiv-teste" style={{width:"100%", maxWidth:"100%"}}>
                                <label style={{width:"100%"}}>
                                <AsyncSelect
                                    isDisabled={type==='view'?true:false}                                                                  
                                    cacheOptions
                                    components={{DropdownIndicator}}
                                    loadOptions={callApiVendedor}
                                    onChange={(data) => {
                                    const dadosVendedor = {
                                        value: data.value,
                                        label: addZeroes(data.value),
                                    };
                                    setselectDataVendedor(dadosVendedor);                                    
                                    setNomeVendedor(data.label.split(" - ")[2]);
                                    }}
                                    value={selectDataVendedor}
                                    defaultOptions
                                    id="vendedor"
                                    type="vendedor"
                                    name="vendedor"  
                                    className="asyncSelectInput"                                      
                                    styles={{
                                        control: (baseStyles, state, className) => ({
                                            ...baseStyles,                                                
                                            margin:"0.6rem 0",
                                            paddingLeft: "2.5rem",
                                            height: "45px",
                                            border:"none",
                                            outline: state.isFocused?'1px solid #4070f4;':'0',
                                            borderRadius: "10px",
                                            boxShadow: "1px 1px 6px lightgray",
                                            fontSize: "0.8rem",           
                                            backgroundColor: "white",                                                                 
                                        }),
                                        input:(style) => ({
                                            ...style,
                                            color:"black",
                                        }),
                                        singleValue: (baseStyles) => ({
                                            ...baseStyles,
                                            color:selectDataVendedor.value && type!=='view'?"black":"gray",
                                        }),
                                        valueContainer: (baseStyles) => ({
                                            ...baseStyles,
                                            fontWeight: "lighter",
                                        }),
                                        option: (styles) => {
                                            return {
                                            ...styles,
                                            fontWeight: "lighter", 
                                            color: "black",                                               
                                            };
                                        },                                           
                                    }}
                                />                                        
                                    {formik.errors.vendedor && (
                                    <div className="error" style={{fontWeight: "lighter"}}>{formik.errors.vendedor}</div>
                                    )}
                                </label>
                                </div>
                                {/*<!-- Custom select structure --> */}
                            </div>  


                            <div className="input-box" style={{width: "32%", transition:sidebar?"100ms":"150ms"}}>   
                                <label>Nome do Vendedor<span style={{color:"red"}}>*</span></label>
                                <input
                                //mask="99/99/9999"
                                readOnly={true}
                                disabled={true}
                                placeholder=""
                                id="nome_vendedor"
                                type="nome_vendedor"
                                name="nome_vendedor"
                                value={nome_vendedor}
                                />
                                {formik.errors.nome_vendedor && (
                                    <div className="error">{formik.errors.nome_vendedor}</div>
                                )}                             
                            </div>
                                                    
                            
                            <div className="input-box" style={{width: "32%", transition:sidebar?"100ms":"150ms"}}>
                                <label htmlFor="total_orc">Total Orçamento<span style={{color:"red"}}>*</span></label>
                                <CurrencyInput
                                id="total_orc"
                                type="total_orc"
                                name="total_orc"
                                placeholder="R$ 0,00"   
                                decimalsLimit={2}                       
                                intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
                                value={obtemTotalOrcamento()}
                                //fixedDecimalLength={2}
                                //defaultValue={0,00}
                                //decimalsLimit={2}
                                readOnly
                                disabled
                                //onValueChange={(value, name) => handleTotalOrc(value, name)}
                                />
                                {formik.errors.total_orc && (
                                    <div className="error" /*style={{fontWeight: "lighter"}}*/>{formik.errors.total_orc}</div>
                                )}
                            </div>
                       
                            <div className="div-buttons" style={{width:"100%", justifyContent:"right", marginTop:'-25px', marginBottom:'-10px'}}>
                                <div onClick={handleAddItem}  className="additem-button" style={{display:type==='view'?'none':''}}>                           
                                    <button type="button">                                        
                                        <AiIcons.AiFillPlusCircle style={{marginRight: "5px",fontSize: "22px",marginBottom: "2px"}}/><a>Adicionar Item</a> 
                                    </button>                            
                                </div>

                                {/* <div className="back-button">                                                       
                                    <button onClick={handleBack}>
                                        <SlIcons.SlActionUndo style={{color:"#ff4c4c",marginRight: "5px",fontSize: "18px",marginBottom: "2px"}}/><a >Voltar</a> 
                                    </button>
                                </div> */}
                            </div>


                        </div>

                    </form>

                    {/* {items.length===0 ?  */}
                    <DivEmptyItem className={"table"} style={{display:items.length===0?'':'none'}}>
                        <label style={{background: 'white'}}>Nenum item adicionado. Para adicionar itens clique no botão "</label>
                        <AiIcons.AiFillPlusCircle style={{marginRight: "5px",fontSize: "22px",marginBottom: "2px"}}/>
                        <label style={{background: 'white'}}>Adicionar item"</label>
                    </DivEmptyItem>
                    {/* : */}
                    <div className={"table"} id="tabela" style={{background:"white", marginTop:'10px', whiteSpace:"nowrap", display:items.length>0?'':'none'}}>                        
                        <table className={"table item"} style={{marginBottom:'4rem'}} >
                            <thead>
                                <tr>                                                                        
                                    <th scope="col" style={{borderRadius:'15px 0 0 0', width:'5%'}}><label>Código</label></th> 
                                    <th scope="col" style={{width:'15%'}}><label>Item</label></th>
                                    <th scope="col"><label>Descrição</label></th>                                       
                                    <th scope="col" style={{width:'20%'}}><label>Modelo</label></th>
                                    <th scope="col" style={{width:'5%'}}><label>Quantidade</label></th>
                                    <th scope="col" style={{width:'10%'}}><label>Preço Unitário</label></th>                                                                      
                                    <th scope="col" style={{width:'10%'}}><label>Preço Total</label></th>
                                    <th scope="col" style={{padding:'0.3rem 0rem', borderRight:'none', borderRadius:'0 15px 0 0'}}><label>Ações</label></th>                                   
                                    {/* <th scope="col"><label>Acionamento</label></th>  
                                    <th scope="col"><label>Grupo</label></th>
                                    <th scope="col"><label>Tecido</label></th>
                                    <th scope="col"><label>Coleção</label></th>
                                    <th scope="col"><label>Cor do Perfil</label></th>
                                    <th scope="col"><label>Motor</label></th>  
                                    <th scope="col"><label>Largura</label></th>
                                    <th scope="col"><label>Altura</label></th>
                                    <th scope="col"><label>Altura do Comando</label></th>
                                    <th scope="col"><label>Lado do Comando</label></th>
                                    <th scope="col"style={{padding:'0.3rem 15rem'}}><label>Observação</label></th>
                                    <th scope="col" style={{border:'none'}}><label>Lista de Acessórios</label></th> */}
                                                               
                                </tr>
                            </thead>
                                                        
                            <tbody>          
                              
                            {items.map((item) => (
                                <tr key={item.codigo}>
                                    
                                    <td><input value={item.codigo} readOnly disabled/></td>

                                    <td><input value={item.item} 
                                        readOnly={type==='view'?true:false}
                                        disabled={type==='view'?true:false}
                                        onChange={ e => {
                                        //(e) => item.item = e.target.value ////    Changing the value
                                        let resultArrayEdited = items.reduce((acc, o) => {
                                            let obj = item.codigo.includes(o.codigo) ? Object.assign(o, { 
                                                item: e.target.value
                                            }) : o;                                        
                                            acc.push(obj);                                        
                                            return acc;                                        
                                        }, []);
                                        setItems(resultArrayEdited);
                                        } 
                                    }
                                    />                                    
                                    </td>

                                    <td><input value={item.descricao} 
                                        readOnly={type==='view'?true:false}
                                        disabled={type==='view'?true:false}
                                        onChange={ e => {
                                        //item.descricao = e.target.value ////    Changing the value
                                        let resultArrayEdited = items.reduce((acc, o) => {
                                            let obj = item.codigo.includes(o.codigo) ? Object.assign(o, { 
                                                descricao: e.target.value
                                            }) : o;                                        
                                            acc.push(obj);                                        
                                            return acc;                                        
                                        }, []);
                                        setItems(resultArrayEdited);
                                        }
                                    }
                                    />
                                    </td>

                                    <td><input defaultValue={item.modelo} 
                                        readOnly={type==='view'?true:false}
                                        disabled={type==='view'?true:false}
                                        onChange={e => { 
                                        //item.modelo = e.target.value}/></td> ////    Changing the value
                                        let resultArrayEdited = items.reduce((acc, o) => {
                                            let obj = item.codigo.includes(o.codigo) ? Object.assign(o, { 
                                                modelo: e.target.value
                                            }) : o;                                        
                                            acc.push(obj);                                 
                                            return acc;                                        
                                        }, []);
                                        setItems(resultArrayEdited);
                                        }
                                    }
                                    />
                                    </td>

                                    <td>
                                        <CurrencyInput decimalsLimit={2} value={item.quantidade} readOnly disabled
                                        /*onValueChange={(value, name) => { 
                                            if(value){            
                                                item.quantidade = parseFloat(value.replace(',','.')); 
                                                item.preco_total = parseFloat(value.replace(',','.'))*item.preco_unitario;
                                                setRefreshTotalItem(!refreshTotalItem)
                                            } 
                                            else{
                                                item.quantidade = "";
                                                setRefreshTotalItem(!refreshTotalItem)
                                            }                                            
                                        } } */                              
                                        />
                                    </td>
                                    <td>
                                        <CurrencyInput intlConfig={{ locale: 'pt-BR', currency: 'BRL' }} value={item.preco_unitario.toFixed(2)} readOnly disabled
                                        /*onValueChange={(value, name) => {
                                            if(value){   
                                                item.preco_unitario = parseFloat(value.replace(',','.'));
                                                item.preco_total = parseFloat(value.replace(',','.'))*item.quantidade;
                                                setRefreshTotalItem(!refreshTotalItem)
                                            }
                                            else{
                                                item.preco_unitario = "";
                                                setRefreshTotalItem(!refreshTotalItem)
                                            }
                                        } }*/                                          
                                        />
                                    </td>
                                    <td><CurrencyInput intlConfig={{ locale: 'pt-BR', currency: 'BRL' }} value={item.preco_total.toFixed(2)} readOnly disabled/></td>
                                    {/* <td><input defaultValue={item.acionamento} onChange={(e) => item.acionamento = e.target.value}/></td>
                                    <td><input defaultValue={item.grupo} onChange={(e) => item.grupo = e.target.value}/></td>
                                    <td><input defaultValue={item.tecido} onChange={(e) => item.tecido = e.target.value}/></td>
                                    <td><input defaultValue={item.colecao} onChange={(e) => item.colecao = e.target.value}/></td>
                                    <td><input defaultValue={item.cor_perfil} onChange={(e) => item.cor_perfil = e.target.value}/></td>
                                    <td><input defaultValue={item.motor} onChange={(e) => item.motor = e.target.value}/></td>
                                    <td><input defaultValue={item.largura} onChange={(e) => item.largura = e.target.value}/></td>
                                    <td><input defaultValue={item.altura} onChange={(e) => item.altura = e.target.value}/></td>
                                    <td><input defaultValue={item.altura_comando} onChange={(e) => item.altura_comando = e.target.value}/></td>
                                    <td><input defaultValue={item.lado_comando} onChange={(e) => item.lado_comando = e.target.value}/></td>
                                    <td><input defaultValue={item.observacao} onChange={(e) => item.observacao = e.target.value}/></td>
                                    <td><input defaultValue={item.lista_acessorios} onChange={(e) => item.lista_acessorios = e.target.value}/></td> */}
                                    {
                                    type==='view' ? 
                                    <td className="menuItemOpt" style={{width:'40px', position:"relative"}}>                                    
                                        <a className="btn btn-sm btn-primary" style={{marginLeft:"10%", height:'25px'}} onClick={e => handleMenuItemOpt(item.codigo)}>
                                            <FiIcons.FiMenu style={{color:"white"}} strokeWidth='3'/>
                                        </a>
                                        {
                                        showItemOpt===item.codigo ?
                                        <ModalItemOptions ref={userOptionsRef} id={item.codigo} showItemOpt={showItemOpt}>                                                                                
                                            <li onClick={e => handleEditItem(item.codigo)} style={{cursor:"pointer"}}><a>Visualizar</a></li>                                      
                                        </ModalItemOptions>
                                        : null
                                        }     
                                    </td>
                                    :
                                    <td className="menuItemOpt" style={{width:'40px', position:"relative"}}>
                                    
                                        <a className="btn btn-sm btn-primary" style={{marginLeft:"10%", height:'25px'}} onClick={e => handleMenuItemOpt(item.codigo)}>
                                            <FiIcons.FiMenu style={{color:"white"}} strokeWidth='3'/>
                                        </a>
                                        {
                                        showItemOpt===item.codigo ?
                                        <ModalItemOptions ref={userOptionsRef} id={item.codigo} showItemOpt={showItemOpt}>                                                                                
                                            <li onClick={e => handleEditItem(item.codigo)} style={{cursor:"pointer"}}><a>Editar</a></li>                  
                                            <li onClick={e => handleDelete(item.codigo)} style={{cursor:"pointer"}}><a>Excluir</a></li>                                        
                                        </ModalItemOptions>
                                        : null
                                        }                                 
                                        {/* <a className="btn btn-sm btn-danger" style={{marginLeft:"13%", height:'24px'}} onClick={e => handleDelete(item.codigo)}>
                                            <FiIcons.FiTrash2 style={{color:"white"}}/>
                                        </a> */}
                                    </td> 
                                    }    
                                  
                                </tr>
                            ))}
                            
                                {/*
                                <tr>
                    
                                    <td><input readOnly/></td>     
                                    <td><input readOnly/></td>    
                                    <td><input readOnly/></td>   
                                    
                                    <td><input value={''}/></td>   
                                    <td><input value={''}/></td>   
                                    <td><input value={''}/></td>   
                                    <td><input value={''}/></td>   
                                    <td><input value={''}/></td>   
                                    <td><input value={''}/></td>   
                                    <td><input value={''}/></td>   
                                    <td><input value={''}/></td>   
                                    <td><input value={''}/></td>   
                                    <td><input value={''}/></td>   
                                    <td><input value={''}/></td>   
                                    <td><input value={''}/></td>   
                                    <td><input value={''}/></td>   
                                    <td><input value={''}/></td>   
                                    <td><input value={''}/></td>   
                                    <td><input value={''}/></td>   
                                   
                                
                                </tr>    
                                */}                                                                        
                            </tbody>

                        </table>
                        
                    </div>
                    {/* } */}

                </div>
            </Teste>
                
        </PermissionGate>       
        </>
        

        );
    };
    
    export default Orcamento;