import React, { useState, useContext, useEffect } from "react";
import './style.css'
import styled from 'styled-components';
import InputMask from 'react-input-mask'
import cep from 'cep-promise'
import CryptoJS from 'crypto-js';
import { AuthContext } from "../../contexts/auth";
import { SidebarContext } from "../../AppRoutes";
import { registerPlanoAdmin } from "../../services/api";

import PermissionGate from "../../services/PermissionGate";

import { useNavigate } from "react-router-dom";
import LeftMenu from "../../components/LeftMenu";

import { useFormik } from "formik";
import * as Yup from "yup";

import * as AiIcons from 'react-icons/ai'
import * as SlIcons from 'react-icons/sl'
import { BsPlusCircle } from "react-icons/bs";
import { FaTrash } from 'react-icons/fa'

import { toast } from "react-toastify";

const Teste = styled.div`

    display: flex;
    margin-left:  ${({ sidebar }) => (sidebar ? '250px' : '100px')}; 
    height:auto;
    width:auto;
    transition: 150ms;
    background-color:rgb(247,247,247)!important;
    overflow: initial;
    z-Index: 9;

    @media (max-width: 880px) {
        margin-left: 0;
    }
`;

const options = [
    { value: "", label: "Selecione a recorrência" },
    { value: "WEEKLY", label: "Semanal" },
    { value: "MONTHLY", label: "Mensal" },
    { value: "YEARLY", label: "Anual" },
    //{ value: "especifico", label: "Plano personalizado" }
];

const PlanosAdmin = () => {
    const secretKey = 'my-custom-secret-key:1af0addcce4b56f753ed10c285667ad031bae57af19747f576b05312b8cf85be';
    const userEncrypted = localStorage.getItem('user')
    const user = CryptoJS.AES.decrypt(userEncrypted, secretKey).toString(CryptoJS.enc.Utf8);
    const userParse = JSON.parse(user)
    const userID = userParse._id;
    const userName = userParse.name;
    const userEmail = userParse.email;
    var userImg = null;
    //console.log(empresa)
    const empresa = localStorage.getItem('empresa')
    const empresaParse = JSON.parse(empresa)
    const idEmpresa = empresaParse.id_empresa;
    const { sidebar, setSidebar } = useContext(SidebarContext);; //TESTANDO CONST NO AppROUTES

    const [selectedOption, setSelectedOption] = useState("");
    const [selectedDays, setSelectedDays] = useState([]);
    const [startTime, setStartTime] = useState("");
    const [endTime, setEndTime] = useState("");
    const [diasHorarios, setDiasHorarios] = useState([
        {
            dias: new Set(),
            startTime: "00:00",
            endTime: "23:59",
        }
    ]);

    //Validações
    const SignUpSchema = Yup.object().shape({
        title: Yup.string().required("Obrigatório"),
        plan_identifier: Yup.string().required("Obrigatório"),
        plan_cycle: selectedOption === "" && Yup.string().required("Obrigatório"),
        //tempo_duracao: Yup.string().required("Obrigatório"),
        valor_plano: Yup.number().required("Obrigatório"), // Garantir que seja um número
        order: Yup.string().required("Obrigatório"),
        //valor_anual: selectedOption==="full" && Yup.string().required("Obrigatório"),
    });

    const formik = useFormik({
        validationSchema: SignUpSchema,
        validateOnBlur: false,
        validateOnChange: false,
        initialValues: {
            title: "",
            plan_identifier: "",
            maxPayments: "",
            //tempo_duracao: "",
            valor_plano: 0,
            order: "",
        },
        onSubmit: (values) => {
            //alert(JSON.stringify(values, null, 2));
            console.log(values);

            const title = values.title;
            const plan_identifier = values.plan_identifier;
            const order = values.order;
            const plan_cycle = selectedOption;
            const maxPayments = values.maxPayments;
            //const tempo_duracao = values.tempo_duracao;
            const valor_plano = Number(values.valor_plano);

            /*if(selectedOption==="free_trial"){
              tempo_duracao = values.tempo_duracao;
              valor_mensal = 0;
              valor_anual = 0;
            } else if(selectedOption==="full"){
              valor_mensal = parseFloat(values.valor_mensal);
              valor_anual = parseFloat(values.valor_anual);
            }*/
            handleSubmitAPI(title, plan_identifier, order, plan_cycle, maxPayments, valor_plano);

        },
    });

    const navigate = useNavigate();

    const handleBack = () => {
        //setSidebar(!sidebar)
        navigate("/list-plans-admin");
    }


    const handleSubmitAPI = (title, plan_identifier, order, plan_cycle, maxPayments, valor_plano) => {
        //e.preventDefault();

        const createdBy = userID;
        const nome = title;
        //Registrando o plano
        console.log("submit plano!", { nome, plan_identifier, order, plan_cycle, maxPayments, valor_plano });
        registerPlanoAdmin(createdBy, nome, plan_identifier, order, plan_cycle, maxPayments, valor_plano).then(cast => {

            if (cast.status === 201) {
                toast(cast.data.msg, { autoClose: 5000, type: "success" });
                navigate("/list-plans-admin");
            }
            else {
                toast(cast.data.msg, { autoClose: 5000, type: "error" });
            }

        }).catch(err => {
            console.log('Oh noooo!!');
            const msg = err.response.data.msg;
            //console.log("MENSAGEM", msg)
            toast(msg, { autoClose: 5000, type: "error" });

        });

    }

    //const [horariosToDelete, setHorariosToDelete] = useState("");
    const handleDeleteHorario = (horarioIndex) => {
        setDiasHorarios((prevDiasHorarios) => {
            const newDiasHorarios = [...prevDiasHorarios];
            newDiasHorarios.splice(horarioIndex, 1);
            return newDiasHorarios;
        });
    };

    // Função para formatar e atualizar o valor no Formik
    const handleCurrencyChange = (e, formik) => {
        const inputValue = e.target.value;

        // Remove tudo que não for número
        const rawValue = inputValue.replace(/\D/g, ""); // Apenas números

        // Se o valor for vazio, seta 0 reais no Formik e no campo
        if (rawValue.length === 0) {
            formik.setFieldValue("valor_plano", 0);
            e.target.value = "R$ 0,00";
            return;
        }

        // Converte o valor bruto para reais com 2 casas decimais
        const formattedValue = (Number(rawValue) / 100).toLocaleString("pt-BR", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });

        // Atualiza o campo com o valor formatado
        e.target.value = `R$ ${formattedValue}`;

        // Atualiza o Formik com o valor bruto em reais
        formik.setFieldValue("valor_plano", Number(rawValue) / 100);
    };


    const handleOptionChange = (event) => {
        /*if (event.target.value==="sempre"||event.target.value==="pausado"){
            setDiasHorarios([
                {
                  dias: new Set([""]),
                  startTime: "00:00",
                  endTime: "23:59",
                }
            ]);
        }*/
        setSelectedOption(event.target.value);
    };

    const horariosPorDia = diasHorarios.reduce((acc, { dias, startTime, endTime }) => {
        dias.forEach((dia) => {
            if (!acc[dia]) {
                acc[dia] = {
                    dia,
                    startTime,
                    endTime,
                };
            } else {
                const { startTime: startTimeAcc, endTime: endTimeAcc } = acc[dia];
                const [startTimeHour, startTimeMin] = startTime.split(':').map(Number);
                const [startTimeAccHour, startTimeAccMin] = startTimeAcc.split(':').map(Number);
                const [endTimeHour, endTimeMin] = endTime.split(':').map(Number);
                const [endTimeAccHour, endTimeAccMin] = endTimeAcc.split(':').map(Number);

                if (startTimeHour < startTimeAccHour || (startTimeHour === startTimeAccHour && startTimeMin < startTimeAccMin)) {
                    acc[dia].startTime = startTime;
                }

                if (endTimeAccHour < endTimeHour || (endTimeAccHour === endTimeHour && endTimeAccMin < endTimeMin)) {
                    acc[dia].endTime = endTime;
                }
            }
        });

        return acc;
    }, {});

    const horariosUnicos = Object.values(horariosPorDia);

    const handleDayChange = (horarioIndex, key) => {
        const newDiasHorarios = [...diasHorarios];
        const horario = newDiasHorarios[horarioIndex];

        if (horario.dias.has(key)) {
            horario.dias.delete(key);
        } else {
            horario.dias.add(key);
        }

        setDiasHorarios(newDiasHorarios);
    };

    const validateTime = (value) => {
        const regex = /^([0-1][0-9]|2[0-3]):[0-5][0-9]$/;
        return regex.test(value);
    };
    const handleTimeChange = (horarioIndex, setTime, event) => {
        const value = event.target.value;
        if (validateTime(value)) {
            const newHorarios = [...diasHorarios];
            newHorarios[horarioIndex][setTime] = value;
            setDiasHorarios(newHorarios);
        }
    };

    const handleAddHorario = () => {
        setDiasHorarios(diasHorarios.concat({
            dias: new Set(),
            startTime: "00:00",
            endTime: "23:59",
        }));
    };

    const isDayAlreadySelectedInHorario = (day, currentHorarioIndex) => {
        return diasHorarios
            .filter((horario, index) => index !== currentHorarioIndex)
            .some((horario) => horario.dias.has(day));
    };


    return (

        <>
            <PermissionGate permissions={['AdminOnly']}>


                {/*<LeftMenu setSidebar={setSidebar} sidebar={sidebar} />*/}

                <Teste sidebar={sidebar}>
                    <div className="m-5">
                        {/*<form onSubmit={handleSubmitButton}>*/}
                        <form onSubmit={formik.handleSubmit} style={{ marginTop: "-30px" }}>

                            <div className="form-header" style={{ marginBottom: "0px" }}>
                                <div className="title">
                                    <h1>Adicionar Planos</h1>
                                </div>
                            </div>

                            <div style={{ display: "flex", justifyContent: "right"/*, height:"80px"*/ }}>

                                <div className="div-buttons">
                                    <div className="continue-button">
                                        <button type="submit">
                                            <AiIcons.AiOutlineSave style={{ marginRight: "5px", fontSize: "22px", marginBottom: "2px" }} /><a>Salvar</a>
                                        </button>
                                    </div>

                                    <div className="back-button">
                                        <button onClick={handleBack}>
                                            <SlIcons.SlActionUndo style={{ color: "#ff4c4c", marginRight: "5px", fontSize: "18px", marginBottom: "2px" }} /><a >Voltar</a>
                                        </button>
                                    </div>
                                </div>

                            </div>


                            <div className="input-group">

                                <div className="rowAddPlanos">

                                    <div className="input-box" style={{ width: "37%", transition: sidebar ? "200ms" : "100ms" }}>
                                        <label>Nome do Plano<span style={{ color: "red" }}>*</span></label>
                                        <input
                                            placeholder="Digite o nome do plano"
                                            id="title"
                                            type="title"
                                            name="title"
                                            onChange={formik.handleChange}
                                            value={formik.values.title}
                                        />
                                        {formik.errors.title && (
                                            <div className="error">{formik.errors.title}</div>
                                        )}
                                    </div>

                                    <div className="input-box" style={{ width: "37%", transition: sidebar ? "200ms" : "100ms" }}>
                                        <label>Identificador do Plano<span style={{ color: "red" }}>*</span></label>
                                        <input
                                            placeholder="Ex: free_trial"
                                            id="plan_identifier"
                                            type="plan_identifier"
                                            name="plan_identifier"
                                            onChange={formik.handleChange}
                                            value={formik.values.plan_identifier}
                                        />
                                        {formik.errors.plan_identifier && (
                                            <div className="error">{formik.errors.plan_identifier}</div>
                                        )}
                                    </div>

                                    <div className="input-box" style={{ width: "22%", transition: sidebar ? "200ms" : "100ms" }}>
                                        <label>Ordem<span style={{ color: "red" }}>*</span></label>
                                        <input
                                            placeholder="Ex: 1"
                                            id="order"
                                            type="number"
                                            name="order"
                                            onChange={formik.handleChange}
                                            value={formik.values.order}
                                        />
                                        {formik.errors.order && (
                                            <div className="error">{formik.errors.order}</div>
                                        )}
                                    </div>
                                </div>


                                <div className="rowAddPlanos">

                                    <div className="disponibilidadeCategoria" style={{ width: "32%", transition: sidebar ? "200ms" : "100ms", marginLeft: 0 }}>
                                        <div className="contentHeaderDisponibilidade">
                                            <h1 className="disponibilidadeH1">Ciclo do Plano<span style={{ color: "red" }}>*</span></h1>
                                            <div className="selectdiv disp">
                                                <label style={{ width: "100%" }}>
                                                    <select value={selectedOption} onChange={(e) => {
                                                        handleOptionChange(e);
                                                        formik.setFieldValue("plan_cycle", selectedOption);
                                                        if (e.target.value === "") {
                                                            setSelectedOption(e.target.value);
                                                            formik.setFieldValue("plan_cycle", "");
                                                            console.log("OQ TEM AQUI", e.target.value)
                                                        }
                                                    }}>
                                                        {/*<option value="">Selecione a recorrência</option>*/}
                                                        {options.map((option) => (
                                                            <option key={option.value} value={option.value}>
                                                                {option.label}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    {formik.errors.plan_cycle && (
                                                        <div className="error">{formik.errors.plan_cycle}</div>
                                                    )}
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="input-box" style={{ width: "32%", transition: sidebar ? "200ms" : "100ms" }}>
                                        <label>{`Numero Máximo de cobranças`}</label>
                                        <input
                                            placeholder="Deixe em branco para ilimitado"
                                            id="maxPayments"
                                            type="number"
                                            name="maxPayments"
                                            onChange={formik.handleChange}
                                            value={formik.values.maxPayments}
                                        />
                                        {formik.errors.maxPayments && (
                                            <div className="error">{formik.errors.maxPayments}</div>
                                        )}
                                    </div>

                                    {/*<div className="input-box" style={{width: "22%", transition:sidebar?"200ms":"100ms"}}>
                                    <label>{`Recorrência (${selectedOption===""?`Definir Ciclo`:selectedOption==="weeks"?`Em semanas`:`Em meses`})`}<span style={{color:"red"}}>*</span></label>
                                    <input 
                                    placeholder="Ex: 1" 
                                    id="tempo_duracao"
                                    type="number"
                                    name="tempo_duracao"
                                    onChange={formik.handleChange}
                                    value={formik.values.tempo_duracao}
                                    />
                                    {formik.errors.tempo_duracao && (
                                        <div className="error">{formik.errors.tempo_duracao}</div>
                                    )}
                                </div>*/}

                                    <div className="input-box" style={{ width: "32%", transition: sidebar ? "200ms" : "100ms" }}>
                                        <label>Valor R$<span style={{ color: "red" }}>*</span></label>
                                        <input
                                            placeholder="Ex: 150,00"
                                            id="valor_plano"
                                            type="text"
                                            name="valor_plano"
                                            onChange={(e) => handleCurrencyChange(e, formik)}
                                            value={
                                                formik.values.valor_plano >= 0
                                                    ? `R$ ${formik.values.valor_plano?.toFixed(2).replace(".", ",")}`
                                                    : ""
                                            }
                                        />
                                        {formik.errors.valor_plano && (
                                            <div className="error">{formik.errors.valor_plano}</div>
                                        )}
                                    </div>

                                </div>

                            </div>


                        </form>
                    </div>
                </Teste>

            </PermissionGate>
        </>


    );
};

export default PlanosAdmin;