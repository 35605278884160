import React from "react";
import * as S from "./stylesUserProfile";
import { FiArrowLeft } from "react-icons/fi";
import userIcon from "../../assets/img/user-icon.png";

const UserProfile = ({ isOpen, onClose, user }) => {
  if (!isOpen) return null; // Não renderiza se não estiver aberto

  const formatPhoneNumber = (number) => {
    if (!number) return "Número indisponível";
  
    // Remove caracteres não numéricos
    const cleaned = number.replace(/\D/g, "");
  
    // Verifica se o número tem pelo menos 10 dígitos (código do país + DDD + número)
    if (cleaned.length < 10) return number; // Se for inválido, retorna o original
  
    const countryCode = cleaned.substring(0, 2); // Código do país (ex: 55)
    const areaCode = cleaned.substring(2, 4); // Código de área (ex: 62)
    
    // Verifica se o número tem 9 dígitos (ex: 98332-0252) ou 8 dígitos (ex: 8332-0252)
    const hasNineDigits = cleaned.length === 11;
    const firstPart = cleaned.substring(4, hasNineDigits ? 5 : 4); // Primeiro número (9 se houver)
    const middlePart = cleaned.substring(hasNineDigits ? 5 : 4, cleaned.length - 4); // Meio do número
    const lastPart = cleaned.substring(cleaned.length - 4); // Últimos 4 dígitos
  
    return `+${countryCode} ${areaCode} ${firstPart} ${middlePart}-${lastPart}`;
  };  

  const formattedNumber = formatPhoneNumber(user?.mobile_number);
  

  return (
    <S.Wrapper>
      <S.Header>
        <S.Back>
          <FiArrowLeft size={25} onClick={onClose} style={{ cursor: "pointer", marginRight: 10, color:'white' }} />
          <h3>Dados do Contato</h3>
        </S.Back>
      </S.Header>

      <S.UserImage>
        <img src={user?.channel_data?.whatsapp?.profile_picture || userIcon} alt="Foto de perfil do usuário" />
      </S.UserImage>

      <S.UserData>
        <span>Nome</span>
        <p>{user?.name || "Nome não disponível"}</p>
      </S.UserData>

      <S.UserData>
        <span>Número</span>
        <p>{formattedNumber}</p>
      </S.UserData>
    </S.Wrapper>
  );
};

export default UserProfile;
