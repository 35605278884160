import React, { useEffect, useState, useContext } from "react";
import Loading from 'react-loading';
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import LeftMenu from "../../components/LeftMenu";
import './styles.css';
import moment from 'moment';
import { useFormik } from "formik";
import * as Yup from "yup";
//import creditCardType from 'credit-card-type';
import valid from 'card-validator'; //import statement
import InputMask from 'react-input-mask'
import axios from 'axios';
import {
    getEmpresa,
    getFilPlansIugu,
    searchClienteAsaasAndGetInvoice,
    getAllInvoicesAsaas,
    criarAssinaturaAsaasBolPix,
    listarAssinaturasAsaas,
    getDataCustomerAsaas,
    getLastPendingInvoiceAsaas
} from "../../services/api";
import { SidebarContext } from "../../AppRoutes";
import { RiErrorWarningLine } from "react-icons/ri";
import { MdPix } from "react-icons/md";
import { FaBarcode } from "react-icons/fa6";
import { IoIosCard } from "react-icons/io";
import { toast } from "react-toastify";
import { useAsync } from "react-select/async";
import CryptoJS from 'crypto-js'
import Modal from "react-modal";
import { FaMoneyBillWave } from "react-icons/fa";


const Teste = styled.div`
  display: flex;
  margin-left:  ${({ sidebar }) => (sidebar ? '250px' : '100px')}; 
  height:auto;
  width:auto;
  transition: 150ms;
  background-color:rgb(247,247,247)!important;
  --background-color:white!important;
  overflow: initial;
  z-Index: 9;

  @media (max-width: 880px) {
        margin-left: 0;
    }
`;

const statusMap = {
    PENDING: "Pendente",
    PAID: "Paga",
    CANCELED: "Cancelada",
    IN_ANALYSIS: "Em Análise",
    DRAFT: "Rascunho",
    PARTIALLY_PAID: "Parcialmente Paga",
    REFUNDED: "Reembolsada",
    EXPIRED: "Expirada",
    IN_PROTEST: "Em Protesto",
    CHARGEBACK: "Contestada",
    EXTERNALLY_PAID: "Paga Externamente",
    RECEIVED: "Paga",
    CONFIRMED: "Confirmada",
    OVERDUE: "Atrasada",
    RECEIVED_IN_CASH: "Recebida em Dinheiro",
    REFUND_REQUESTED: "Reembolso Solicitado",
    REFUND_IN_PROGRESS: "Reembolso em Andamento",
    CHARGEBACK_REQUESTED: "Contestação Solicitada",
    CHARGEBACK_DISPUTE: "Disputa de Contestação",
    AWAITING_CHARGEBACK_REVERSAL: "Aguardando Reversão de Contestação",
    DUNNING_REQUESTED: "Cobrança Judicial Solicitada",
    DUNNING_RECEIVED: "Cobrança Judicial Recebida",
    AWAITING_RISK_ANALYSIS: "Aguardando Análise de Risco",
};

const InvoicesModal = ({ invoices, isOpen, onClose }) => {
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            contentLabel="Faturas Disponíveis"
            ariaHideApp={false}
            className="custom-invoices-modal"
            overlayClassName="custom-invoices-overlay"
        >
            <h2>Faturas Disponíveis</h2>
            <table className="custom-invoices-table">
                <thead>
                    <tr>
                        <th>Data de Vencimento</th>
                        <th>Status</th>
                        <th>Total</th>
                        <th>Ação</th>
                    </tr>
                </thead>
                <tbody>
                    {invoices?.map((invoice, index) => (
                        <tr key={index}>
                            <td>{moment(invoice?.dueDate).format("DD/MM/YYYY")}</td>
                            <td>{statusMap[invoice?.status] || "Status desconhecido"}</td>
                            {(invoice?.status === "OVERDUE" || invoice?.status === "PENDING") ?
                                <td>
                                    {((invoice?.value || 0) - (invoice?.discount?.value || 0))
                                        .toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}
                                </td>
                                :
                                <td>
                                    {((invoice?.value || 0))
                                        .toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}
                                </td>
                            }
                            <td>
                                {(invoice?.status === "OVERDUE" || invoice?.status === "PENDING") ? (
                                    <a
                                        href={invoice?.invoiceUrl}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="custom-invoices-pay-button"
                                    >
                                        <FaMoneyBillWave /> Pagar
                                    </a>
                                ) : (
                                    <button className="custom-invoices-pay-button custom-invoices-disabled-button" disabled>
                                        <FaMoneyBillWave /> Indisponível
                                    </button>
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <button onClick={onClose} className="custom-invoices-close-button">Fechar</button>
        </Modal>
    );
};

const Planos = () => {
    const navigate = useNavigate();
    const { sidebar, setSidebar } = useContext(SidebarContext);; //TESTANDO CONST NO AppROUTES
    const currentDay = moment().format('DD');

    const [payment_type, setPayment_type] = useState("");
    const [person_type, setPerson_type] = useState("cnpj");
    const [plan_cycle, setPlan_cycle] = useState("");
    const [plan_type, setPlan_type] = useState("silver"); //silver, gold, premium
    const [expirationDate, setExpirationDate] = useState(new Date());

    const [isFinalizando, setIsFinalizando] = useState(false);

    const secretKey = 'my-custom-secret-key:1af0addcce4b56f753ed10c285667ad031bae57af19747f576b05312b8cf85be';
    const userEncrypted = localStorage.getItem('user')
    const user = CryptoJS.AES.decrypt(userEncrypted, secretKey).toString(CryptoJS.enc.Utf8);
    const userParse = JSON.parse(user)
    const userID = userParse._id;
    const empresa = localStorage.getItem('empresa')
    const empresaParse = JSON.parse(empresa)
    const idEmpresa = empresaParse.id_empresa;
    const createdAt = empresaParse.createdAt;
    const cnpj = empresaParse.cnpj;
    const razao = empresaParse.razao;
    const nomeEmpresa = empresaParse.name;
    //console.log("createdAt::", createdAt);
    const [planos, setPlanos] = useState([]);
    const [priceToPay, setPriceToPay] = useState("");
    const [assinatura, setAssinatura] = useState({});

    const [seeAllPlans, setSeeAllPlans] = useState(false);
    const [loadingCustomerData, setLoadingCustomerData] = useState(false);
    const [invoiceData, setInvoiceData] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [invoices, setInvoices] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [clienteAsaas, setClienteAsaas] = useState(null);
    const [empresaFromDB, setEmpresaFromDB] = useState(null);
    const [loadingAssinaturas, setLoadingAssinaturas] = useState(true);
    const [isPromotional, setIsPromotional] = useState(false);

    useEffect(() => {
        (async () => {

            const response = await getFilPlansIugu(userID);

            setPlanos(response.data.planos.planos);
            console.log(response.data)
            //setLoading(false);
            //setRefresh(false);

        })();
    }, [refresh]);

    const handleDateChange = (date) => {
        setExpirationDate(date);
    };

    const [ip, setIp] = useState("");
    const getUserIp = async () => {
        const ip = await axios.get('https://api64.ipify.org?format=json');
        console.log(ip.data.ip);
        setIp(ip.data.ip)
    }

    useEffect(() => {
        getUserIp();
    }, [refresh]);

    useEffect(() => {
        const fetchAssinaturasAndInvoices = async () => {
            setLoadingAssinaturas(true); // Inicia o estado de carregamento
            try {
                const empresaTemp = await getEmpresa(userID, idEmpresa);
                setEmpresaFromDB(empresaTemp?.data.empresa);

                if (empresaTemp?.data.empresa.customer_asaas) {
                    await listarAssinaturas(empresaTemp?.data.empresa.customer_asaas);
                } else {
                    console.warn("customer_asaas não encontrado na empresa.");
                }
            } catch (error) {
                console.error("Erro ao buscar assinaturas ou invoices:", error);
            } finally {
                setLoadingAssinaturas(false); // Finaliza o estado de carregamento
            }
        };

        fetchAssinaturasAndInvoices();
    }, [refresh]);

    const listarAssinaturas = async (customer_asaas_id) => {
        try {
            const assinaturas = await listarAssinaturasAsaas(customer_asaas_id);
            console.log("Assinaturas>", assinaturas);

            const clienteAsaasTemp = await getDataCustomerAsaas(customer_asaas_id);
            console.log("Cliente Asaas>", clienteAsaasTemp);

            // Todas as faturas
            const allInvoicesTemp = assinaturas?.data?.paymentData?.data;
            console.log("All Invoices:::", allInvoicesTemp);

            let lastInvoiceTemp = null;

            if (allInvoicesTemp && allInvoicesTemp.length > 0) {
                // Filtra as faturas com status PENDING ou OVERDUE
                const filteredInvoices = allInvoicesTemp.filter(invoice =>
                    invoice.status === "OVERDUE" || invoice.status === "PENDING"
                );

                // Ordena as faturas com prioridade para OVERDUE
                const sortedInvoices = filteredInvoices.sort((a, b) => {
                    if (a.status === b.status) {
                        // Se os status forem iguais, ordena pela data mais recente
                        return new Date(b.dueDate) - new Date(a.dueDate);
                    }
                    // PRIORIDADE: OVERDUE > PENDING
                    return a.status === "OVERDUE" ? -1 : 1;
                });

                // O invoice mais recente com status prioritário
                lastInvoiceTemp = sortedInvoices[0];

                // Caso não encontre nenhuma fatura PENDING ou OVERDUE, pega a última fatura mais recente
                if (!lastInvoiceTemp) {
                    lastInvoiceTemp = allInvoicesTemp.sort((a, b) => {
                        return new Date(b.dueDate) - new Date(a.dueDate);
                    })[0];
                }
            }

            // Define o objeto de assinatura no estado
            setAssinatura(assinaturas?.data?.subscriptionData?.data[0]);
            setClienteAsaas(clienteAsaasTemp?.data);

            // Define o último invoice no estado
            if (lastInvoiceTemp) {
                console.log("lastInvoiceTemp::", lastInvoiceTemp);
                setInvoiceData(lastInvoiceTemp);
            } else {
                console.warn("Nenhuma fatura encontrada.");
            }

            // Define todas as faturas no estado
            if (allInvoicesTemp) {
                setInvoices(allInvoicesTemp);
            } else {
                console.warn("Nenhuma fatura encontrada para a assinatura.");
            }
        } catch (error) {
            console.error("Erro ao listar assinaturas ou faturas:", error);
        }
    };

    const handlePaymentType = (tipo_pagamento) => {
        if (tipo_pagamento === "CREDIT_CARD") {
            setPayment_type("CREDIT_CARD");
        } else if (tipo_pagamento === "BOLETO") {
            setPayment_type("BOLETO");
        } else if (tipo_pagamento === "PIX") {
            setPayment_type("PIX");
        }
    }

    const handlePersonType = (tipo_pessoa) => {
        if (tipo_pessoa === "cnpj") {
            setPerson_type("cnpj");
        } else if (tipo_pessoa === "cpf") {
            setPerson_type("cpf");
        }
    }

    const handleSeePlans = () => {
        //if(assinatura.externalReference !== "free_trial"){
        setSeeAllPlans(prev => !prev);
        //}
    }

    const handleOpenFaturas = () => {

    }

    const handleGetDataCustomer = async () => {
        try {
            setLoadingCustomerData(true);
            // Fazendo a requisição para obter os dados do cliente
            const response = await getDataCustomerAsaas(empresaFromDB?.customer_asaas);
            console.log("Reponse getDataCustomerAsaas:::", response.data);

            // Atualizando os valores dos campos do formulário com os dados recebidos
            if (response.data) {
                const result = response.data;

                // Atualizando os valores no Formik
                formik.setValues({
                    // Dependendo do tipo de pessoa, você pode configurar os campos de forma diferente
                    cpf_cnpj: result.cpfCnpj || "",
                    nomeOrRazao: result.name || "", // Aqui você pode mapear para o nome ou razão social
                    email: result.email || "",
                    cep: result.postalCode || "",
                    numero: result.addressNumber || "",
                    // Se precisar de outros campos, adicione-os aqui
                });

                // Atualizando o tipo de pessoa (CPF ou CNPJ)
                if (result.cpfCnpj && result.cpfCnpj.length > 11) {
                    setPerson_type("cnpj");
                } else {
                    setPerson_type("cpf");
                }
            } else {
                console.error("Dados do cliente não encontrados.");
            }
        } catch (error) {
            console.error("Erro ao obter dados do cliente:", error);
        } finally {
            setLoadingCustomerData(false);
        }
    }

    //Validações
    const SignUpSchema = Yup.object().shape({
        //tipo_pessoa: Yup.string().required("Campo obrigatório"),        
        cpf_cnpj: Yup.string().required("Campo obrigatório"),
        nomeOrRazao: Yup.string().required("Campo obrigatório"),
        email: Yup.string().email("Email inválido").required("Campo obrigatório"),
        cep: Yup.string().required("Campo obrigatório"),
        numero: Yup.string().required("Campo obrigatório"),
        //telefone: Yup.string().required("Campo obrigatório"),

        /*num_cartao: Yup.string()
        .test('test-number', // this is used internally by yup
        'Cartão de crédito invalido', //validation message
        value => valid.number(value).isValid) // return true false based on validation
        .required("Campo obrigatório"),  

        validade: Yup.string().required("Campo obrigatório"),

        cvv: Yup.string()
        .matches(/^[0-9]{3,4}$/, 'CVV inválido') 
        .required("Campo obrigatório")
        */

    });

    const formik = useFormik({
        validationSchema: SignUpSchema,
        validateOnBlur: false,
        validateOnChange: false,
        //enableReinitialize: true,
        initialValues: {
            tipo_pessoa: "",
            num_cartao: "",
            validade: "",
            cvv: "",
            cpf_cnpj: "",
            nomeOrRazao: "",
            parcelas: 1,
            email: "",
            cep: "",
            numero: "",
            telefone: "",
        },
        onSubmit: (values) => {

            //const tipo_pessoa = values.tipo_pessoa;
            const num_cartao = values.num_cartao;
            const validade = values.validade;
            const cvv = values.cvv;
            const cpf_cnpj = values.cpf_cnpj;
            const nomeOrRazao = values.nomeOrRazao;
            const parcelas = values.parcelas;
            const email = values.email;
            const cep = values.cep;
            const numero = values.numero;
            const telefone = values.telefone;

            console.log("PARCELAS>", parcelas)

            handleSubmitAPI(cpf_cnpj, nomeOrRazao, email, cep, numero);

        },
    });

    const handleSubmitAPI = async (cpf_cnpj, nomeOrRazao, email, cep, numero) => {
        setIsFinalizando(true);

        const createdBy = userID;
        const name = nomeOrRazao;
        const zip_code = cep;
        const number = numero;
        const plan_identifier = plan_type;

        try {

            // Chama a função para buscar/registrar cliente e criar a assinatura
            const response = await searchClienteAsaasAndGetInvoice(createdBy, email, name, cpf_cnpj, zip_code, number, plan_identifier, payment_type, isPromotional);

            console.log("OQ VOLTOU>>>", response)
            // Verifica se o invoiceUrl foi retornado com sucesso
            if (response && response.data.invoiceUrl) {
                // Abre uma nova aba com a URL da invoice
                window.open(response.data.invoiceUrl, '_blank');
                // Aguarda um pequeno delay antes de atualizar a página para garantir que a guia foi aberta
                setTimeout(() => {
                    window.location.reload();
                }, 1000); // Atualiza após 1 segundo
            } else {
                console.error('URL da fatura não encontrada!');
            }


        } catch (error) {
            console.error('Erro ao processar a assinatura:', error.response.data.msg);
            toast(error.response.data.msg, { autoClose: 3000, type: "error" });
        } finally {
            setIsFinalizando(false); // Finaliza o loading ou processo
            setRefresh((prev) => !prev);
        }

    }

    const valorCentsConvertido = (value) => {
        const valorConvertido = value / 100;
        return valorConvertido.toFixed(2).toString().replace('.', ',');
    }


    /*const handleChoosePlan = (plan_identifier, planCycle, valor_plano, isPromotionalPrice, valorPromocional) => {
        setPlan_type(plan_identifier);
        setPlan_cycle(planCycle);
        //const valueToPay = valorCentsConvertido(valor_plano);
        isPromotionalPrice ? setPriceToPay(valorPromocional) : setPriceToPay(valor_plano);
        if (isPromotionalPrice) {
            setIsPromotional(true)
        }

    }*/
    const handleChoosePlan = (plan_identifier, planCycle, valor_plano) => {
        setPlan_type(plan_identifier);
        setPlan_cycle(planCycle);
        setPriceToPay(valor_plano);
    }

    return (
        <div>
            {/*<LeftMenu setSidebar={setSidebar} sidebar={sidebar} />*/}
            <Teste sidebar={sidebar}>
                <InvoicesModal
                    invoices={invoices}
                    isOpen={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                />
                <div className="w-100 p-4 planos">
                    <div className="input-group inputGroup-adicinaItem mw-100 mh-100">
                        <div className="formGroupRowPlanos">
                            <div className="subscription-layout">
                                {
                                    assinatura && Object.keys(assinatura).length > 0 && assinatura.externalReference !== "free_trial" && !seeAllPlans ?
                                        <div>
                                            <h2>Detalhes da Assinatura</h2>
                                            <div>
                                                <div className="boxFaturaDetail">
                                                    <p><strong>Nome do Cliente:</strong> {clienteAsaas?.name}</p>
                                                    <p><strong>E-mail do Cliente:</strong> {clienteAsaas?.email}</p>
                                                    <p><strong>Plano:</strong> {assinatura.description}</p>
                                                    {/*
                                                        assinatura.externalReference !== "free_trial" &&
                                                        <p><strong>Preço:</strong> {`R$ ${(assinatura.value).toFixed(2).toString().replace('.', ',')}`}</p>
                                                    */}
                                                    <p>
                                                        <strong>Status:</strong>
                                                        <span
                                                            style={{
                                                                display: "inline-block",
                                                                padding: "0px 10px",
                                                                borderRadius: "12px",
                                                                backgroundColor: assinatura.status === "ACTIVE" ? "#4CAF50" : assinatura.status === "INACTIVE" ? "#F44336" : "#9E9E9E",
                                                                color: "white",
                                                                fontWeight: "600",
                                                                marginLeft: "10px",
                                                                transition: "background-color 0.3s ease, transform 0.3s ease",
                                                                transform: "scale(1)",
                                                                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                                                            }}
                                                            className="status-badge"
                                                        >
                                                            {assinatura.status === "ACTIVE" ? "Ativa" : assinatura.status === "INACTIVE" ? "Inativa" : assinatura.status}
                                                        </span>
                                                    </p>
                                                    {
                                                        assinatura.externalReference !== "free_trial" &&
                                                        <>
                                                            <p><strong>Ciclo:</strong> {assinatura.cycle === "MONTHLY" ? "Mensal" : assinatura.cycle === "WEEKLY" ? "Semanal" : assinatura.cycle === "YEARLY" && "Anual"}</p>
                                                            {/*<p><strong>Data de Expiração:</strong> {moment(assinatura.endDate).format('DD/MM/YYYY')}</p>*/}
                                                        </>
                                                    }
                                                </div>
                                                {
                                                    assinatura.externalReference !== "free_trial" ?
                                                        <div className="boxFaturaDetail">
                                                            <h3>Fatura</h3>

                                                            <p><strong>Data de Vencimento:</strong> {moment(invoiceData?.dueDate).format('DD/MM/YYYY')}</p>

                                                            <p><strong>Status da Fatura:</strong>
                                                                <span style={{ padding: 5 }}>{statusMap[invoiceData?.status] || "Status desconhecido"}{console.log(invoiceData?.status)}</span>
                                                                {(invoiceData?.status === "OVERDUE" || invoiceData?.status === "PENDING") &&
                                                                    <a href={invoiceData?.invoiceUrl} target="_blank" rel="noopener noreferrer" className="pagarFaturaBtn">Clique aqui para pagar</a>
                                                                }
                                                            </p>
                                                            {(invoiceData?.status === "OVERDUE" || invoiceData?.status === "PENDING") ?
                                                                <p>
                                                                    <strong>Total:</strong> {`R$ ${((invoiceData?.value || 0) - (invoiceData?.discount?.value || 0))
                                                                        .toFixed(2)
                                                                        .replace('.', ',')}`}
                                                                </p>
                                                                :
                                                                <p>
                                                                    <strong>Total:</strong> {`R$ ${((invoiceData?.value || 0))
                                                                        .toFixed(2)
                                                                        .replace('.', ',')}`}
                                                                </p>
                                                            }

                                                            <a onClick={() => setIsModalOpen(true)} className="openFaturasText">
                                                                Ver todas as faturas
                                                            </a>
                                                        </div>
                                                        :
                                                        <div className="boxFaturaDetail">
                                                            <p><strong>Status da Licença:</strong> {assinatura.active ? "Ativa" : "Desativada"}</p>
                                                        </div>
                                                }
                                            </div>
                                        </div>
                                        :
                                        <div className="header">
                                            <h1>Não perca mais vendas! Continue resolvendo seus problemas de atendimento de forma simples</h1>
                                            <p>Escolha o plano que melhor se encaixa no seu negócio e comece a vender mais</p>

                                            <div className="divChoosePlan">
                                                {
                                                    planos && !loadingAssinaturas ? planos
                                                        .sort((a, b) => a.order - b.order) // Ordena os planos por ordem crescente
                                                        .filter((plano) => plano.plan_identifier !== "free_trial") // Remove o plano free_trial
                                                        /*.map((plano) => {
                                                            const createdDateEmpresa = moment(createdAt);
                                                            const currentDate = moment();
                                                            const monthsDifference = currentDate.diff(createdDateEmpresa, 'months');

                                                            // Definir se exibe o preço normal ou com desconto
                                                            const isPromotionalPrice = monthsDifference < 1;
                                                            const valorOriginal = plano.valor_plano;
                                                            const valorPromocional = 5.00; // Apenas R$ 5,00 no primeiro mês

                                                            return (
                                                                <div key={plano._id} className="choosePlanTypes plan" style={{
                                                                    border: plan_type === plano.plan_identifier ? "1px solid #0a84ff" : "1px dashed lightgray",
                                                                    background: plan_type === plano.plan_identifier ? "aliceblue" : "#FFF"
                                                                }}>
                                                                    <div>
                                                                        {plano.plan_cycle === "YEARLY" ? <div className="badge">RECOMENDADO</div> : <></>}
                                                                        <div className="title">{plano.name}</div>

                                                                        <div className="price">
                                                                            {plano.plan_identifier === "plano_starter" && isPromotionalPrice ? (
                                                                                <>
                                                                                    <span style={{ textDecoration: "line-through", color: "gray", marginRight: 5 }}>
                                                                                        R$ {valorOriginal.toFixed(2).replace('.', ',')}
                                                                                    </span>
                                                                                    <strong style={{ color: "#0a84ff", fontSize: 20 }}>
                                                                                        Apenas R$ {valorPromocional.toFixed(2).replace('.', ',')} no primeiro mês!
                                                                                    </strong>
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                    R$ {(plano.plan_cycle === "YEARLY" ? valorOriginal / 12 : valorOriginal).toFixed(2).replace('.', ',')}
                                                                                    {plano.plan_cycle === "WEEKLY" && <span>/Semana</span>}
                                                                                    {plano.plan_cycle === "MONTHLY" && <span>/Mês</span>}
                                                                                    {plano.plan_cycle === "YEARLY" && <span>/Mês</span>}
                                                                                </>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                    <div className="btnChoosePlan">
                                                                        <button onClick={() => handleChoosePlan(plano.plan_identifier, plano.plan_cycle, plano.valor_plano, isPromotionalPrice, valorPromocional)}>Contratar</button>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })*/
                                                        .map((plano) => {
                                                            const valorOriginal = plano.valor_plano;
                                                            return (
                                                                <div key={plano._id} className="choosePlanTypes plan" style={{
                                                                    border: plan_type === plano.plan_identifier ? "1px solid #0a84ff" : "1px dashed lightgray",
                                                                    background: plan_type === plano.plan_identifier ? "aliceblue" : "#FFF"
                                                                }}>
                                                                    <div>
                                                                        {plano.plan_cycle === "YEARLY" ? <div className="badge">RECOMENDADO</div> : <></>}
                                                                        <div className="title">{plano.name}</div>

                                                                        <div className="price">
                                                                            {plano.plan_identifier === "plano_starter" ? (
                                                                                <>                                                                                
                                                                                    R$ {valorOriginal.toFixed(2).replace('.', ',')}                                                                                   
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                    R$ {(plano.plan_cycle === "YEARLY" ? valorOriginal / 12 : valorOriginal).toFixed(2).replace('.', ',')}
                                                                                    {plano.plan_cycle === "WEEKLY" && <span>/Semana</span>}
                                                                                    {plano.plan_cycle === "MONTHLY" && <span>/Mês</span>}
                                                                                    {plano.plan_cycle === "YEARLY" && <span>/Mês</span>}
                                                                                </>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                    <div className="btnChoosePlan">
                                                                        <button onClick={() => handleChoosePlan(plano.plan_identifier, plano.plan_cycle, plano.valor_plano)}>Contratar</button>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                        :
                                                        <Loading type="spin" color="gray" height={100} width={100} />
                                                }
                                            </div>

                                        </div>
                                }

                                {
                                    plan_cycle === "" ?
                                        <></>
                                        :
                                        <div className="payment-methods">
                                            <div className="info">
                                                <p><RiErrorWarningLine style={{ fontSize: 25, paddingBottom: 4 }} /> A data da cobrança do plano será alterada para todo dia {currentDay}</p>
                                            </div>


                                            <div className="title">Escolha uma forma de pagamento:</div>
                                            <div className="methods">
                                                <div className="method credit-card" onClick={() => handlePaymentType("CREDIT_CARD")}
                                                    style={{
                                                        background: payment_type === "CREDIT_CARD" ? "aliceblue" : "",
                                                        border: payment_type === "CREDIT_CARD" ? "1px solid #007bff" : ""
                                                    }}>
                                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
                                                        <input type="radio" name="payment_type" value="credit-card" checked={payment_type === "CREDIT_CARD"} className="inputBoxRadioPersonalized" readOnly />
                                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                            <span>Cartão de crédito</span>
                                                        </div>
                                                    </div>
                                                    <div><IoIosCard style={{ fontSize: 30 }} /></div>
                                                </div>
                                                <div className="method boleto" onClick={() => handlePaymentType("BOLETO")}
                                                    style={{
                                                        background: payment_type === "BOLETO" ? "aliceblue" : "",
                                                        border: payment_type === "BOLETO" ? "1px solid #007bff" : ""
                                                    }}>
                                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
                                                        <input type="radio" name="payment_type" value="credit-card" checked={payment_type === "BOLETO"} className="inputBoxRadioPersonalized" readOnly />
                                                        <span>Boleto</span>
                                                    </div>
                                                    <div><FaBarcode style={{ fontSize: 30 }} /></div>
                                                </div>
                                                <div className="method pix" onClick={() => handlePaymentType("PIX")}
                                                    style={{
                                                        background: payment_type === "PIX" ? "aliceblue" : "",
                                                        border: payment_type === "PIX" ? "1px solid #007bff" : ""
                                                    }}>
                                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
                                                        <input type="radio" name="payment_type" value="credit-card" checked={payment_type === "PIX"} className="inputBoxRadioPersonalized" readOnly />
                                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                            <span>Pix</span>

                                                        </div>
                                                    </div>
                                                    <div><MdPix style={{ fontSize: 30 }} /></div>
                                                </div>
                                            </div>



                                            <div className="credit-card-form">
                                                <form onSubmit={formik.handleSubmit}>
                                                    <div style={{ margin: "10px 0px", display: "flex", gap: 5 }}>
                                                        <button type="button" disabled={loadingCustomerData} className="btnUtilizarDadosCadastrados" onClick={handleGetDataCustomer}>Utilizar dados já cadastrados</button>
                                                        {loadingCustomerData && <Loading type="spin" fontSize={10} color="black" height={30} width={30} />}
                                                    </div>
                                                    <div className="input-box">
                                                        <label>Escolha o tipo de pessoa:</label>

                                                        <div className="typePersonDiv">
                                                            <div style={{ display: 'flex', gap: 5 }}>
                                                                <input type="radio" name="person_type" value="CNPJ" checked={person_type === "cnpj"} className="inputBoxRadioPersonalized" readOnly
                                                                    onClick={() => handlePersonType("cnpj")} />
                                                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                                    <span style={{ fontSize: 12, fontWeight: 600 }}>CNPJ</span>
                                                                    <span style={{ fontSize: 12 }}>Pessoa Jurídica</span>
                                                                </div>
                                                            </div>
                                                            <div style={{ display: 'flex', gap: 5 }}>
                                                                <input type="radio" name="person_type" value="CPF" checked={person_type === "cpf"} className="inputBoxRadioPersonalized" readOnly
                                                                    onClick={() => handlePersonType("cpf")} />
                                                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                                    <span style={{ fontSize: 12, fontWeight: 600 }}>CPF</span>
                                                                    <span style={{ fontSize: 12 }}>Pessoa Física</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div style={{ justifyContent: "space-between", display: 'flex' }}>

                                                        <div className="input-box planosFormInputs" style={{ width: "47%" }}>
                                                            <label>{person_type === "cnpj" ? "CNPJ" : "CPF"}<span style={{ color: "red" }}>*</span></label>
                                                            <InputMask
                                                                placeholder={person_type === "cnpj" ? "Digite seu CNPJ" : "Digite seu CPF"}
                                                                id="cpf_cnpj"
                                                                type="cpf_cnpj"
                                                                name="cpf_cnpj"
                                                                mask={person_type === "cnpj" ? "99.999.999/9999-99" : "999.999.999-99"}
                                                                onChange={formik.handleChange}
                                                                value={formik.values.cpf_cnpj}
                                                            />
                                                            {formik.errors.cpf_cnpj && (
                                                                <div className="error">{formik.errors.cpf_cnpj}</div>
                                                            )}
                                                        </div>
                                                        <div className="input-box planosFormInputs" style={{ width: "47%" }}>
                                                            <label>{person_type === "cnpj" ? "Razão Social" : "Nome"}<span style={{ color: "red" }}>*</span></label>
                                                            <input
                                                                placeholder={person_type === "cnpj" ? "Escreva aqui sua Razão Social" : "Escreva aqui seu Nome"}
                                                                id="nomeOrRazao"
                                                                type="nomeOrRazao"
                                                                name="nomeOrRazao"
                                                                onChange={formik.handleChange}
                                                                value={formik.values.nomeOrRazao}
                                                            />
                                                            {formik.errors.nomeOrRazao && (
                                                                <div className="error">{formik.errors.nomeOrRazao}</div>
                                                            )}
                                                        </div>

                                                    </div>

                                                    <div style={{ justifyContent: "space-between", display: 'flex' }}>

                                                        <div className="input-box planosFormInputs" style={{ width: "40%" }}>
                                                            <label>Email<span style={{ color: "red" }}>*</span></label>
                                                            <InputMask
                                                                placeholder="Digite seu Email"
                                                                id="email"
                                                                type="email"
                                                                name="email"
                                                                //mask="99.999.999/9999-99"
                                                                onChange={formik.handleChange}
                                                                value={formik.values.email}
                                                            />
                                                            {formik.errors.email && (
                                                                <div className="error">{formik.errors.email}</div>
                                                            )}
                                                        </div>
                                                        <div className="input-box planosFormInputs" style={{ width: "25%" }}>
                                                            <label>CEP<span style={{ color: "red" }}>*</span></label>
                                                            <InputMask
                                                                placeholder="Digite seu CEP"
                                                                id="cep"
                                                                type="cep"
                                                                name="cep"
                                                                mask={"99999-999"}
                                                                onChange={formik.handleChange}
                                                                value={formik.values.cep}
                                                            />
                                                            {formik.errors.cep && (
                                                                <div className="error">{formik.errors.cep}</div>
                                                            )}
                                                        </div>

                                                        <div className="input-box planosFormInputs" style={{ width: "25%" }}>
                                                            <label>Número<span style={{ color: "red" }}>*</span></label>
                                                            <input
                                                                placeholder="Número do endereço"
                                                                id="numero"
                                                                type="numero"
                                                                name="numero"
                                                                onChange={formik.handleChange}
                                                                value={formik.values.numero}
                                                            />
                                                            {formik.errors.numero && (
                                                                <div className="error">{formik.errors.numero}</div>
                                                            )}
                                                        </div>
                                                    </div>


                                                    <div className="input-box planosFormInputs" style={{ width: "100%", paddingTop: "28px", marginBottom: "0px" }}>
                                                        {
                                                            isFinalizando ?
                                                                <button className="buttonFinalizarPagamento" readOnly={isFinalizando ? true : false} disabled={isFinalizando ? true : false} style={{ cursor: isFinalizando ? 'not-allowed' : '' }}>
                                                                    <Loading type='spin' color='white' height={24} width={24} />
                                                                </button>
                                                                :
                                                                <button type="submit" className="buttonFinalizarPagamento">
                                                                    <span style={{ color: 'white', fontWeight: '700', fontSize: 20 }}>FINALIZAR PAGAMENTO</span>
                                                                </button>
                                                        }
                                                    </div>

                                                    <div className="input-box planosFormInputs" style={{ width: "98%", paddingTop: "28px", marginBottom: "0px" }}>
                                                        <div className="info" style={{ display: 'flex', justifyContent: 'end' }}>

                                                            <span style={{ fontSize: 20, fontWeight: '700' }}>Total a pagar: R$ {priceToPay.toFixed(2).toString().replace('.', ',')}</span>

                                                        </div>
                                                    </div>
                                                </form>
                                            </div>


                                        </div>
                                }

                                <div className="features">
                                    <div className="title">Tenha acesso a mais de 10 funcionalidades para alavancar suas vendas</div>
                                    <div className="feature-list">
                                        <div className="feature">Atendente Virtual WhatsApp</div>
                                        {/*<div className="feature">Atendente Virtual para Facebook</div>*/}
                                        <div className="feature">Módulo de Caixa</div>
                                        <div className="feature">Modo Garçom</div>
                                        {/*<div className="feature">PIX Automatizado*</div>*/}
                                        <div className="feature">QR CODE do Cardápio para Mesas</div>
                                        <div className="feature">Serviço de Instalação</div>
                                        <div className="feature">Cadastro de Entregadores</div>
                                        <div className="feature">Painel de Pedidos</div>
                                        <div className="feature">Suporte Online</div>
                                        <div className="feature">Cardápio Digital</div>
                                        <div className="feature">Ponto de Venda</div>
                                        <div className="feature">Relatórios de vendas</div>
                                    </div>
                                </div>
                                {/*invoiceData &&
                                    <div className="footerBtnPlanos">
                                        <button type="button" onClick={handleSeePlans}>{seeAllPlans ? "Verificar Faturas" : "Verificar Planos"}</button>
                                    </div>
                                */}
                            </div>

                        </div>
                    </div>
                </div>
            </Teste>
        </div>
    );
}

export default Planos; 