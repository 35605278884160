import React, { useState, useContext, useEffect } from "react";
import Loading from 'react-loading';
import { Br, Cut, Line, Printer, Text, Row, render } from 'react-thermal-printer';
import './style.css'
import './styleResponsive.css'
import styled from 'styled-components';
import axios from "axios";
import { toast } from "react-toastify";
import pdfMake from "pdfmake/build/pdfmake";
import html2canvas from 'html2canvas';
//import html2pdf from 'html2pdf.js';
import CryptoJS from 'crypto-js';
import imageUrl from '../../img/logoBlue.png'
import io from 'socket.io-client';
import { SidebarContext } from "../../AppRoutes";
import { AuthContext } from "../../contexts/auth";

import {
    getUser,
    getVinculoEmpresa,
    getPedido,
    updateStatusPrint,
    updateStatusPedido,
    updateStatusPedidoFinalizado,
    getPedidosByStatus,
    getPedidosByStatusSimples,
    getPedidosByStatusFinalizados
} from "../../services/api";

import PermissionGate from "../../services/PermissionGate";

import { useNavigate } from "react-router-dom";
import LeftMenu from "../../components/LeftMenu";
import { GiFullMotorcycleHelmet } from "react-icons/gi";
import { MdStorefront } from "react-icons/md";

import moment from 'moment'
import * as AiIcons from 'react-icons/ai'
import * as FiIcons from 'react-icons/fi'
import { FaMotorcycle, FaRegClock, FaStore } from "react-icons/fa";
import { MdTableBar } from "react-icons/md";
import { IoMdPin } from "react-icons/io";

import ModalEditTempoEntrega from "../../components/ModalEditTempoEntrega";
import ModalPedido from "../../components/ModalPedido";
import ModalAddEntregador from "./ModalAddEntregador";

import { MobileKanban } from "./MobileKanban";

const Teste = styled.div`

    display: flex;
    margin-left:  ${({ sidebar }) => (sidebar ? '250px' : '100px')}; 
    height:auto;
    width:auto;
    transition: 150ms;
    background-color:rgb(247,247,247)!important;
    --background-color:white!important;
    overflow: initial;
    z-Index: 9;

    @media (max-width: 880px) {
        margin-left: 0;
    }
`;

const Ordercolumn = styled.div`
    min-width: 25%;
`;

const ModalUserOptions = styled.div`
  font-size: 14px;
  position: absolute;
  top: 82px;
  left: 150px;
  display: ${({ showOptions }) => (showOptions ? 'none' : '')};
  float: left;
  min-width: 160px;
  margin: 2px 0 0;
  padding: 5px 0;
  list-style: none;
  text-align: left;
  border: 1px solid #ccc;
  border: 1px solid rgba(0,0,0,.15);
  border-radius: 4px;
  background-color: #fff;
  --background-color:rgb(247,247,247)!important;
  background-clip: padding-box;
  -webkit-box-shadow: 0 6px 12px rgba(0,0,0,.175);
  box-shadow: 0 6px 12px rgba(0,0,0,.175);
  z-index: 25;

  li{
    font-weight: 400;
    line-height: 1.42857143;
    display: block;
    clear: both;
    padding: 3px 20px;
    white-space: nowrap;
    color: #58595b;
  }
  li:hover{
    background: #f5f5f5
  }
`;


const User = () => {
    //const { user } = useContext(AuthContext);
    const empresa = localStorage.getItem('empresa')
    const empresaParse = JSON.parse(empresa)
    const objIdEmpresa = empresaParse._id;
    const idEmpresa = empresaParse.id_empresa;
    const vinculo_empresa = empresaParse.cnpj;

    const secretKey = 'my-custom-secret-key:1af0addcce4b56f753ed10c285667ad031bae57af19747f576b05312b8cf85be';
    const userEncrypted = localStorage.getItem('user')
    const user = CryptoJS.AES.decrypt(userEncrypted, secretKey).toString(CryptoJS.enc.Utf8);
    //const user = localStorage.getItem('user')
    const userParse = JSON.parse(user)
    //console.log(userParse)
    //const userParse = user;
    const userID = userParse._id;
    const [showEditTempoEntregaEmpresa, setEditTempoEntregaEmpresa] = useState(true);
    const [_idEmpresaEdit, set_idEmpresaEdit] = useState('');
    //const [showOptions, setUserOptions] = useState(true);
    const INITIAL_DATA = {
        value: "",
        label: 'Selecione uma empresa',
    };
    const [selectData, setselectData] = useState(INITIAL_DATA);

    const [showPedido, setShowPedido] = useState(true);

    const { sidebar, setSidebar } = useContext(SidebarContext);; //TESTANDO CONST NO AppROUTES

    var [tempoBalcaoMinBD, setTempoBalcaoMinBD] = useState(0);
    var [tempoBalcaoMaxBD, setTempoBalcaoMaxBD] = useState(0);
    var [tempoEntregaMinBD, setTempoEntregaMinBD] = useState(0);
    var [tempoEntregaMaxBD, setTempoEntregaMaxBD] = useState(0);
    var [tipoImpressao, setTipoImpressao] = useState("");
    const [refresh, setRefresh] = useState(false);

    const [filtroSelecionado, setFiltroSelecionado] = useState("all");

    const [pedidosAnalise, setPedidosAnalise] = useState([]);
    const [pedidosProducao, setPedidosProducao] = useState([]);
    const [pedidosEntrega, setPedidosEntrega] = useState([]);
    const [pedidosFinalizado, setPedidosFinalizado] = useState([]);

    //const [pedidos, setPedidos] = useState([]);
    const [pedidosSimples, setPedidosSimples] = useState([]);
    const [pedidosFinalizados, setPedidosFinalizados] = useState([]);
    const [isLoadingSimples, setIsLoadingSimples] = useState(true);
    const [isLoadingFinalizados, setIsLoadingFinalizados] = useState(true);

    const navigate = useNavigate();
    const [infoPedido, setInfoPedido] = useState({});
    const [filterIsLoading, setFilterIsLoading] = useState(false);
    const [showModalAddEntregador, setShowModalAddEntregador] = useState(true);
    const [pedidoToAddEntregador, setPedidoToAddEntregador] = useState({});
    const [searchQuery, setSearchQuery] = useState('');
    const [originalPedidos, setOriginalPedidos] = useState([]); // Para armazenar a lista completa de pedidos
    //const [imageBase64, setImageBase64] = useState("");
    //const [pdfBase64, setPdfBase64] = useState("");
    var imageDataURL = null;
    const [isLoading, setIsLoading] = useState(false);
    const [hasCancelPassword, setHasCancelPassword] = useState(false);
    //const [printJobId, setPrintJobId] = useState('');

    useEffect(() => {
        if (refresh) {
            const fetchDataAndSetPedidos = async () => {
                const fetchedPedidosSimples = await fetchPedidosSimples();
                const fetchedPedidosFinalizados = await fetchPedidosFinalizados();
                setPedidosSimples(fetchedPedidosSimples);
                setPedidosFinalizados(fetchedPedidosFinalizados);
            };
            fetchDataAndSetPedidos();
            setRefresh(false);
        }
    }, [refresh]);
    /*
    const fetchDataAndSetPedidosForFilters = async () => {
        const fetchedPedidos = await fetchData();
        setPedidos(fetchedPedidos);
    };
    
    const fetchData = async () => {
        const response = await getVinculoEmpresa(userID);
        //const vinculo = response.data.vinculo.cnpj;
        //console.log("response>",response.data.vinculo);
        //const pedidosResponse = await getPedidos(userID, id_empresa);
        const pedidosAnalise = await getPedidosByStatus(userID, idEmpresa, vinculo_empresa, '1');
        const pedidosProducao = await getPedidosByStatus(userID, idEmpresa, vinculo_empresa, '2');
        const pedidosEntrega = await getPedidosByStatus(userID, idEmpresa, vinculo_empresa, '3');
        const pedidosFinalizados = await getPedidosByStatus(userID, idEmpresa, vinculo_empresa, '4');
        //console.log("pedidosAnaliseResponse>",pedidosAnaliseResponse.data.pedidos);
        setTipoImpressao(response.data.vinculo.tipo_impressao);
        setTempoBalcaoMinBD(parseInt(response.data.vinculo.tempoBalcaoMin));
        setTempoBalcaoMaxBD(parseInt(response.data.vinculo.tempoBalcaoMax));
        setTempoEntregaMinBD(parseInt(response.data.vinculo.tempoEntregaMin));
        setTempoEntregaMaxBD(parseInt(response.data.vinculo.tempoEntregaMax));
        
    //setPedidos(pedidosResponse.data.pedidos);
        setRefresh(false);
        return [
            ...pedidosAnalise.data.pedidos,
            ...pedidosProducao.data.pedidos,
            ...pedidosEntrega.data.pedidos,
            ...pedidosFinalizados.data.pedidos,
          ];
    };*/



    /*const fetchData = async () => {
        const response = await getVinculoEmpresa(userID);
        const pedidosAnalise = await getPedidosByStatus(userID, idEmpresa, vinculo_empresa, '1');
        const pedidosProducao = await getPedidosByStatus(userID, idEmpresa, vinculo_empresa, '2');
        const pedidosEntrega = await getPedidosByStatus(userID, idEmpresa, vinculo_empresa, '3');
        const pedidosFinalizados = await getPedidosByStatus(userID, idEmpresa, vinculo_empresa, '4');
        setTipoImpressao(response.data.vinculo.tipo_impressao);
        setTempoBalcaoMinBD(parseInt(response.data.vinculo.tempoBalcaoMin));
        setTempoBalcaoMaxBD(parseInt(response.data.vinculo.tempoBalcaoMax));
        setTempoEntregaMinBD(parseInt(response.data.vinculo.tempoEntregaMin));
        setTempoEntregaMaxBD(parseInt(response.data.vinculo.tempoEntregaMax));
        setHasCancelPassword(response.data.vinculo.has_cancel_password)

        setRefresh(false);
        setFilterIsLoading(false);
        return [...pedidosAnalise.data.pedidos, ...pedidosProducao.data.pedidos, ...pedidosEntrega.data.pedidos, ...pedidosFinalizados.data.pedidos];
    };*/

    /*const fetchDataAndSetPedidosForFilters = async (paramReceived) => {
        const fetchedPedidos = await fetchData();
        paramReceived === "all" && setPedidos(fetchedPedidos);
        paramReceived === "delivery" && setPedidos(fetchedPedidos.filter(pedido => pedido.entrega.tipo_entrega.toLowerCase() === "entrega"));
        paramReceived === "balcao" && setPedidos(fetchedPedidos.filter(pedido => pedido.entrega.tipo_entrega.toLowerCase() === "retirada"));

    };*/

    // Função para carregar pedidos simples (status 1, 2, 3)
    const fetchPedidosSimples = async () => {
        const response = await getVinculoEmpresa(userID);
        const pedidosStatus123 = await getPedidosByStatusSimples(userID, idEmpresa, vinculo_empresa, ['1', '2', '3'], 'false');

        // Atualizar as informações adicionais
        setTipoImpressao(response.data.vinculo.tipo_impressao);
        setTempoBalcaoMinBD(parseInt(response.data.vinculo.tempoBalcaoMin));
        setTempoBalcaoMaxBD(parseInt(response.data.vinculo.tempoBalcaoMax));
        setTempoEntregaMinBD(parseInt(response.data.vinculo.tempoEntregaMin));
        setTempoEntregaMaxBD(parseInt(response.data.vinculo.tempoEntregaMax));
        setHasCancelPassword(response.data.vinculo.has_cancel_password);
        setFilterIsLoading(false);
        return pedidosStatus123.data.pedidos;
    };

    // Função para carregar pedidos finalizados (status 4)
    const fetchPedidosFinalizados = async () => {
        const pedidosStatus4 = await getPedidosByStatusFinalizados(userID, idEmpresa, vinculo_empresa, 'false');
        setFilterIsLoading(false);
        return pedidosStatus4.data.pedidos;
    };

    // Função para aplicar os filtros
    const fetchDataAndSetPedidosForFilters = async (paramReceived) => {
        const fetchedPedidosSimples = await fetchPedidosSimples();
        const fetchedPedidosFinalizados = await fetchPedidosFinalizados();

        let filteredPedidosSimples = fetchedPedidosSimples;
        let filteredPedidosFinalizados = fetchedPedidosFinalizados;

        if (paramReceived === "delivery") {
            filteredPedidosSimples = fetchedPedidosSimples.filter(pedido => pedido.entrega?.tipo_entrega.toLowerCase() === "entrega");
            filteredPedidosFinalizados = fetchedPedidosFinalizados.filter(pedido => pedido.entrega?.tipo_entrega?.toLowerCase() === "entrega");
        } else if (paramReceived === "balcao") {
            filteredPedidosSimples = fetchedPedidosSimples.filter(pedido => pedido.entrega?.tipo_entrega?.toLowerCase() === "retirada");
            filteredPedidosFinalizados = fetchedPedidosFinalizados.filter(pedido => pedido.entrega?.tipo_entrega?.toLowerCase() === "retirada");
        } else if (paramReceived === "mesas") {
            filteredPedidosSimples = fetchedPedidosSimples.filter(pedido => pedido.createdBy.toLowerCase().includes('mesa'));
            filteredPedidosFinalizados = fetchedPedidosFinalizados.filter(pedido => pedido.createdBy.toLowerCase().includes('mesa'));
        }

        // Atualizar os estados de pedidos
        setPedidosSimples(filteredPedidosSimples);
        setPedidosFinalizados(filteredPedidosFinalizados);
    };

    useEffect(() => {
        // Carregar pedidos com o filtro específico
        setFilterIsLoading(true);
        fetchDataAndSetPedidosForFilters(filtroSelecionado);
    }, [refresh]);

    // Organizar os pedidos simples em suas respectivas categorias
    useEffect(() => {
        setPedidosAnalise([]);
        setPedidosProducao([]);
        setPedidosEntrega([]);

        pedidosSimples.forEach((dados) => {
            if (dados.status_pedido === '1') {
                setPedidosAnalise(pedidosAnteriores => [...pedidosAnteriores, dados]);
            } else if (dados.status_pedido === '2') {
                setPedidosProducao(pedidosAnteriores => [...pedidosAnteriores, dados]);
            } else if (dados.status_pedido === '3') {
                setPedidosEntrega(pedidosAnteriores => [...pedidosAnteriores, dados]);
            }
        });
    }, [pedidosSimples, refresh]);  // Esse efeito é acionado quando 'pedidosSimples' é atualizado

    // Organizar os pedidos finalizados em sua categoria
    useEffect(() => {
        setPedidosFinalizado([]);

        pedidosFinalizados.forEach((dados) => {
            if (dados.status_pedido === '4') {
                setPedidosFinalizado(pedidosAnteriores => [...pedidosAnteriores, dados]);
            }
        });
    }, [pedidosFinalizados, refresh]);  // Esse efeito é acionado quando 'pedidosFinalizados' é atualizado

    /*useEffect(() => {
        setPedidosAnalise([]);
        setPedidosProducao([]);
        setPedidosEntrega([]);
        setPedidosFinalizado([]);
        pedidos.map((dados, i) => {
            if (dados.status_pedido == '1') {
                setPedidosAnalise(pedidosAnteriores => [...pedidosAnteriores, dados]);
            }
            if (dados.status_pedido == '2') {
                setPedidosProducao(pedidosAnteriores => [...pedidosAnteriores, dados])
            }
            if (dados.status_pedido == '3') {
                setPedidosEntrega(pedidosAnteriores => [...pedidosAnteriores, dados])
            }
            if (dados.status_pedido == '4') {
                setPedidosFinalizado(pedidosAnteriores => [...pedidosAnteriores, dados])
            }
        })
    }, [pedidos, refresh]); // Sem dependências, então o efeito será executado apenas uma vez*/

    /*  ///////////////////////// COMENTARIO TEMPORÁRIO SÓ PRA TESTAR  //////////////////////////
    const isDevelopment = window.location.hostname === 'localhost';
    const apiUrl = isDevelopment
        ? process.env.REACT_APP_SERVER_URL_DEV
        : process.env.REACT_APP_SERVER_URL_PROD;
    useEffect(() => {
        const fetchPedidos = async () => {
            const data = await fetchData();
            setOriginalPedidos(data); // Armazena os pedidos originais
            setPedidos(data); // Inicializa os pedidos com os dados originais
        };

        fetchPedidos();

        const wsUrl = apiUrl; // Alterar a URL se o servidor estiver em um endereço diferente
        const socket = io(wsUrl, { withCredentials: true, transports: ['websocket'], auth: { token: localStorage.getItem('token') } });

        socket.emit('joinCompanyRoom', { companyId: idEmpresa.toString(), clientId: 'reactClient' });

        socket.on('novoPedido', async (data) => {
            console.log("NOVO PEDIDO RECEBIDO!");
            const newPedidos = await fetchData();
            setOriginalPedidos(newPedidos);
            setPedidos(newPedidos);
        });

        return () => {
            socket.off('novoPedido');
        };
    }, [idEmpresa]);

    useEffect(() => {
        const filteredPedidos = originalPedidos.filter(dados =>
            dados.nome_cliente.toLowerCase().includes(searchQuery.toLowerCase()) ||
            dados.id_pedido_counter?.toString().includes(searchQuery) ||
            dados.id_pedido.toString().includes(searchQuery)
        );
        setPedidos(filteredPedidos);
    }, [searchQuery, originalPedidos]);*/
    const isDevelopment = window.location.hostname === 'localhost';
    const apiUrl = isDevelopment
        ? process.env.REACT_APP_SERVER_URL_DEV
        : process.env.REACT_APP_SERVER_URL_PROD;

    useEffect(() => {
        // Função para carregar os pedidos simples e finalizados
        const fetchPedidos = async () => {
            // Carregar pedidos simples (status 1, 2, 3)
            const allPedidosSimples = await fetchPedidosSimples();
            // Carregar pedidos finalizados (status 4)
            const allPedidosFinalizados = await fetchPedidosFinalizados();
            const allPedidos = [...allPedidosSimples, ...allPedidosFinalizados];
            setOriginalPedidos(allPedidos);
        };

        fetchPedidos(); // Carrega os pedidos na inicialização

        // Configuração do WebSocket
        const wsUrl = apiUrl;
        //const socket = io(wsUrl, { withCredentials: true, transports: ['websocket'], auth: { token: localStorage.getItem('token') } });
        const socket = io(wsUrl, { 
            withCredentials: true, 
            transports: ['websocket'], 
            auth: { token: localStorage.getItem('token') },
            reconnection: true, // Tenta reconectar automaticamente
            reconnectionAttempts: 10, // Máximo de 10 tentativas
            reconnectionDelay: 5000, // Tenta reconectar a cada 5 segundos
        });

        socket.on("connect", () => {
            console.log("WebSocket conectado!");
        });

        socket.on("disconnect", (reason) => {
            console.error("WebSocket desconectado:", reason);
        });
        
        socket.on("reconnect_attempt", (attempt) => {
            console.log(`Tentativa de reconexão ${attempt}`);
        });

        socket.once("ping", () => {
            console.log("🔄 Ping recebido do servidor, enviando pong...");
            socket.emit("pong");
        });

        //socket.emit('joinCompanyRoom', { companyId: idEmpresa.toString(), clientId: 'reactClient' });
        const clientId = `${userID}-${Date.now()}`;
        socket.emit('joinCompanyRoom', { companyId: idEmpresa.toString(), clientId });

        const handleNovoPedido = async () => {
            console.log("NOVO PEDIDO RECEBIDO!");
            const pedidosSimplesData = await fetchPedidosSimples();
            const pedidosFinalizadosData = await fetchPedidosFinalizados();
            const newPedidos = [...pedidosSimplesData, ...pedidosFinalizadosData];
            setOriginalPedidos(newPedidos);
            setPedidosSimples(pedidosSimplesData);
            setPedidosFinalizados(pedidosFinalizadosData);
        };

        socket.on('novoPedido', handleNovoPedido);

        // Limpeza do WebSocket
        return () => {
            socket.off('novoPedido');
            socket.disconnect(); // 🔹 Fecha a conexão WebSocket ao sair da tela
        };
    }, [idEmpresa]); // Dependência para que o efeito seja disparado quando 'idEmpresa' mudar

    useEffect(() => {
        const filteredPedidos = originalPedidos.filter(dados =>
            dados.nome_cliente.toLowerCase().includes(searchQuery.toLowerCase()) ||
            dados.id_pedido_counter?.toString().includes(searchQuery) //||
            //dados.id_pedido.toString().includes(searchQuery)
        );
        // Separa os pedidos filtrados em simples e finalizados
        const pedidosSimplesFiltrados = filteredPedidos.filter(dados =>
            dados.status_pedido === '1' || dados.status_pedido === '2' || dados.status_pedido === '3'
        );

        const pedidosFinalizadosFiltrados = filteredPedidos.filter(dados =>
            dados.status_pedido === '4'
        );

        // Atualiza os estados de pedidosSimples e pedidosFinalizados com os pedidos filtrados
        setPedidosSimples(pedidosSimplesFiltrados);
        setPedidosFinalizados(pedidosFinalizadosFiltrados);
    }, [searchQuery, originalPedidos]);


    const handleCadastroPDV = () => {
        navigate('/pdv');
    }


    const handleAddEntregador = (event, pedido) => {
        event.stopPropagation();
        setPedidoToAddEntregador(pedido);
        setShowModalAddEntregador(!showModalAddEntregador);
    }

    const handleAvancar = async (event, _id, id_pedido, status_pedido) => {
        event.stopPropagation();
        setIsLoading(true); // Inicia o carregamento

        let newStatus = "";
        let finalizadoAt = "";
        let response = null;

        switch (status_pedido) {
            case "1":
                newStatus = "2";
                break;
            case "2":
                newStatus = "3";
                break;
            case "3":
                newStatus = "4";
                //finalizadoAt = moment().local().format("DD/MM/YYYY");
                //finalizadoAt = momentTz().tz('America/Sao_Paulo').format("DD/MM/YYYY");
                finalizadoAt = new Date();
                break;
            default:
                setIsLoading(false);
                return; // Se o status_pedido não é 1, 2, ou 3, termina a função
        }

        try {
            // Unifica a chamada da API dependendo do caso
            if (status_pedido === "3") {
                response = await updateStatusPedidoFinalizado(userID, _id, id_pedido, newStatus, finalizadoAt);
            } else {
                response = await updateStatusPedido(userID, _id, id_pedido, newStatus);
            }
            setRefresh(true); // Atualiza o estado para forçar recarga dos dados
            toast(response.data.msg, { autoClose: 2000, type: "success" });
        } catch (err) {
            toast(err.response?.data?.msg || "An error occurred", { autoClose: 2000, type: "error" });
        } finally {
            setRefresh(true); // Atualiza o estado para forçar recarga dos dados
            setIsLoading(false); // Finaliza o carregamento imediatamente após a conclusão ou falha da chamada da API
        }
    };


    /*const totalValuePerOrder = pedidos.map((order) => {
        const totalValue = order.itens.reduce((acc, item) => {
            return acc + (item.valor * item.quantidade);
        }, 0);
        return { ...order, totalValue };
    });*/

    const handleEditTempoEntrega = async idToEdit => {
        setEditTempoEntregaEmpresa(!showEditTempoEntregaEmpresa);
        set_idEmpresaEdit(idEmpresa);
        //setUserOptions(!showOptions);
        /*const response = await getUser(idToEdit);
        if(showEditTempoEntregaEmpresa){       
            if (response.data.user.vinculo_empresa){                
                const responseVinculo = await getVinculoEmpresa(idToEdit);                     
                //console.log("TEM EMPRESA VINCULADA!",responseVinculo.data.vinculo.id_empresa)
                set_idEmpresaEdit(responseVinculo.data.vinculo.id_empresa);                
            }    
        }*/
    }

    const handleFilterAll = async () => {
        setFilterIsLoading(true);
        setFiltroSelecionado("all");
        await fetchDataAndSetPedidosForFilters("all"); // Garantindo que os dados estão atualizados;              
    }

    const handleFilterDelivery = async () => {
        setFilterIsLoading(true);
        setFiltroSelecionado("delivery");
        await fetchDataAndSetPedidosForFilters("delivery"); // Garantindo que os dados estão atualizados
        //setPedidos(currentPedidos => currentPedidos.filter(pedido => pedido.entrega.tipo_entrega?.toLowerCase() === "entrega"));        
    };

    const handleFilterBalcao = async () => {
        setFilterIsLoading(true);
        setFiltroSelecionado("balcao");
        await fetchDataAndSetPedidosForFilters("balcao"); // Garantindo que os dados estão atualizados
        //setPedidos(currentPedidos => currentPedidos.filter(pedido => pedido.entrega.tipo_entrega?.toLowerCase() === "retirada"));
    };

    const handleFilterMesas = async () => {
        setFilterIsLoading(true);
        setFiltroSelecionado("mesas");
        await fetchDataAndSetPedidosForFilters("mesas"); // Garantindo que os dados estão atualizados
        //setPedidos(currentPedidos => currentPedidos.filter(pedido => pedido.entrega.tipo_entrega?.toLowerCase() === "retirada"));
    };

    const handleShowPedido = async (idPedidoToOpen, id_empresa) => {

        setShowPedido(!showPedido);
        const response = await getPedido(userID, id_empresa, vinculo_empresa, idPedidoToOpen);
        if (showPedido) {
            if (response.data.pedido) {
                setInfoPedido(response.data.pedido[0]);
            }
        }
    }

    return (

        <>
            <PermissionGate permissions={['default']}>


                {/*<LeftMenu setSidebar={setSidebar} sidebar={sidebar} />*/}

                <Teste sidebar={sidebar}>




                    <ModalEditTempoEntrega setEditTempoEntregaEmpresa={setEditTempoEntregaEmpresa} showEditTempoEntregaEmpresa={showEditTempoEntregaEmpresa}
                        setRefresh={setRefresh}
                        selectData={selectData}
                        setselectData={setselectData}
                        _idEmpresaEdit={_idEmpresaEdit}
                        tempoBalcaoMinBD={tempoBalcaoMinBD}
                        tempoBalcaoMaxBD={tempoBalcaoMaxBD}
                        tempoEntregaMinBD={tempoEntregaMinBD}
                        tempoEntregaMaxBD={tempoEntregaMaxBD}
                        tipoImpressao={tipoImpressao}
                    //usernameEdit={usernameEdit} 
                    //emailEdit={emailEdit} 
                    //roleEdit={roleEdit}    
                    //editPerfil={false}
                    />

                    <ModalPedido setShowPedido={setShowPedido} showPedido={showPedido}
                        setRefresh={setRefresh}
                        infoPedido={infoPedido}
                        setInfoPedido={setInfoPedido}
                        handleAvancar={handleAvancar}
                        userID={userID}
                        hasCancelPassword={hasCancelPassword}
                    />

                    <ModalAddEntregador showModalAddEntregador={showModalAddEntregador}
                        setShowModalAddEntregador={setShowModalAddEntregador}
                        pedidoToAddEntregador={pedidoToAddEntregador}
                        objIdEmpresa={objIdEmpresa}
                        userID={userID}
                        setRefresh={setRefresh}
                    />

                    <div className="m-5">
                        <div style={{ width: '96%' }}>

                            <div className="form-header" style={{ marginBottom: "0px" }}>
                                <div className="title">
                                    <h1>Meus Pedidos</h1>
                                </div>
                            </div>

                            {/*<div className="imgDashboard" style={{zIndex:"10", marginTop:"30px"}}>
                            <img src={imageUrl} width={"100%"} height={"100%"} className='dashImg' style={{transition:"100ms"}}/>
                        </div> */}
                            <div className="header-mobile-kanban" style={{ display: 'inline-flex', width: '100%', justifyContent: 'space-between', marginLeft: 4 }}>
                                <div style={{ display: 'inline-flex', flexDirection: "column", justifyContent: 'space-between', justifyItems: 'center' }}>
                                    <div style={{ display: 'flex', alignItems: 'center', height: 'max-content', width: 'max-content', boxShadow: '0px 0px 3px 2px lightgray', borderRadius: '10px' }}>
                                        <div onClick={() => handleFilterAll()} style={{
                                            cursor: 'pointer', width: 79, height: 50, padding: 16, borderRadius: '10px 0px 0px 10px',
                                            background: filtroSelecionado === "all" ? '#318CD5' : 'white', justifyContent: 'center', alignItems: 'center', gap: 8, display: 'inline-flex'
                                        }}>
                                            <div style={{ color: filtroSelecionado === "all" ? 'white' : '#001B30', fontSize: 14, fontWeight: '700', letterSpacing: 0.48, wordWrap: 'break-word' }}>Todos</div>
                                        </div>
                                        <div style={{
                                            cursor: 'pointer', width: 132, height: 50, padding: 16, background: filtroSelecionado === "delivery" ? '#318CD5' : 'white',
                                            justifyContent: 'center', alignItems: 'center', gap: 8,
                                            display: 'inline-flex',
                                            borderLeft: '1px solid lightgray',
                                            borderRight: '1px solid lightgray'
                                        }} onClick={() => handleFilterDelivery()}>
                                            <div style={{ color: filtroSelecionado === "delivery" ? 'white' : '#001B30', fontSize: 14, fontWeight: '700', letterSpacing: 0.48, wordWrap: 'break-word' }}>
                                                <GiFullMotorcycleHelmet style={{ fontSize: 20 }} />
                                                <span style={{ paddingLeft: 8 }}>Delivery</span>
                                            </div>
                                        </div>
                                        <div onClick={() => handleFilterBalcao()} style={{
                                            cursor: 'pointer', width: 132, height: 50, padding: 16,
                                            background: filtroSelecionado === "balcao" ? '#318CD5' : 'white', justifyContent: 'center', alignItems: 'center', gap: 8, display: 'inline-flex'
                                        }}>
                                            <div style={{ color: filtroSelecionado === "balcao" ? 'white' : '#001B30', fontSize: 14, fontWeight: '700', letterSpacing: 0.48, wordWrap: 'break-word' }}>
                                                <MdStorefront style={{ fontSize: 20 }} />

                                                <span style={{ paddingLeft: 8 }}>Balcão</span>
                                            </div>
                                        </div>
                                        <div onClick={() => handleFilterMesas()} style={{
                                            borderLeft: '1px solid lightgray',
                                            cursor: 'pointer', width: 132, height: 50, padding: 16, borderRadius: '0px 10px 10px 0px',
                                            background: filtroSelecionado === "mesas" ? '#318CD5' : 'white', justifyContent: 'center', alignItems: 'center', gap: 8, display: 'inline-flex'
                                        }}>
                                            <div style={{ color: filtroSelecionado === "mesas" ? 'white' : '#001B30', fontSize: 14, fontWeight: '700', letterSpacing: 0.48, wordWrap: 'break-word' }}>
                                                <MdTableBar style={{ fontSize: 20 }} />
                                                <span style={{ paddingLeft: 8 }}>Mesas</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="input-box-list home" >
                                        <div style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
                                            <input style={{ width: '100%' }} value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)}
                                                className="input-fieldClienteOuPedido" placeholder="Buscar pelo cliente ou nº do pedido" />
                                            <i className="icon"><FiIcons.FiSearch /></i>
                                        </div>
                                    </div>
                                </div>

                                <div style={{ display: 'inline-flex' }}>
                                    <div className="div-buttons">
                                        <div className="continue-button">
                                            <button onClick={handleCadastroPDV}>
                                                <AiIcons.AiOutlinePlusCircle style={{ marginRight: "5px", fontSize: "22px", marginBottom: "2px" }} /><a>Novo Pedido</a>
                                            </button>
                                        </div>
                                    </div>

                                </div>

                            </div>


                        </div>


                        {window.innerWidth > 780 ? (
                            <div className="Ordercolumn" style={{ display: 'inline-flex', height: '674px', boxShadow: '0px 0px 5px 2px rgb(0,0,0,.1)' }}>

                                {filterIsLoading ? (
                                    <Loading type='spin' color='rgb(49, 140, 213)' height={56} width={56} className="loadingSpinHomeFilters" />
                                )
                                    : null
                                }

                                {/* Pedidos em Análise */}
                                <Ordercolumn
                                    typecolumn="analysis"
                                    className="column"
                                //style={{display:'table-row'}}
                                >
                                    <div className="containerAnalise">

                                        <div className="header-column-analise">

                                            <div className="header-column--align">
                                                <h4>Em análise</h4>
                                            </div>
                                            <h4>{pedidosAnalise.length}</h4>

                                        </div>

                                        <div className="blocoAnalise">
                                            <div className="column-container column-container--first">
                                                <div className="time">
                                                    <div>
                                                        <p className="titleOptEmp">
                                                            <b>Balcão: </b>
                                                            <span>{tempoBalcaoMinBD} a {tempoBalcaoMaxBD} min</span>
                                                        </p>
                                                        <p className="titleOptEmp">
                                                            <b>Delivery: </b>
                                                            <span>{tempoEntregaMinBD} a {tempoEntregaMaxBD} min</span>
                                                        </p>
                                                        <p className="titleOptEmp">
                                                            <b>Aceite automático: </b>
                                                            <span>{tipoImpressao == 'manual' ? 'Desativado' : tipoImpressao == 'automatico' ? 'Ativado' : 'Não definido'}</span>
                                                        </p>
                                                    </div>
                                                    <div className="text-edit" onClick={() => handleEditTempoEntrega(userID)} style={{ cursor: "pointer" }}>
                                                        {" "}
                                                        Editar{" "}
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                pedidosAnalise.length == 0 ?
                                                    <div key={Math.random()} className="column-container column-container--first">
                                                        <div className="text">
                                                            <p style={{ marginBottom: 0, justifyContent: 'center', textAlign: 'center', display: 'flex' }}>Nenhum pedido no momento.</p>
                                                            <p style={{ marginBottom: 0, justifyContent: 'center', textAlign: 'center', display: 'flex' }}>
                                                                Compartilhe os seus links nas redes sociais e receba pedidos!
                                                            </p>
                                                        </div>
                                                    </div>
                                                    :
                                                    //pedidos && pedidos.map((dados, i) => (
                                                    pedidosSimples && pedidosSimples.map((dados, i) => (
                                                        dados.status_pedido == '1' ?
                                                            <div key={Math.random()} className="bloco-pedidos" onClick={() => handleShowPedido(dados._id, dados.id_empresa)}>
                                                                <div className="pedido-time">
                                                                    <div className="tag-pedido">
                                                                        <div className="icon-pedido">
                                                                            {dados.entrega?.tipo_entrega == "Retirada" ? <FaStore /> : <FaMotorcycle />}
                                                                        </div>
                                                                        <span className="bold">Pedido #{dados.id_pedido_counter ?? dados.id_pedido}</span>

                                                                    </div>
                                                                    <div className="time-container">
                                                                        <div className="time-div">
                                                                            <FaRegClock />
                                                                            <span>{moment.parseZone(dados.createdAt).local().format("HH:mm")}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="cliente-total">
                                                                    <div className="bloco">
                                                                        <div className="linha">
                                                                            <div className="texto">{dados.nome_cliente}</div>
                                                                            {dados.counter_qtd_pedido > 1 ?
                                                                                <div className="label-recorrencia-pedidoMoreThanOne">
                                                                                    <div className="caixaNumero">
                                                                                        <span style={{ fontSize: 10 }}>{dados.counter_qtd_pedido}</span>
                                                                                    </div>
                                                                                </div>
                                                                                :
                                                                                <div className="label-recorrencia-pedido">
                                                                                    <div className="caixaNumero">
                                                                                        <span style={{ fontSize: 10 }}>1º</span>
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                        <div className="linha">
                                                                            <div className="texto">
                                                                                {dados.celular_cliente}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="bloco">
                                                                        <div className="linha">
                                                                            <div className="texto bold">Total:</div>
                                                                            <div className="texto bold">
                                                                                R$ {dados.valor_total && dados.valor_total.toFixed(2).toString().replace('.', ',')}
                                                                            </div>
                                                                        </div>
                                                                        <div className="linha">
                                                                            <div className="texto">Pagamento:</div>
                                                                            <div className="texto">{dados.tipo_pagamento}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="bloco-entrega">
                                                                    {dados.entrega?.tipo_entrega == "Retirada" ?
                                                                        <div className="flex-entrega">
                                                                            <div className="bloco-footer">
                                                                                <div className="pin" style={{ display: 'flex', marginTop: 1 }}>
                                                                                    <IoMdPin className="pin" />
                                                                                </div>
                                                                                <div className="texto">Retirada no Local</div>
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        <div className="flex-entrega">
                                                                            <div className="bloco-footer">
                                                                                <div className="pin">
                                                                                    <IoMdPin className="pin" />
                                                                                </div>
                                                                                <div className="texto">
                                                                                    {dados.entrega?.endereco && `${dados.entrega?.endereco?.rua}, ${dados.entrega?.endereco?.numero} 
                                                                        ${dados.entrega?.endereco?.complemento && " - " + dados.entrega?.endereco?.complemento}, ${dados.entrega?.endereco?.bairro}`}
                                                                                </div>
                                                                            </div>
                                                                            <div className="bloco" onClick={(event) => handleAddEntregador(event, dados)}>
                                                                                {dados.entregador ?
                                                                                    <div className="texto underline">{dados.entregador.name}</div>
                                                                                    :
                                                                                    <div className="texto underline">Escolher Entregador</div>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </div>

                                                                <div style={{ marginTop: 10 }}>
                                                                    <div className="button-avancar-pedido">
                                                                        <button onClick={(event) => handleAvancar(event, dados._id, dados.id_pedido, dados.status_pedido)}>
                                                                            <div>
                                                                                <a>Avançar Pedido</a>
                                                                                <AiIcons.AiOutlineArrowRight style={{ marginLeft: "2px", fontSize: "22px", marginBottom: "2px", fontColor: 'white' }} />
                                                                            </div>
                                                                        </button>
                                                                        {/*isLoading ? 
                                                                    <button>                                                               
                                                                        <Loading type='spin' color='rgb(49, 140, 213)' height={32} width={32} />
                                                                    </button>
                                                                    :
                                                                    <button onClick={(event) => handleAvancar(event, dados._id, dados.id_pedido, dados.status_pedido)}>                                                                 
                                                                        <div>
                                                                            <a>Avançar Pedido</a>
                                                                            <AiIcons.AiOutlineArrowRight style={{marginLeft: "2px",fontSize: "22px",marginBottom: "2px", fontColor:'white'}}/>
                                                                        </div>                                                                                                                                 
                                                                    </button>
                                                                */}
                                                                    </div>
                                                                </div>

                                                            </div>
                                                            :
                                                            null

                                                    ))

                                            }

                                        </div>
                                    </div>

                                </Ordercolumn>

                                {/* Pedidos em produção */}
                                <Ordercolumn
                                    typecolumn="production"
                                    className="column"
                                //style={{display:'table-row'}}
                                >
                                    <div className="containerProducao">
                                        <div className="header-column-producao">
                                            <div className="header-column--align">
                                                <h4>Em produção</h4>
                                            </div>
                                            <h4>{pedidosProducao.length}</h4>
                                        </div>

                                        <div className="blocoProducao">

                                            {pedidosProducao.length == 0 ?
                                                <div key={Math.random()} className="column-container column-container--first">
                                                    <div className="text">
                                                        <p style={{ marginBottom: 0, justifyContent: 'center', textAlign: 'center', display: 'flex' }}>Nenhum pedido no momento.</p>
                                                        <p style={{ marginBottom: 0, justifyContent: 'center', textAlign: 'center', display: 'flex' }}>
                                                            Receba pedidos e visualize os que estão em produção.
                                                        </p>
                                                    </div>
                                                </div>
                                                :
                                                pedidosSimples && pedidosSimples.map((dados, i) => (
                                                    dados.status_pedido == '2' ?
                                                        <div key={Math.random()} className="bloco-pedidos" onClick={() => handleShowPedido(dados._id, dados.id_empresa)}>
                                                            <div className="pedido-time">
                                                                <div className="tag-pedido">
                                                                    <div className="icon-pedido">
                                                                        {dados.entrega?.tipo_entrega == "Retirada" ? <FaStore /> : <FaMotorcycle />}
                                                                    </div>
                                                                    <span className="bold">Pedido #{dados.id_pedido_counter ?? dados.id_pedido}</span>

                                                                </div>
                                                                <div className="time-container">
                                                                    <div className="time-div">
                                                                        <FaRegClock />
                                                                        <span>{moment.parseZone(dados.createdAt).local().format("HH:mm")}</span>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="cliente-total">
                                                                <div className="bloco">
                                                                    <div className="linha">
                                                                        <div className="texto">{dados.nome_cliente}</div>
                                                                        {dados.counter_qtd_pedido > 1 ?
                                                                            <div className="label-recorrencia-pedidoMoreThanOne">
                                                                                <div className="caixaNumero">
                                                                                    <span style={{ fontSize: 10 }}>{dados.counter_qtd_pedido}</span>
                                                                                </div>
                                                                            </div>
                                                                            :
                                                                            <div className="label-recorrencia-pedido">
                                                                                <div className="caixaNumero">
                                                                                    <span style={{ fontSize: 10 }}>1º</span>
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                    <div className="linha">
                                                                        <div className="texto">
                                                                            {dados.celular_cliente}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="bloco">
                                                                    <div className="linha">
                                                                        <div className="texto bold">Total:</div>
                                                                        <div className="texto bold">
                                                                            R$ {dados.valor_total && dados.valor_total.toFixed(2).toString().replace('.', ',')}
                                                                        </div>
                                                                    </div>
                                                                    <div className="linha">
                                                                        <div className="texto">Pagamento:</div>
                                                                        <div className="texto">{dados.tipo_pagamento}</div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="bloco-entrega">
                                                                {dados.createdBy.toLowerCase().includes("mesa") ?
                                                                    (
                                                                        <div className="flex-entrega">
                                                                            <div className="bloco-footer">
                                                                                <div className="pin" style={{ display: 'flex', marginTop: 1 }}>
                                                                                    <IoMdPin className="pin" />
                                                                                </div>
                                                                                <div className="texto">{dados.createdBy}</div>
                                                                            </div>
                                                                        </div>
                                                                    ) : (
                                                                        <>
                                                                            {dados.entrega?.tipo_entrega == "Retirada" ?
                                                                                <div className="flex-entrega">
                                                                                    <div className="bloco-footer">
                                                                                        <div className="pin" style={{ display: 'flex', marginTop: 1 }}>
                                                                                            <IoMdPin className="pin" />
                                                                                        </div>
                                                                                        <div className="texto">Retirada no Local</div>
                                                                                    </div>
                                                                                </div>
                                                                                :
                                                                                <div className="flex-entrega">
                                                                                    <div className="bloco-footer">
                                                                                        <div className="pin">
                                                                                            <IoMdPin className="pin" />
                                                                                        </div>
                                                                                        <div className="texto">
                                                                                            {dados.entrega && `${dados.entrega?.endereco?.rua}, ${dados.entrega?.endereco?.numero} 
                                                                                ${dados.entrega?.endereco?.complemento && " - " + dados.entrega?.endereco?.complemento}, ${dados.entrega?.endereco?.bairro}`}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="bloco" onClick={(event) => handleAddEntregador(event, dados)}>
                                                                                        {dados.entregador ?
                                                                                            <div className="texto underline">{dados.entregador.name}</div>
                                                                                            :
                                                                                            <div className="texto underline">Escolher Entregador</div>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                        </>
                                                                    )}
                                                            </div>

                                                            <div style={{ marginTop: 10 }}>
                                                                <div className="button-avancar-pedido">

                                                                    <button onClick={(event) => handleAvancar(event, dados._id, dados.id_pedido, dados.status_pedido)}>
                                                                        <div>
                                                                            <a>Avançar Pedido</a>
                                                                            <AiIcons.AiOutlineArrowRight style={{ marginLeft: "2px", fontSize: "22px", marginBottom: "2px", fontColor: 'white' }} />
                                                                        </div>
                                                                    </button>

                                                                </div>
                                                            </div>

                                                        </div>
                                                        :
                                                        null
                                                ))
                                            }

                                        </div>

                                    </div>

                                </Ordercolumn>

                                {/* Pedidos prontos para Entrega */}
                                <Ordercolumn
                                    _ngcontent-ng-c2041748172=""
                                    typecolumn="ready"
                                    className="column"
                                    _nghost-ng-c2960941283=""
                                    style={{ display: 'table-row' }}
                                >
                                    <div className="containerPronto">
                                        <div className="header-column-pronto">
                                            <div className="header-column--align" style={{ display: 'inline-flex' }}>
                                                <h4>Prontos para entrega</h4>
                                            </div>
                                            <h4>{pedidosEntrega.length}</h4>
                                        </div>

                                        <div className="blocoPronto">
                                            {pedidosEntrega.length == 0 ?
                                                <div key={Math.random()} className="column-container column-container--first">
                                                    <div className="text">
                                                        <p style={{ marginBottom: 0, justifyContent: 'center', textAlign: 'center', display: 'flex' }}>Nenhum pedido no momento.</p>
                                                        <p style={{ marginBottom: 0, justifyContent: 'center', textAlign: 'center', display: 'flex' }}>
                                                            Receba pedidos e visualize os que estão prontos para entrega.
                                                        </p>
                                                    </div>
                                                </div>
                                                :
                                                pedidosSimples && pedidosSimples.map((dados, i) => (
                                                    dados.status_pedido == '3' ?
                                                        <div key={Math.random()} className="bloco-pedidos" onClick={() => handleShowPedido(dados._id, dados.id_empresa)}>
                                                            <div className="pedido-time">
                                                                <div className="tag-pedido">
                                                                    <div className="icon-pedido">
                                                                        {dados.entrega?.tipo_entrega == "Retirada" ? <FaStore /> : <FaMotorcycle />}
                                                                    </div>
                                                                    <span className="bold">Pedido #{dados.id_pedido_counter ?? dados.id_pedido}</span>

                                                                </div>
                                                                <div className="time-container">
                                                                    <div className="time-div">
                                                                        <FaRegClock />
                                                                        <span>{moment.parseZone(dados.createdAt).local().format("HH:mm")}</span>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="cliente-total">
                                                                <div className="bloco">
                                                                    <div className="linha">
                                                                        <div className="texto">{dados.nome_cliente}</div>
                                                                        {dados.counter_qtd_pedido > 1 ?
                                                                            <div className="label-recorrencia-pedidoMoreThanOne">
                                                                                <div className="caixaNumero">
                                                                                    <span style={{ fontSize: 10 }}>{dados.counter_qtd_pedido}</span>
                                                                                </div>
                                                                            </div>
                                                                            :
                                                                            <div className="label-recorrencia-pedido">
                                                                                <div className="caixaNumero">
                                                                                    <span style={{ fontSize: 10 }}>1º</span>
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                    <div className="linha">
                                                                        <div className="texto">
                                                                            {dados.celular_cliente}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="bloco">
                                                                    <div className="linha">
                                                                        <div className="texto bold">Total:</div>
                                                                        <div className="texto bold">
                                                                            R$ {dados.valor_total && dados.valor_total.toFixed(2).toString().replace('.', ',')}
                                                                        </div>
                                                                    </div>
                                                                    <div className="linha">
                                                                        <div className="texto">Pagamento:</div>
                                                                        <div className="texto">{dados.tipo_pagamento}</div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="bloco-entrega">
                                                                {dados.createdBy.toLowerCase().includes("mesa") ?
                                                                    (
                                                                        <div className="flex-entrega">
                                                                            <div className="bloco-footer">
                                                                                <div className="pin" style={{ display: 'flex', marginTop: 1 }}>
                                                                                    <IoMdPin className="pin" />
                                                                                </div>
                                                                                <div className="texto">{dados.createdBy}</div>
                                                                            </div>
                                                                        </div>
                                                                    ) : (
                                                                        <>
                                                                            {dados.entrega?.tipo_entrega == "Retirada" ?
                                                                                <div className="flex-entrega">
                                                                                    <div className="bloco-footer">
                                                                                        <div className="pin" style={{ display: 'flex', marginTop: 1 }}>
                                                                                            <IoMdPin className="pin" />
                                                                                        </div>
                                                                                        <div className="texto">Retirada no Local</div>
                                                                                    </div>
                                                                                </div>
                                                                                :
                                                                                <div className="flex-entrega">
                                                                                    <div className="bloco-footer">
                                                                                        <div className="pin">
                                                                                            <IoMdPin className="pin" />
                                                                                        </div>
                                                                                        <div className="texto">
                                                                                            {dados.entrega && `${dados.entrega?.endereco?.rua}, ${dados.entrega?.endereco?.numero} 
                                                                                ${dados.entrega?.endereco?.complemento && " - " + dados.entrega?.endereco?.complemento}, ${dados.entrega?.endereco?.bairro}`}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="bloco" onClick={(event) => handleAddEntregador(event, dados)}>
                                                                                        {dados.entregador ?
                                                                                            <div className="texto underline">{dados.entregador.name}</div>
                                                                                            :
                                                                                            <div className="texto underline">Escolher Entregador</div>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                        </>
                                                                    )}
                                                            </div>

                                                            <div style={{ marginTop: 10 }}>
                                                                <div className="button-avancar-pedido">

                                                                    <button onClick={(event) => handleAvancar(event, dados._id, dados.id_pedido, dados.status_pedido)}>
                                                                        <div>
                                                                            <a>Avançar Pedido</a>
                                                                            <AiIcons.AiOutlineArrowRight style={{ marginLeft: "2px", fontSize: "22px", marginBottom: "2px", fontColor: 'white' }} />
                                                                        </div>
                                                                    </button>

                                                                </div>
                                                            </div>

                                                        </div>
                                                        :
                                                        null
                                                ))
                                            }
                                        </div>
                                    </div>

                                </Ordercolumn>


                                {/* Pedidos Finalizados */}
                                <Ordercolumn
                                    _ngcontent-ng-c2041748172=""
                                    typecolumn="ready"
                                    className="column"
                                    _nghost-ng-c2960941283=""
                                    style={{ display: 'table-row' }}
                                >
                                    <div className="containerFinalizado">
                                        <div className="header-column-finalizado">
                                            <div className="header-column--align" style={{ display: 'inline-flex' }}>
                                                <h4>Finalizados</h4>
                                                <div className="header-column-clear" style={{ display: 'flex', alignItems: 'center', marginLeft: 6 }}>
                                                    <pedeja-icon
                                                        iconname="check-circle"
                                                        iconsize={18}
                                                        pedejatooltip="Finalizar todos os pedidos não relacionados a mesa"
                                                        iconcolor="#FFFFFF"
                                                        style={{ height: 18, display: 'flex', alignItems: 'center' }}
                                                    >
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width={18}
                                                            height={18}
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            stroke="#FFFFFF"
                                                            strokeWidth={3}
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            className="feather feather-check-circle"
                                                        >
                                                            <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14" />
                                                            <polyline points="22 4 12 14.01 9 11.01" />
                                                        </svg>
                                                    </pedeja-icon>
                                                    {/**/}
                                                </div>
                                            </div>
                                            <h4>{pedidosFinalizado.length}</h4>
                                        </div>

                                        <div className="blocoFinalizado">
                                            {pedidosFinalizado.length == 0 ?
                                                <div key={Math.random()} className="column-container column-container--first">
                                                    <div className="text">
                                                        <p style={{ marginBottom: 0, justifyContent: 'center', textAlign: 'center', display: 'flex' }}>Nenhum pedido no momento.</p>
                                                        <p style={{ marginBottom: 0, justifyContent: 'center', textAlign: 'center', display: 'flex' }}>
                                                            Receba pedidos e visualize os finalizados.
                                                        </p>
                                                    </div>
                                                </div>
                                                :
                                                pedidosFinalizados && pedidosFinalizados.map((dados, i) => (
                                                    dados.status_pedido == '4' ?
                                                        <div key={Math.random()} className="bloco-pedidos" onClick={() => handleShowPedido(dados._id, dados.id_empresa)}>
                                                            <div className="pedido-time">
                                                                <div className="tag-pedido">
                                                                    <div className="icon-pedido">
                                                                        {dados.entrega?.tipo_entrega == "Retirada" ? <FaStore /> : <FaMotorcycle />}
                                                                    </div>
                                                                    <span className="bold">Pedido #{dados.id_pedido_counter ?? dados.id_pedido}</span>
                                                                    {dados.cancelado &&
                                                                        <div className="divSpanCancelado">
                                                                            <span>CANCELADO</span>
                                                                        </div>
                                                                    }
                                                                </div>
                                                                <div className="time-container">
                                                                    <div className="time-div">
                                                                        <FaRegClock />
                                                                        <span>{moment.parseZone(dados.createdAt).local().format("HH:mm")}</span>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="cliente-total">
                                                                <div className="bloco">
                                                                    <div className="linha">
                                                                        <div className="texto">{dados.nome_cliente}</div>
                                                                        {dados.counter_qtd_pedido > 1 ?
                                                                            <div className="label-recorrencia-pedidoMoreThanOne">
                                                                                <div className="caixaNumero">
                                                                                    <span style={{ fontSize: 10 }}>{dados.counter_qtd_pedido}</span>
                                                                                </div>
                                                                            </div>
                                                                            :
                                                                            <div className="label-recorrencia-pedido">
                                                                                <div className="caixaNumero">
                                                                                    <span style={{ fontSize: 10 }}>1º</span>
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                    <div className="linha">
                                                                        <div className="texto">
                                                                            {dados.celular_cliente}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="bloco">
                                                                    <div className="linha">
                                                                        <div className="texto bold">Total:</div>
                                                                        <div className="texto bold">
                                                                            R$ {dados.valor_total && dados.valor_total.toFixed(2).toString().replace('.', ',')}
                                                                        </div>
                                                                    </div>
                                                                    <div className="linha">
                                                                        <div className="texto">Pagamento:</div>
                                                                        <div className="texto">{dados.tipo_pagamento}</div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="bloco-entrega">
                                                                {dados.createdBy.toLowerCase().includes("mesa") ?
                                                                    (
                                                                        <div className="flex-entrega">
                                                                            <div className="bloco-footer">
                                                                                <div className="pin" style={{ display: 'flex', marginTop: 1 }}>
                                                                                    <IoMdPin className="pin" />
                                                                                </div>
                                                                                <div className="texto">{dados.createdBy}</div>
                                                                            </div>
                                                                        </div>
                                                                    ) : (
                                                                        <>
                                                                            {dados.entrega?.tipo_entrega == "Retirada" ?
                                                                                <div className="flex-entrega">
                                                                                    <div className="bloco-footer">
                                                                                        <div className="pin" style={{ display: 'flex', marginTop: 1 }}>
                                                                                            <IoMdPin className="pin" />
                                                                                        </div>
                                                                                        <div className="texto">Retirada no Local</div>
                                                                                    </div>
                                                                                </div>
                                                                                :
                                                                                <div className="flex-entrega">
                                                                                    <div className="bloco-footer">
                                                                                        <div className="pin">
                                                                                            <IoMdPin className="pin" />
                                                                                        </div>
                                                                                        <div className="texto">
                                                                                            {dados.entrega && `${dados.entrega?.endereco?.rua}, ${dados.entrega?.endereco?.numero} 
                                                                                ${dados.entrega?.endereco?.complemento && " - " + dados.entrega?.endereco?.complemento}, ${dados.entrega?.endereco?.bairro}`}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="bloco" onClick={(event) => handleAddEntregador(event, dados)}>
                                                                                        {dados.entregador ?
                                                                                            <div className="texto underline">{dados.entregador.name}</div>
                                                                                            :
                                                                                            <div className="texto underline">Escolher Entregador</div>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                        </>
                                                                    )}
                                                            </div>



                                                        </div>
                                                        :
                                                        null

                                                ))
                                            }
                                        </div>
                                    </div>

                                </Ordercolumn>

                            </div>
                        ) : (
                            <MobileKanban
                                tempoBalcaoMinBD={tempoBalcaoMinBD}
                                tempoBalcaoMaxBD={tempoBalcaoMaxBD}
                                tempoEntregaMinBD={tempoEntregaMinBD}
                                tempoEntregaMaxBD={tempoEntregaMaxBD}
                                tipoImpressao={tipoImpressao}
                                pedidosAnalise={pedidosAnalise}
                                pedidosFinalizados={pedidosFinalizados}
                                pedidosSimples={pedidosSimples}
                                pedidosProducao={pedidosProducao}
                                pedidosEntrega={pedidosEntrega}
                                pedidosFinalizado={pedidosFinalizado}
                                filterIsLoading={filterIsLoading}
                                handleShowPedido={handleShowPedido}
                                handleEditTempoEntrega={handleEditTempoEntrega}
                                handleAddEntregador={handleAddEntregador}
                                handleAvancar={handleAvancar}
                                userID={userID}
                            />
                        )}
                    </div>
                </Teste>

            </PermissionGate>
        </>


    );
};

export default User;