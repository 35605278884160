import React from "react";
import { useNavigate } from "react-router-dom";

const AtendimentoModal = ({ atendimentosPendentes, removerAtendimento, modalOpen, setModalOpen }) => {
    const navigate = useNavigate();

    if (!modalOpen) return null;

    return (
        <div className="atendimento-modal">
            <div className="atendimento-modal-content">
                <h3>📢 Atendimentos Pendentes</h3>

                {atendimentosPendentes.length === 0 ? (
                    <p>✅ Nenhum atendimento pendente</p>
                ) : (
                    atendimentosPendentes.map((atendimento) => {
                        // **📌 Normalizar número de telefone**
                        const telefone = atendimento.celular.split("@")[0]; // Remove o domínio
                        const telefoneFormatado = `+${telefone.slice(0, 2)} ${telefone.slice(2, 4)} ${telefone.slice(4, 8)}-${telefone.slice(8)}`;

                        // **📌 Função para direcionar ao WhatsApp**
                        const direcionarParaWhatsApp = () => {
                            removerAtendimento(atendimento.atendimento_id); // Remove da lista
                            setModalOpen(false);
                            navigate("/whatsapp", { state: { lead_id: atendimento.lead_id } }); // Passa o lead_id
                        };

                        return (
                            <div key={atendimento.atendimento_id} className="atendimento-card">
                                <p><strong>👤 Cliente:</strong> {atendimento.nome}</p>
                                <p><strong>📞 Telefone:</strong> {telefoneFormatado}</p>
                                <p><strong>💬 Mensagem:</strong> {atendimento.mensagem}</p>

                                <div className="atendimento-buttons">
                                    <button className="atendimento-whatsapp-btn" onClick={direcionarParaWhatsApp}>📲 Responder no WhatsApp</button>
                                    <button className="atendimento-resolvido-btn" onClick={() => removerAtendimento(atendimento.atendimento_id)}>✅ Resolvido</button>
                                </div>
                            </div>
                        );
                    })
                )}

                <button className="atendimento-fechar-btn" onClick={() => setModalOpen(false)}>❌ Fechar</button>
            </div>
        </div>
    );
};

export default AtendimentoModal;