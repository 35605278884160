import React, { useState, useRef, useEffect, useContext } from "react";
import './style.css'
import { Modal, Button } from "react-bootstrap"; // Usando o react-bootstrap para o modal
import styled from 'styled-components';
import 'bootstrap/dist/css/bootstrap.min.css';
import CryptoJS from 'crypto-js';
import { AuthContext } from "../../contexts/auth";
import { SidebarContext } from "../../AppRoutes";
import {
    getCategorias,
    getItens,
    updateCategoriasOrder,
    deleteCategoria,
    updateItensOrder,
    updateItemOut,
    updateItemPrice,
    deleteItem,
    getAdicionais,
    updateItemOutSalao,
    updateItemPriceSalao,
    updateItemAdicionalOut,
    updateItemAdicionalOutSalao,
    deleteItemAdicional,
    removeAdicionalFromItem
} from "../../services/api";
import InputMoney from "./InputMoney";
import PermissionGate from "../../services/PermissionGate";

import { useNavigate } from "react-router-dom";
import LeftMenu from "../../components/LeftMenu";
import ConfirmDialog from "../../components/ConfirmDialog";

import { toast } from "react-toastify";
import Loading from "react-loading";

import * as FiIcons from 'react-icons/fi'
import * as AiIcons from 'react-icons/ai'
import * as SlIcons from 'react-icons/sl'
import { MdKeyboardArrowDown } from "react-icons/md";
import { MdFastfood } from "react-icons/md";
import { FaRegEdit } from "react-icons/fa";
import { FaRegTrashAlt } from "react-icons/fa";
import { BsPlusCircle } from "react-icons/bs";
import { MdDragIndicator } from "react-icons/md";
import { IoLink } from "react-icons/io5";
import { SlArrowDown, SlArrowUp } from "react-icons/sl";
import { TbDotsVertical } from "react-icons/tb";
import { FaPizzaSlice } from "react-icons/fa6";

import ModalEditCategoria from "../../components/ModalEditCategoria";
import ModalEditGrupoAdd from "./ModalEditGrupoAdd";
import ModalEditItemAdicional from "./ModalEditItemAdicional"
import ModalRegisterItemAdicional from "./ModalRegisterItemAdicional"
import { Draggable, Droppable, DragDropContext } from '@hello-pangea/dnd';

const Teste = styled.div`
    display: flex;
    margin-left:  ${({ sidebar }) => (sidebar ? '250px' : '100px')}; 
    height:auto;
    width:auto;
    transition: 150ms;
    background-color:rgb(247,247,247)!important;
    overflow: initial;as
    z-Index: 9;

    @media (max-width: 880px) {
        margin-left: 0;
    }
`;

const SmoothShowAndHideDropdown = styled.div`
    max-height: 0px; /* Calcula a altura dinamicamente */
    overflow: hidden;
    transition: max-height 0.3s ease-out; /* Transição suave */
    visibility: hidden;

    &.expandedItemCardapio {
        visibility: visible;
        max-height: ${(props) => props.dynamicHeight};
        overflow: visible;                
    }
`;

const SmoothShowAndHideDropdownItens = styled.div`
    max-height: 0px; /* Calcula a altura dinamicamente */
    overflow: hidden;
    transition: max-height 0.3s ease-out; /* Transição suave */
    visibility: hidden;
    --border: 1px solid lightgray;
    --border-radius: 0px 0px 5px 5px;
    --padding: 10px;

    &.expandedAdicionalCardapio {
        visibility: visible;
        max-height: ${(props) => props.dynamicHeight};
        overflow: visible;
        border-bottom: 1px dashed #7e7e7e;
        border-left: 1px dashed #7e7e7e;
        border-right: 1px dashed #7e7e7e;
        border-radius: 0px 0px 10px 10px;
    }
`;

const ListCategoria = ({ list = [] }) => {
    //const { user } = useContext(AuthContext);
    const ref = useRef();
    //TESTES
    const secretKey = 'my-custom-secret-key:1af0addcce4b56f753ed10c285667ad031bae57af19747f576b05312b8cf85be';
    const userEncrypted = localStorage.getItem('user')
    const user = CryptoJS.AES.decrypt(userEncrypted, secretKey).toString(CryptoJS.enc.Utf8);
    const userParse = JSON.parse(user)
    const userID = userParse._id;
    const empresa = localStorage.getItem('empresa')
    const empresaParse = JSON.parse(empresa)
    const empresaObjId = empresaParse._id;
    const idEmpresa = empresaParse.id_empresa
    const vinculo_empresa = empresaParse.cnpj
    const nomeEmpresa = empresaParse.name;
    const nomeEmpresaForUrl = nomeEmpresa.replace(/\s+/g, '-').replace(/[A-Z]/g, c => c.toLowerCase());
    const navigate = useNavigate();

    const [categorias, setCategorias] = useState([]);
    const [itens, setItens] = useState([]);
    const [filter, setFilter] = useState('');
    const [result, setResult] = useState(false);

    const [order, setOrder] = useState(1)
    const [columnOrder, setColumnOrder] = useState("")

    const { sidebar, setSidebar } = useContext(SidebarContext);

    const [loading, setLoading] = useState(true);
    const [refresh, setRefresh] = useState(false);
    const [refreshAdicional, setRefreshAdicional] = useState(false);
    const [categoriaAddToEdit, setCategoriaAddToEdit] = useState(null)

    const [showEditCategoria, setEditCategoria] = useState(true);
    const [showEditCategoriaAdd, setShowEditCategoriaAdd] = useState(true);
    const [visibleDropdown, setVisibleDropdown] = useState(null);
    const dropdownRef = useRef(null);
    const dropdownRefAdicional = useRef(null);
    const [visibleDropdownAdicional, setVisibleDropdownAdicional] = useState(null);

    const [visibleDropdownItem, setVisibleDropdownItem] = useState(null);
    const [visibleInput, setVisibleInput] = useState(null);
    const [visibleInputSalao, setVisibleInputSalao] = useState(null);
    const inputRef = useRef(null);
    const inputRefSalao = useRef(null);
    const dropdownRefItem = useRef(null);

    const [currentPrice, setCurrentPrice] = useState(0);
    const [num, setNum] = useState(itens.map(item => 1));
    const [currentPriceSalao, setCurrentPriceSalao] = useState(0);

    const [expandedCategories, setExpandedCategories] = useState(new Set());
    const [adicionais, setAdicionais] = useState([]);
    const [showEditItemAdicional, setShowEditItemAdicional] = useState(true);
    const [itemAdicionalToEdit, setItemAdicionalToEdit] = useState(null);
    const [idGrupoToEdit, setIdGrupoToEdit] = useState("");

    const [showRegisterItemAdicional, setShowRegisterItemAdicional] = useState(true);
    //const [itemAdicionalToRegister, setItemAdicionalToRegister] = useState(null);
    const [idGrupoToRegister, setIdGrupoToRegister] = useState("");

    const [expandedItens, setExpandedItens] = useState(new Set());

    const toggleDropdown = (id) => {
        setExpandedCategories((prevState) => {
            const newExpandedCategories = new Set(prevState);
            if (newExpandedCategories.has(id)) {
                newExpandedCategories.delete(id);  // Remove se já estiver expandido
            } else {
                newExpandedCategories.add(id);    // Adiciona se não estiver expandido
            }
            return newExpandedCategories;
        });
    }

    const toggleDropdownItens = (id) => {
        setExpandedItens((prevState) => {
            const newExpandedItens = new Set(prevState);
            if (newExpandedItens.has(id)) {
                newExpandedItens.delete(id);  // Remove se já estiver expandido
            } else {
                newExpandedItens.add(id);    // Adiciona se não estiver expandido
            }
            return newExpandedItens;
        });
    }

    // Função para alternar a visibilidade do dropdown
    const toggleOptions = (id) => {
        console.log("id:", id);
        console.log("visibleDropdown:", visibleDropdown)
        if (visibleDropdown === id) {
            setVisibleDropdown(null);
        } else {
            setVisibleDropdown(id);
        }
    };
    const toggleOptionsItem = (id) => {
        if (visibleDropdownItem === id) {
            setVisibleDropdownItem(null);
        } else {
            setVisibleDropdownItem(id);
        }
    };

    const toggleOptionsAdicional = (id) => {
        if (visibleDropdownAdicional === id) {
            setVisibleDropdownAdicional(null);
        } else {
            setVisibleDropdownAdicional(id);
        }
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setVisibleDropdown(null);
        }
    };

    const handleClickOutsideItem = (event) => {
        if (dropdownRefItem.current && !dropdownRefItem.current.contains(event.target)) {
            setVisibleDropdownItem(null);
        }
        if (inputRef.current && !inputRef.current.contains(event.target)) {
            console.log("visibleInput>>", visibleInput)
            if (visibleInput !== null) {
                const item = itens.find(it => it._id === visibleInput);
                handleBlurOrEnter(item._id, item.id_item, currentPrice);
                //setVisibleInput(null);
            }
        }
        if (inputRefSalao.current && !inputRefSalao.current.contains(event.target)) {
            console.log("visibleInputSalao>>", visibleInputSalao)
            if (visibleInputSalao !== null) {
                const item = itens.find(it => it._id === visibleInputSalao);
                handleBlurOrEnterSalao(item._id, item.id_item, currentPriceSalao);
                //setVisibleInput(null);
            }
        }
    };

    const handleClickOutsideAdicional = (event) => {
        if (dropdownRefAdicional.current && !dropdownRefAdicional.current.contains(event.target)) {
            setVisibleDropdownAdicional(null);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        document.addEventListener('mousedown', handleClickOutsideItem);
        document.addEventListener('mousedown', handleClickOutsideAdicional);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('mousedown', handleClickOutsideItem);
            document.removeEventListener('mousedown', handleClickOutsideAdicional);
        };
    }, []);


    useEffect(() => {
        (async () => {
            const response = await getCategorias(userID, idEmpresa, vinculo_empresa, empresaObjId)//getEmpresas(user._id);
            const itemTemp = await getItens(userID, idEmpresa, vinculo_empresa, empresaObjId)
            const adicionaisTemp = await getAdicionais(userID, idEmpresa, vinculo_empresa, empresaObjId)
            //setEmpresas(response.data.empresa);
            setCategorias(response.data.categorias);
            setItens(itemTemp.data.itens);
            setAdicionais(adicionaisTemp.data.adicionais)
            setLoading(false);
            setRefresh(false);

        })();
    }, [refresh, refreshAdicional]);


    const [confirmOpen, setConfirmOpen] = useState(false);
    const [shouldDelete, setShouldDelete] = useState(false);
    const [idToDelete, setIdToDelete] = useState('');
    const handleDelete = (idRecebido) => {
        // Abre Componente de Confirmação do delete
        setConfirmOpen(true)

        setIdToDelete(idRecebido)
        if (idRecebido) {
            console.log("ID RECEBIDO:", idRecebido)
            setShouldDelete(true);
        }
    }
    const deleteReg = async () => {
        console.log("O ID CLICADO FOI :::", idToDelete);
        console.log("ID do Usuário que irá efetuar a exclusão", userID);

        if (shouldDelete) {
            deleteCategoria(userID, idToDelete).then(cast => {
                console.log("RESULT DELETE:", cast);
                if (cast.status === 201) {
                    toast(cast.data.msg, { autoClose: 5000, type: "success" });
                    setRefresh(true);
                } else {
                    toast(cast.data.msg, { autoClose: 5000, type: "error" });
                }
            }).catch(err => {
                console.log("ERROR:", err);
                toast(err.response.data.msg, { autoClose: 5000, type: "error" });
            });
        }

    }

    const [confirmOpenItem, setConfirmOpenItem] = useState(false);
    const [shouldDeleteItem, setShouldDeleteItem] = useState(false);
    const [itemIdToDelete, setItemIdToDelete] = useState('');
    const handleDeleteItem = (idRecebido) => {
        // Abre Componente de Confirmação do delete
        setConfirmOpenItem(true)

        setItemIdToDelete(idRecebido)
        if (idRecebido) {
            //console.log("ID RECEBIDO:",idRecebido)
            setShouldDeleteItem(true);
        }
    }
    const deleteItemReg = async () => {
        //console.log("O ID CLICADO FOI :::",idToDelete);
        //console.log("ID do Usuário que irá efetuar a exclusão", user._id);

        if (shouldDeleteItem) {
            deleteItem(user._id, itemIdToDelete).then(cast => {
                //console.log("RESULT DELETE:",cast);
                if (cast.status === 201) {
                    toast(cast.data.msg, { autoClose: 5000, type: "success" });
                    setRefresh(true);
                } else {
                    toast(cast.data.msg, { autoClose: 5000, type: "error" });
                }
            }).catch(err => {
                //console.log("ERROR:",err);
                toast(err.response.data.msg, { autoClose: 5000, type: "error" });
            });
        }

    }

    const handleDeleteSubAdicional = (id_grupo, id) => {
        console.log("ID DO ITEM A SER DELETADO:", id);
        const id_subadicional = id;
        const data = {
            userID,
            id_subadicional,
            id_grupo,
        };

        deleteItemAdicional(data).then(response => {
            if (response.status === 200) {
                toast(response.data.msg, { autoClose: 5000, type: "success" });
                // Atualize a lista de adicionais ou faça outra ação necessária
                setRefresh(true);
            } else {
                toast(response.data.msg, { autoClose: 5000, type: "error" });
            }
        }).catch(err => {
            toast(err.response.data.msg, { autoClose: 5000, type: "error" });
        });
    }

    const [_idCategoriaToEdit, setIdCategoriaToEdit] = useState("")
    const [id_categoria, setIdCategoria] = useState("")
    const [_modeloToEdit, setModeloToEdit] = useState("")
    const [_titleToEdit, setTitleToEdit] = useState("")
    const [_disponibilidadeToEdit, setDisponibilidadeToEdit] = useState("")
    const [_diaHorarioDispToEdit, setDiaHorarioDispToEdit] = useState([])
    const handleEdit = async (idToEdit, id_categoria, modeloToEdit, titleToEdit, disponibilidade, dia_horario_disponibilidade) => {
        console.log("modeloToEdit: ", modeloToEdit);
        setIdCategoriaToEdit(idToEdit);
        setIdCategoria(id_categoria);
        setModeloToEdit(modeloToEdit)
        setTitleToEdit(titleToEdit);
        setDisponibilidadeToEdit(disponibilidade);
        //setDiaHorarioDispToEdit(dia_horario_disponibilidade);

        const novoArray = dia_horario_disponibilidade.map(obj => {
            const { dia, startTime, endTime } = obj;
            const novoObj = {
                dias: new Set([dia]),
                startTime,
                endTime
            };
            return novoObj;
        });

        const teste = novoArray.reduce((acc, curr) => {
            const prevItem = acc[acc.length - 1];
            if (
                prevItem &&
                prevItem.startTime === curr.startTime &&
                prevItem.endTime === curr.endTime
            ) {
                prevItem.dias = new Set([...prevItem.dias, ...curr.dias]);
            } else {
                acc.push(curr);
            }
            return acc;
        }, []);

        //console.log("teste",teste)
        //setDiaHorarioDispToEdit(teste);
        if (teste.length === 0) {
            setDiaHorarioDispToEdit([
                {
                    dias: new Set([""]),
                    startTime: "00:00",
                    endTime: "23:59",
                }
            ]);
        } else {
            setDiaHorarioDispToEdit(teste);
        }

        setEditCategoria(!showEditCategoria)
    }

    const handleEditCategoriaAdd = async (categoria) => {
        setCategoriaAddToEdit(categoria);
        setShowEditCategoriaAdd(!showEditCategoriaAdd)
    }

    const handleRemoveCategoriaAdd = async (item, categoria) => {
        let itemId = item._id;
        let grupoAdicionalValue = categoria._id
        console.log("Item que terá a categoria removida:", itemId)
        console.log("Categoria a ser removida:", grupoAdicionalValue)
        removeAdicionalFromItem(userID, itemId, grupoAdicionalValue).then(cast => {
            if (cast.status === 200) {
                console.log("resposta:", cast)
                toast(cast.data.msg, { autoClose: 3000, type: "success" });
            } else {
                toast(cast.data.msg, { autoClose: 3000, type: "error" });
            }
        }).catch(err => {
            console.log('Ocorreu algum erro no servidor!', err);
            //console.log(err.response.data.msg);
            toast(err.response.data.msg, { autoClose: 3000, type: "error" });
        }).finally(() => {
            setRefresh(prev => !prev);
        });
    }

    const handleEditClickAdicional = (idGrupo, adicional) => {
        console.log("Adicional a ser editado:", adicional)
        setItemAdicionalToEdit(adicional);
        setIdGrupoToEdit(idGrupo);
        setShowEditItemAdicional(!showEditItemAdicional);
    }

    const [typeAdicional, setTypeAdicional] = useState("");
    const handleRegisterClickAdicional = (idGrupo, type) => {
        //setItemAdicionalToEdit(adicional);
        setTypeAdicional(type);
        setIdGrupoToRegister(idGrupo);
        setShowRegisterItemAdicional(!showRegisterItemAdicional);
    }

    const [copied, setCopied] = useState(false);
    const handleCopyClick = (productID) => {
        const hostname = window.location.hostname === "localhost" ? "http://localhost:3000" : `https://${window.location.hostname}`;
        const link = `${hostname}/${nomeEmpresaForUrl}/${idEmpresa}/detalhes/${productID}`
        navigator.clipboard.writeText(link).then(() => {
            setCopied(true);
            toast("Link copiado com sucesso!", { autoClose: 2500, type: "success" });
            setTimeout(() => setCopied(false), 2000); // Reset the copied state after 2 seconds
        }).catch((err) => {
            console.error('Failed to copy: ', err);
        });
    };

    const handleEditClick = (itemObjId, modelo) => {
        navigate(`/edit-item`, { state: { itemObjId, modelo } });
    };

    const handleEditAdditionalClick = (itemObjId) => {
        navigate(`/edit-item`, { state: { itemObjId, toWhere: "adicional" } });
    };

    const handleEditSaboresClick = (itemObjId) => {
        navigate(`/edit-item`, { state: { itemObjId, toWhere: "sabor" } });
    };

    const handlePriceChange = (e, itemID, isNumber) => {
        const newPrice = isNumber ? Number(e.target.value) : e.target.value;
        console.log("newPrice>>", newPrice)
        setCurrentPrice(newPrice);
        setItens(prevItens =>
            prevItens.map(item =>
                item._id === itemID ? { ...item, price: newPrice } : item
            )
        );
    };

    const handlePriceChangeSalao = (e, itemID, isNumber) => {
        const newPrice = isNumber ? Number(e.target.value) : e.target.value;
        console.log("newPrice>>", newPrice)
        setCurrentPriceSalao(newPrice);
        setItens(prevItens =>
            prevItens.map(item =>
                item._id === itemID ? { ...item, price_salao: newPrice } : item
            )
        );
    };

    const [isFocused, setIsFocused] = useState(false);
    const handleFocus = () => {
        setIsFocused(true);
    };

    const [typeInputPrice, setTypeInputPrice] = useState("");
    const toggleInput = (id, price) => {
        console.log("toggleInput de ID> ", id)
        setCurrentPrice(price);
        setVisibleInput(id);
        setTypeInputPrice("delivery");
    };

    const toggleInputSalao = (id, price) => {
        console.log("toggleInput de ID> ", id)
        setCurrentPriceSalao(price);
        setVisibleInputSalao(id);
        setTypeInputPrice("salao");
    };

    const handleRefresh = () => {
        setLoading(true);
        setRefresh(true);
    }

    const handleOrder = fieldName => {
        setOrder(-order)
        setColumnOrder(fieldName)
        console.log("order:", order);
        console.log("fieldName", fieldName)
    }

    const handleCheckboxChange = async (itemID, id_item, currentOut) => {
        const newOutValue = !currentOut;
        setItens(prevItens =>
            prevItens.map(item =>
                item._id === itemID ? { ...item, out: newOutValue } : item
            )
        ); // Atualiza o estado local imediatamente

        try {
            const response = await updateItemOut(userID, itemID, id_item, idEmpresa, newOutValue);
            if (response.status === 200) {
                toast('Item atualizado com sucesso', { autoClose: 2500, type: 'success' });
                setRefresh(true);
            }
        } catch (error) {
            console.error('Erro ao atualizar item:', error);
            toast('Erro ao atualizar o item', { autoClose: 2500, type: 'error' });
            // Reverte o estado local se a atualização falhar
            setItens(prevItens =>
                prevItens.map(item =>
                    item._id === itemID ? { ...item, out: currentOut } : item
                )
            );
        }
    };

    const handleCheckboxChangeAdicional = async (idGrupo, itemID, titulo, price, id_item, currentOut) => {
        const newOutValue = !currentOut;
        setAdicionais(prevAdicionais =>
            prevAdicionais.map(adicional =>
                adicional._id === itemID ? { ...adicional, out: newOutValue } : adicional
            )
        ); // Atualiza o estado local imediatamente

        try {
            console.log("O que esta indo no submit:", { userID, idGrupo, itemID, titulo, price, id_item, idEmpresa, newOutValue })
            const response = await updateItemAdicionalOut(userID, idGrupo, itemID, titulo, price, id_item, idEmpresa, newOutValue);
            if (response.status === 200) {
                toast('Item atualizado com sucesso', { autoClose: 2500, type: 'success' });
                setRefresh(true);
            }
        } catch (error) {
            console.error('Erro ao atualizar item:', error);
            toast('Erro ao atualizar o item', { autoClose: 2500, type: 'error' });
            // Reverte o estado local se a atualização falhar
            setAdicionais(prevAdicionais =>
                prevAdicionais.map(adicional =>
                    adicional._id === itemID ? { ...adicional, out: currentOut } : adicional
                )
            );
        }
    };

    const handleCheckboxChangeAdicionalSalao = async (idGrupo, itemID, titulo, price, id_item, currentOut) => {
        const newOutValue = !currentOut;
        setAdicionais(prevAdicionais =>
            prevAdicionais.map(adicional =>
                adicional._id === itemID ? { ...adicional, out_salao: newOutValue } : adicional
            )
        ); // Atualiza o estado local imediatamente

        try {
            console.log("O que esta indo no submit:", { userID, idGrupo, itemID, titulo, price, id_item, idEmpresa, newOutValue })
            const response = await updateItemAdicionalOutSalao(userID, idGrupo, itemID, titulo, price, id_item, idEmpresa, newOutValue);
            if (response.status === 200) {
                toast('Item atualizado com sucesso', { autoClose: 2500, type: 'success' });
                setRefresh(true);
            }
        } catch (error) {
            console.error('Erro ao atualizar item:', error);
            toast('Erro ao atualizar o item', { autoClose: 2500, type: 'error' });
            // Reverte o estado local se a atualização falhar
            setAdicionais(prevAdicionais =>
                prevAdicionais.map(adicional =>
                    adicional._id === itemID ? { ...adicional, out_salao: currentOut } : adicional
                )
            );
        }
    };

    const handleCheckboxSalaoChange = async (itemID, id_item, currentOut) => {
        const newOutValue = !currentOut;
        setItens(prevItens =>
            prevItens.map(item =>
                item._id === itemID ? { ...item, out_salao: newOutValue } : item
            )
        ); // Atualiza o estado local imediatamente

        try {
            const response = await updateItemOutSalao(userID, itemID, id_item, idEmpresa, newOutValue);
            if (response.status === 200) {
                toast('Item atualizado com sucesso', { autoClose: 2500, type: 'success' });
                setRefresh(true);
            }
        } catch (error) {
            console.error('Erro ao atualizar item:', error);
            toast('Erro ao atualizar o item', { autoClose: 2500, type: 'error' });
            // Reverte o estado local se a atualização falhar
            setItens(prevItens =>
                prevItens.map(item =>
                    item._id === itemID ? { ...item, out_salao: currentOut } : item
                )
            );
        }
    };

    const handleBlurOrEnter = async (itemID, id_item, currentPrice) => {
        setVisibleInput(null); // Esconde o input
        try {
            const response = await updateItemPrice(userID, itemID, id_item, idEmpresa, parseFloat(currentPrice));
            if (response.status === 200) {
                toast('Preço atualizado com sucesso', { autoClose: 2500, type: 'success' });
                setRefresh(true);
            }
        } catch (error) {
            console.error('Erro ao atualizar preço do item:', error);
            toast('Erro ao atualizar o preço do item', { autoClose: 2500, type: 'error' });
            // Reverte o estado local se a atualização falhar
            setItens(prevItens =>
                prevItens.map(item =>
                    item._id === itemID ? { ...item, price: parseFloat(currentPrice) } : item
                )
            );
        } finally {
            setVisibleInput(null); // Esconde o input
            setTypeInputPrice("");
        }
    };

    const handleBlurOrEnterSalao = async (itemID, id_item, currentPriceSalao) => {
        setVisibleInput(null); // Esconde o input
        try {
            const response = await updateItemPriceSalao(userID, itemID, id_item, idEmpresa, parseFloat(currentPriceSalao));
            if (response.status === 200) {
                toast('Preço atualizado com sucesso', { autoClose: 2500, type: 'success' });
                setRefresh(true);
            }
        } catch (error) {
            console.error('Erro ao atualizar preço do item:', error);
            toast('Erro ao atualizar o preço do item', { autoClose: 2500, type: 'error' });
            // Reverte o estado local se a atualização falhar
            setItens(prevItens =>
                prevItens.map(item =>
                    item._id === itemID ? { ...item, price_salao: parseFloat(currentPriceSalao) } : item
                )
            );
        } finally {
            setVisibleInput(null); // Esconde o input
            setTypeInputPrice("");
        }
    };

    /*var arrayCategorias = [];
    arrayCategorias = categorias.sort( (a, b) => {
        return a[columnOrder] < b[columnOrder] ? -order : order;
    })*/

    var arrayCategorias = categorias.sort((a, b) => {
        return a.order - b.order;
    });

    var arrayItens = itens.sort((a, b) => {
        return a.order - b.order;
    });
    //var arrayCategorias = categorias;

    if (filter) {
        const exp = eval(`/${filter.replace(/[^\d\w]+/, '.*')}/i`)
        list = categorias.filter(item => exp.test(item.title))
    }

    const handleFilter = e => {
        setFilter(e.target.value);
        setResult(!result);
    }

    const handleCadastro = () => {
        navigate("/cadastro-categoria");
    }

    const handleCadastroItem = (categoria) => {
        //navigate("/cadastro-item");
        const modelo = categoria.modelo || null;
        const infoCategoria = {
            value: categoria._id,
            label: categoria.title
        }
        navigate("/cadastro-item", { state: { infoCategoria, modelo } })
    }


    const [draggedCategoria, setDraggedCategoria] = useState(null);
    const handleDragStartCategoria = (draggableId) => {
        setDraggedCategoria(draggableId);
    };
    const handleDragEndCategoria = () => {
        setDraggedCategoria(null);
    };

    const moveRowCategoria = async (dragIndex, hoverIndex) => {

        const dragItem = categorias[dragIndex];
        const hoverItem = categorias[hoverIndex];

        // Mover o item do dragIndex para o hoverIndex
        const newCategorias = [...categorias];
        newCategorias.splice(dragIndex, 1);
        newCategorias.splice(hoverIndex, 0, dragItem);
        // Atualizar o estado com o novo array de categorias

        const categoriasAtualizadas = newCategorias.map((categoria, index) => {
            return { ...categoria, order: index + 1 };
        });

        const promises = categoriasAtualizadas.map((categoriasToApi) => {
            return updateCategoriasOrder(userID, categoriasToApi._id, categoriasToApi.id_categoria, categoriasToApi.id_empresa, categoriasToApi.order).then(response => {
                return response;
            }).catch(err => {
                return err;
            })
        });
        Promise.all(promises).then(results => {
            toast("Ordem atualizada com sucesso!", { autoClose: 3000, type: "success" });
        }).catch(err => {
            toast("Ocorreu um erro ao tentar atualizar a ordem!", { autoClose: 3000, type: "error" });
        });
        setCategorias(categoriasAtualizadas);
    };

    const [draggedItem, setDraggedItem] = useState(null);
    const handleDragStart = (draggableId) => {
        //console.log("draggedItem>",draggableId)
        setDraggedItem(draggableId);
    };
    const handleDragEnd = () => {
        setDraggedItem(null);
    };

    const moveRow = async (source, destination) => {

        const filteredItens = itens.filter(item => item.category_item_id === source.droppableId);

        const dragItem = filteredItens[source.index];
        const hoverItem = filteredItens[destination.index];

        // Mover o item do dragIndex para o hoverIndex
        const newItens = [...filteredItens];
        newItens.splice(source.index, 1);
        newItens.splice(destination.index, 0, dragItem);
        // Atualizar o estado com o novo array de itens
        const itensAtualizados = newItens.map((item, index) => {
            return { ...item, order: index + 1 };
        });

        const itensAtualizadosMap = itensAtualizados.reduce((acc, item) => {
            acc[item._id] = item;
            return acc;
        }, {});

        const newItensArray = itens.map(item => {
            const itemAtualizado = itensAtualizadosMap[item._id];
            return itemAtualizado || item;
        });

        const promises = newItensArray.map((itensToApi) => {
            return updateItensOrder(userID, itensToApi._id, itensToApi.id_item, itensToApi.id_empresa, itensToApi.order).then(response => {
                return response;
            }).catch(err => {
                return err;
            })
        });
        Promise.all(promises).then(results => {
            toast("Ordem atualizada com sucesso!", { autoClose: 2500, type: "success" });
        }).catch(err => {
            toast("Ocorreu um erro ao tentar atualizar a ordem!", { autoClose: 2500, type: "error" });
        });

        setItens(newItensArray);
    };

    // Calcular a altura dinâmica baseada no número de itens
    const calculateHeight = (idCategoria) => {
        const itemsOpened = arrayItens && arrayItens.filter((item) => item.category_item_id === idCategoria);

        // Ajuste a altura conforme o número de itens (50px por item)
        const heightSizeItem = itemsOpened.length === 0 ? 1 * 200 : itemsOpened.length * 200;

        let lengthAdicionais = 0;

        itemsOpened.forEach((item) => {
            if (Array.isArray(item.adicionais)) {
                // Se 'item.adicionais' for um array, iterar sobre cada "adicional" no array
                item.adicionais.forEach((adicional, i) => {
                    // Aqui você filtra 'adicionais' que correspondem ao 'value' de 'adicional'
                    const grupoAdicionaisCompleto = adicionais.filter((grupoAdicional) =>
                        grupoAdicional._id === adicional.value // Comparação com o '_id' do grupo
                    );

                    // Se o grupo de adicionais for encontrado, soma a quantidade de adicionais desse grupo
                    if (grupoAdicionaisCompleto.length > 0) {
                        // Para cada grupo de adicionais, soma a quantidade
                        grupoAdicionaisCompleto.forEach((grupo) => {
                            // Aqui estamos somando o total de adicionais, e adicionando 20px por adicional
                            lengthAdicionais += grupo.adicionais.length === 0 ? 1 * 200 : grupo.adicionais.length * 200;
                        });
                    }
                });
            }
        });

        // Calcular a altura total dos adicionais
        const heightSizeAdicionais = lengthAdicionais === 0 ? 1 * 200 : lengthAdicionais;

        // Calcular o tamanho total da altura
        const totalHeightSizePx = `${heightSizeItem + heightSizeAdicionais}px`;
        return totalHeightSizePx; // 100px por item, ajuste conforme necessário
    };

    const calculateHeightItens = (idItem) => {
        const itemsOpened = arrayItens && arrayItens.filter((item) => item._id === idItem);
        //console.log("itemsOpened:::", itemsOpened)
        // Ajuste a altura conforme o número de itens (50px por item)
        const heightSizeItem = itemsOpened.length === 0 ? 1 * 200 : itemsOpened.length * 200;

        let lengthAdicionais = 0;

        itemsOpened.forEach((item) => {
            if (Array.isArray(item.adicionais)) {
                // Se 'item.adicionais' for um array, iterar sobre cada "adicional" no array
                item.adicionais.forEach((adicional, i) => {
                    // Aqui você filtra 'adicionais' que correspondem ao 'value' de 'adicional'
                    const grupoAdicionaisCompleto = adicionais.filter((grupoAdicional) =>
                        grupoAdicional._id === adicional.value // Comparação com o '_id' do grupo
                    );

                    // Se o grupo de adicionais for encontrado, soma a quantidade de adicionais desse grupo
                    if (grupoAdicionaisCompleto.length > 0) {
                        // Para cada grupo de adicionais, soma a quantidade
                        grupoAdicionaisCompleto.forEach((grupo) => {
                            // Aqui estamos somando o total de adicionais, e adicionando 20px por adicional
                            lengthAdicionais += grupo.adicionais.length === 0 ? 1 * 200 : grupo.adicionais.length * 200;
                        });
                    }
                });
            }
        });

        // Calcular a altura total dos adicionais
        const heightSizeAdicionais = lengthAdicionais === 0 ? 1 * 200 : lengthAdicionais;

        // Calcular o tamanho total da altura
        const totalHeightSizePx = `${heightSizeItem + heightSizeAdicionais}px`;
        return totalHeightSizePx; // 100px por item, ajuste conforme necessário
    };

    return (

        <>
            <PermissionGate permissions={['default']}>

                {/*<LeftMenu setSidebar={setSidebar} sidebar={sidebar} />*/}

                <ConfirmDialog
                    title="Deletar Categoria?"
                    open={confirmOpen}
                    setOpen={setConfirmOpen}
                    onConfirm={deleteReg}
                >
                    <div>
                        Tem certeza que deseja deletar a categoria?
                        <br />
                        <span style={{ fontSize: 12 }}>(Os Itens e adicionais da categoria também serão exlcuídos)</span>
                    </div>
                </ConfirmDialog>

                <ConfirmDialog
                    title="Deletar Item?"
                    open={confirmOpenItem}
                    setOpen={setConfirmOpenItem}
                    onConfirm={deleteItemReg}
                >
                    <div>
                        Tem certeza que deseja deletar o item?
                    </div>
                </ConfirmDialog>

                <ModalEditCategoria showEditCategoria={showEditCategoria}
                    setEditCategoria={setEditCategoria}
                    setRefresh={setRefresh}
                    _idCategoriaToEdit={_idCategoriaToEdit}
                    id_categoria={id_categoria}
                    _modeloToEdit={_modeloToEdit}
                    _titleToEdit={_titleToEdit}
                    _disponibilidadeToEdit={_disponibilidadeToEdit}
                    setDisponibilidadeToEdit={setDisponibilidadeToEdit}
                    _diaHorarioDispToEdit={_diaHorarioDispToEdit}
                    setDiaHorarioDispToEdit={setDiaHorarioDispToEdit}
                />

                <ModalEditGrupoAdd showEditCategoriaAdd={showEditCategoriaAdd}
                    setShowEditCategoriaAdd={setShowEditCategoriaAdd}
                    setRefreshAdicional={setRefreshAdicional}
                    categoriaAddToEdit={categoriaAddToEdit}
                />

                <ModalEditItemAdicional showEditItemAdicional={showEditItemAdicional}
                    setShowEditItemAdicional={setShowEditItemAdicional}
                    setRefresh={setRefresh}
                    itemAdicionalToEdit={itemAdicionalToEdit}
                    idGrupoToEdit={idGrupoToEdit}
                />

                <ModalRegisterItemAdicional showRegisterItemAdicional={showRegisterItemAdicional}
                    setShowRegisterItemAdicional={setShowRegisterItemAdicional}
                    setRefresh={setRefresh}
                    //itemAdicionalToRegister={itemAdicionalToRegister}
                    idGrupoToRegister={idGrupoToRegister}
                    typeAdicional={typeAdicional}
                />

                <Teste sidebar={sidebar}>

                    {loading && <Loading type={"spinningBubbles"} style={{ marginLeft: '14%' }} className="calculandoDistanciaModalEntrega" />}
                    <div className="w-100 p-4">
                        <div className="list-header-categories">
                            <div className="title">
                                <h1>Categorias</h1>
                            </div>
                        </div>


                        <div className="flex-column flex-md-row" style={{ display: "flex", justifyContent: "space-between"/*, height:"80px"*/ }}>
                            <div className="input-box-list" style={{ maxWidth: "300px" }}>
                                <input className="input-field" placeholder="Pesquisar" onChange={handleFilter} />
                                <i className="icon"><FiIcons.FiSearch /></i>
                            </div>

                            <div className="div-buttons flex-column flex-md-row">
                                <div className="continue-button">
                                    <button onClick={handleCadastro}>
                                        <AiIcons.AiOutlinePlusCircle style={{ marginRight: "5px", fontSize: "22px", marginBottom: "2px" }} /><a>Adicionar</a>
                                    </button>
                                </div>

                                <div className="refresh-button align-mobile">
                                    <button onClick={handleRefresh}>
                                        <SlIcons.SlRefresh style={{ color: "#4281FF", marginRight: "5px", fontSize: "18px", marginBottom: "2px" }} /><a >Atualizar</a>
                                    </button>
                                </div>
                            </div>

                        </div>


                        <div className="divPaiCategorias">
                            {filter ?
                                <>
                                    <span>Para alterar a ordem de exibição no cardápio basta clicar no ícone de arrastar <MdDragIndicator fontSize={20} /> e mover para a posição desejada!</span>
                                    <DragDropContext onDragStart={(event) => handleDragStartCategoria(event.draggableId)} onDragEnd={({ source, destination }) => {
                                        handleDragEndCategoria();
                                        if (destination) {
                                            moveRowCategoria(source.index, destination.index);
                                        }
                                    }}>

                                        <Droppable droppableId="categorias"
                                        >
                                            {(provided) => (
                                                <div className="divCategorias" ref={provided.innerRef} {...provided.droppableProps}>
                                                    {list.map((categoria, i) => (

                                                        <Draggable key={categoria._id} draggableId={categoria._id} index={i}>
                                                            {(provided) => (
                                                                <div
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    //{...provided.dragHandleProps}
                                                                    //className="categoria-individual"
                                                                    style={{
                                                                        ...provided.draggableProps.style,
                                                                        backgroundColor: i % 2 === 0 ? 'white' : 'white',
                                                                        boxShadow: draggedCategoria === categoria._id ? '1px 1px 6px #a4a4a4' : '0px 0px 2px 0px lightgray',
                                                                    }}
                                                                    className={`${'resultCategoria'} ${i % 2 === 0 ? 'even' : 'odd'}`}
                                                                >
                                                                    <div className="categoriaPool">
                                                                        <div style={{ display: 'flex', flexDirection: 'row', gap: 80, alignItems: 'center' }}>
                                                                            <div style={{ display: 'flex', flexDirection: 'row', gap: 10, alignItems: 'center' }}>
                                                                                <div {...provided.dragHandleProps}>
                                                                                    <MdDragIndicator style={{ fontSize: 30, color: "darkgray" }} />
                                                                                </div>
                                                                                <span>Ordem de Exibição: {Number(i + 1)}º</span>
                                                                            </div>
                                                                            <div className="nomeCategoria">{categoria.title}</div>
                                                                        </div>
                                                                        <div style={{ display: "flex", gap: 10 }}>
                                                                            <div>
                                                                                <span onClick={() => toggleOptions(categoria._id)} className="actionsItem" style={{ cursor: "pointer" }}>
                                                                                    Ações da Categoria <MdKeyboardArrowDown />
                                                                                </span>
                                                                                {visibleDropdown === categoria._id && (
                                                                                    <div className="optionsDropdown" ref={dropdownRef} style={{ right: 80, minWidth: 120 }}>
                                                                                        <div className="optionItem" onClick={() => handleEdit(categoria._id, categoria.id_categoria, categoria.modelo, categoria.title, categoria.disponibilidade, categoria.dia_horario_disponibilidade)} style={{ padding: "8px 12px", cursor: "pointer" }}>
                                                                                            <FaRegEdit /> Editar
                                                                                        </div>
                                                                                        <div className="optionItem" onClick={() => handleDelete(categoria._id)} style={{ padding: "8px 12px", cursor: "pointer" }}>
                                                                                            <FaRegTrashAlt /> Excluir
                                                                                        </div>
                                                                                    </div>
                                                                                )}

                                                                            </div>
                                                                            {
                                                                                expandedCategories.has(categoria._id) ?
                                                                                    <div onClick={() => toggleDropdown(categoria._id)}
                                                                                        style={{
                                                                                            display: 'flex',
                                                                                            flexDirection: 'column',
                                                                                            alignItems: 'center',
                                                                                            fontSize: 13,
                                                                                            color: 'rgb(49, 140, 213)',
                                                                                            fontWeight: '600',
                                                                                            cursor: 'pointer'
                                                                                        }}>
                                                                                        <span>Esconder</span>
                                                                                        <SlArrowUp strokeWidth={'4rem'} color="rgb(49, 140, 213)" fontSize={18} style={{ width: 25, cursor: "pointer" }} />
                                                                                    </div>
                                                                                    :
                                                                                    <div onClick={() => toggleDropdown(categoria._id)}
                                                                                        style={{
                                                                                            display: 'flex',
                                                                                            flexDirection: 'column',
                                                                                            alignItems: 'center',
                                                                                            fontSize: 13,
                                                                                            color: 'rgb(49, 140, 213)',
                                                                                            fontWeight: '600',
                                                                                            cursor: 'pointer'
                                                                                        }}>
                                                                                        <span>Mostrar</span>
                                                                                        <SlArrowDown strokeWidth={'4rem'} color="rgb(49, 140, 213)" fontSize={18} style={{ width: 25, cursor: "pointer" }} />
                                                                                    </div>
                                                                            }


                                                                        </div>
                                                                    </div>
                                                                    <div className="itemPool">

                                                                        <DragDropContext onDragStart={(event) => handleDragStart(event.draggableId)} onDragEnd={({ source, destination }) => {
                                                                            handleDragEnd();
                                                                            if (destination) {
                                                                                moveRow(source, destination);
                                                                            }
                                                                        }}>
                                                                            <Droppable droppableId={categoria._id} direction="vertical">
                                                                                {(provided) => (
                                                                                    <SmoothShowAndHideDropdown
                                                                                        ref={provided.innerRef}
                                                                                        {...provided.droppableProps}
                                                                                        className={`${expandedCategories.has(categoria._id) ? 'expandedItemCardapio' : ''}`}
                                                                                        dynamicHeight={calculateHeight(categoria._id)} // Passando a altura calculada
                                                                                    >
                                                                                        <div className="itens-categoriaHorizontal">
                                                                                            {arrayItens && arrayItens
                                                                                                .filter((item) => item.category_item_id === categoria._id)
                                                                                                .map((item, index) => (
                                                                                                    <Draggable key={item._id} draggableId={item._id} index={index}>
                                                                                                        {(provided) => (
                                                                                                            <div
                                                                                                                {...provided.draggableProps}
                                                                                                                ref={provided.innerRef}
                                                                                                                className={`${"resultItem"} ${index % 2 === 0 ? "even" : "odd"
                                                                                                                    }`}
                                                                                                                style={{
                                                                                                                    ...provided.draggableProps.style,
                                                                                                                    backgroundColor: draggedItem == item._id ? 'white' : 'white',
                                                                                                                    boxShadow: draggedItem == item._id ? '1px 1px 6px #a4a4a4' : 'none',
                                                                                                                }} // Adicione essa linha
                                                                                                            >
                                                                                                                <div className="itensList">
                                                                                                                    <div className="divItemImgList">
                                                                                                                        {item.image.startsWith("data:image") ? (
                                                                                                                            // 🔴 Exibir erro se a imagem estiver errada (Base64 salva no banco)
                                                                                                                            <div className="errorImage">
                                                                                                                                <p style={{ color: "red", fontWeight: "bold" }}>
                                                                                                                                    ⚠️ ERRO: Imagem Base64 no banco!
                                                                                                                                </p>
                                                                                                                            </div>
                                                                                                                        ) : item.image !== "" ? (
                                                                                                                            // ✅ Exibir a imagem normalmente se for um link correto
                                                                                                                            <img width={100} height={100} src={item.image} />
                                                                                                                        ) : (
                                                                                                                            // 🍔 Exibir ícone padrão quando não houver imagem
                                                                                                                            <div className="iconWhenImgNull">
                                                                                                                                <MdFastfood style={{ fontSize: 75 }} />
                                                                                                                            </div>
                                                                                                                        )}
                                                                                                                    </div>
                                                                                                                    <div className="divItemNameList flex-column flex-lg-row">
                                                                                                                        <div className="titlePlusDragIcon" {...provided.dragHandleProps}>
                                                                                                                            <MdDragIndicator className="dragIndicatorCategoria" />
                                                                                                                            <span style={{ maxWidth: 220 }}>{item.title}</span>
                                                                                                                        </div>
                                                                                                                        <div className="itemsOptJustifyEnd">
                                                                                                                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 2 }}>
                                                                                                                                <span>Preço Delivery</span>
                                                                                                                                <div className="divItemPrice">
                                                                                                                                    {visibleInput === item._id && typeInputPrice === "delivery" ?
                                                                                                                                        <div ref={inputRef}>
                                                                                                                                            <InputMoney
                                                                                                                                                onChange={(event) => handlePriceChange(event, item._id, true)}
                                                                                                                                                value={item.price}
                                                                                                                                                onBlur={() => handleBlurOrEnter(item._id, item.id_item, item.price)}
                                                                                                                                                onKeyDown={(e) => {
                                                                                                                                                    if (e.key === 'Enter') {
                                                                                                                                                        handleBlurOrEnter(item._id, item.id_item, item.price);
                                                                                                                                                    }
                                                                                                                                                }}
                                                                                                                                                autoFocus
                                                                                                                                                margin="0px 0px 16px 0px"
                                                                                                                                                style={{
                                                                                                                                                    outline: isFocused ? '2px solid #4070f4' : 'none',
                                                                                                                                                    borderRadius: 6,
                                                                                                                                                    margin: '0.6rem 0 0.2rem 0',
                                                                                                                                                }}
                                                                                                                                                className="inputPersonalizedItem"
                                                                                                                                                placeholder="Preço"
                                                                                                                                            />
                                                                                                                                        </div>
                                                                                                                                        :
                                                                                                                                        <>
                                                                                                                                            <span>R$ {item.price?.toFixed(2).toString().replace('.', ',')}</span>
                                                                                                                                            <div className={"icon-btn edit-print"} onClick={() => toggleInput(item._id, item.price)}>
                                                                                                                                                <div>
                                                                                                                                                    <svg
                                                                                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                                                                                        width={16}
                                                                                                                                                        height={16}
                                                                                                                                                        viewBox="0 0 24 24"
                                                                                                                                                        fill="none"
                                                                                                                                                        stroke="currentColor"
                                                                                                                                                        strokeWidth={2}
                                                                                                                                                        strokeLinecap="round"
                                                                                                                                                        strokeLinejoin="round"
                                                                                                                                                        className="feather feather-edit-2"
                                                                                                                                                    >
                                                                                                                                                        <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z" />
                                                                                                                                                    </svg>
                                                                                                                                                </div>
                                                                                                                                            </div>
                                                                                                                                        </>
                                                                                                                                    }
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 2 }}>
                                                                                                                                <span>Preço Salão</span>
                                                                                                                                <div className="divItemPrice">
                                                                                                                                    {visibleInputSalao === item._id && typeInputPrice === "salao" ?
                                                                                                                                        <div ref={inputRefSalao}>
                                                                                                                                            <InputMoney
                                                                                                                                                onChange={(event) => handlePriceChangeSalao(event, item._id, true)}
                                                                                                                                                value={item.price_salao ?? 0}
                                                                                                                                                onBlur={() => handleBlurOrEnterSalao(item._id, item.id_item, item.price_salao)}
                                                                                                                                                onKeyDown={(e) => {
                                                                                                                                                    if (e.key === 'Enter') {
                                                                                                                                                        handleBlurOrEnterSalao(item._id, item.id_item, item.price_salao);
                                                                                                                                                    }
                                                                                                                                                }}
                                                                                                                                                autoFocus
                                                                                                                                                margin="0px 0px 16px 0px"
                                                                                                                                                style={{
                                                                                                                                                    outline: isFocused ? '2px solid #4070f4' : 'none',
                                                                                                                                                    borderRadius: 6,
                                                                                                                                                    margin: '0.6rem 0 0.2rem 0',
                                                                                                                                                }}
                                                                                                                                                className="inputPersonalizedItem"
                                                                                                                                                placeholder="Preço"
                                                                                                                                            />
                                                                                                                                        </div>
                                                                                                                                        :
                                                                                                                                        <>
                                                                                                                                            <span>R$ {item.price_salao?.toFixed(2).toString().replace('.', ',')}</span>
                                                                                                                                            <div className={"icon-btn edit-print"} onClick={() => toggleInputSalao(item._id, item.price_salao)}>
                                                                                                                                                <div>
                                                                                                                                                    <svg
                                                                                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                                                                                        width={16}
                                                                                                                                                        height={16}
                                                                                                                                                        viewBox="0 0 24 24"
                                                                                                                                                        fill="none"
                                                                                                                                                        stroke="currentColor"
                                                                                                                                                        strokeWidth={2}
                                                                                                                                                        strokeLinecap="round"
                                                                                                                                                        strokeLinejoin="round"
                                                                                                                                                        className="feather feather-edit-2"
                                                                                                                                                    >
                                                                                                                                                        <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z" />
                                                                                                                                                    </svg>
                                                                                                                                                </div>
                                                                                                                                            </div>
                                                                                                                                        </>
                                                                                                                                    }
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            <div style={{ display: 'flex', gap: 20 }}>
                                                                                                                                <div className="divEsgotarItem">
                                                                                                                                    <span>Esgotar Delivery</span>
                                                                                                                                    <div className="wrapper">
                                                                                                                                        <div className="switch_box box_1">
                                                                                                                                            <input type="checkbox" className="switch_1"
                                                                                                                                                checked={item.out} onChange={() => handleCheckboxChange(item._id, item.id_item, item.out)} />
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                                <div className="divEsgotarItem">
                                                                                                                                    <span>Esgotar Salão</span>
                                                                                                                                    <div className="wrapper">
                                                                                                                                        <div className="switch_box box_1">
                                                                                                                                            <input type="checkbox" className="switch_1"
                                                                                                                                                checked={item.out_salao} onChange={() => handleCheckboxSalaoChange(item._id, item.id_item, item.out_salao)} />
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            <div className="divLinkAcoes">
                                                                                                                                <div>
                                                                                                                                    <span className="actionsItem" onClick={() => handleCopyClick(item._id)}><IoLink fontSize={15} /> Link</span>
                                                                                                                                </div>
                                                                                                                                <div>
                                                                                                                                    <span onClick={() => toggleOptionsItem(item._id)}
                                                                                                                                        style={{
                                                                                                                                        }}
                                                                                                                                        className="actionsItem"
                                                                                                                                    >
                                                                                                                                        Ações do Item <MdKeyboardArrowDown />
                                                                                                                                    </span>
                                                                                                                                    {visibleDropdownItem === item._id && (
                                                                                                                                        <div className="optionsDropdown" ref={dropdownRefItem}>
                                                                                                                                            <div
                                                                                                                                                className="optionItem"
                                                                                                                                                onClick={() => handleEditClick(item._id, categoria.modelo)}
                                                                                                                                            >
                                                                                                                                                <FaRegEdit /> Editar
                                                                                                                                            </div>
                                                                                                                                            <div
                                                                                                                                                className="optionItem"
                                                                                                                                                onClick={() => handleDeleteItem(item._id)}
                                                                                                                                            >
                                                                                                                                                <FaRegTrashAlt /> Excluir
                                                                                                                                            </div>
                                                                                                                                            {item.type === "pizza" &&
                                                                                                                                                <div
                                                                                                                                                    className="optionItem"
                                                                                                                                                    onClick={() => handleEditSaboresClick(item._id)}
                                                                                                                                                >
                                                                                                                                                    <FaPizzaSlice /> Editar Sabores
                                                                                                                                                </div>
                                                                                                                                            }

                                                                                                                                            <div
                                                                                                                                                className="optionItem"
                                                                                                                                                onClick={() => handleEditAdditionalClick(item._id)}
                                                                                                                                            >
                                                                                                                                                <FaRegEdit /> Editar Adicionais
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                    )}
                                                                                                                                </div>
                                                                                                                                {
                                                                                                                                    expandedItens.has(item._id) ?
                                                                                                                                        <div style={{
                                                                                                                                            display: 'flex',
                                                                                                                                            flexDirection: 'column',
                                                                                                                                            alignItems: 'center',
                                                                                                                                            fontSize: 10,
                                                                                                                                            //color: 'rgb(49, 140, 213)',
                                                                                                                                            //fontWeight: '600'
                                                                                                                                        }}>
                                                                                                                                            <span>Esconder</span>
                                                                                                                                            <SlArrowUp className="dropDownIconForAdicionaisGray"
                                                                                                                                                onClick={() => toggleDropdownItens(item._id)} strokeWidth={'4rem'} fontSize={15}
                                                                                                                                            />
                                                                                                                                        </div>
                                                                                                                                        :
                                                                                                                                        <div style={{
                                                                                                                                            display: 'flex',
                                                                                                                                            flexDirection: 'column',
                                                                                                                                            alignItems: 'center',
                                                                                                                                            fontSize: 10,
                                                                                                                                            //color: 'rgb(49, 140, 213)',
                                                                                                                                            //fontWeight: '600'
                                                                                                                                        }}>
                                                                                                                                            <span>Mostrar</span>
                                                                                                                                            <SlArrowDown className="dropDownIconForAdicionaisGray"
                                                                                                                                                onClick={() => toggleDropdownItens(item._id)} strokeWidth={'4rem'} fontSize={15}
                                                                                                                                            />
                                                                                                                                        </div>
                                                                                                                                }

                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <input
                                                                                                                        //onChange={console.log()}
                                                                                                                        value={num[index]}
                                                                                                                        type="number"
                                                                                                                        style={{
                                                                                                                            display: isNaN(num[index]) ? "none" : "",
                                                                                                                        }}
                                                                                                                    />
                                                                                                                </div>
                                                                                                                {provided.placeholder}
                                                                                                                <div className="adicionaisPool">
                                                                                                                    <SmoothShowAndHideDropdownItens
                                                                                                                        className={`${expandedItens.has(item._id) ? 'expandedAdicionalCardapio' : ''}`}
                                                                                                                        dynamicHeight={calculateHeightItens(item._id)} // Passando a altura calculada                                                                                                            
                                                                                                                    >
                                                                                                                        <div style={{ width: '100%' }}>
                                                                                                                            {
                                                                                                                                item.adicionais?.length === 0 ? (
                                                                                                                                    <>
                                                                                                                                        <div className="nenhumItem">
                                                                                                                                            {item.type === "pizza" ?
                                                                                                                                                "Nenhum Grupo de Sabor ou de Adicionais Vinculado"
                                                                                                                                                :
                                                                                                                                                "Nenhum Grupo de Adicionais Vinculado"
                                                                                                                                            }
                                                                                                                                        </div>
                                                                                                                                        <div className="divCadastroAdicionalChildren" onClick={() => handleEditAdditionalClick(item._id)}
                                                                                                                                            style={{ marginBottom: 10 }}
                                                                                                                                        >
                                                                                                                                            <span>Atribuir um Grupo de Adicionais</span>
                                                                                                                                        </div>
                                                                                                                                        {item.type === "pizza" &&
                                                                                                                                            <div className="divCadastroAdicionalChildren" onClick={() => handleEditSaboresClick(item._id)}
                                                                                                                                                style={{ marginBottom: 10 }}
                                                                                                                                            >
                                                                                                                                                <span>Atribuir a um Grupo de Sabores</span>
                                                                                                                                            </div>
                                                                                                                                        }
                                                                                                                                    </>
                                                                                                                                ) :
                                                                                                                                    (
                                                                                                                                        item.adicionais?.map((grupoAdicional) => {
                                                                                                                                            // Filtra os adicionais do banco de dados que pertencem a este grupo
                                                                                                                                            const adicionaisDoGrupo = adicionais?.filter((adicional) => adicional._id === grupoAdicional.value);

                                                                                                                                            // Separa os adicionais entre 'sabores' e 'adicionais normais'
                                                                                                                                            const sabores = adicionaisDoGrupo.filter(adicional => adicional.type === "pizza");
                                                                                                                                            const adicionaisNormais = adicionaisDoGrupo.filter(adicional => !adicional.type || adicional.type === "simples");

                                                                                                                                            return (
                                                                                                                                                <div key={grupoAdicional.value}>

                                                                                                                                                    {/* Exibe o título "Sabores" apenas uma vez, se houver sabores */}
                                                                                                                                                    {sabores.length > 0 && (
                                                                                                                                                        <div className="blocoAdicionaisExibidos" >
                                                                                                                                                            <div style={{ fontSize: 16, fontWeight: 600, color: "gray", marginLeft: 40, marginTop: 20, textDecoration: 'underline' }}>Grupo de Sabor</div>
                                                                                                                                                            {sabores.map((categoriaAdicional, i) => (
                                                                                                                                                                <div key={categoriaAdicional._id} index={i}>

                                                                                                                                                                    <div
                                                                                                                                                                        style={{
                                                                                                                                                                            backgroundColor: i % 2 === 0 ? 'transparent' : 'transparent',
                                                                                                                                                                            boxShadow: draggedItem === categoriaAdicional._id ? '1px 1px 6px #a4a4a4' : 'none',
                                                                                                                                                                        }}
                                                                                                                                                                        className={`resultCategoriaAdicionais ${i % 2 === 0 ? 'even' : 'odd'}`}
                                                                                                                                                                    >
                                                                                                                                                                        <div style={{ display: 'flex', flexDirection: 'row', gap: 80, alignItems: 'center', width: '90%', justifyContent: 'space-between', paddingLeft: 10 }}>
                                                                                                                                                                            <div style={{ maxWidth: '50vw' }}>
                                                                                                                                                                                <div className="flex-column flex-md-row" style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
                                                                                                                                                                                    <div className="nomeCategoria">{categoriaAdicional.title}</div>
                                                                                                                                                                                    <div className={categoriaAdicional.mandatory ? "divMandatory" : "divOpcional"}>
                                                                                                                                                                                        <span>{categoriaAdicional.mandatory ? "Obrigatório" : "Opcional"}</span>
                                                                                                                                                                                    </div>
                                                                                                                                                                                    <div>
                                                                                                                                                                                        <span>Adicionais selecionáveis de {categoriaAdicional.min} até {categoriaAdicional.max}</span>
                                                                                                                                                                                    </div>
                                                                                                                                                                                </div>
                                                                                                                                                                                <div className="divDescricaoAdicionais">{categoriaAdicional.description}</div>
                                                                                                                                                                            </div>
                                                                                                                                                                        </div>
                                                                                                                                                                        <div>
                                                                                                                                                                            <a className="btn btn-sm btn-primary editaCategoria"
                                                                                                                                                                                onClick={(e) => handleEditCategoriaAdd(categoriaAdicional)}
                                                                                                                                                                            >
                                                                                                                                                                                <FiIcons.FiEdit2 style={{ color: 'white' }} />
                                                                                                                                                                            </a>
                                                                                                                                                                            <a className="btn btn-sm btn-danger deletaCategoria" style={{ marginLeft: '5px' }}
                                                                                                                                                                                onClick={() => handleRemoveCategoriaAdd(item, categoriaAdicional)}
                                                                                                                                                                            >
                                                                                                                                                                                <FiIcons.FiTrash2 style={{ color: 'white' }} />
                                                                                                                                                                            </a>
                                                                                                                                                                        </div>
                                                                                                                                                                    </div>
                                                                                                                                                                    <div className="resultAdicionaisChildren">
                                                                                                                                                                        {
                                                                                                                                                                            categoriaAdicional.adicionais && categoriaAdicional.adicionais.length > 0 &&
                                                                                                                                                                            categoriaAdicional.adicionais.map((adicional, i) => (
                                                                                                                                                                                <div key={adicional._id} index={i}>
                                                                                                                                                                                    <div className="divItemAdicionalInfo">
                                                                                                                                                                                        <div style={{ display: 'flex', gap: 20, alignItems: 'center' }}>
                                                                                                                                                                                            {
                                                                                                                                                                                                adicional.image === "" || !adicional.image ?
                                                                                                                                                                                                    <div>
                                                                                                                                                                                                        <MdFastfood style={{ fontSize: 50 }} />
                                                                                                                                                                                                    </div>
                                                                                                                                                                                                    :
                                                                                                                                                                                                    <img width={50} height={50} src={adicional.image} />
                                                                                                                                                                                            }
                                                                                                                                                                                            {adicional.title}
                                                                                                                                                                                        </div>
                                                                                                                                                                                        <div style={{ display: 'flex', justifyContent: 'space-between', minWidth: '40%' }}>
                                                                                                                                                                                            <div style={{ display: 'flex', gap: 20 }}>
                                                                                                                                                                                                <div className="divEsgotarItem">
                                                                                                                                                                                                    <span style={{ fontSize: 12 }}>Esgotar Delivery</span>
                                                                                                                                                                                                    <div className="wrapper">
                                                                                                                                                                                                        <div className="switch_box box_1">
                                                                                                                                                                                                            <input type="checkbox" className="switch_1"
                                                                                                                                                                                                                checked={adicional.out} onChange={() =>
                                                                                                                                                                                                                    handleCheckboxChangeAdicional(categoriaAdicional._id,
                                                                                                                                                                                                                        adicional._id,
                                                                                                                                                                                                                        adicional.title,
                                                                                                                                                                                                                        adicional.price,
                                                                                                                                                                                                                        adicional.id_adicional,
                                                                                                                                                                                                                        adicional.out
                                                                                                                                                                                                                    )
                                                                                                                                                                                                                }
                                                                                                                                                                                                            />
                                                                                                                                                                                                        </div>
                                                                                                                                                                                                    </div>
                                                                                                                                                                                                </div>
                                                                                                                                                                                                <div className="divEsgotarItem">
                                                                                                                                                                                                    <span style={{ fontSize: 12 }}>Esgotar Salão</span>
                                                                                                                                                                                                    <div className="wrapper">
                                                                                                                                                                                                        <div className="switch_box box_1">
                                                                                                                                                                                                            <input type="checkbox" className="switch_1"
                                                                                                                                                                                                                checked={adicional.out_salao} onChange={() =>
                                                                                                                                                                                                                    handleCheckboxChangeAdicionalSalao(categoriaAdicional._id,
                                                                                                                                                                                                                        adicional._id,
                                                                                                                                                                                                                        adicional.title,
                                                                                                                                                                                                                        adicional.price,
                                                                                                                                                                                                                        adicional.id_adicional,
                                                                                                                                                                                                                        adicional.out_salao
                                                                                                                                                                                                                    )
                                                                                                                                                                                                                }
                                                                                                                                                                                                            />
                                                                                                                                                                                                        </div>
                                                                                                                                                                                                    </div>
                                                                                                                                                                                                </div>
                                                                                                                                                                                            </div>
                                                                                                                                                                                            <div className="divEditRmvSubAdicionais">
                                                                                                                                                                                                {adicional.price > 0 ?
                                                                                                                                                                                                    <div>A partir de R$ {adicional.price.toFixed(2).toString().replace('.', ',')}</div>
                                                                                                                                                                                                    :
                                                                                                                                                                                                    <div>Adicional Grátis</div>
                                                                                                                                                                                                }
                                                                                                                                                                                                <TbDotsVertical onClick={() => toggleOptionsAdicional(item._id + adicional._id)} />
                                                                                                                                                                                                {visibleDropdownAdicional === item._id + adicional._id && (
                                                                                                                                                                                                    <div className="optionsDropdown" ref={dropdownRefAdicional} style={{ top: 22 }}>
                                                                                                                                                                                                        <div className="optionItem"
                                                                                                                                                                                                            onClick={() => {
                                                                                                                                                                                                                console.log("PASSIU AQUI NO SABOR?")
                                                                                                                                                                                                                handleEditClickAdicional(categoriaAdicional._id, adicional)
                                                                                                                                                                                                            }
                                                                                                                                                                                                            }
                                                                                                                                                                                                        >
                                                                                                                                                                                                            <FaRegEdit /> Editar
                                                                                                                                                                                                        </div>
                                                                                                                                                                                                        <div className="optionItem"
                                                                                                                                                                                                            onClick={() => handleDeleteSubAdicional(categoriaAdicional._id, adicional._id)}
                                                                                                                                                                                                        >
                                                                                                                                                                                                            <FaRegTrashAlt /> Excluir
                                                                                                                                                                                                        </div>
                                                                                                                                                                                                    </div>
                                                                                                                                                                                                )}
                                                                                                                                                                                            </div>
                                                                                                                                                                                        </div>
                                                                                                                                                                                    </div>
                                                                                                                                                                                </div>
                                                                                                                                                                            ))
                                                                                                                                                                        }
                                                                                                                                                                        <div className="divCadastroAdicionalChildren"
                                                                                                                                                                            onClick={() => handleRegisterClickAdicional(categoriaAdicional._id, "pizza")}
                                                                                                                                                                        >
                                                                                                                                                                            <span>Cadastrar Sabor</span>
                                                                                                                                                                        </div>
                                                                                                                                                                    </div>
                                                                                                                                                                </div>
                                                                                                                                                            ))}
                                                                                                                                                        </div>
                                                                                                                                                    )}

                                                                                                                                                    {/* Exibe o título "Adicionais normais" apenas uma vez, se houver adicionais normais */}
                                                                                                                                                    {adicionaisNormais.length > 0 && (
                                                                                                                                                        <div className="blocoAdicionaisExibidos" >
                                                                                                                                                            <div style={{ fontSize: 16, fontWeight: 600, color: "gray", marginLeft: 40, marginTop: 20, textDecoration: 'underline' }}>Grupo de Adicional</div>
                                                                                                                                                            {adicionaisNormais.map((categoriaAdicional, i) => (
                                                                                                                                                                <div key={categoriaAdicional._id} index={i}>
                                                                                                                                                                    <div
                                                                                                                                                                        style={{
                                                                                                                                                                            backgroundColor: i % 2 === 0 ? 'transparent' : 'transparent',
                                                                                                                                                                            boxShadow: draggedItem === categoriaAdicional._id ? '1px 1px 6px #a4a4a4' : 'none',
                                                                                                                                                                        }}
                                                                                                                                                                        className={`resultCategoriaAdicionais ${i % 2 === 0 ? 'even' : 'odd'}`}
                                                                                                                                                                    >
                                                                                                                                                                        <div style={{ display: 'flex', flexDirection: 'row', gap: 80, alignItems: 'center', width: '90%', justifyContent: 'space-between', paddingLeft: 10 }}>
                                                                                                                                                                            <div style={{ maxWidth: '50vw' }}>
                                                                                                                                                                                <div className="flex-column flex-md-row" style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
                                                                                                                                                                                    <div className="nomeCategoria">{categoriaAdicional.title}</div>
                                                                                                                                                                                    <div className={categoriaAdicional.mandatory ? "divMandatory" : "divOpcional"}>
                                                                                                                                                                                        <span>{categoriaAdicional.mandatory ? "Obrigatório" : "Opcional"}</span>
                                                                                                                                                                                    </div>
                                                                                                                                                                                    <div>
                                                                                                                                                                                        <span>Adicionais selecionáveis de {categoriaAdicional.min} até {categoriaAdicional.max}</span>
                                                                                                                                                                                    </div>
                                                                                                                                                                                </div>
                                                                                                                                                                                <div className="divDescricaoAdicionais">{categoriaAdicional.description}</div>
                                                                                                                                                                            </div>
                                                                                                                                                                        </div>
                                                                                                                                                                        <div>
                                                                                                                                                                            <a className="btn btn-sm btn-primary editaCategoria"
                                                                                                                                                                                onClick={(e) => handleEditCategoriaAdd(categoriaAdicional)}
                                                                                                                                                                            >
                                                                                                                                                                                <FiIcons.FiEdit2 style={{ color: 'white' }} />
                                                                                                                                                                            </a>
                                                                                                                                                                            <a className="btn btn-sm btn-danger deletaCategoria" style={{ marginLeft: '5px' }}
                                                                                                                                                                                onClick={() => handleRemoveCategoriaAdd(item, categoriaAdicional)}
                                                                                                                                                                            >
                                                                                                                                                                                <FiIcons.FiTrash2 style={{ color: 'white' }} />
                                                                                                                                                                            </a>
                                                                                                                                                                        </div>
                                                                                                                                                                    </div>
                                                                                                                                                                    <div className="resultAdicionaisChildren">
                                                                                                                                                                        {
                                                                                                                                                                            categoriaAdicional.adicionais && categoriaAdicional.adicionais.length > 0 &&
                                                                                                                                                                            categoriaAdicional.adicionais.map((adicional, i) => (
                                                                                                                                                                                <div key={adicional._id} index={i}>
                                                                                                                                                                                    <div className="divItemAdicionalInfo">
                                                                                                                                                                                        <div style={{ display: 'flex', gap: 20, alignItems: 'center' }}>
                                                                                                                                                                                            {
                                                                                                                                                                                                adicional.image === "" || !adicional.image ?
                                                                                                                                                                                                    <div>
                                                                                                                                                                                                        <MdFastfood style={{ fontSize: 50 }} />
                                                                                                                                                                                                    </div>
                                                                                                                                                                                                    :
                                                                                                                                                                                                    <img width={50} height={50} src={adicional.image} />
                                                                                                                                                                                            }
                                                                                                                                                                                            {adicional.title}
                                                                                                                                                                                        </div>
                                                                                                                                                                                        <div style={{ display: 'flex', justifyContent: 'space-between', minWidth: '40%' }}>
                                                                                                                                                                                            <div style={{ display: 'flex', gap: 20 }}>
                                                                                                                                                                                                <div className="divEsgotarItem">
                                                                                                                                                                                                    <span style={{ fontSize: 12 }}>Esgotar Delivery</span>
                                                                                                                                                                                                    <div className="wrapper">
                                                                                                                                                                                                        <div className="switch_box box_1">
                                                                                                                                                                                                            <input type="checkbox" className="switch_1"
                                                                                                                                                                                                                checked={adicional.out} onChange={() =>
                                                                                                                                                                                                                    handleCheckboxChangeAdicional(categoriaAdicional._id,
                                                                                                                                                                                                                        adicional._id,
                                                                                                                                                                                                                        adicional.title,
                                                                                                                                                                                                                        adicional.price,
                                                                                                                                                                                                                        adicional.id_adicional,
                                                                                                                                                                                                                        adicional.out
                                                                                                                                                                                                                    )
                                                                                                                                                                                                                }
                                                                                                                                                                                                            />
                                                                                                                                                                                                        </div>
                                                                                                                                                                                                    </div>
                                                                                                                                                                                                </div>
                                                                                                                                                                                                <div className="divEsgotarItem">
                                                                                                                                                                                                    <span style={{ fontSize: 12 }}>Esgotar Salão</span>
                                                                                                                                                                                                    <div className="wrapper">
                                                                                                                                                                                                        <div className="switch_box box_1">
                                                                                                                                                                                                            <input type="checkbox" className="switch_1"
                                                                                                                                                                                                                checked={adicional.out_salao} onChange={() =>
                                                                                                                                                                                                                    handleCheckboxChangeAdicionalSalao(categoriaAdicional._id,
                                                                                                                                                                                                                        adicional._id,
                                                                                                                                                                                                                        adicional.title,
                                                                                                                                                                                                                        adicional.price,
                                                                                                                                                                                                                        adicional.id_adicional,
                                                                                                                                                                                                                        adicional.out_salao
                                                                                                                                                                                                                    )
                                                                                                                                                                                                                }
                                                                                                                                                                                                            />
                                                                                                                                                                                                        </div>
                                                                                                                                                                                                    </div>
                                                                                                                                                                                                </div>
                                                                                                                                                                                            </div>
                                                                                                                                                                                            <div className="divEditRmvSubAdicionais">
                                                                                                                                                                                                {adicional.price > 0 ?
                                                                                                                                                                                                    <div>A partir de R$ {adicional.price.toFixed(2).toString().replace('.', ',')}</div>
                                                                                                                                                                                                    :
                                                                                                                                                                                                    <div>Adicional Grátis</div>
                                                                                                                                                                                                }
                                                                                                                                                                                                <TbDotsVertical onClick={() => toggleOptionsAdicional(item._id + adicional._id)} />
                                                                                                                                                                                                {visibleDropdownAdicional === item._id + adicional._id && (
                                                                                                                                                                                                    <div className="optionsDropdown" ref={dropdownRefAdicional} style={{ top: 22 }}>
                                                                                                                                                                                                        <div className="optionItem"
                                                                                                                                                                                                            onClick={() => {
                                                                                                                                                                                                                console.log("PASSOU AQUI NO SIMPLES?")
                                                                                                                                                                                                                handleEditClickAdicional(categoriaAdicional._id, adicional)
                                                                                                                                                                                                            }
                                                                                                                                                                                                            }
                                                                                                                                                                                                        >
                                                                                                                                                                                                            <FaRegEdit /> Editar
                                                                                                                                                                                                        </div>
                                                                                                                                                                                                        <div className="optionItem"
                                                                                                                                                                                                            onClick={() => handleDeleteSubAdicional(categoriaAdicional._id, adicional._id)}
                                                                                                                                                                                                        >
                                                                                                                                                                                                            <FaRegTrashAlt /> Excluir
                                                                                                                                                                                                        </div>
                                                                                                                                                                                                    </div>
                                                                                                                                                                                                )}
                                                                                                                                                                                            </div>
                                                                                                                                                                                        </div>
                                                                                                                                                                                    </div>
                                                                                                                                                                                </div>
                                                                                                                                                                            ))
                                                                                                                                                                        }
                                                                                                                                                                        <div className="divCadastroAdicionalChildren"
                                                                                                                                                                            onClick={() => handleRegisterClickAdicional(categoriaAdicional._id, "simples")}
                                                                                                                                                                        >
                                                                                                                                                                            <span>Cadastrar Adicional</span>
                                                                                                                                                                        </div>
                                                                                                                                                                    </div>
                                                                                                                                                                </div>
                                                                                                                                                            ))}
                                                                                                                                                        </div>
                                                                                                                                                    )}
                                                                                                                                                </div>
                                                                                                                                            );
                                                                                                                                        })
                                                                                                                                    )
                                                                                                                            }
                                                                                                                        </div>
                                                                                                                    </SmoothShowAndHideDropdownItens>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        )}
                                                                                                    </Draggable>
                                                                                                ))}
                                                                                            {/* Esta parte é para verificar se há itens na categoria */}
                                                                                            {arrayItens.filter((item) => item.category_item_id === categoria._id).length === 0 && (
                                                                                                <div className="nenhumItem">{categoria.modelo?.value === 2 ? "Nenhum tamanho cadastrado" : "Nenhum item cadastrado"}</div>
                                                                                            )}
                                                                                        </div>
                                                                                        {provided.placeholder}
                                                                                    </SmoothShowAndHideDropdown>
                                                                                )}
                                                                            </Droppable>
                                                                        </DragDropContext>


                                                                        <button type="button" className="buttonAddHorarios" onClick={() => handleCadastroItem(categoria)}
                                                                            style={{ fontWeight: 700, fontSize: 15, display: "flex", alignItems: "center", gap: 3, marginTop: 10 }}>
                                                                            <BsPlusCircle style={{ strokeWidth: 1, fontSize: 15, height: 20 }} />{categoria.modelo?.value === 2 ? "Adicionar Tamanho" : "Adicionar Item"}
                                                                        </button>
                                                                    </div>
                                                                </div>


                                                            )}

                                                        </Draggable>


                                                    ))}
                                                    {provided.placeholder}
                                                </div>
                                            )}
                                        </Droppable>
                                    </DragDropContext>
                                </>
                                :
                                <>
                                    <span>Para alterar a ordem de exibição no cardápio basta clicar no ícone de arrastar <MdDragIndicator fontSize={20} /> e mover para a posição desejada!</span>
                                    <DragDropContext onDragStart={(event) => handleDragStartCategoria(event.draggableId)} onDragEnd={({ source, destination }) => {
                                        handleDragEndCategoria();
                                        if (destination) {
                                            moveRowCategoria(source.index, destination.index);
                                        }
                                    }}>

                                        <Droppable droppableId="categorias"
                                        >
                                            {(provided) => (
                                                <div className="divCategorias" ref={provided.innerRef} {...provided.droppableProps}>
                                                    {arrayCategorias.map((categoria, i) => (

                                                        <Draggable key={categoria._id} draggableId={categoria._id} index={i}>
                                                            {(provided) => (
                                                                <div
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    //{...provided.dragHandleProps}
                                                                    //className="categoria-individual"
                                                                    style={{
                                                                        ...provided.draggableProps.style,
                                                                        backgroundColor: i % 2 === 0 ? 'white' : 'white',
                                                                        boxShadow: draggedCategoria === categoria._id ? '1px 1px 6px #a4a4a4' : '0px 0px 2px 0px lightgray',
                                                                    }}
                                                                    className={`${'resultCategoria'} ${i % 2 === 0 ? 'even' : 'odd'}`}
                                                                >
                                                                    <div className="categoriaPool">
                                                                        <div style={{ display: 'flex', flexDirection: 'row', gap: 80, alignItems: 'center' }}>
                                                                            <div style={{ display: 'flex', flexDirection: 'row', gap: 10, alignItems: 'center' }}>
                                                                                <div {...provided.dragHandleProps}>
                                                                                    <MdDragIndicator style={{ fontSize: 30, color: "darkgray" }} />
                                                                                </div>
                                                                                <span>Ordem de Exibição: {Number(i + 1)}º</span>
                                                                            </div>
                                                                            <div className="nomeCategoria">{categoria.title}</div>
                                                                        </div>
                                                                        <div style={{ display: "flex", gap: 10 }}>
                                                                            <div>
                                                                                <span onClick={() => toggleOptions(categoria._id)} className="actionsItem" style={{ cursor: "pointer" }}>
                                                                                    Ações da Categoria <MdKeyboardArrowDown />
                                                                                </span>
                                                                                {visibleDropdown === categoria._id && (
                                                                                    <div className="optionsDropdown" ref={dropdownRef} style={{ right: 80, minWidth: 120 }}>
                                                                                        <div className="optionItem" onClick={() => handleEdit(categoria._id, categoria.id_categoria, categoria.modelo, categoria.title, categoria.disponibilidade, categoria.dia_horario_disponibilidade)} style={{ padding: "8px 12px", cursor: "pointer" }}>
                                                                                            <FaRegEdit /> Editar
                                                                                        </div>
                                                                                        <div className="optionItem" onClick={() => handleDelete(categoria._id)} style={{ padding: "8px 12px", cursor: "pointer" }}>
                                                                                            <FaRegTrashAlt /> Excluir
                                                                                        </div>
                                                                                    </div>
                                                                                )}

                                                                            </div>
                                                                            {
                                                                                expandedCategories.has(categoria._id) ?
                                                                                    <div onClick={() => toggleDropdown(categoria._id)}
                                                                                        style={{
                                                                                            display: 'flex',
                                                                                            flexDirection: 'column',
                                                                                            alignItems: 'center',
                                                                                            fontSize: 13,
                                                                                            color: 'rgb(49, 140, 213)',
                                                                                            fontWeight: '600',
                                                                                            cursor: 'pointer'
                                                                                        }}>
                                                                                        <span>Esconder</span>
                                                                                        <SlArrowUp strokeWidth={'4rem'} color="rgb(49, 140, 213)" fontSize={18} style={{ width: 25, cursor: "pointer" }} />
                                                                                    </div>
                                                                                    :
                                                                                    <div onClick={() => toggleDropdown(categoria._id)}
                                                                                        style={{
                                                                                            display: 'flex',
                                                                                            flexDirection: 'column',
                                                                                            alignItems: 'center',
                                                                                            fontSize: 13,
                                                                                            color: 'rgb(49, 140, 213)',
                                                                                            fontWeight: '600',
                                                                                            cursor: 'pointer'
                                                                                        }}>
                                                                                        <span>Mostrar</span>
                                                                                        <SlArrowDown strokeWidth={'4rem'} color="rgb(49, 140, 213)" fontSize={18} style={{ width: 25, cursor: "pointer" }} />
                                                                                    </div>
                                                                            }


                                                                        </div>
                                                                    </div>
                                                                    <div className="itemPool">

                                                                        <DragDropContext onDragStart={(event) => handleDragStart(event.draggableId)} onDragEnd={({ source, destination }) => {
                                                                            handleDragEnd();
                                                                            if (destination) {
                                                                                moveRow(source, destination);
                                                                            }
                                                                        }}>
                                                                            <Droppable droppableId={categoria._id} direction="vertical">
                                                                                {(provided) => (
                                                                                    <SmoothShowAndHideDropdown
                                                                                        ref={provided.innerRef}
                                                                                        {...provided.droppableProps}
                                                                                        className={`${expandedCategories.has(categoria._id) ? 'expandedItemCardapio' : ''}`}
                                                                                        dynamicHeight={calculateHeight(categoria._id)} // Passando a altura calculada
                                                                                    >
                                                                                        <div className="itens-categoriaHorizontal">
                                                                                            {arrayItens && arrayItens
                                                                                                .filter((item) => item.category_item_id === categoria._id)
                                                                                                .map((item, index) => (
                                                                                                    <Draggable key={item._id} draggableId={item._id} index={index}>
                                                                                                        {(provided) => (
                                                                                                            <div
                                                                                                                {...provided.draggableProps}
                                                                                                                ref={provided.innerRef}
                                                                                                                className={`${"resultItem"} ${index % 2 === 0 ? "even" : "odd"
                                                                                                                    }`}
                                                                                                                style={{
                                                                                                                    ...provided.draggableProps.style,
                                                                                                                    backgroundColor: draggedItem == item._id ? 'white' : 'white',
                                                                                                                    boxShadow: draggedItem == item._id ? '1px 1px 6px #a4a4a4' : 'none',
                                                                                                                }} // Adicione essa linha
                                                                                                            >
                                                                                                                <div className="itensList">
                                                                                                                    <div className="divItemImgList">
                                                                                                                        {item.image.startsWith("data:image") ? (
                                                                                                                            // 🔴 Exibir erro se a imagem estiver errada (Base64 salva no banco)
                                                                                                                            <div className="errorImage">
                                                                                                                                <p style={{ color: "red", fontWeight: "bold" }}>
                                                                                                                                    ⚠️ ERRO: Imagem Base64 no banco!
                                                                                                                                </p>
                                                                                                                            </div>
                                                                                                                        ) : item.image !== "" ? (
                                                                                                                            // ✅ Exibir a imagem normalmente se for um link correto
                                                                                                                            <img width={100} height={100} src={item.image} />
                                                                                                                        ) : (
                                                                                                                            // 🍔 Exibir ícone padrão quando não houver imagem
                                                                                                                            <div className="iconWhenImgNull">
                                                                                                                                <MdFastfood style={{ fontSize: 75 }} />
                                                                                                                            </div>
                                                                                                                        )}
                                                                                                                    </div>
                                                                                                                    <div className="divItemNameList flex-column flex-lg-row">
                                                                                                                        <div className="titlePlusDragIcon" {...provided.dragHandleProps}>
                                                                                                                            <MdDragIndicator className="dragIndicatorCategoria" />
                                                                                                                            <span style={{ maxWidth: 220 }}>{item.title}</span>
                                                                                                                        </div>
                                                                                                                        <div className="itemsOptJustifyEnd">
                                                                                                                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 2 }}>
                                                                                                                                <span>Preço Delivery</span>
                                                                                                                                <div className="divItemPrice">
                                                                                                                                    {visibleInput === item._id && typeInputPrice === "delivery" ?
                                                                                                                                        <div ref={inputRef}>
                                                                                                                                            <InputMoney
                                                                                                                                                onChange={(event) => handlePriceChange(event, item._id, true)}
                                                                                                                                                value={item.price}
                                                                                                                                                onBlur={() => handleBlurOrEnter(item._id, item.id_item, item.price)}
                                                                                                                                                onKeyDown={(e) => {
                                                                                                                                                    if (e.key === 'Enter') {
                                                                                                                                                        handleBlurOrEnter(item._id, item.id_item, item.price);
                                                                                                                                                    }
                                                                                                                                                }}
                                                                                                                                                autoFocus
                                                                                                                                                margin="0px 0px 16px 0px"
                                                                                                                                                style={{
                                                                                                                                                    outline: isFocused ? '2px solid #4070f4' : 'none',
                                                                                                                                                    borderRadius: 6,
                                                                                                                                                    margin: '0.6rem 0 0.2rem 0',
                                                                                                                                                }}
                                                                                                                                                className="inputPersonalizedItem"
                                                                                                                                                placeholder="Preço"
                                                                                                                                            />
                                                                                                                                        </div>
                                                                                                                                        :
                                                                                                                                        <>
                                                                                                                                            <span>R$ {item.price?.toFixed(2).toString().replace('.', ',')}</span>
                                                                                                                                            <div className={"icon-btn edit-print"} onClick={() => toggleInput(item._id, item.price)}>
                                                                                                                                                <div>
                                                                                                                                                    <svg
                                                                                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                                                                                        width={16}
                                                                                                                                                        height={16}
                                                                                                                                                        viewBox="0 0 24 24"
                                                                                                                                                        fill="none"
                                                                                                                                                        stroke="currentColor"
                                                                                                                                                        strokeWidth={2}
                                                                                                                                                        strokeLinecap="round"
                                                                                                                                                        strokeLinejoin="round"
                                                                                                                                                        className="feather feather-edit-2"
                                                                                                                                                    >
                                                                                                                                                        <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z" />
                                                                                                                                                    </svg>
                                                                                                                                                </div>
                                                                                                                                            </div>
                                                                                                                                        </>
                                                                                                                                    }
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 2 }}>
                                                                                                                                <span>Preço Salão</span>
                                                                                                                                <div className="divItemPrice">
                                                                                                                                    {visibleInputSalao === item._id && typeInputPrice === "salao" ?
                                                                                                                                        <div ref={inputRefSalao}>
                                                                                                                                            <InputMoney
                                                                                                                                                onChange={(event) => handlePriceChangeSalao(event, item._id, true)}
                                                                                                                                                value={item.price_salao ?? 0}
                                                                                                                                                onBlur={() => handleBlurOrEnterSalao(item._id, item.id_item, item.price_salao)}
                                                                                                                                                onKeyDown={(e) => {
                                                                                                                                                    if (e.key === 'Enter') {
                                                                                                                                                        handleBlurOrEnterSalao(item._id, item.id_item, item.price_salao);
                                                                                                                                                    }
                                                                                                                                                }}
                                                                                                                                                autoFocus
                                                                                                                                                margin="0px 0px 16px 0px"
                                                                                                                                                style={{
                                                                                                                                                    outline: isFocused ? '2px solid #4070f4' : 'none',
                                                                                                                                                    borderRadius: 6,
                                                                                                                                                    margin: '0.6rem 0 0.2rem 0',
                                                                                                                                                }}
                                                                                                                                                className="inputPersonalizedItem"
                                                                                                                                                placeholder="Preço"
                                                                                                                                            />
                                                                                                                                        </div>
                                                                                                                                        :
                                                                                                                                        <>
                                                                                                                                            <span>R$ {item.price_salao?.toFixed(2).toString().replace('.', ',')}</span>
                                                                                                                                            <div className={"icon-btn edit-print"} onClick={() => toggleInputSalao(item._id, item.price_salao)}>
                                                                                                                                                <div>
                                                                                                                                                    <svg
                                                                                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                                                                                        width={16}
                                                                                                                                                        height={16}
                                                                                                                                                        viewBox="0 0 24 24"
                                                                                                                                                        fill="none"
                                                                                                                                                        stroke="currentColor"
                                                                                                                                                        strokeWidth={2}
                                                                                                                                                        strokeLinecap="round"
                                                                                                                                                        strokeLinejoin="round"
                                                                                                                                                        className="feather feather-edit-2"
                                                                                                                                                    >
                                                                                                                                                        <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z" />
                                                                                                                                                    </svg>
                                                                                                                                                </div>
                                                                                                                                            </div>
                                                                                                                                        </>
                                                                                                                                    }
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            <div style={{ display: 'flex', gap: 20 }}>
                                                                                                                                <div className="divEsgotarItem">
                                                                                                                                    <span>Esgotar Delivery</span>
                                                                                                                                    <div className="wrapper">
                                                                                                                                        <div className="switch_box box_1">
                                                                                                                                            <input type="checkbox" className="switch_1"
                                                                                                                                                checked={item.out} onChange={() => handleCheckboxChange(item._id, item.id_item, item.out)} />
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                                <div className="divEsgotarItem">
                                                                                                                                    <span>Esgotar Salão</span>
                                                                                                                                    <div className="wrapper">
                                                                                                                                        <div className="switch_box box_1">
                                                                                                                                            <input type="checkbox" className="switch_1"
                                                                                                                                                checked={item.out_salao} onChange={() => handleCheckboxSalaoChange(item._id, item.id_item, item.out_salao)} />
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            <div className="divLinkAcoes">
                                                                                                                                <div>
                                                                                                                                    <span className="actionsItem" onClick={() => handleCopyClick(item._id)}><IoLink fontSize={15} /> Link</span>
                                                                                                                                </div>
                                                                                                                                <div>
                                                                                                                                    <span onClick={() => toggleOptionsItem(item._id)}
                                                                                                                                        style={{
                                                                                                                                        }}
                                                                                                                                        className="actionsItem"
                                                                                                                                    >
                                                                                                                                        Ações do Item <MdKeyboardArrowDown />
                                                                                                                                    </span>
                                                                                                                                    {visibleDropdownItem === item._id && (
                                                                                                                                        <div className="optionsDropdown" ref={dropdownRefItem}>
                                                                                                                                            <div
                                                                                                                                                className="optionItem"
                                                                                                                                                onClick={() => handleEditClick(item._id, categoria.modelo)}
                                                                                                                                            >
                                                                                                                                                <FaRegEdit /> Editar
                                                                                                                                            </div>
                                                                                                                                            <div
                                                                                                                                                className="optionItem"
                                                                                                                                                onClick={() => handleDeleteItem(item._id)}
                                                                                                                                            >
                                                                                                                                                <FaRegTrashAlt /> Excluir
                                                                                                                                            </div>
                                                                                                                                            {item.type === "pizza" &&
                                                                                                                                                <div
                                                                                                                                                    className="optionItem"
                                                                                                                                                    onClick={() => handleEditSaboresClick(item._id)}
                                                                                                                                                >
                                                                                                                                                    <FaPizzaSlice /> Editar Sabores
                                                                                                                                                </div>
                                                                                                                                            }

                                                                                                                                            <div
                                                                                                                                                className="optionItem"
                                                                                                                                                onClick={() => handleEditAdditionalClick(item._id)}
                                                                                                                                            >
                                                                                                                                                <FaRegEdit /> Editar Adicionais
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                    )}
                                                                                                                                </div>
                                                                                                                                {
                                                                                                                                    expandedItens.has(item._id) ?
                                                                                                                                        <div style={{
                                                                                                                                            display: 'flex',
                                                                                                                                            flexDirection: 'column',
                                                                                                                                            alignItems: 'center',
                                                                                                                                            fontSize: 10,
                                                                                                                                            //color: 'rgb(49, 140, 213)',
                                                                                                                                            //fontWeight: '600'
                                                                                                                                        }}>
                                                                                                                                            <span>Esconder</span>
                                                                                                                                            <SlArrowUp className="dropDownIconForAdicionaisGray"
                                                                                                                                                onClick={() => toggleDropdownItens(item._id)} strokeWidth={'4rem'} fontSize={15}
                                                                                                                                            />
                                                                                                                                        </div>
                                                                                                                                        :
                                                                                                                                        <div style={{
                                                                                                                                            display: 'flex',
                                                                                                                                            flexDirection: 'column',
                                                                                                                                            alignItems: 'center',
                                                                                                                                            fontSize: 10,
                                                                                                                                            //color: 'rgb(49, 140, 213)',
                                                                                                                                            //fontWeight: '600'
                                                                                                                                        }}>
                                                                                                                                            <span>Mostrar</span>
                                                                                                                                            <SlArrowDown className="dropDownIconForAdicionaisGray"
                                                                                                                                                onClick={() => toggleDropdownItens(item._id)} strokeWidth={'4rem'} fontSize={15}
                                                                                                                                            />
                                                                                                                                        </div>
                                                                                                                                }

                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <input
                                                                                                                        //onChange={console.log()}
                                                                                                                        value={num[index]}
                                                                                                                        type="number"
                                                                                                                        style={{
                                                                                                                            display: isNaN(num[index]) ? "none" : "",
                                                                                                                        }}
                                                                                                                    />
                                                                                                                </div>
                                                                                                                {provided.placeholder}
                                                                                                                <div className="adicionaisPool">
                                                                                                                    <SmoothShowAndHideDropdownItens
                                                                                                                        className={`${expandedItens.has(item._id) ? 'expandedAdicionalCardapio' : ''}`}
                                                                                                                        dynamicHeight={calculateHeightItens(item._id)} // Passando a altura calculada                                                                                                            
                                                                                                                    >
                                                                                                                        <div style={{ width: '100%' }}>
                                                                                                                            {
                                                                                                                                item.adicionais?.length === 0 ? (
                                                                                                                                    <>
                                                                                                                                        <div className="nenhumItem">
                                                                                                                                            {item.type === "pizza" ?
                                                                                                                                                "Nenhum Grupo de Sabor ou de Adicionais Vinculado"
                                                                                                                                                :
                                                                                                                                                "Nenhum Grupo de Adicionais Vinculado"
                                                                                                                                            }
                                                                                                                                        </div>
                                                                                                                                        <div className="divCadastroAdicionalChildren" onClick={() => handleEditAdditionalClick(item._id)}
                                                                                                                                            style={{ marginBottom: 10 }}
                                                                                                                                        >
                                                                                                                                            <span>Atribuir um Grupo de Adicionais</span>
                                                                                                                                        </div>
                                                                                                                                        {item.type === "pizza" &&
                                                                                                                                            <div className="divCadastroAdicionalChildren" onClick={() => handleEditSaboresClick(item._id)}
                                                                                                                                                style={{ marginBottom: 10 }}
                                                                                                                                            >
                                                                                                                                                <span>Atribuir a um Grupo de Sabores</span>
                                                                                                                                            </div>
                                                                                                                                        }
                                                                                                                                    </>
                                                                                                                                ) :
                                                                                                                                    (
                                                                                                                                        item.adicionais?.map((grupoAdicional) => {
                                                                                                                                            // Filtra os adicionais do banco de dados que pertencem a este grupo
                                                                                                                                            const adicionaisDoGrupo = adicionais?.filter((adicional) => adicional._id === grupoAdicional.value);

                                                                                                                                            // Separa os adicionais entre 'sabores' e 'adicionais normais'
                                                                                                                                            const sabores = adicionaisDoGrupo.filter(adicional => adicional.type === "pizza");
                                                                                                                                            const adicionaisNormais = adicionaisDoGrupo.filter(adicional => !adicional.type || adicional.type === "simples");

                                                                                                                                            return (
                                                                                                                                                <div key={grupoAdicional.value}>

                                                                                                                                                    {/* Exibe o título "Sabores" apenas uma vez, se houver sabores */}
                                                                                                                                                    {sabores.length > 0 && (
                                                                                                                                                        <div className="blocoAdicionaisExibidos" >
                                                                                                                                                            <div style={{ fontSize: 16, fontWeight: 600, color: "gray", marginLeft: 40, marginTop: 20, textDecoration: 'underline' }}>Grupo de Sabor</div>
                                                                                                                                                            {sabores.map((categoriaAdicional, i) => (
                                                                                                                                                                <div key={categoriaAdicional._id} index={i}>

                                                                                                                                                                    <div
                                                                                                                                                                        style={{
                                                                                                                                                                            backgroundColor: i % 2 === 0 ? 'transparent' : 'transparent',
                                                                                                                                                                            boxShadow: draggedItem === categoriaAdicional._id ? '1px 1px 6px #a4a4a4' : 'none',
                                                                                                                                                                        }}
                                                                                                                                                                        className={`resultCategoriaAdicionais ${i % 2 === 0 ? 'even' : 'odd'}`}
                                                                                                                                                                    >
                                                                                                                                                                        <div style={{ display: 'flex', flexDirection: 'row', gap: 80, alignItems: 'center', width: '90%', justifyContent: 'space-between', paddingLeft: 10 }}>
                                                                                                                                                                            <div style={{ maxWidth: '50vw' }}>
                                                                                                                                                                                <div className="flex-column flex-md-row" style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
                                                                                                                                                                                    <div className="nomeCategoria">{categoriaAdicional.title}</div>
                                                                                                                                                                                    <div className={categoriaAdicional.mandatory ? "divMandatory" : "divOpcional"}>
                                                                                                                                                                                        <span>{categoriaAdicional.mandatory ? "Obrigatório" : "Opcional"}</span>
                                                                                                                                                                                    </div>
                                                                                                                                                                                    <div>
                                                                                                                                                                                        <span>Adicionais selecionáveis de {categoriaAdicional.min} até {categoriaAdicional.max}</span>
                                                                                                                                                                                    </div>
                                                                                                                                                                                </div>
                                                                                                                                                                                <div className="divDescricaoAdicionais">{categoriaAdicional.description}</div>
                                                                                                                                                                            </div>
                                                                                                                                                                        </div>
                                                                                                                                                                        <div>
                                                                                                                                                                            <a className="btn btn-sm btn-primary editaCategoria"
                                                                                                                                                                                onClick={(e) => handleEditCategoriaAdd(categoriaAdicional)}
                                                                                                                                                                            >
                                                                                                                                                                                <FiIcons.FiEdit2 style={{ color: 'white' }} />
                                                                                                                                                                            </a>
                                                                                                                                                                            <a className="btn btn-sm btn-danger deletaCategoria" style={{ marginLeft: '5px' }}
                                                                                                                                                                                onClick={() => handleRemoveCategoriaAdd(item, categoriaAdicional)}
                                                                                                                                                                            >
                                                                                                                                                                                <FiIcons.FiTrash2 style={{ color: 'white' }} />
                                                                                                                                                                            </a>
                                                                                                                                                                        </div>
                                                                                                                                                                    </div>
                                                                                                                                                                    <div className="resultAdicionaisChildren">
                                                                                                                                                                        {
                                                                                                                                                                            categoriaAdicional.adicionais && categoriaAdicional.adicionais.length > 0 &&
                                                                                                                                                                            categoriaAdicional.adicionais.map((adicional, i) => (
                                                                                                                                                                                <div key={adicional._id} index={i}>
                                                                                                                                                                                    <div className="divItemAdicionalInfo">
                                                                                                                                                                                        <div style={{ display: 'flex', gap: 20, alignItems: 'center' }}>
                                                                                                                                                                                            {
                                                                                                                                                                                                adicional.image === "" || !adicional.image ?
                                                                                                                                                                                                    <div>
                                                                                                                                                                                                        <MdFastfood style={{ fontSize: 50 }} />
                                                                                                                                                                                                    </div>
                                                                                                                                                                                                    :
                                                                                                                                                                                                    <img width={50} height={50} src={adicional.image} />
                                                                                                                                                                                            }
                                                                                                                                                                                            {adicional.title}
                                                                                                                                                                                        </div>
                                                                                                                                                                                        <div style={{ display: 'flex', justifyContent: 'space-between', minWidth: '40%' }}>
                                                                                                                                                                                            <div style={{ display: 'flex', gap: 20 }}>
                                                                                                                                                                                                <div className="divEsgotarItem">
                                                                                                                                                                                                    <span style={{ fontSize: 12 }}>Esgotar Delivery</span>
                                                                                                                                                                                                    <div className="wrapper">
                                                                                                                                                                                                        <div className="switch_box box_1">
                                                                                                                                                                                                            <input type="checkbox" className="switch_1"
                                                                                                                                                                                                                checked={adicional.out} onChange={() =>
                                                                                                                                                                                                                    handleCheckboxChangeAdicional(categoriaAdicional._id,
                                                                                                                                                                                                                        adicional._id,
                                                                                                                                                                                                                        adicional.title,
                                                                                                                                                                                                                        adicional.price,
                                                                                                                                                                                                                        adicional.id_adicional,
                                                                                                                                                                                                                        adicional.out
                                                                                                                                                                                                                    )
                                                                                                                                                                                                                }
                                                                                                                                                                                                            />
                                                                                                                                                                                                        </div>
                                                                                                                                                                                                    </div>
                                                                                                                                                                                                </div>
                                                                                                                                                                                                <div className="divEsgotarItem">
                                                                                                                                                                                                    <span style={{ fontSize: 12 }}>Esgotar Salão</span>
                                                                                                                                                                                                    <div className="wrapper">
                                                                                                                                                                                                        <div className="switch_box box_1">
                                                                                                                                                                                                            <input type="checkbox" className="switch_1"
                                                                                                                                                                                                                checked={adicional.out_salao} onChange={() =>
                                                                                                                                                                                                                    handleCheckboxChangeAdicionalSalao(categoriaAdicional._id,
                                                                                                                                                                                                                        adicional._id,
                                                                                                                                                                                                                        adicional.title,
                                                                                                                                                                                                                        adicional.price,
                                                                                                                                                                                                                        adicional.id_adicional,
                                                                                                                                                                                                                        adicional.out_salao
                                                                                                                                                                                                                    )
                                                                                                                                                                                                                }
                                                                                                                                                                                                            />
                                                                                                                                                                                                        </div>
                                                                                                                                                                                                    </div>
                                                                                                                                                                                                </div>
                                                                                                                                                                                            </div>
                                                                                                                                                                                            <div className="divEditRmvSubAdicionais">
                                                                                                                                                                                                {adicional.price > 0 ?
                                                                                                                                                                                                    <div>A partir de R$ {adicional.price.toFixed(2).toString().replace('.', ',')}</div>
                                                                                                                                                                                                    :
                                                                                                                                                                                                    <div>Adicional Grátis</div>
                                                                                                                                                                                                }
                                                                                                                                                                                                <TbDotsVertical onClick={() => toggleOptionsAdicional(item._id + adicional._id)} />
                                                                                                                                                                                                {visibleDropdownAdicional === item._id + adicional._id && (
                                                                                                                                                                                                    <div className="optionsDropdown" ref={dropdownRefAdicional} style={{ top: 22 }}>
                                                                                                                                                                                                        <div className="optionItem"
                                                                                                                                                                                                            onClick={() => {
                                                                                                                                                                                                                console.log("PASSIU AQUI NO SABOR?")
                                                                                                                                                                                                                handleEditClickAdicional(categoriaAdicional._id, adicional)
                                                                                                                                                                                                            }
                                                                                                                                                                                                            }
                                                                                                                                                                                                        >
                                                                                                                                                                                                            <FaRegEdit /> Editar
                                                                                                                                                                                                        </div>
                                                                                                                                                                                                        <div className="optionItem"
                                                                                                                                                                                                            onClick={() => handleDeleteSubAdicional(categoriaAdicional._id, adicional._id)}
                                                                                                                                                                                                        >
                                                                                                                                                                                                            <FaRegTrashAlt /> Excluir
                                                                                                                                                                                                        </div>
                                                                                                                                                                                                    </div>
                                                                                                                                                                                                )}
                                                                                                                                                                                            </div>
                                                                                                                                                                                        </div>
                                                                                                                                                                                    </div>
                                                                                                                                                                                </div>
                                                                                                                                                                            ))
                                                                                                                                                                        }
                                                                                                                                                                        <div className="divCadastroAdicionalChildren"
                                                                                                                                                                            onClick={() => handleRegisterClickAdicional(categoriaAdicional._id, "pizza")}
                                                                                                                                                                        >
                                                                                                                                                                            <span>Cadastrar Sabor</span>
                                                                                                                                                                        </div>
                                                                                                                                                                    </div>
                                                                                                                                                                </div>
                                                                                                                                                            ))}
                                                                                                                                                        </div>
                                                                                                                                                    )}

                                                                                                                                                    {/* Exibe o título "Adicionais normais" apenas uma vez, se houver adicionais normais */}
                                                                                                                                                    {adicionaisNormais.length > 0 && (
                                                                                                                                                        <div className="blocoAdicionaisExibidos" >
                                                                                                                                                            <div style={{ fontSize: 16, fontWeight: 600, color: "gray", marginLeft: 40, marginTop: 20, textDecoration: 'underline' }}>Grupo de Adicional</div>
                                                                                                                                                            {adicionaisNormais.map((categoriaAdicional, i) => (
                                                                                                                                                                <div key={categoriaAdicional._id} index={i}>
                                                                                                                                                                    <div
                                                                                                                                                                        style={{
                                                                                                                                                                            backgroundColor: i % 2 === 0 ? 'transparent' : 'transparent',
                                                                                                                                                                            boxShadow: draggedItem === categoriaAdicional._id ? '1px 1px 6px #a4a4a4' : 'none',
                                                                                                                                                                        }}
                                                                                                                                                                        className={`resultCategoriaAdicionais ${i % 2 === 0 ? 'even' : 'odd'}`}
                                                                                                                                                                    >
                                                                                                                                                                        <div style={{ display: 'flex', flexDirection: 'row', gap: 80, alignItems: 'center', width: '90%', justifyContent: 'space-between', paddingLeft: 10 }}>
                                                                                                                                                                            <div style={{ maxWidth: '50vw' }}>
                                                                                                                                                                                <div className="flex-column flex-md-row" style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
                                                                                                                                                                                    <div className="nomeCategoria">{categoriaAdicional.title}</div>
                                                                                                                                                                                    <div className={categoriaAdicional.mandatory ? "divMandatory" : "divOpcional"}>
                                                                                                                                                                                        <span>{categoriaAdicional.mandatory ? "Obrigatório" : "Opcional"}</span>
                                                                                                                                                                                    </div>
                                                                                                                                                                                    <div>
                                                                                                                                                                                        <span>Adicionais selecionáveis de {categoriaAdicional.min} até {categoriaAdicional.max}</span>
                                                                                                                                                                                    </div>
                                                                                                                                                                                </div>
                                                                                                                                                                                <div className="divDescricaoAdicionais">{categoriaAdicional.description}</div>
                                                                                                                                                                            </div>
                                                                                                                                                                        </div>
                                                                                                                                                                        <div>
                                                                                                                                                                            <a className="btn btn-sm btn-primary editaCategoria"
                                                                                                                                                                                onClick={(e) => handleEditCategoriaAdd(categoriaAdicional)}
                                                                                                                                                                            >
                                                                                                                                                                                <FiIcons.FiEdit2 style={{ color: 'white' }} />
                                                                                                                                                                            </a>
                                                                                                                                                                            <a className="btn btn-sm btn-danger deletaCategoria" style={{ marginLeft: '5px' }}
                                                                                                                                                                                onClick={() => handleRemoveCategoriaAdd(item, categoriaAdicional)}
                                                                                                                                                                            >
                                                                                                                                                                                <FiIcons.FiTrash2 style={{ color: 'white' }} />
                                                                                                                                                                            </a>
                                                                                                                                                                        </div>
                                                                                                                                                                    </div>
                                                                                                                                                                    <div className="resultAdicionaisChildren">
                                                                                                                                                                        {
                                                                                                                                                                            categoriaAdicional.adicionais && categoriaAdicional.adicionais.length > 0 &&
                                                                                                                                                                            categoriaAdicional.adicionais.map((adicional, i) => (
                                                                                                                                                                                <div key={adicional._id} index={i}>
                                                                                                                                                                                    <div className="divItemAdicionalInfo">
                                                                                                                                                                                        <div style={{ display: 'flex', gap: 20, alignItems: 'center' }}>
                                                                                                                                                                                            {
                                                                                                                                                                                                adicional.image === "" || !adicional.image ?
                                                                                                                                                                                                    <div>
                                                                                                                                                                                                        <MdFastfood style={{ fontSize: 50 }} />
                                                                                                                                                                                                    </div>
                                                                                                                                                                                                    :
                                                                                                                                                                                                    <img width={50} height={50} src={adicional.image} />
                                                                                                                                                                                            }
                                                                                                                                                                                            {adicional.title}
                                                                                                                                                                                        </div>
                                                                                                                                                                                        <div style={{ display: 'flex', justifyContent: 'space-between', minWidth: '40%' }}>
                                                                                                                                                                                            <div style={{ display: 'flex', gap: 20 }}>
                                                                                                                                                                                                <div className="divEsgotarItem">
                                                                                                                                                                                                    <span style={{ fontSize: 12 }}>Esgotar Delivery</span>
                                                                                                                                                                                                    <div className="wrapper">
                                                                                                                                                                                                        <div className="switch_box box_1">
                                                                                                                                                                                                            <input type="checkbox" className="switch_1"
                                                                                                                                                                                                                checked={adicional.out} onChange={() =>
                                                                                                                                                                                                                    handleCheckboxChangeAdicional(categoriaAdicional._id,
                                                                                                                                                                                                                        adicional._id,
                                                                                                                                                                                                                        adicional.title,
                                                                                                                                                                                                                        adicional.price,
                                                                                                                                                                                                                        adicional.id_adicional,
                                                                                                                                                                                                                        adicional.out
                                                                                                                                                                                                                    )
                                                                                                                                                                                                                }
                                                                                                                                                                                                            />
                                                                                                                                                                                                        </div>
                                                                                                                                                                                                    </div>
                                                                                                                                                                                                </div>
                                                                                                                                                                                                <div className="divEsgotarItem">
                                                                                                                                                                                                    <span style={{ fontSize: 12 }}>Esgotar Salão</span>
                                                                                                                                                                                                    <div className="wrapper">
                                                                                                                                                                                                        <div className="switch_box box_1">
                                                                                                                                                                                                            <input type="checkbox" className="switch_1"
                                                                                                                                                                                                                checked={adicional.out_salao} onChange={() =>
                                                                                                                                                                                                                    handleCheckboxChangeAdicionalSalao(categoriaAdicional._id,
                                                                                                                                                                                                                        adicional._id,
                                                                                                                                                                                                                        adicional.title,
                                                                                                                                                                                                                        adicional.price,
                                                                                                                                                                                                                        adicional.id_adicional,
                                                                                                                                                                                                                        adicional.out_salao
                                                                                                                                                                                                                    )
                                                                                                                                                                                                                }
                                                                                                                                                                                                            />
                                                                                                                                                                                                        </div>
                                                                                                                                                                                                    </div>
                                                                                                                                                                                                </div>
                                                                                                                                                                                            </div>
                                                                                                                                                                                            <div className="divEditRmvSubAdicionais">
                                                                                                                                                                                                {adicional.price > 0 ?
                                                                                                                                                                                                    <div>A partir de R$ {adicional.price.toFixed(2).toString().replace('.', ',')}</div>
                                                                                                                                                                                                    :
                                                                                                                                                                                                    <div>Adicional Grátis</div>
                                                                                                                                                                                                }
                                                                                                                                                                                                <TbDotsVertical onClick={() => toggleOptionsAdicional(item._id + adicional._id)} />
                                                                                                                                                                                                {visibleDropdownAdicional === item._id + adicional._id && (
                                                                                                                                                                                                    <div className="optionsDropdown" ref={dropdownRefAdicional} style={{ top: 22 }}>
                                                                                                                                                                                                        <div className="optionItem"
                                                                                                                                                                                                            onClick={() => {
                                                                                                                                                                                                                console.log("PASSOU AQUI NO SIMPLES?")
                                                                                                                                                                                                                handleEditClickAdicional(categoriaAdicional._id, adicional)
                                                                                                                                                                                                            }
                                                                                                                                                                                                            }
                                                                                                                                                                                                        >
                                                                                                                                                                                                            <FaRegEdit /> Editar
                                                                                                                                                                                                        </div>
                                                                                                                                                                                                        <div className="optionItem"
                                                                                                                                                                                                            onClick={() => handleDeleteSubAdicional(categoriaAdicional._id, adicional._id)}
                                                                                                                                                                                                        >
                                                                                                                                                                                                            <FaRegTrashAlt /> Excluir
                                                                                                                                                                                                        </div>
                                                                                                                                                                                                    </div>
                                                                                                                                                                                                )}
                                                                                                                                                                                            </div>
                                                                                                                                                                                        </div>
                                                                                                                                                                                    </div>
                                                                                                                                                                                </div>
                                                                                                                                                                            ))
                                                                                                                                                                        }
                                                                                                                                                                        <div className="divCadastroAdicionalChildren"
                                                                                                                                                                            onClick={() => handleRegisterClickAdicional(categoriaAdicional._id, "simples")}
                                                                                                                                                                        >
                                                                                                                                                                            <span>Cadastrar Adicional</span>
                                                                                                                                                                        </div>
                                                                                                                                                                    </div>
                                                                                                                                                                </div>
                                                                                                                                                            ))}
                                                                                                                                                        </div>
                                                                                                                                                    )}
                                                                                                                                                </div>
                                                                                                                                            );
                                                                                                                                        })
                                                                                                                                    )
                                                                                                                            }
                                                                                                                        </div>
                                                                                                                    </SmoothShowAndHideDropdownItens>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        )}
                                                                                                    </Draggable>
                                                                                                ))}
                                                                                            {/* Esta parte é para verificar se há itens na categoria */}
                                                                                            {arrayItens.filter((item) => item.category_item_id === categoria._id).length === 0 && (
                                                                                                <div className="nenhumItem">{categoria.modelo?.value === 2 ? "Nenhum tamanho cadastrado" : "Nenhum item cadastrado"}</div>
                                                                                            )}
                                                                                        </div>
                                                                                        {provided.placeholder}
                                                                                    </SmoothShowAndHideDropdown>
                                                                                )}
                                                                            </Droppable>
                                                                        </DragDropContext>


                                                                        <button type="button" className="buttonAddHorarios" onClick={() => handleCadastroItem(categoria)}
                                                                            style={{ fontWeight: 700, fontSize: 15, display: "flex", alignItems: "center", gap: 3, marginTop: 10 }}>
                                                                            <BsPlusCircle style={{ strokeWidth: 1, fontSize: 15, height: 20 }} />{categoria.modelo?.value === 2 ? "Adicionar Tamanho" : "Adicionar Item"}
                                                                        </button>
                                                                    </div>
                                                                </div>


                                                            )}

                                                        </Draggable>


                                                    ))}
                                                    {provided.placeholder}
                                                </div>
                                            )}
                                        </Droppable>
                                    </DragDropContext>
                                </>
                            }
                        </div>

                    </div>
                </Teste>

            </PermissionGate>
        </>


    );
};


export default ListCategoria;