import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import './style.css'
import { SidebarContext } from "../../AppRoutes";
import PermissionGate from "../../services/PermissionGate";
import LeftMenu from "../../components/LeftMenu";
import styled from 'styled-components';
import QRCode from 'qrcode.react';
import { LuPlusCircle } from "react-icons/lu";
import * as AiIcons from 'react-icons/ai'
import * as SlIcons from 'react-icons/sl'
import { FaRegTrashAlt } from "react-icons/fa";
import CryptoJS from 'crypto-js';
import { updateTempoHorarioFuncionamento, getEmpresaWithObjId } from "../../services/api";
import { toast } from "react-toastify";
import axios from "axios";
const Teste = styled.div`

    display: flex;
    margin-left:  ${({ sidebar }) => (sidebar ? '250px' : '100px')}; 
    height:auto;
    width:auto;
    transition: 150ms;
    background-color:rgb(247,247,247)!important;
    overflow: initial;
    z-Index: 9;

    @media (max-width: 880px) {
        margin-left: 0;
    }
`;


const HorarioFuncionamento = () => {

    const secretKey = 'my-custom-secret-key:1af0addcce4b56f753ed10c285667ad031bae57af19747f576b05312b8cf85be';
    const userEncrypted = localStorage.getItem('user')
    const user = CryptoJS.AES.decrypt(userEncrypted, secretKey).toString(CryptoJS.enc.Utf8);
    const userParse = JSON.parse(user)
    const userID = userParse._id;
    const empresa = localStorage.getItem('empresa')
    const empresaParse = JSON.parse(empresa)
    const idEmpresa = empresaParse.id_empresa;
    const empresaObjId = empresaParse._id;

    const { sidebar, setSidebar } = useContext(SidebarContext);; //TESTANDO CONST NO AppROUTES
    const [isSubmitting, setIsSubmitting] = useState(false);
    const navigate = useNavigate();

    const [availability, setAvailability] = useState('especifico'); // 'especifico' é o valor padrão
    const [activeDays, setActiveDays] = useState(new Set());
    // Estado inicial agora inclui o horário padrão para cada dia
    const initialTimeRange = { start: '08:00', end: '18:00' };
    const initialTimeRanges = {
        sunday: [initialTimeRange],
        monday: [initialTimeRange],
        tuesday: [initialTimeRange],
        wednesday: [initialTimeRange],
        thursday: [initialTimeRange],
        friday: [initialTimeRange],
        saturday: [initialTimeRange],
    };
    const [timeRanges, setTimeRanges] = useState(initialTimeRanges);
    const [timeZones, setTimeZones] = useState([]);
    const [selectedTimeZone, setSelectedTimeZone] = useState('');

    useEffect(() => {
       // Função para buscar os dados do banco de dados
        const fetchData = async () => {
            try {
            // Supondo que getEmpresaWithObjId seja uma função que retorna uma Promise
            const empresaTemp = await getEmpresaWithObjId(empresaObjId);
            console.log(empresaTemp);
            if (empresaTemp && empresaTemp.data.empresa.horario_funcionamento) {
                // Configura o estado com os dados recebidos
                setTimeRanges(empresaTemp.data.empresa.horario_funcionamento);
                
                // Configura os dias ativos baseando-se nas chaves do objeto retornado
                const daysActive = new Set(Object.keys(empresaTemp.data.empresa.horario_funcionamento));
                setActiveDays(daysActive);
            } 
            
            if(empresaTemp && empresaTemp.data.empresa.status_loja){
                // Configura o estado de disponibilidade baseado no status da loja
                console.log(empresaTemp.data.empresa.status_loja)
                setAvailability(empresaTemp.data.empresa.status_loja);
            }

            if (empresaTemp && empresaTemp.data.empresa.timezone) {
                setSelectedTimeZone(empresaTemp.data.empresa.timezone);
            }

            else {
                setSelectedTimeZone('America/Sao_Paulo'); // Define o padrão caso não haja timezone especificado
                // Se não receber dados válidos, mantém os valores padrões
                // Você pode querer mostrar um aviso ou tratar esse caso conforme sua lógica de negócio
            }
            } catch (error) {
            console.error("Erro ao buscar dados da empresa:", error);
            // Lidar com o erro, talvez definindo algum estado de erro na UI
            }
        };

        fetchData();
    }, []); // O array vazio [] indica que o useEffect será executado uma vez após a montagem do componente

    useEffect(() => {
        const fetchTimeZones = async () => {
            try {
                const response = await axios.get('https://api.timezonedb.com/v2.1/list-time-zone', {
                    params: {
                        key: '7UIGZ1GOJE0U',
                        format: 'json',
                        country: 'BR'
                    }
                });
                setTimeZones(response.data.zones);
                // Verifica se "America/Sao_Paulo" está na lista recebida, se não, ajusta para o primeiro da lista
                //const saoPauloZone = response.data.zones.find(zone => zone.zoneName === 'America/Sao_Paulo');
                //if (!saoPauloZone && response.data.zones.length > 0) {
                //    setSelectedTimeZone(response.data.zones[0].zoneName);
                //}
            } catch (error) {
                console.error('Erro ao buscar fusos horários', error);
                // Lidar com erro, talvez definindo algum estado de erro na UI
            }
        };

        fetchTimeZones();
    }, []);

    

    const handleBack = () => {
        //setSidebar(!sidebar)
        navigate("/");
    }

    // Atualiza o estado com base na seleção do rádio
    const handleAvailabilityChange = (event) => {
        setAvailability(event.target.value);
    };

    // Adiciona ou remove o dia do conjunto de dias ativos
    const toggleDay = (day) => {
        setActiveDays((prevActiveDays) => {
          const newActiveDays = new Set(prevActiveDays);
          if (newActiveDays.has(day)) {
            newActiveDays.delete(day);
          } else {
            newActiveDays.add(day);
            if (!timeRanges[day] || timeRanges[day].length === 0) {
              addTimeRange(day);
            }
          }
          return newActiveDays;
        });
    };

    
    // Manipula a mudança de horários para cada dia
    const handleTimeChange = (day, index, field, value) => {
        setTimeRanges((prevTimeRanges) => {
            const updatedRanges = [...prevTimeRanges[day]];
            const updatedTimeRange = { ...updatedRanges[index], [field]: value };
            updatedRanges[index] = updatedTimeRange;
            return {
                ...prevTimeRanges,
                [day]: updatedRanges,
            };
        });
    };

    // Adiciona um novo intervalo de horários para um dia
    const addTimeRange = (day) => {
        setTimeRanges((prevTimeRanges) => {
          const newRanges = prevTimeRanges[day] ? [...prevTimeRanges[day]] : [];
          newRanges.push({ start: '08:00', end: '18:00' });
          return {
            ...prevTimeRanges,
            [day]: newRanges,
          };
        });
    };

    const removeTimeRange = (day, index) => {
        setTimeRanges((prevTimeRanges) => {
            const updatedRanges = [...prevTimeRanges[day]]; // cria uma cópia do array de horários
            updatedRanges.splice(index, 1); // remove o intervalo no índice especificado
            return {
                ...prevTimeRanges,
                [day]: updatedRanges,
            };
        });
    };


    const mapDayToFull = (day) => {
        switch (day) {
            case 'domingo':
                return 'Domingo';
            case 'segunda':
                return 'Segunda';
            case 'terca':
                return 'Terça';
            case 'quarta':
                return 'Quarta';
            case 'quinta':
                return 'Quinta';
            case 'sexta':
                return 'Sexta';
            case 'sabado':
                return 'Sábado';
        }
    };
    const mapDayToOneChar = (day) => {
        switch (day) {
            case 'domingo':
                return 'D';
            case 'segunda':
                return 'S';
            case 'terca':
                return 'T';
            case 'quarta':
                return 'Q';
            case 'quinta':
                return 'Q';
            case 'sexta':
                return 'S';
            case 'sabado':
                return 'S';
        }
    };

    const saveSchedule = async () => {
        const activeTimeRanges = {};
        
        daysOfWeek.forEach((day) => {
          // Supondo que daysOfWeek seja 'DOM', 'SEG', etc., e que você tenha um mapeamento para os nomes completos
          // Se daysOfWeek já estiver no formato correto, ajuste conforme necessário
          //const dayKey = mapDayToKey(day); // Função para mapear 'DOM' para 'domingo', por exemplo "Comentei pois irei gravar no banco 'DOM' mesmo

          // Verifica se o dia atual está ativo
          if (activeDays.has(day)) {
            const dayRanges = timeRanges[day];
            // Se dayRanges for undefined, evita a chamada de .filter com um operador condicional
            if (dayRanges) {
              const filteredRanges = dayRanges;
      
              if (filteredRanges.length > 0) {
                activeTimeRanges[day] = filteredRanges;
              }
            }
          }
        });
        try{
            if(availability==='sempre'){
                const status_loja = availability;
                const horario_funcionamento = {};
                const timezone = selectedTimeZone; // Supondo que selectedTimeZone é o estado que guarda o fuso horário selecionado
                const response = await updateTempoHorarioFuncionamento(userID, empresaObjId, idEmpresa, status_loja, horario_funcionamento, timezone)
                if(response){
                    console.log("Horário de funcionamento update>", response);
                    toast.success(response.data.msg);
                }
            }
            else if (availability==='especifico'){
                const status_loja = availability;
                const horario_funcionamento = activeTimeRanges;
                const timezone = selectedTimeZone; // Supondo que selectedTimeZone é o estado que guarda o fuso horário selecionado
                const response = await updateTempoHorarioFuncionamento(userID, empresaObjId, idEmpresa, status_loja, horario_funcionamento, timezone);    
                if(response){
                    console.log("Horário de funcionamento update>", response);
                    toast.success(response.data.msg);
                }
            }
        }catch(error){
            toast.error("Erro ao atualizar horário de funcionamento");
        }
        //console.log(activeTimeRanges);
    };

    const daysOfWeek = ['domingo', 'segunda', 'terca', 'quarta', 'quinta', 'sexta', 'sabado'];
    
    return (
        <>
        <PermissionGate permissions={['default']}>
    

            {/*<LeftMenu setSidebar={setSidebar} sidebar={sidebar} />*/}

            <Teste sidebar={sidebar}>
                <div className="w-100 p-4">
                    <form >
                    
                        <div className="form-header" style={{marginBottom:"0px"}}>
                            <div className="title">
                                <h1>Horário de Funcionamento</h1>
                            </div>
                        </div>

                        <div style={{display:"flex", justifyContent:"right"/*, height:"80px"*/}}>

                            <div className="div-buttons">
                                <div className="continue-button">                           
                                    <button type="button" onClick={saveSchedule} disabled={isSubmitting}>
                                        <AiIcons.AiOutlineSave style={{marginRight: "5px",fontSize: "22px",marginBottom: "2px"}}/><a>Salvar</a> 
                                    </button>                            
                                </div>

                                <div className="back-button">                                                       
                                    <button onClick={handleBack}>
                                        <SlIcons.SlActionUndo style={{color:"#ff4c4c",marginRight: "5px",fontSize: "18px",marginBottom: "2px"}}/><a >Voltar</a> 
                                    </button>
                                </div>
                            </div>

                        </div>

                        <div className="contentItemComplete flex-column flex-md-row">
                            {/*<div className="input-group inputGroup-etapasItem w-100" style={{ maxWidth: "450px", maxHeight:50 }}> 
                                <div className="etapasAddItem etapaAtiva">
                                    <label>1. Definir Horário</label>
                                </div>                       
                            </div>*/}
                        
                            <div className="input-group inputGroup-adicinaItem mh-100">                               
                                
                                <div className="formGroupRow">
                                                                    
                                    <div className="schedule-settings">
                                        <h3>Horários</h3>
                                        <div className="timezone-select">
                                            <label htmlFor="timezone">Fuso horário *</label>
                                            <select
                                                id="timezone"
                                                name="timezone"
                                                value={selectedTimeZone}
                                                onChange={e => setSelectedTimeZone(e.target.value)}
                                            >
                                                {timeZones.map((zone) => (
                                                    <option key={zone.zoneName} value={zone.zoneName}>
                                                        {zone.zoneName.replace('_', ' ')}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="availability-options">
                                            <div className="option">
                                                <input type="radio" id="alwaysAvailable" name="availability" value="sempre" 
                                                checked={availability === 'sempre'}
                                                onChange={handleAvailabilityChange}/>
                                                <label htmlFor="alwaysAvailable">Sempre disponível</label>
                                            </div>

                                            <div className="option">
                                                <input type="radio" id="specificTimes" name="availability" value="especifico" 
                                                checked={availability === 'especifico'}
                                                onChange={handleAvailabilityChange}/>
                                                <label htmlFor="specificTimes">Disponível em dias e horários específicos</label>
                                            </div>
                                        </div>
                                        {availability === 'especifico' ?
                                            <>
                                                <div className="day-selector">
                                                    <label>Dias da semana *</label>
                                                    <div className="days">
                                                        {daysOfWeek.map((day) => (
                                                        <div
                                                            //type="button"
                                                            key={day}
                                                            className={activeDays.has(day) ? 'custom-checkbox checked' : 'custom-checkbox'}
                                                            onClick={() => toggleDay(day)}
                                                        >
                                                            {activeDays.has(day) ? <p className="fas fa-check">{mapDayToOneChar(day)}</p>: <p>{mapDayToOneChar(day)}</p>}
                                                        </div>
                                                        ))}                                                
                                                    </div>
                                                </div>                                        
                                                {daysOfWeek.map((day) => (
                                                activeDays.has(day) && (
                                                    <div key={day} className="divInputsFuncionamento">
                                                        <h5>{mapDayToFull(day)} *</h5>
                                                        {Array.isArray(timeRanges[day]) && timeRanges[day].map((range, index) => (
                                                            <div key={index} className="timeInputFuncionamento">
                                                                {/* Inputs e botão de remover aqui */}
                                                                <input
                                                                    type="time"
                                                                    value={range.start || '08:00'} // Garante um valor padrão
                                                                    style={{padding:5}}                                                       
                                                                    onChange={(e) =>
                                                                        handleTimeChange(day, index, 'start', e.target.value)
                                                                    }
                                                                />
                                                                <span>às</span>
                                                                <input
                                                                    type="time"
                                                                    value={range.end || '18:00'} // Garante um valor padrão
                                                                    style={{padding:5}}                                                           
                                                                    onChange={(e) =>
                                                                        handleTimeChange(day, index, 'end', e.target.value)
                                                                    }
                                                                />
                                                                {index === 0 ? 
                                                                    null
                                                                    :
                                                                    <div className="btnRemoveHorarioFuncionamento" onClick={() => removeTimeRange(day, index)}>
                                                                        <FaRegTrashAlt /><i>Remover</i>
                                                                    </div>
                                                                }     
                                                            </div>
                                                        ))}
                                                        <div className="btnAddHorarioFuncionamento" onClick={() => addTimeRange(day)}>
                                                            {/* Botão de adicionar horário aqui */}
                                                            <LuPlusCircle /><span>Adicionar Horário</span>
                                                        </div>
                                                    </div>
                                                )
                                                ))}
                                                {/*<button type="button" onClick={saveSchedule}>Salvar Horários</button>*/}
                                            </>
                                            :
                                            null
                                        }                                        
                                        
                                    </div>

                                </div>                           

                            </div>

                            

                        </div>

                    </form>
                </div>
            </Teste>
                
        </PermissionGate>       
        </>
    )
}

export default HorarioFuncionamento;