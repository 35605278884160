import React, { useState, useEffect } from 'react';
import logoPedeJA from '../../img/logoPedeJA.png';
import './styles.css';
import { useNavigate } from 'react-router-dom';
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import InputMask from 'react-input-mask';
import Loading from 'react-loading';
import cep from 'cep-promise'
import { createEmpresaUser, getEstados, getMunicipios, importCardapioAnotaai, importCardapioIfood } from '../../services/api';
import { FaSortNumericDownAlt } from 'react-icons/fa';

const Endereco = () => {
    const location = useLocation();
    const { infoEmpresaObj, passwordObj, linkObj } = location.state || {};
    //console.log(infoEmpresaObj);

    const navigate = useNavigate();
    const [optSelected, setOptSelected] = useState('endereco');
    const [expandEndereco, setExpandEndereco] = useState(false);
    const [isSearching, setIsSearching] = useState(false);

    const [ufs, setUfs] = useState([]);
    const [cities, setCities] = useState('');

    const [cepNumber, setCepNumber] = useState('');
    const [estado, setEstado] = useState("");
    const [municipio, setMunicipio] = useState('');
    const [bairro, setBairro] = useState('');
    const [logradouro, setLogradouro] = useState('');
    const [loading, setLoading] = useState(false); // Estado para bloquear o botão

    useEffect(() => {
        (async () => {

            const response = await getEstados();
            //console.log("ESTADOS:::",response)
            //const teste = [response.data]
            setUfs(response.data);
            //setLoading(false);

        })();
    }, []);

    useEffect(() => {
        getMunicipios(estado).then((response) => {
            //console.log("MUNICIPIOS:::",response);
            setCities(response.data);
        })
    }, [estado]);

    const handleOptSelected = (opt) => {
        setOptSelected(opt);

        if (opt === "info") {
            navigate("/informacoes", { state: { infoEmpresaObj, passwordObj, linkObj } });
        }
        if (opt === "credential") {
            navigate("/credenciais", { state: { infoEmpresaObj, passwordObj, linkObj } });
        }
        if (opt === "import") {
            navigate("/integracao", { state: { infoEmpresaObj, passwordObj, linkObj } });
        }
    }

    //Validações
    const SignUpSchema = Yup.object().shape({
        cep: Yup.string().matches(/^\d{5}-\d{3}$/, 'CEP inválido').required('Campo obrigatório'),
        estado: Yup.string().required('Campo obrigatório'),
        municipio: Yup.string().required('Campo obrigatório'),
        bairro: Yup.string().required('Campo obrigatório'),
        logradouro: Yup.string().required('Campo obrigatório'),
        numero: Yup.string().required('Campo obrigatório'),
        complemento: Yup.string(),
    });

    const formik = useFormik({
        validationSchema: SignUpSchema,
        validateOnBlur: false,
        validateOnChange: false,
        //enableReinitialize: true,
        initialValues: {
            cep: "",
            estado: "",
            municipio: "",
            bairro: "",
            logradouro: "",
            numero: "",
            complemento: "",
            //password: passwordObj?passwordObj.password:"",
            //confirmPassword: passwordObj?passwordObj.confirmPassword:"",
        },
        onSubmit: (values) => {

            const cep = values.cep;
            const estado = values.estado;
            const municipio = values.municipio;
            const bairro = values.bairro;
            const logradouro = values.logradouro;
            const numero = values.numero;
            const complemento = values.complemento;

            //navigate("/integracao", {state: {infoEmpresaObj, passwordObj}});

            handleSubmitAPI(cep, estado, municipio, bairro, logradouro, numero, complemento);

        },
    });

    /*const importaCardapioIfood = async (ifoodLinkToSend) => {
        const response = await importCardapioIfood(ifoodLinkToSend);
        //console.log(response);
        toast('Importação realizada com sucesso!', {autoClose:5000, type: 'success'});
    };*/

    /*
    const importaCardapioAnotaai = async (anotaAiLinkToSend) => {
        //console.log("anotaAiLinkToSend>",anotaAiLinkToSend.toString())
        const response = await importCardapioAnotaai(anotaAiLinkToSend);
        //console.log(response);
        toast('Importação realizada com sucesso!', {autoClose:5000, type: 'success'});
    };*/

    const handleSubmitAPI = async (cep, estado, municipio, bairro, logradouro, numero, complemento) => {
        if (loading) return; // Evita chamadas duplicadas

        setLoading(true); // Desativa o botão

        try {
            const nome_empresa = infoEmpresaObj.establishmentName;
            const cpf_cnpj = infoEmpresaObj.cpf_cnpj;
            const hasComputer = infoEmpresaObj.hasComputer;
            const email = infoEmpresaObj.email;
            const telefone = infoEmpresaObj.phone;
            const nome_pessoa = infoEmpresaObj.personName;
            const password = passwordObj.password;
            const confirmPassword = passwordObj.confirmPassword;

            const type = "Empresa";
            const vinculo = cpf_cnpj;

            // Registrando a empresa
            const response = await createEmpresaUser(
                cpf_cnpj, nome_empresa, email, cep, estado, municipio,
                bairro, logradouro, numero, complemento, telefone, type,
                hasComputer, nome_pessoa, password, confirmPassword, vinculo
            );

            if (response.status === 201) {
                toast(response.data.msg, { autoClose: 5000, type: "success" });

                // 🔹 Disparando evento do Meta Pixel após cadastro bem-sucedido
                if (window.fbq) {
                    window.fbq('track', 'Lead');
                }

                if (linkObj) {
                    if (linkObj.importType === 'linkIfood') {
                        importCardapioIfood(linkObj.importLink, response.data.seqIdEmpresa);
                    } else if (linkObj.importType === 'linkAnotaAi') {
                        importCardapioAnotaai(linkObj.importLink, response.data.seqIdEmpresa);
                    }
                }
                navigate("/");
            } else {
                toast(response.data.msg, { autoClose: 5000, type: "error" });
            }
        } catch (err) {
            /*console.error('Error:', err);
            const msg = err.response?.data?.msg || 'Erro desconhecido';
            toast(msg, { autoClose: 5000, type: "error" });*/
            console.error('Erro na API:', err);

            // 🔹 Capturando apenas a mensagem de erro
            const msg = err.response?.data?.msg || "Erro desconhecido";

            toast.error(`Erro: ${msg}`, { autoClose: 5000 });
        } finally {
            setLoading(false); // Reativa o botão ao final da requisição
        }
    };

    const handleSearchCep = (cepToSearch) => {
        setIsSearching(true);
        //console.log("cepToSearch",cepToSearch.length)
        if (cepToSearch.length === 9) {
            cep(cepToSearch).then(response => {
                //console.log(response)      
                //idEstado.value = response.state
                //setEstado(idEstado.value)
                setEstado(response.state)
                formik.setFieldValue('estado', response.state)
                setMunicipio(response.city)
                formik.setFieldValue('municipio', response.city)
                setBairro(response.neighborhood)
                formik.setFieldValue('bairro', response.neighborhood)
                setLogradouro(response.street)
                formik.setFieldValue('logradouro', response.street)
                //console.log(estado);
            }).catch(error => {
                //console.log(error);
            }).finally(() => {
                setIsSearching(false);
                setExpandEndereco(true);
            });

        }

    }

    return (
        <div className='containerAddEmpresa'>
            <div className='divSidePanel'>
                <div className='divSidePanelContent'>
                    <div className='divLogo'>
                        <img width={140} height={42} src={logoPedeJA} alt='logoPedeJA' />
                    </div>
                    <div className='divMenu'>
                        <div className='divMenuItem'>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                {optSelected === "info" ? <div className='menuItemSelected' /> : null}
                                <p className={optSelected === "info" ? 'btnOptionsAddEmpresa checked' : "btnOptionsAddEmpresa"} onClick={() => handleOptSelected("info")}>1. Informações da Conta</p>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                {optSelected === "credential" ? <div className='menuItemSelected' /> : null}
                                <p className={optSelected === "credential" ? 'btnOptionsAddEmpresa checked' : "btnOptionsAddEmpresa"} onClick={() => handleOptSelected("credential")}>2. Senha</p>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                {optSelected === "import" ? <div className='menuItemSelected' /> : null}
                                <p className={optSelected === "import" ? 'btnOptionsAddEmpresa checked' : "btnOptionsAddEmpresa"} onClick={() => handleOptSelected("import")}>3. Importar Cardápio</p>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                {optSelected === "endereco" ? <div className='menuItemSelected' /> : null}
                                <p className={optSelected === "endereco" ? 'btnOptionsAddEmpresa checked' : "btnOptionsAddEmpresa"} onClick={() => handleOptSelected("endereco")}>4. Endereço</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <div className="containerAddEmpresaForm">
                {loading ?
                    <div className='waitingFinishFormEmpresa'>
                        <span>Aguarde, finalizando cadastro...</span>
                        <Loading type='spin' fill='black' style={{ color: 'black', width: '50px' }} />
                    </div>
                    : null
                }
                <form onSubmit={formik.handleSubmit} className="formAddEmpresa">

                    <div className='informationsAccount'>

                        <div style={styles.container}>
                            <h1 style={styles.header}>Endereço.</h1>
                            <p style={styles.description}>
                                Pronto! Me conte agora qual o seu endereço do seu <span style={{ fontWeight: '900', fontSize: 15, textDecoration: 'underline' }}>Delivery</span>
                            </p>
                            <div style={styles.inputGroup}>
                                <label style={styles.label} htmlFor="cep">CEP *</label>
                                <InputMask style={styles.input} type="text"
                                    id="cep"
                                    name="cep"
                                    mask="99999-999"
                                    placeholder="_____-___"
                                    onChange={(e) => {
                                        //setCpfCnpjValue(e.currentTarget.value);
                                        const matchedNums = e.currentTarget.value.match(/\d/g);
                                        const numLength = matchedNums ? matchedNums.length : 0;
                                        //const numLength = e.currentTarget.value!==null && e.currentTarget.value.match(/\d/g).length;
                                        numLength === 8 && handleSearchCep(e.target.value);
                                        formik.setFieldValue('cep', e.currentTarget.value);
                                        setCepNumber(e.target.value);
                                    }}
                                    value={formik.values.cep} />
                                {formik.errors.cep && (
                                    <div className="error">{formik.errors.cep}</div>
                                )}
                            </div>

                            {isSearching &&
                                <Loading style={{ fill: 'gray', height: '32px', width: '32px' }} />
                            }

                            {expandEndereco &&
                                <>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <div style={styles.inputGroup}>
                                            <label style={styles.label} htmlFor="cep">Estado *</label>
                                            {/*<!-- Custom select structure --> */}
                                            <div className="selectdiv estadoPersonalized" style={{ width: '260px' }}>
                                                <label style={{ width: "100%" }}>
                                                    <select
                                                        id="estado"
                                                        type="text"
                                                        name="estado"
                                                        onChange={(e) => {
                                                            setEstado(e.currentTarget.value)
                                                            formik.setFieldValue('estado', e.currentTarget.value);
                                                        }}
                                                        value={formik.values.estado}
                                                        className={estado === "" ? 'invalid-option' : 'beleza'}
                                                        style={{
                                                            width: "100%", color: "black", fontWeight: "400", fontSize: "16px",
                                                            border: "1px solid rgb(204, 204, 204)", borderRadius: "4px", boxShadow: 'none', padding: "0px 24px 0px 10px"
                                                        }}
                                                    >
                                                        <option value="" /*className="invalid-option"*/>Escolha o Estado</option>
                                                        {ufs.map((uf) => (
                                                            <option key={uf.id} value={uf.sigla} style={{ color: "black" }}>
                                                                {uf.nome}
                                                            </option>
                                                        ))}

                                                    </select>
                                                </label>
                                            </div>
                                            {formik.errors.estado && (
                                                <div className="error">{formik.errors.estado}</div>
                                            )}
                                        </div>

                                        <div style={styles.inputGroup}>
                                            <label style={styles.label} htmlFor="cep">Município *</label>
                                            {/*<!-- Custom select structure --> */}
                                            <div className="selectdiv" style={{ width: '260px' }}>
                                                <label style={{ width: "100%" }}>
                                                    <select
                                                        id="municipio"
                                                        type="text"
                                                        name="municipio"
                                                        onChange={(e) => {
                                                            setMunicipio(e.currentTarget.value)
                                                            formik.setFieldValue('municipio', e.currentTarget.value);
                                                        }}
                                                        value={formik.values.municipio}
                                                        className={municipio === "" ? 'invalid-option' : 'beleza'}
                                                        style={{
                                                            width: "100%", color: "black", fontWeight: "400", fontSize: "16px",
                                                            border: "1px solid rgb(204, 204, 204)", borderRadius: "4px", boxShadow: 'none', padding: "0px 24px 0px 10px"
                                                        }}
                                                    >
                                                        <option value="" /*className="invalid-option"*/>Escolha o Município</option>
                                                        {cities && cities.map((city) => (
                                                            <option key={city.id} value={city.nome} style={{ color: "black" }}>
                                                                {city.nome}
                                                            </option>
                                                        ))}

                                                    </select>
                                                </label>
                                            </div>
                                            {formik.errors.municipio && (
                                                <div className="error">{formik.errors.municipio}</div>
                                            )}
                                        </div>
                                    </div>

                                    <div style={{ justifyContent: 'space-between', display: 'flex' }}>
                                        <div style={styles.inputGroup} className='divBairro'>
                                            <label style={styles.label} htmlFor="bairro">Bairro *</label>
                                            <input style={styles.input} type="text"
                                                id="bairro"
                                                name="bairro"
                                                placeholder="digite o bairro"
                                                onChange={(e) => {
                                                    setBairro(e.currentTarget.value)
                                                    formik.setFieldValue('bairro', e.currentTarget.value);
                                                }}
                                                value={formik.values.bairro} />
                                            {formik.errors.bairro && (
                                                <div className="error">{formik.errors.bairro}</div>
                                            )}
                                        </div>

                                        <div style={styles.inputGroup} className='divLogradouro'>
                                            <label style={styles.label} htmlFor="logradouro">Logradouro *</label>
                                            <input style={styles.input} type="text"
                                                id="logradouro"
                                                name="logradouro"
                                                placeholder="Digite o logradouro"
                                                onChange={(e) => {
                                                    setLogradouro(e.currentTarget.value)
                                                    formik.setFieldValue('logradouro', e.currentTarget.value);
                                                }}
                                                value={formik.values.logradouro} />
                                            {formik.errors.logradouro && (
                                                <div className="error">{formik.errors.logradouro}</div>
                                            )}
                                        </div>
                                    </div>

                                    <div style={{ justifyContent: 'space-between', display: 'flex' }}>
                                        <div style={styles.inputGroup} className='divNumero'>
                                            <label style={styles.label} htmlFor="numero">Numero *</label>
                                            <input style={styles.input} type="text"
                                                id="numero"
                                                name="numero"
                                                placeholder="Ex: 12A"
                                                onChange={(e) => {
                                                    setBairro(e.currentTarget.value)
                                                    formik.setFieldValue('numero', e.currentTarget.value);
                                                }}
                                                value={formik.values.numero} />
                                            {formik.errors.numero && (
                                                <div className="error">{formik.errors.numero}</div>
                                            )}
                                        </div>

                                        <div style={styles.inputGroup} className='divComplemento'>
                                            <label style={styles.label} htmlFor="complemento">Complemento do endereço</label>
                                            <input style={styles.input} type="text"
                                                id="complemento"
                                                name="complemento"
                                                placeholder="Digite o complemento"
                                                onChange={(e) => {
                                                    setLogradouro(e.currentTarget.value)
                                                    formik.setFieldValue('complemento', e.currentTarget.value);
                                                }}
                                                value={formik.values.complemento} />
                                            {formik.errors.complemento && (
                                                <div className="error">{formik.errors.complemento}</div>
                                            )}
                                        </div>
                                    </div>

                                    <div style={styles.buttonGroup}>
                                        <button
                                            type="submit"
                                            style={styles.button}
                                            className='btnFwdCadastros'
                                            disabled={loading} // 🔹 Desativa o botão durante a requisição
                                        >
                                            {loading ? "Finalizando..." : "Finalizar Cadastro"}
                                        </button>
                                    </div>
                                </>
                            }

                        </div>

                    </div>

                </form>
            </div>



        </div>
    );
}


const styles = {
    container: {
        //maxWidth: '400px',
        minWidth: '600px',
        margin: '0 auto',
        padding: '20px',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
    },
    header: {
        fontSize: '24px',
        marginBottom: '10px',
    },
    description: {
        fontSize: '16px',
        marginBottom: '20px',
    },
    inputGroup: {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
    },
    label: {
        fontSize: '16px',
        fontWeight: 'bold',
    },
    input: {
        padding: '10px',
        fontSize: '16px',
        border: '1px solid #ccc',
        borderRadius: '4px',
    },
    buttonGroup: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    button: {
        padding: '10px 20px',
        fontSize: '16px',
        borderRadius: '4px',
        cursor: 'pointer',
    }
};


export default Endereco;

